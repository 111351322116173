import React from 'react';
import { FormattedMessage } from 'react-intl';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Widget } from '.';

const HardwareWidget = ({ smDown, cpu, ram, handleOpenHardwareDialog }) => (
  <Widget
    title={<FormattedMessage id='mainPage.hardwareInfo' defaultMessage='Hardware info' />}
    handleClick={handleOpenHardwareDialog}
  >
    <Table padding={smDown ? 'checkbox' : 'normal'}>
      <TableBody>
        <TableRow>
          <TableCell>
            <FormattedMessage id='mainPage.hardwareInfoCpu' defaultMessage='CPU' />
          </TableCell>
          <TableCell>{cpu}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <FormattedMessage id='mainPage.hardwareInfoRam' defaultMessage='RAM' />
          </TableCell>
          <TableCell>{ram}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </Widget>
);

export default HardwareWidget;
