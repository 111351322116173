import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import compose from 'recompose/compose';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import withWidth from '@material-ui/core/withWidth';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Fade from '@material-ui/core/Fade';
import Collapse from '@material-ui/core/Collapse';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import Checkbox from '@material-ui/core/Checkbox';
import CancelIcon from '@material-ui/icons/Cancel';

import { compareModules } from '../../util/modulesHelpers';
import ButtonWithProgress from '../ButtonWithProgress';
import {
  installUpdateMessages,
  getActiveVersions,
  getLatestVersions,
  isAnyUpdatable,
  isAnyInstallable,
  getModulesToInstall,
  getActiveModules,
} from '../../util/modulesHelpers';
import { modulesUploadsActions, infoActions } from '../../redux-stuff/actions';
import { getSize } from '../../util/commonHelpers';
import { getFirmwarePackage, getModulePackages, getBrokenPackages } from '../../util/semverUtils';

const styles = theme => ({
  AccordionlowerTopShadow: { '$paperRedecoration + &::before': { top: 0 } },

  paperRedecoration: {
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
  },
  grid: {
    display: 'grid',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
  },
  gridInner: {
    paddingLeft: '24px',
    paddingRight: '8px',
    '&:not(:last-child)': { borderBottom: '1px solid rgba(0, 0, 0, 0.12)' },
    overflowWrap: 'break-word',
    wordBreak: 'break-word',

    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'minmax(0, calc(60% - 10px)) 1fr auto',
    gridTemplateRows: 'minmax(48px, auto)',
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'minmax(0, calc(50% - 10px)) 1fr auto',
    },
    gridColumnGap: '10px',
  },
  gridInnerTextPadding: {
    padding: '8px 0',
  },
  gridInnerProgressbar: { gridColumn: '1 / -1', margin: '-4px -8px 0 -24px' },
  summary: {
    margin: 0,
  },
  container: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    paddingRight: '0 !important',
  },
  details: {
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  column30: {
    flexBasis: '30%',
    display: 'flex',
    alignItems: 'center',
  },
  column50: {
    flexBasis: '50%',
    display: 'flex',
    alignItems: 'center',
  },
  updateButton: {
    marginTop: -theme.spacing(1),
    marginBottom: -theme.spacing(1),
  },
  topContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  icon: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(1.5),
    [theme.breakpoints.only('xs')]: {
      marginRight: theme.spacing(1.5),
    },
  },
  iconButton: {
    visibility: 'hidden',
    'tr:hover &': {
      visibility: 'inherit',
    },
  },
  table: {
    tableLayout: 'fixed',
  },
  tableBody: {
    '& tr': {
      borderBottom: `1px solid ${theme.palette.borderColor}`,
    },
    '& tr:last-child': {
      borderBottom: 'none',
    },
  },
  tableRow: {
    display: 'flex',
    alignItems: 'center',
  },
  selectedRow: {
    backgroundColor: 'rgba(0, 0, 0, 0.16)',
    display: 'flex',
    alignItems: 'center',
  },
  activeModule: {
    backgroundColor: 'rgba(0, 0, 0, 0.06)',
  },
  cell: {
    borderBottom: 0,
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    minWidth: 0,
    '@media screen and (max-width: 350px)': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  buttonCell: {
    borderBottom: 0,
    minWidth: '48px',
  },
  flexBasis15: {
    flexBasis: '15%',
  },
  flexBasis20: {
    flexBasis: '20%',
  },
  flexBasis25: {
    flexBasis: '25%',
  },
  flexBasis30: {
    flexBasis: '30%',
  },
  flexBasis40: {
    flexBasis: '40%',
  },
  totalSize: {
    marginLeft: theme.spacing(3),
  },

  semverTooltipIcon: {
    marginLeft: '4px',
    color: theme.palette.icons.default,
  },
});

function mapBrokenPackageToDivList({ deps, conflicts, invalidDeps, invalidConflicts }) {
  let res = [];

  if (invalidDeps) {
    res = [
      <div key='invalid deps'>
        <FormattedMessage
          id='semver.modulesTableTooltip.depsParsingFailed'
          defaultMessage='Dependencies parsing failed'
        />
      </div>,
    ];
  }

  if (invalidConflicts) {
    res = [
      ...res,
      <div key='invalid conflicts'>
        <FormattedMessage
          id='semver.modulesTableTooltip.conflictsParsingFailed'
          defaultMessage='Conflicts parsing failed'
        />
      </div>,
    ];
  }

  if (deps) {
    let deps_ = deps && Object.entries(deps);
    let divs = deps_.map(([depName, { requiredRange, availableVersion }]) => {
      return (
        <div key={depName}>
          <FormattedMessage
            id='semver.modulesTableTooltip.missingDep'
            defaultMessage={`missing dep: {depName} {requiredRange}{availableVersion, select, true {. Installed version: {availableVersion}} other {}}`}
            values={{
              depName,
              requiredRange,
              availableVersion,
            }}
          />
        </div>
      );
    });

    res = [...res, ...divs];
  }

  if (conflicts) {
    let conflicts_ = Object.entries(conflicts);
    let divs = conflicts_.map(([conflictName, { conflictRange, conflictVersion }]) => {
      return (
        <div key={conflictName}>
          <FormattedMessage
            id='semver.modulesTableTooltip.conflict'
            defaultMessage={`conflict: {conflictName} {conflictRange}. Installed version: {conflictVersion}`}
            values={{
              conflictName,
              conflictRange,
              conflictVersion,
            }}
          />
        </div>
      );
    });
    res = [...res, ...divs];
  }

  return res;
}

class Modules extends React.Component {
  componentDidMount() {
    this.props.getModulesUploads();
    this.props.loadInfo();
  }

  interruptFirmwareUpload = id => {
    this.props.interruptModulesUpload(id);
  };

  getInterruptUploadHandler = id => () => this.interruptFirmwareUpload(id);

  static mapStateToProps = store => ({
    build: store.info.build,
  });

  render() {
    const {
      build,
      width,

      modulesUploads,
      updatingModules,
      modules,
      services,
      permissions,
      expanded,
      selectedList,

      handleUpdateAll,
      handleExpand,
      handleInstallUpdateClick,
      handleDeleteSelected,
      handleSetSelected,
      handleOpenMenu,
      clickItem,

      usage,
      // using BI but should probably rename
      BIImagesUploaded,

      classes,
    } = this.props;

    const moduleNames = [...new Set(modules.map(m => m.name))];

    const xs = width === 'xs';
    const mobile = xs || isMobile;

    const { w } = permissions;

    let activeVersions = getActiveVersions(services);
    let latestVersions = getLatestVersions(modules);

    let brokenPackages = {};
    if (build && modules && services) {
      let firmwarePackage = getFirmwarePackage(build);
      // wait until firmware loads
      if (firmwarePackage) {
        let activeModules = getActiveModules(modules, services, activeVersions);
        let modulePackages = getModulePackages(activeModules);
        let packages = {
          ...firmwarePackage,
          ...modulePackages,
        };
        brokenPackages = getBrokenPackages(packages);
      }
    }

    let updatesAvailable = isAnyUpdatable(activeVersions, latestVersions);
    let installsAvailable = isAnyInstallable(activeVersions, latestVersions);

    let allButtonListOfModulesToInstall =
      (updatesAvailable || installsAvailable) && getModulesToInstall(activeVersions, latestVersions, modules);

    let allButtonAction = 'update';
    if (installsAvailable && updatesAvailable) {
      allButtonAction = 'install/update';
    } else if (installsAvailable && !updatesAvailable) {
      allButtonAction = 'install';
    }

    return (
      <Fragment>
        <div className={classes.topContainer}>
          <div className={classes.totalSize}>
            {usage.total && usage.total.images && (
              <Typography>
                <FormattedMessage
                  id='modulesPage.totalSize'
                  defaultMessage='Total size: {totalSize}'
                  values={{ totalSize: getSize(usage.total.images.size) }}
                />
              </Typography>
            )}
          </div>
          <Collapse in={w && (updatesAvailable || installsAvailable)}>
            <Button variant='contained' onClick={handleUpdateAll(allButtonListOfModulesToInstall, allButtonAction)}>
              {installUpdateMessages.button[allButtonAction]}
            </Button>
          </Collapse>
        </div>
        <div>
          {!isEmpty(modulesUploads) && (
            <Paper className={classes.paperRedecoration}>
              <div className={classes.grid}>
                {modulesUploads.map(moduleUpload => {
                  const { id, info } = moduleUpload;
                  const uri = moduleUpload.meta.uri;
                  const progress = Math.floor((info.received / info.estimated) * 100);
                  const interruptUploadHandler = this.getInterruptUploadHandler(id);
                  const abortable = !moduleUpload.info.uploadFinished;

                  return (
                    <div className={classes.gridInner} key={id}>
                      <div>
                        <Typography className={classes.gridInnerTextPadding}>{uri}</Typography>
                      </div>

                      <div>
                        {info.uploadFinished ? (
                          <Typography>
                            <FormattedMessage id='inlineUploads.status.processing' defaultMessage='processing..' />
                          </Typography>
                        ) : (
                          <Typography>
                            <FormattedMessage
                              id='inlineUploads.status.uploading'
                              defaultMessage='{progress}% complete'
                              values={{ progress }}
                            />
                          </Typography>
                        )}
                      </div>

                      <div>
                        {w && abortable && (
                          <Tooltip
                            title={
                              <FormattedMessage
                                id='firmwarePage.firmwareUploads.abortTooltip'
                                defaultMessage='Abort upload'
                              />
                            }
                          >
                            <IconButton onClick={interruptUploadHandler}>
                              <CancelIcon className={isMobile ? '' : 'hover-button'} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>

                      <div className={classes.gridInnerProgressbar}>
                        <LinearProgress variant='determinate' value={progress} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </Paper>
          )}

          {moduleNames.sort().map(name => {
            const sortedModules = modules.filter(m => m.name === name).sort(compareModules);
            const moduleIds = sortedModules.map(m => m.id);
            const service = services.find(s => s.name === name) || {};

            let BIImageIsInstalling;
            if (BIImagesUploaded) {
              const BIImage = BIImagesUploaded.find(image => image.name === name);
              BIImageIsInstalling = BIImage && BIImage.installingPhase === 'INSTALLING';
            }
            const moduleIsUpdating = BIImageIsInstalling || sortedModules.some(m => updatingModules.includes(m.id));

            const activeVersion = { [name]: activeVersions[name] };
            const latestVersion = { [name]: latestVersions[name] };
            const updateAvailable = isAnyUpdatable(activeVersion, latestVersion);
            const installAvailable = isAnyInstallable(activeVersion, latestVersion);

            const activeModule = modules.find(m => m.id === activeVersion[name]);
            const fullVersionStr = activeModule ? `${activeModule.version}.${activeModule.build}` : '';

            const inactiveModuleIds = moduleIds.filter(id => id !== service.module);
            const someModulesSelected = inactiveModuleIds.some(id => selectedList.includes(id));
            const allModulesSelected = inactiveModuleIds.every(id => selectedList.includes(id));
            const selectedModuleIds = inactiveModuleIds.filter(id => selectedList.includes(id));
            const selectedLength = selectedModuleIds.length;

            let issueRows = brokenPackages[name] ? mapBrokenPackageToDivList(brokenPackages[name]) : [];

            return (
              <Accordion key={name} onChange={handleExpand(name)} className={classes.AccordionlowerTopShadow}>
                <AccordionSummary
                  classes={{ content: classes.summary }}
                  expandIcon={
                    <Tooltip title={<FormattedMessage id='modulesPage.showHide' defaultMessage='Show/Hide' />}>
                      <ExpandMoreIcon />
                    </Tooltip>
                  }
                >
                  <div className={classes.container}>
                    <div className={xs ? classes.column50 : classes.column30}>
                      <Typography className={classes.heading}>{name}</Typography>
                      {!!issueRows.length && (
                        <Tooltip title={issueRows}>
                          <CancelIcon className={classes.semverTooltipIcon} />
                        </Tooltip>
                      )}
                    </div>

                    {!xs && (
                      <div className={classes.column30}>
                        <Fade in={!expanded.includes(name)}>
                          <Typography className={classes.heading}>{fullVersionStr}</Typography>
                        </Fade>
                      </div>
                    )}

                    <div className={xs ? classes.column50 : classes.column30}>
                      {w && (updateAvailable || installAvailable) && (
                        <Fade in={!expanded.includes(name)} unmountOnExit>
                          <ButtonWithProgress showProgress={moduleIsUpdating}>
                            <Button
                              onClick={handleInstallUpdateClick(name, updateAvailable ? 'update' : 'install')}
                              color='primary'
                              disabled={moduleIsUpdating}
                              className={classes.updateButton}
                            >
                              {updateAvailable && <FormattedMessage id='modulesPage.update' defaultMessage='Update' />}
                              {installAvailable && (
                                <FormattedMessage id='modulesPage.install' defaultMessage='Install' />
                              )}
                            </Button>
                          </ButtonWithProgress>
                        </Fade>
                      )}
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <Table className={classes.table}>
                    {!mobile && (
                      <TableHead>
                        <TableRow className={classes.tableRow} selected={someModulesSelected}>
                          <TableCell padding='checkbox' className={classNames(classes.flexBasis30, classes.cell)}>
                            {inactiveModuleIds.length > 0 && (
                              <Fragment>
                                <Checkbox
                                  checked={allModulesSelected}
                                  indeterminate={!allModulesSelected && someModulesSelected}
                                  onChange={handleSetSelected([
                                    ...selectedList.filter(id => !moduleIds.includes(id)),
                                    ...(allModulesSelected ? [] : inactiveModuleIds),
                                  ])}
                                  color='primary'
                                />
                                {selectedLength > 0 ? (
                                  <FormattedMessage
                                    id='modulesPage.tableHead.selectedCount'
                                    defaultMessage='{selectedLength} selected'
                                    values={{ selectedLength }}
                                  />
                                ) : (
                                  ''
                                )}
                              </Fragment>
                            )}
                          </TableCell>
                          <TableCell className={classNames(classes.flexBasis20, classes.cell)}>
                            <FormattedMessage id='modulesPage.tableHead.version' defaultMessage='Version' />
                          </TableCell>
                          <TableCell className={classNames(classes.flexBasis20, classes.cell)}>
                            <FormattedMessage id='modulesPage.tableHead.build' defaultMessage='Build' />
                          </TableCell>
                          {!mobile && (
                            <TableCell className={classNames(classes.flexBasis15, classes.cell)}>
                              <FormattedMessage id='modulesPage.tableHead.uniqueSize' defaultMessage='Unique size' />
                            </TableCell>
                          )}
                          {!mobile && (
                            <TableCell
                              padding='none'
                              align='right'
                              className={classNames(classes.flexBasis15, classes.buttonCell)}
                            >
                              {selectedLength > 0 &&
                                (selectedLength === 1 ? (
                                  <IconButton onClick={handleOpenMenu(selectedModuleIds[0])}>
                                    <MoreVertIcon />
                                  </IconButton>
                                ) : (
                                  <IconButton onClick={handleDeleteSelected(selectedModuleIds)}>
                                    <DeleteIcon />
                                  </IconButton>
                                ))}
                            </TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                    )}
                    <TableBody className={classes.tableBody}>
                      {sortedModules.map(m => {
                        const moduleIsActive = service.module === m.id;
                        const moduleIsUpdating = updatingModules.includes(m.id);
                        const isSelected = selectedList.includes(m.id);
                        const { uniqueSize } = (usage.images && usage.images.find(img => img.id === m.id)) || {};
                        return (
                          <TableRow
                            key={m.id}
                            className={classNames(
                              moduleIsActive && (!mobile || !isSelected) && classes.activeModule,
                              isSelected && mobile ? classes.selectedRow : classes.tableRow
                            )}
                            hover
                            selected={isSelected}
                            onClick={mobile || !moduleIsActive ? clickItem(m, moduleIsActive) : null}
                          >
                            <TableCell
                              padding='checkbox'
                              className={classNames(
                                classes.mobile ? classes.flexBasis20 : classes.flexBasis30,
                                classes.cell
                              )}
                            >
                              {moduleIsActive && !moduleIsUpdating && <CheckIcon className={classes.icon} />}
                              {moduleIsUpdating && <CircularProgress size={24} className={classes.icon} />}
                              {!moduleIsUpdating && !moduleIsActive && (
                                <Checkbox checked={isSelected} color='primary' />
                              )}
                            </TableCell>
                            <TableCell
                              padding={mobile ? 'dense' : 'normal'}
                              className={classNames(mobile ? classes.flexBasis40 : classes.flexBasis20, classes.cell)}
                            >
                              {m.version}
                            </TableCell>
                            <TableCell
                              padding={mobile ? 'dense' : 'normal'}
                              className={classNames(mobile ? classes.flexBasis40 : classes.flexBasis20, classes.cell)}
                            >
                              {m.build}
                            </TableCell>
                            {!mobile && (
                              <TableCell className={classNames(classes.flexBasis15, classes.cell)}>
                                {getSize(uniqueSize)}
                              </TableCell>
                            )}
                            {!mobile && (
                              <TableCell
                                padding='none'
                                className={classNames(classes.flexBasis15, classes.buttonCell)}
                                align='right'
                              >
                                {selectedLength === 0 && (
                                  <IconButton className={classes.iconButton} onClick={handleOpenMenu(m.id)}>
                                    <MoreVertIcon />
                                  </IconButton>
                                )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </Fragment>
    );
  }
}

Modules.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  withWidth(),
  connect(Modules.mapStateToProps, { ...modulesUploadsActions, ...infoActions })
)(Modules);
