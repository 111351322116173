import { Button, ButtonProps } from '@material-ui/core';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import ButtonWithProgress from '@/components/ButtonWithProgress';

interface ActionButtonsProps {
  show: boolean;
  allowApply: boolean;
  inProgress: boolean;
  handleDiscard(): void;
  handleSave(): void;
  saveButtonText: ButtonProps['children'];
}

const ActionButtons: FC<ActionButtonsProps> = ({
  show,
  allowApply,
  inProgress,
  handleDiscard,
  handleSave,
  saveButtonText,
}) => {
  if (!show) return null;

  return (
    <>
      <Button color='primary' disabled={inProgress} onClick={handleDiscard}>
        <FormattedMessage id='vtunPanel.discard' defaultMessage='Discard' />
      </Button>
      <ButtonWithProgress showProgress={inProgress}>
        <Button color='primary' disabled={!allowApply || inProgress} onClick={handleSave}>
          {saveButtonText}
        </Button>
      </ButtonWithProgress>
    </>
  );
};

export default ActionButtons;
