import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isEqual } from 'lodash';
import { isIp, isValid, isIpv4, isIpv6, isMulticastRange } from './ip';
import { DEFAULT_GATEWAY, DEFAULT_MULTICAST_GATEWAY } from '../redux-stuff/constants';

const getNumberFromName = name => {
  const numberStartIndex = name.length - [...name].reverse().findIndex(e => isNaN(e) && e !== '.');
  return +name.slice(numberStartIndex);
};

const compare = (iface1, iface2) => {
  if (iface1.justCreated) {
    return -1;
  } else if (iface2.justCreated) {
    return 1;
  } else if (iface1.type === 'bond' && iface2.type !== 'bond') {
    return -1;
  } else if (iface2.type === 'bond' && iface1.type !== 'bond') {
    return 1;
  } else if (iface1.type === 'vlan' && iface2.type !== 'vlan') {
    return 1;
  } else if (iface2.type === 'vlan' && iface1.type !== 'vlan') {
    return -1;
  } else {
    return getNumberFromName(iface1.name) - getNumberFromName(iface2.name);
  }
};

/* IP helpers */

const getIps = (addresses = []) =>
  addresses.reduce((acc, a) => {
    const type = isIpv4(a.split('/')[0]) ? 'ipv4' : 'ipv6';
    return { ...acc, [type]: [...(acc[type] || []), a] };
  }, {});

const segregateAddresses = (addresses = []) => {
  let res = { v4: [], v6: [] };

  addresses.forEach(addressBundle => {
    const { address: addressStr } = addressBundle;
    const isv4 = isIpv4(addressStr.split('/')[0]);

    if (isv4) {
      res.v4.push(addressBundle);
    } else {
      res.v6.push(addressBundle);
    }
  });

  return res;
};

const updateIpMismatch = ifaces => {
  const getIps = (config, isIp) => {
    let res;

    if (!config || !config.addresses) {
      res = [];
    } else if (!config.addressInfo) {
      res = config.addresses.filter(ip => isIp(ip.split('/')[0]));
    } else {
      res = config.addressInfo.filter(addressBundle => {
        const { address: addressStr, scope, flags } = addressBundle;
        const isAutomatic = scope === 'link' || (scope === 'global' && flags.includes('dynamic'));

        if (!isAutomatic) {
          return isIp(addressStr.split('/')[0]);
        }

        return false;
      });

      res = res.map(({ address }) => address);
    }

    return res;
  };

  let res = {};

  Object.entries(ifaces).forEach(([name, int]) => {
    const { actual, config } = int;

    const actualIpv4 = getIps(actual, isIpv4).sort();
    const configIpv4 = getIps(config, isIpv4).sort();

    const actualIpv6 = getIps(actual, isIpv6).sort();
    const configIpv6 = getIps(config, isIpv6).sort();

    res[name] = {
      ...int,
      ipv4Mismatch: isEqual(actualIpv4, configIpv4) ? [] : actualIpv4,
      ipv6Mismatch: isEqual(actualIpv6, configIpv6) ? [] : actualIpv6,
    };
  });

  return res;
};

/* errors helpers */

const errors = {
  vlanId: <FormattedMessage id='errors.vlanId' defaultMessage='Invalid VLAN ID' />,
  mii: <FormattedMessage id='errors.mii' defaultMessage='Invalid miiMonitorSec' />,
  ipv4: <FormattedMessage id='errors.ipv4' defaultMessage='Invalid IPv4 address' />,
  'ipv4/mask': <FormattedMessage id='errors.ipv4Mask' defaultMessage='Invalid IPv4 address/mask' />,
  'ipv6/mask': <FormattedMessage id='errors.ipv6Mask' defaultMessage='Invalid IPv6 address/mask' />,
};

const vtunErrors = {
  session: <FormattedMessage id='vtunErrors.invalidSession' defaultMessage='Invalid session' />,
  host: <FormattedMessage id='vtunErrors.invalidHost' defaultMessage='Invalid host' />,
  port: <FormattedMessage id='vtunErrors.invalidPort' defaultMessage='Invalid port' />,
  password: <FormattedMessage id='vtunErrors.invalidPassword' defaultMessage='Invalid password' />,
  local_ip: <FormattedMessage id='vtunErrors.invalidLocalIp' defaultMessage='Invalid local IP' />,
  remote_ip: <FormattedMessage id='vtunErrors.invalidRemoteIp' defaultMessage='Invalid remote IP' />,
  name: <FormattedMessage id='vtunErrors.invalidName' defaultMessage='Invalid name' />,
  timeout: <FormattedMessage id='vtunErrors.invalidTimeout' defaultMessage='Invalid timeout' />,
};

const getErrors = iface => {
  const { newIpv4, newIpv6, config } = iface;
  const { id, miiMonitorSec } = config || {};
  const ipv4Error = newIpv4 && !isValid('ipv4/mask', newIpv4) && errors['ipv4/mask'];
  const ipv6Error = newIpv6 && !isValid('ipv6/mask', newIpv6) && errors['ipv6/mask'];
  const vlanIdError = id !== undefined && (isNaN(id) || id < 0 || id > 4095) && errors['vlanId'];
  const miiError = miiMonitorSec !== undefined && (isNaN(miiMonitorSec) || miiMonitorSec < 0) && errors['mii'];
  return { ipv4Error, ipv6Error, vlanIdError, miiError };
};

const parseActualRoutes = routes =>
  routes.map(route => {
    if (route.destination === DEFAULT_MULTICAST_GATEWAY) {
      return { ...route, destination: 'default multicast gateway' };
    }
    if (route.destination === DEFAULT_GATEWAY) {
      return { ...route, destination: 'default gateway' };
    } else {
      return route;
    }
  });

const convertUserRoutes = userRoutes => {
  const defaultUnicast = {
    ...(userRoutes.find(({ destination }) => destination === DEFAULT_GATEWAY) || {
      gateway: 'Not set',
      iface: 'Not set',
    }),
    destination: 'default gateway',
  };
  const defaultMulticast = {
    ...(userRoutes.find(({ destination }) => destination === DEFAULT_MULTICAST_GATEWAY) || {
      gateway: 'Not set',
      iface: 'Not set',
    }),
    destination: 'default multicast gateway',
  };
  return [
    defaultUnicast,
    defaultMulticast,
    ...userRoutes.filter(
      ({ destination }) => destination !== DEFAULT_MULTICAST_GATEWAY && destination !== DEFAULT_GATEWAY
    ),
  ];
};

const convertDestination = destination => {
  if (destination === 'default gateway') {
    return DEFAULT_GATEWAY;
  } else if (destination === 'default multicast gateway') {
    return DEFAULT_MULTICAST_GATEWAY;
  }
  return destination;
};

const passwordHasError = password => {
  const hasAlpha = /[a-zA-Z]/.test(password);
  const isAlphanumeric = /^[a-zA-Z0-9]+$/.test(password);
  return !hasAlpha || !isAlphanumeric;
};

const isError = (field, value) => {
  switch (field) {
    case 'name':
      return (
        !/^[a-z0-9-]{1,10}$/i.test(value) || value === 'netup' || value[0] === '-' || value[value.length - 1] === '-'
      );
    case 'session':
      return !/^[\w-]{1,}$/i.test(value) || value[0] === '-' || value[value.length - 1] === '-';
    case 'password':
      return passwordHasError(value);
    case 'host':
      return value.length === 0;
    case 'port':
      return isNaN(value) || value < 1 || value > 65535;
    case 'local_ip':
    case 'remote_ip':
      return !isValid('ipv4', value) && !isValid('ipv6', value);
    case 'timeout':
      return value.length === 0 || isNaN(value);
    default:
  }
};

const getVtunError = (field, value) => (isError(field, value) ? vtunErrors[field] : null);

const getIfaceTemplate = type => {
  /** We are setting "BOND" or "VLAN" or "VTUN" name to newly created iface.
   * We will change it after applying in handleApply method.  */
  switch (type) {
    case 'vlan':
      return {
        VLAN: {
          name: 'VLAN',
          type: 'vlan',
          config: { ifaces: [''], id: '' },
          justCreated: true,
          expanded: true,
        },
      };
    case 'bond':
      return {
        BOND: {
          name: 'BOND',
          type: 'bond',
          config: {
            mode: '802.3ad',
            miiMonitorSec: 0,
            lacpTransmitRate: 'slow',
            transmitHashPolicy: 'layer2',
            bond: null,
          },
          justCreated: true,
          expanded: true,
        },
      };
    case 'vtun':
      return {
        VTUN: {
          session: '',
          password: '',
          host: '',
          port: 5000,
          local_ip: '',
          remote_ip: '',
          enabled: true,
        },
      };
    default:
  }
};

const getNewName = (vtunState, number = 1) =>
  vtunState[`vtun${number}`] ? getNewName(vtunState, number + 1) : `vtun${number}`;

function decorateActualAddresses(payload) {
  try {
    let copy = { ...payload };
    copy.ifaces = { ...copy.ifaces };
    for (let [iface, data] of Object.entries(copy.ifaces)) {
      copy.ifaces[iface] = { ...data };
      if (data.actual) {
        copy.ifaces[iface].actual = { ...data.actual };
        copy.ifaces[iface].actual.addresses = data.actual.addresses.map(address => address.address);
        copy.ifaces[iface].actual.addressInfo = [...data.actual.addresses];
      }
    }
    return copy;
  } catch (error) {
    alert(error.message);
    return payload;
  }
}

const getRoutes = (ifaces, actualRoutes) => {
  const allConfigRoutes = Object.entries(ifaces)
    .filter(([, { config }]) => config)
    .map(([name, { config }]) => config.routes.map(route => ({ iface: name, ...route })))
    .reduce((acc, routes) => [...acc, ...routes], []);
  const systemRoutes = parseActualRoutes(actualRoutes);
  const userRoutes = convertUserRoutes(allConfigRoutes);
  return { systemRoutes, userRoutes };
};

const getMissingRoutes = ({ ifaces = {}, actualRoutes = [] }) => {
  const { systemRoutes, userRoutes } = getRoutes(ifaces, actualRoutes);
  const missingSystemRoutes = systemRoutes.filter(
    ({ destination, gateway, iface, proto }) =>
      (proto === 'static' || proto === undefined) &&
      !userRoutes.some(route => route.destination === destination && route.gateway === gateway && route.iface === iface)
  );
  const missingConfigRoutes = userRoutes.filter(
    ({ destination, gateway, iface }) =>
      iface !== 'Not set' &&
      !systemRoutes.some(
        route => route.destination === destination && route.gateway === gateway && route.iface === iface
      )
  );
  return { missingConfigRoutes, missingSystemRoutes };
};

export {
  getIps,
  segregateAddresses,
  getErrors,
  compare,
  updateIpMismatch,
  isIp,
  isIpv4,
  isValid,
  parseActualRoutes,
  convertUserRoutes,
  convertDestination,
  decorateActualAddresses,
  getIfaceTemplate,
  getVtunError,
  getNewName,
  getRoutes,
  getMissingRoutes,
  isMulticastRange,
};
