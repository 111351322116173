import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

export default createSvgIcon(
  <g>
    <path
      d={
        'M8 3a2 2 0 0 0-2 2v4a2 2 0 0 1-2 2H3v2h1a2 2 0 0 1 2 2v4c0 1.105.928 1.732 2 2h2v-2H8v-5a2 2 0 0 0-2-2 2 2 0 0 0 2-2V5h2V3m6 0a2 2 0 0 1 2 2v4a2 2 0 0 0 2 2h1v2h-1a2 2 0 0 0-2 2v4c0 1.105-.928 1.732-2 2h-2v-2h2v-5a2 2 0 0 1 2-2 2 2 0 0 1-2-2V5h-2V3h2z'
      }
    />
  </g>,
  'code braces'
);
