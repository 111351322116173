import { fetcher } from '../../util/deps';
import { SET_DISK_USAGE } from '../constants';

const loadDiskUsage = () => async dispatch => {
  let data = await fetcher.get('modules/disk-usage/full', undefined, false);
  if (data) {
    dispatch(setDiskUsage(data));
  }
};

const setDiskUsage = diskUsage => ({ type: SET_DISK_USAGE, payload: diskUsage });

export default { loadDiskUsage };
