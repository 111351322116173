import { FC, useCallback, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage, FormattedRelativeTime } from 'react-intl';
import Fade from '@material-ui/core/Fade';

import { infoActions, loadActions, modulesActions, networkActions, widgetsActions } from '@/redux-stuff/actions';
import { gears, tower, StreamProcessorSvg } from '@/util/svg';
import {
  MenuCard,
  LoadWidget,
  TemperatureWidget,
  DisksWidget,
  HardwareDialog,
  HardwareWidget,
  Tutorial,
  ServiceCard,
} from '@/components/mainPage';
import { getSize } from '@/util/commonHelpers';
import { fetcher } from '@/util/deps';
import Alerts from '@/components/Alerts';
import { parsePermissions } from '@/util/permissions';
import { useAppDispatch, useAppSelector } from '@/util/store';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: 1200,
    margin: 'auto',
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  background: {
    backgroundColor: '#FAFAFA',
  },
  gridMargin: {
    marginBottom: theme.spacing(1.5),
  },
}));

interface Permission {
  read?: boolean;
  write?: boolean;
}
interface MainPageProps {
  permissions: {
    info?: Permission;
    load?: Permission;
    modules?: Permission;
    network?: Permission;
    smart?: Permission;
    license?: Permission;
  };
  addMessage(message: { type: string; data: unknown }): unknown;
  width: string;
}

const MainPage: FC<MainPageProps> = ({ permissions, addMessage, width }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [disksInfo, setDisksInfo] = useState<{ hardware: unknown }>();
  const system = useAppSelector(({ info }) => info.system);
  const load = useAppSelector(({ load }) => load);
  // FIXME: remove any type then widgets will converted to ts
  const widgets: any = useAppSelector(({ widgets }) => widgets);
  const network = useAppSelector(({ network }) => network);

  useEffect(() => {
    const { info = {}, load = {}, modules = {}, network = {}, smart = {} } = permissions;
    if (info.read) {
      dispatch(infoActions.loadInfo());
    }
    if (load.read) {
      dispatch(loadActions.loadLoad());
    }
    if (modules.read) {
      dispatch(modulesActions.loadModules());
    }
    if (network.read) {
      dispatch(networkActions.loadNetwork());
    }
    if (smart.read) {
      fetcher
        .get('diskinfo')
        .then(v => setDisksInfo(v))
        .catch(e => addMessage({ type: 'failure', data: e }));
    }
  }, []);

  useEffect(() => {
    const { uptime } = system || {};
    if (!uptime) return;

    const lastUptime = Number(localStorage.getItem('lastUptime'));
    localStorage.setItem('lastUptime', uptime);

    if (!lastUptime || isNaN(lastUptime)) return;

    if (lastUptime > uptime) {
      addMessage({
        type: 'info',
        data: (
          <>
            <FormattedMessage id='serverWasRebooted' defaultMessage='Server was rebooted' />{' '}
            <FormattedRelativeTime value={-uptime} numeric='auto' updateIntervalInSeconds={1} />
          </>
        ),
      });
    }
  }, [system]);

  const [hardwareDialogOpened, setHardwareDialogOpened] = useState(false);

  const handleOpenHardwareDialog = useCallback(() => setHardwareDialogOpened(true), []);

  const handleCloseWidgetDialog = useCallback(() => setHardwareDialogOpened(false), []);

  const handleRemoveWidget = useCallback(
    name => () => {
      handleCloseWidgetDialog();
      dispatch(widgetsActions.toggleWidgetState(name));
    },
    []
  );

  const [cpu] = (system && system.cpus) || [];
  const ram = getSize((system && system.ram) || 0);
  const smDown = width === 'xs' || width === 'sm';
  const haveLicenseReadPermission = permissions && permissions.license && permissions.license.read;

  return (
    <div className={classes.background}>
      <div className={classes.container}>
        <Alerts issuesPermission={parsePermissions(permissions, 'issues').r} />
        <Grid container spacing={1} className={classes.gridMargin}>
          {load && (
            <>
              <LoadWidget load={load} ifaces={(network || {}).ifaces} uptime={system && system.uptime} />
              <TemperatureWidget load={load} />
            </>
          )}
          <ServiceCard
            moduleName='dvb-gw'
            licenseName={haveLicenseReadPermission ? 'NetUP IPTV Streamer' : undefined}
            uri='/dvb-gw'
            label='DVB IP Streamer'
            SvgComponent={tower}
            type='dvb'
          />
          <ServiceCard
            moduleName='iptv-relay'
            licenseName={haveLicenseReadPermission ? 'NetUP IPTV Relay' : undefined}
            uri='/relay'
            label={<FormattedMessage id='mainPage.relay' defaultMessage='Multimedia Processor' />}
            SvgComponent={tower}
            type='relay'
          />
          <ServiceCard
            moduleName='stream-processor'
            licenseName={haveLicenseReadPermission ? 'NetUP IPTV Stream Processor' : undefined}
            uri='/stream-processor'
            label={<FormattedMessage id='mainPage.streamProcessor' defaultMessage='Stream Processor' />}
            SvgComponent={StreamProcessorSvg}
            type='streamProcessor'
          />
          <ServiceCard
            moduleName='catch-up'
            uri='/cu-admin'
            label={<FormattedMessage id='mainPage.catchUpTvServer' defaultMessage='Catch-up TV server' />}
            type='streamProcessor'
          />
          <ServiceCard
            moduleName='stream-redundancy'
            licenseName={haveLicenseReadPermission ? 'NetUP Stream Redundancy System' : undefined}
            uri='/rs'
            label={<FormattedMessage id='mainPage.streamRedundancySystem' defaultMessage='Stream Redundancy System' />}
            type='streamProcessor'
          />
          <ServiceCard
            moduleName='middleware'
            licenseName={haveLicenseReadPermission ? 'NetUP IPTV Middleware' : undefined}
            uri='/middleware'
            label={<FormattedMessage id='mainPage.Middleware' defaultMessage='Middleware' />}
            type='middleware'
          />
          <ServiceCard
            moduleName='mw'
            licenseName={haveLicenseReadPermission ? 'NetUP IPTV Middleware' : undefined}
            uri='/mw'
            label={<FormattedMessage id='mainPage.Middleware3' defaultMessage='Middleware 3.0' />}
            type='middleware'
          />
          <ServiceCard
            moduleName='vod'
            licenseName={haveLicenseReadPermission ? 'NetUP IPTV Encryption VoD Server' : undefined}
            uri='/vod'
            label={<FormattedMessage id='mainPage.vod' defaultMessage='VoD' />}
            type='vod'
          />
          <ServiceCard
            moduleName='vod-metadata'
            uri='/md'
            label={<FormattedMessage id='mainPage.vodMetadataService' defaultMessage='VoD metadata service' />}
            type='vod'
          />
          <ServiceCard
            moduleName='angular-web-player'
            uri='/web-player'
            label={<FormattedMessage id='mainPage.angularWebPlayer' defaultMessage='Angular web player' />}
          />
          <ServiceCard
            moduleName='react-web-player'
            uri='/web-player'
            label={<FormattedMessage id='mainPage.reactWebPlayer' defaultMessage='React web player' />}
          />

          <MenuCard
            uri='/modules'
            name={<FormattedMessage id='mainPage.settings' defaultMessage='Server Settings' />}
            SvgComponent={gears}
            type='systemSettings'
            // FIXME: remove then MenuCard wil be converted to ts
            greyout={undefined}
            customTitle={undefined}
          />
        </Grid>
        {(system || disksInfo) && (
          <Grid container spacing={1}>
            {system && (
              <Fade in={widgets.hardware} unmountOnExit>
                <HardwareWidget
                  smDown={smDown}
                  cpu={cpu}
                  ram={ram}
                  handleOpenHardwareDialog={handleOpenHardwareDialog}
                />
              </Fade>
            )}
            {disksInfo && (
              <Fade in={widgets.disks} unmountOnExit>
                <DisksWidget disksInfo={disksInfo} />
              </Fade>
            )}
          </Grid>
        )}
      </div>
      {network && system && (
        <HardwareDialog
          open={hardwareDialogOpened}
          onClose={handleCloseWidgetDialog}
          ram={ram}
          cpu={cpu}
          network={network}
          system={system}
          hardwareDisks={(disksInfo || {}).hardware}
          handleRemoveWidget={handleRemoveWidget('hardware')}
        />
      )}
      <Tutorial />
    </div>
  );
};

export default withWidth()(MainPage);
