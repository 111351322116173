import { SET_ACTIONS, RESET_ACTIONS } from '../constants';

export interface AppBarStateType {
  leftButton?: unknown;
  rightButtons?: unknown;
  text?: unknown;
}

export type AppBarActionsType =
  | {
      type: typeof SET_ACTIONS;
      payload: AppBarStateType;
    }
  | {
      type: typeof RESET_ACTIONS;
    };

export default (
  appBar: AppBarStateType | undefined = { leftButton: null, rightButtons: null, text: null },
  action: AppBarActionsType
) => {
  switch (action.type) {
    case SET_ACTIONS:
      return { ...appBar, ...action.payload };
    case RESET_ACTIONS:
      return { leftButton: undefined, rightButtons: undefined, text: undefined };
    default:
      return appBar;
  }
};
