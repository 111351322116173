import React from 'react';
import { FormattedMessage } from 'react-intl';
// prettier-ignore
const messages = {
  backup: <FormattedMessage id="resource.backup" defaultMessage="resource.backup" />,
  connections: <FormattedMessage id="resource.connections" defaultMessage="resource.connections" />,
  dns: <FormattedMessage id="resource.dns" defaultMessage="resource.dns" />,
  firmware: <FormattedMessage id="resource.firmware" defaultMessage="resource.firmware" />,
  info: <FormattedMessage id="resource.info" defaultMessage="resource.info" />,
  issues: <FormattedMessage id="resource.issues" defaultMessage="resource.issues" />,
  license: <FormattedMessage id="resource.license" defaultMessage="resource.license" />,
  load: <FormattedMessage id="resource.load" defaultMessage="resource.load" />,
  mail: <FormattedMessage id="resource.mail" defaultMessage="resource.mail" />,
  modules: <FormattedMessage id="resource.modules" defaultMessage="resource.modules" />,
  network: <FormattedMessage id="resource.network" defaultMessage="resource.network" />,
  power: <FormattedMessage id="resource.power" defaultMessage="resource.power" />,
  roles: <FormattedMessage id="resource.roles" defaultMessage="resource.roles" />,
  services: <FormattedMessage id="resource.services" defaultMessage="resource.services" />,
  smart: <FormattedMessage id="resource.smart" defaultMessage="resource.smart" />,
  statistics: <FormattedMessage id="resource.statistics" defaultMessage="resource.statistics" />,
  time: <FormattedMessage id="resource.time" defaultMessage="resource.time" />,
  users: <FormattedMessage id="resource.users" defaultMessage="resource.users" />,
  vtun: <FormattedMessage id="resource.vtun" defaultMessage="resource.vtun" />,
};

// prettier-ignore
export function getResourceMessage(resource) {
    let message = messages[resource];
    if (message === undefined) {
        throw new Error(`Unknown resource: ${resource}`);
    }
    return message;
}
