import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import Snackbar from '@material-ui/core/Snackbar';
import Hidden from '@material-ui/core/Hidden';

import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import Info from '@material-ui/icons/Info';

import factory from '../../localization-factories/snackbarMessageFactory';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { getErrorMessage } from '../../util/getErrorMessage';
import { FormattedMessage } from 'react-intl';

const styles = {
  snackbarContent: {
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    padding: '8px 16px',
  },

  icon: {
    display: 'block',
  },
};

class MySnackbar extends React.Component {
  showDetails = () => {
    this.props.openDetails(this.props.data);
  };

  reload = () => setTimeout(() => window.location.reload(), 200);

  render() {
    let icons = {
      check: Check,
      clear: Clear,
      info: Info,
    };

    let {
      classes,
      data,
      open,
      type,
      actionButtons,
      DOMMessage,
      onEntering,
      onExited,
      handleClose,
      isReload,
      isUndo,
      forbidCloseOnClick,
    } = this.props;
    let summary = data instanceof Error ? getErrorMessage(data.errorMessage) : data;
    let message = factory(type, summary);
    let detailsAvailable = !!data && !!data.details;

    if (!message && !isReload && !isUndo) return null;

    if (detailsAvailable) {
      actionButtons = (
        <Button onClick={this.showDetails} color='secondary'>
          <FormattedMessage id='snackbar.error.button.details' defaultMessage='details' />
        </Button>
      );
    } else if (isReload) {
      actionButtons = (
        <Fragment>
          <Button onClick={handleClose} color='secondary'>
            <FormattedMessage id='snackbar.frontNeedsReload.button.dismiss' defaultMessage='dismiss' />
          </Button>
          <Button onClick={this.reload} color='secondary'>
            <FormattedMessage id='snackbar.frontNeedsReload.button.reload' defaultMessage='reload' />
          </Button>
        </Fragment>
      );
    } else if (isUndo) {
      actionButtons = (
        <Button onClick={this.props.undo} color='secondary'>
          <FormattedMessage id='snackbar.frontNeedsReload.button.undo' defaultMessage='undo' />
        </Button>
      );
    }

    if (isReload) {
      DOMMessage = (
        <FormattedMessage
          id='snackbar.frontNeedsReload'
          defaultMessage='System module has been updated. Please reload the page to get the new version.'
        />
      );
    } else if (isUndo) {
      DOMMessage = <FormattedMessage id='snackbar.errorRemoved' defaultMessage='Error entry has been deleted' />;
    } else if (!DOMMessage) {
      let CurrentIcon = icons[message.icon];
      let currentStyle = CurrentIcon ? { display: 'flex', alignItems: 'center' } : {};
      DOMMessage = (
        <div style={currentStyle}>
          {message.icon && (
            <span style={{ color: message.color || '', paddingRight: 16 }}>
              <CurrentIcon className={classes.icon} />
            </span>
          )}
          {message.colored && <span style={{ color: message.color }}>{message.colored} </span>}
          {message.text}
        </div>
      );
    }

    const commonProps = {
      message: DOMMessage,
      action: actionButtons,
      TransitionProps: {
        onEntering,
        onExited,
      },
      open,
      onClose: !forbidCloseOnClick && handleClose,
      onClick: !forbidCloseOnClick && handleClose,
    };

    return ReactDOM.createPortal(
      <div>
        <Hidden smDown>
          <Snackbar
            {...commonProps}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            ContentProps={{
              'aria-describedby': 'message-id',
              style: {
                cursor: 'default',
                zIndex: 1200,
                position: 'relative',
              },
              className: classes.snackbarContent,
            }}
          />
        </Hidden>
        <Hidden mdUp>
          <Snackbar
            {...commonProps}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            ContentProps={{
              'aria-describedby': 'message-id',
              className: classes.snackbarContent,
            }}
          />
        </Hidden>
      </div>,
      document.querySelector(`#snackbars`)
    );
  }
}

export default withStyles(styles)(MySnackbar);
