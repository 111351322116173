import { SET_SMART } from '../constants';

export default (smart = {}, action) => {
  switch (action.type) {
    case SET_SMART:
      return { ...smart, ...action.payload };
    default:
      return smart;
  }
};
