import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

export default createSvgIcon(
  <g>
    <path
      d={
        'm12 1c-6.07 0-11 4.93-11 11s4.93 11 11 11 11-4.93 ' +
        '11-11-4.93-11-11-11zm-0.001953 1.9297 1.7324 1.7656 ' +
        '1.7305 1.7637h-2.2832v2.2422h-2.3574v-2.2422h-2.2832l1.7305-1.7637 ' +
        '1.7305-1.7656zm-6.7715 5.6074 1.7617 1.7324 1.7656 1.7305-1.7656 ' +
        '1.7305-1.7617 1.7324v-2.2832h-2.2422v-2.3574h2.2422v-2.2852zm13.547 ' +
        '0v2.2852h2.2422v2.3574h-2.2422v2.2832l-1.7637-1.7324-1.7656-1.7285 ' +
        '1.7656-1.7324 1.7637-1.7324zm-7.9531 6.7617h2.3574v2.2422h2.2832l-1.7305 ' +
        '1.7637-1.7324 1.7656-1.7305-1.7656-1.7305-1.7637h2.2832v-2.2422z'
      }
    />
  </g>,
  'router'
);
