import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Add from '@material-ui/icons/Add';
import { omit } from 'lodash';

const styles = theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  extendedIcon: {
    marginRight: theme.spacing(),
  },
});

const CustomFab = props => {
  const { onClick, label, icon: Icon = Add, classes } = props;
  return (
    <Fab
      className={classes.fab}
      variant='extended'
      color='primary'
      onClick={onClick}
      {...omit(props, 'icon', 'classes')}
    >
      <Icon className={classes.extendedIcon} />
      {label}
    </Fab>
  );
};

export default withStyles(styles)(CustomFab);
