export const SET_INIT_STATE = Symbol();
export const ADD_VTUN = Symbol();
export const SET_VALIDATION_ERROR = Symbol();
export const CLEAR_VALIDATION_ERROR = Symbol();
export const SET_OPTION = Symbol();
export const SET_PENDING_IFACE = Symbol();
export const CLEAR_PENDING_IFACE = Symbol();
export const RESET_CHANGES = Symbol();
export const REMOVE_IFACE = Symbol();
export const OMIT_OPTION = Symbol();
export const DEFAULT_VTUN_CONFIG = {
  host: 'tuns.netup.tv',
  port: 5000,
  enabled: false,
};

export const customSettings = ['session', 'password', 'local_ip', 'remote_ip'];
export const netupSettings = ['host', 'port', 'enabled'];
export const settingsToExclude = ['persist', 'timeout'];
