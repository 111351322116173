import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

export default createSvgIcon(
  <g>
    <path
      d={
        'm9 3c-4.42 0-8 1.79-8 4s3.58 4 8 4 8-1.79 8-4-3.58-4-8-4zm-8 ' +
        '6v3c0 2.21 3.58 4 8 4 1.9168 0 3.6519-0.35057 5.0293-0.91211a5.7327 ' +
        '5.7327 0 0 1 2.8809-2.5176c0.053472-0.18686 0.089844-0.37603 ' +
        '0.089844-0.57031v-3c0 2.21-3.58 4-8 4s-8-1.79-8-4zm18.568 4.4238 ' +
        '0.13477 1.7754 0.19922 2.0039 1.0859-1.0859a2.7458 2.7458 0 0 1 0.7793 ' +
        '1.9062 2.7458 2.7458 0 0 1-2.7461 2.7441 2.7458 2.7458 0 0 1-2.7461-2.7441 ' +
        '2.7458 2.7458 0 0 1 0.73047-1.8594l-1.1348-1.1348a4.3475 4.3475 0 0 0-1.1973 ' +
        '2.9941 4.3475 4.3475 0 0 0 4.3477 4.3477 4.3475 4.3475 0 0 0 4.3477-4.3477 4.3475 ' +
        '4.3475 0 0 0-1.248-3.0371l1.0879-1.0898-1.8379-0.23438-1.8027-0.23828zm-18.568 ' +
        '0.57617v3c0 2.21 3.58 4 8 4 1.451-0.03523 2.7375-0.22555 4-0.53906 ' +
        '0.25242-0.051688 0.51831-0.13803 0.78711-0.24024a5.7327 5.7327 0 0 ' +
        '1-0.49805-2.3223 5.7327 5.7327 0 0 1 0.029297-0.57031c-0.19232 ' +
        '0.068315-0.38561 0.14382-0.57617 0.19727-1.1479 0.30518-2.4292 ' +
        '0.47461-3.7422 0.47461-4.42 0-8-1.79-8-4z'
      }
    />
  </g>,
  'data backup'
);
