import wsEventHandlersMap from './wsEventHandlersMap';

export default class Listener {
  constructor(wsClient) {
    this.wsClient = wsClient;
    this.wsClient.on('error', this.onError);
  }

  enable() {
    Object.entries(wsEventHandlersMap).forEach(([resource, cb]) => this.wsClient.on(resource, cb));
  }

  disable() {
    Object.entries(wsEventHandlersMap).forEach(([resource, cb]) => this.wsClient.off(resource, cb));
  }

  onError = error => {
    console.error(error); // eslint-disable-line
  };
}
