import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import compose from 'recompose/compose';
import { isEmpty } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Info from '@material-ui/icons/Info';

import { getSize } from '../../util/commonHelpers';
import Transition from '../util/DialogTransition';

const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  toolbar: {
    padding: `0 ${theme.spacing(1.5)}px`,
  },
  cancelButton: {
    marginRight: theme.spacing(1),
  },
  spacer: {
    height: theme.spacing(3),
  },
  toolBarTypography: {
    flex: 1,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  containerRemove: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
  nestedTable: {
    tableLayout: 'fixed',
    '& tr': {
      height: 'initial',
    },
    '& td': {
      border: 0,
      overflowWrap: 'break-word',
      wordBreak: 'break-word',
    },
    '& td:first-child': {
      color: theme.palette.text.secondary,
    },
  },
  diskCell: {
    display: 'flex',
    alignItems: 'center',
  },
});

const HardwareDialog = ({
  open,
  onClose,
  classes,
  cpu,
  ram,
  system,
  network,
  hardwareDisks = [],
  handleRemoveWidget,
  fullScreen,
  width,
}) => {
  const ifacesToDisplay =
    (!isEmpty(network) &&
      Object.entries(network.ifaces).reduce((acc, [name, { actual, type }]) => {
        if ((type === 'phys' || type === 'unconfigured') && actual && actual.mac) {
          return [...acc, { name, mac: actual.mac }];
        }
        return acc;
      }, [])) ||
    [];
  const { cards = [] } = system || {};
  const title = <FormattedMessage id='hardwareDialog.hardwareInfo' defaultMessage='Hardware info' />;
  const smDown = width === 'xs' || width === 'sm';
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      TransitionComponent={fullScreen ? Transition : undefined}
    >
      {fullScreen && (
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <IconButton color='inherit' onClick={onClose} className={classes.cancelButton}>
              <CloseIcon />
            </IconButton>
            <Typography variant='h6' color='inherit' className={classes.toolBarTypography}>
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
      )}
      {fullScreen && <div className={classes.spacer} />}
      {!fullScreen && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <Table padding={smDown ? 'checkbox' : 'default'}>
          <TableBody>
            <TableRow>
              <TableCell>
                <FormattedMessage id='hardwareDialog.cpu' defaultMessage='CPU' />
              </TableCell>
              <TableCell>{cpu}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <FormattedMessage id='hardwareDialog.ram' defaultMessage='RAM' />
              </TableCell>
              <TableCell>{ram}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <FormattedMessage id='hardwareDialog.storageDevices' defaultMessage='Storage devices' />
              </TableCell>
              <TableCell>
                <Table className={classes.nestedTable} padding='none'>
                  <TableBody>
                    {hardwareDisks.map(({ model, serial, size, name }, i) => (
                      <TableRow key={i}>
                        <TableCell className={classes.diskCell}>
                          <div>
                            {model} {getSize(size)}
                          </div>
                          <Tooltip
                            title={
                              <Fragment>
                                <div>
                                  <FormattedMessage
                                    id='hardwareDialog.tooltip.diskName'
                                    defaultMessage='Name: {name}'
                                    values={{ name }}
                                  />
                                </div>
                                <div>
                                  <FormattedMessage
                                    id='hardwareDialog.tooltip.diskSerial'
                                    defaultMessage='Serial number: {serial}'
                                    values={{ serial }}
                                  />
                                </div>
                              </Fragment>
                            }
                          >
                            <Info />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <FormattedMessage id='hardwareDialog.networkInterfaces' defaultMessage='Network interfaces' />
              </TableCell>
              <TableCell>
                <Table className={classes.nestedTable} padding='none'>
                  <TableBody>
                    {ifacesToDisplay.map(({ name, mac }) => (
                      <TableRow key={name}>
                        <TableCell>{`${name} (${mac})`}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <FormattedMessage id='hardwareDialog.dvbCards' defaultMessage='DVB cards' />
              </TableCell>
              <TableCell>
                <Table className={classes.nestedTable} padding='none'>
                  <TableBody>
                    {cards.map(name => (
                      <TableRow key={name}>
                        <TableCell>{name}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {fullScreen && (
          <div className={classes.containerRemove}>
            <Button onClick={handleRemoveWidget}>
              <FormattedMessage id='HardwareDialog.fullScreen.hideWidget' defaultMessage='Hide widget' />
            </Button>
          </div>
        )}
      </DialogContent>
      {!fullScreen && (
        <DialogActions className={classes.actions}>
          <Button onClick={handleRemoveWidget}>
            <FormattedMessage id='HardwareDialog.standardDialog.hideWidget' defaultMessage='Hide widget' />
          </Button>
          <Button onClick={onClose} color='primary'>
            <FormattedMessage id='HardwareDialog.close' defaultMessage='Close' />
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default compose(withStyles(styles), withMobileDialog(), withWidth())(HardwareDialog);
