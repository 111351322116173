import { fetcher } from '../../util/deps';
import {
  SET_USERS,
  UPDATE_USER,
  DISCARD_USER_CHANGES,
  SET_UNSAVED_USERS_CHANGES,
  CLEAR_UNSAVED_USERS_CHANGES,
} from '../constants';

const loadUsers = userToUpdate => async dispatch => {
  const users = await fetcher.get('user', undefined, false);
  dispatch({ type: SET_USERS, payload: { newUsers: users, userToUpdate } });
};

const createUser = (username, password) => async dispatch => {
  await fetcher.post(`user`, { username, password, roles: [] }, false);
  loadUsers(username)(dispatch);
};

const deleteUser = username => async dispatch => {
  await fetcher.delete(`user`, { username }, false);
  loadUsers(username)(dispatch);
};

const discardUserChanges = username => ({ type: DISCARD_USER_CHANGES, payload: username });

const updateUser = (username, roles) => ({ type: UPDATE_USER, payload: { username, roles } });

const setUnsavedChanges = (user, unsavedChangesExist) => ({
  type: SET_UNSAVED_USERS_CHANGES,
  payload: [user, unsavedChangesExist],
});

const clearUnsavedChanges = () => ({ type: CLEAR_UNSAVED_USERS_CHANGES });

export default {
  loadUsers,
  createUser,
  deleteUser,
  updateUser,
  discardUserChanges,
  setUnsavedChanges,
  clearUnsavedChanges,
};
