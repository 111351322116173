import React from 'react';
import PowerAndSettings from './PowerAndSettings';

export default (addMessage, permissions, handleLogout, username, handleChangePassword, changePasswordPermission) =>
  ({ location }) =>
    location.pathname !== '/login' && (
      <PowerAndSettings
        addMessage={addMessage}
        permissions={permissions}
        logout={handleLogout}
        changePassword={handleChangePassword}
        changePasswordPermission={changePasswordPermission}
        username={username}
        location={location}
      />
    );
