import React from 'react';
import { FormattedMessage } from 'react-intl';

import desktopInterfaceSettingsImg from './img/desktop-interface-settings.png';
import desktopErrorLogImg from './img/desktop-error-log.png';
import desktopFabImg from './img/desktop-fab.png';
import mobileInterfaceSettingsImg from './img/mobile-interface-settings.png';
import mobileErrorLogImg from './img/mobile-error-log.png';
import mobileFabImg from './img/mobile-fab.png';

export default [
  [
    <FormattedMessage id='tutorial.interfaceSettingsStep.Label' defaultMessage='Interface settings' />,
    <FormattedMessage
      id='tutorial.interfaceSettingsStep.Message'
      defaultMessage={
        'On the Home Page use interface settings to switch interface language, or enable/disable Home Page widgets'
      }
    />,
    {
      desktop: <img src={desktopInterfaceSettingsImg} alt='interface settings' />,
      mobile: <img src={mobileInterfaceSettingsImg} alt='interface settings' />,
    },
  ],
  [
    <FormattedMessage id='tutorial.errorLogStep.Label' defaultMessage='Error log' />,
    <FormattedMessage
      id='tutorial.errorLogStep.Message'
      defaultMessage='The error log collects errors and error details, should they happen in the interface'
    />,
    {
      desktop: <img src={desktopErrorLogImg} alt='error log' />,
      mobile: <img src={mobileErrorLogImg} alt='error log' />,
    },
  ],
  [
    <FormattedMessage id='tutorial.fabStep.Label' defaultMessage='Floating action button' />,
    <FormattedMessage
      id='tutorial.fabStep.Message'
      defaultMessage='Most pages have an action button, that allows one to perform one or more primary tasks'
    />,
    {
      desktop: <img src={desktopFabImg} alt='fab' />,
      mobile: <img src={mobileFabImg} alt='fab' />,
    },
  ],
];
