import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import compose from 'recompose/compose';
import classnames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import { isMobile } from 'react-device-detect';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/Add';
import SpeedDial from '../components/CustomSpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import FileUpload from '../components/customIcons/FileUpload';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { isEmpty } from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { Link, LinkOutline } from '../components/customIcons';

import FileOutline from '../components/customIcons/FileOutline';
import { backupActions, appBarActions } from '../redux-stuff/actions';
import { fetcher } from '../util/deps';
import { setCookie, getCookie } from '../util/cookie';
import { parsePermissions } from '../util/permissions';
import { getSize } from '../util/commonHelpers';
import { snackbarMessageTypes } from '../redux-stuff/constants';
import ActionsMenu from '../components/ActionsMenu';
import TableToolbar from '../components/TableToolbar';
import ResponsiveDialog from '../components/responsive-dialog';
import TablePaginationActions from '../components/TablePaginationActions';
import LinkAccessPopover from '../components/backupPage/LinkAccessPopover';
import ConfirmDialogWithPhrase from '../components/ConfirmDialogWithPhrase';
import BootstrapLikeAlert from '../components/BootstrapLikeAlert';
import FileUploader from '../components/FileUploader';
import PageLoadingIndicator from '../components/PageLoadingIndicator';
import Alerts from '../components/Alerts';
import TooltipWrapper from '../components/TooltipWrapper';

const CURRENT_BACKUP_SYMLINK_NAME = 'backup_current.tar.bz2';

const styles = theme => ({
  root: {
    margin: 'auto',
    maxWidth: 1100,
  },
  mobileRoot: {
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    height: `calc(100vh - 56px)`,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: `calc(100vh - 48px)`,
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100vh - 64px)`,
    },
  },
  filenameCell: {
    [theme.breakpoints.only('xs')]: {
      width: 'calc(100vw - 72px)',
      paddingLeft: theme.spacing(1),
    },
  },
  tableRow: {
    cursor: 'default',
    '&:hover': {
      backgroundColor: theme.palette.table.hover,
    },
    '& .hover-button': {
      visibility: 'hidden',
    },
    '&:hover .hover-button': {
      visibility: 'initial',
    },
  },
  currentBackupRow: {
    '& p': {
      color: theme.palette.text.secondary,
    },
    '& span:not(:first-of-type)': {
      color: theme.palette.text.secondary,
    },
  },
  paper: {
    position: 'relative',
    width: '100%',
    overflowX: 'auto',
  },
  secondaryColor: {
    color: theme.palette.text.secondary,
  },
  cell: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 0,
    },
  },
  container: {
    display: 'flex',
  },
  verticalCentered: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  toggleButton: {
    padding: '4px 12px',
    borderRadius: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  corruptedFile: {
    color: theme.palette.error.main,
  },
  toggleButtonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  breakAll: {
    wordBreak: 'break-all',
  },
  primary: {
    '&:hover': {
      backgroundColor: 'rgba(255, 177, 43, 0.2)',
    },
    '& span': {
      color: theme.palette.primary.main,
    },
  },
});

class BackupPage extends React.Component {
  state = {
    dialOpen: false,
    restoreDialogOpen: false,
    deleteDialogOpen: false,
    deleteSelectedDialogOpen: false,
    linkPopoverOpen: false,
    linkRegenerating: false,
    managementMenuOpen: false,
    backupUploaderOpen: false,
    anchorEl: null,
    popoverAnchorEl: null,
    selected: [],
    page: 0,
    rowsPerPage: +getCookie('backupsPerPage') || 10,
  };

  componentDidMount() {
    const { dispatch, permissions } = this.props;
    this.actions = {
      ...bindActionCreators(backupActions, dispatch),
      ...bindActionCreators(appBarActions, dispatch),
    };
    this.actions.loadBackups();
    this.setState({ permissions: parsePermissions(permissions, 'backup') });
  }

  componentWillUnmount() {
    this.actions.resetActions();
  }

  updateAppBarMenu = () => {
    const { selected, permissions } = this.state;
    let selectedCount = selected.length;
    let text = null,
      leftButton = null,
      rightButtons = null;

    if (selectedCount) {
      text = (
        <FormattedMessage
          id='backupPage.appbarMessage.selected'
          defaultMessage='{selectedCount} selected'
          values={{ selectedCount }}
        />
      );

      leftButton = {
        title: <FormattedMessage id='drawerWrapper.deselectAll' defaultMessage='Undo selection' />,
        icon: <CloseIcon />,
        action: this.undoSelection,
      };

      rightButtons =
        permissions.w && (selectedCount > 1 || selected[0] !== CURRENT_BACKUP_SYMLINK_NAME)
          ? [
              {
                key: 'delete_selected',
                title: <FormattedMessage id='drawerWrapper.deleteSelected' defaultMessage='Delete selected' />,
                icon: <DeleteIcon />,
                action: this.handleDeleteSelected,
              },
            ]
          : [];

      if (
        permissions.w &&
        selectedCount === 1 &&
        !this.props.backups.find(({ filename }) => filename === selected[0]).corrupted
      ) {
        rightButtons.push({
          key: 'other_actions',
          title: <FormattedMessage id='drawerWrapper.otherActions' defaultMessage='Other actions' />,
          icon: <MoreVertIcon />,
          action: this.handleOpenMenu(selected[0]),
        });
      }
    }

    this.actions.setActions({
      text,
      leftButton,
      rightButtons,
    });
  };

  handleClose = () =>
    this.setState({ restoreDialogOpen: false, deleteDialogOpen: false, deleteSelectedDialogOpen: false });

  handleOpenMenu = name => event => {
    event.stopPropagation();
    this.setState({ name, anchorEl: event.currentTarget, managementMenuOpen: true });
  };

  handleDeleteAccept = () => {
    fetcher.delete(`backup/${this.state.name}`, undefined, false);
    this.setState({ deleteDialogOpen: false });
  };

  handleSelectPage = displayedBackups => (event, checked) => {
    const otherSelected = this.state.selected.filter(
      name => !displayedBackups.find(({ filename }) => filename === name)
    );
    const selected = checked ? [...otherSelected, ...displayedBackups.map(({ filename }) => filename)] : otherSelected;
    this.setState({ selected }, this.updateAppBarMenu);
  };

  handleDeleteSelected = () => this.setState({ deleteSelectedDialogOpen: true });

  handleDeleteSelectedAccept = async () => {
    const { selected } = this.state;
    await Promise.all(
      selected
        .filter(name => name !== CURRENT_BACKUP_SYMLINK_NAME)
        .map(name => fetcher.delete(`backup/${name}`, undefined, false))
    );
    this.setState({ deleteSelectedDialogOpen: false, selected: [] }, this.updateAppBarMenu);
  };

  handleDelete = () => this.setState({ deleteDialogOpen: true, managementMenuOpen: false });

  handleRestore = () => this.setState({ restoreDialogOpen: true, managementMenuOpen: false });

  handleCloseMenu = () => this.setState({ managementMenuOpen: false });

  handleChangeRowsPerPage = event => {
    setCookie('backupsPerPage', event.target.value);
    this.setState({ rowsPerPage: event.target.value });
  };

  handleChangePage = (event, page) => this.setState({ page });

  handleBackup = async () => {
    this.setState({ dialOpen: false });
    try {
      await fetcher.post('backup');
    } catch (e) {
      this.props.addMessage({ type: snackbarMessageTypes.failure, data: e });
    }
  };

  handleClick = filename => () => {
    const { selected } = this.state;
    const checked = selected.includes(filename);
    const selectedFilenames = !checked ? [...selected, filename] : selected.filter(name => name !== filename);
    const name = selectedFilenames.length === 1 ? selectedFilenames[0] : undefined;
    this.setState({ name, selected: selectedFilenames }, this.updateAppBarMenu);
  };

  undoSelection = () => {
    this.setState({ selected: [] }, this.updateAppBarMenu);
  };

  handleRestoreAccept = async () => {
    let error = false;
    try {
      await fetcher.put(`backup/${this.state.name}`);
    } catch (e) {
      error = true;
      this.props.addMessage({
        type: snackbarMessageTypes.failure,
        data: <FormattedMessage id='backupPage.snackbar.restoringFailed' defaultMessage='Restore from backup failed' />,
      });
    }
    if (!error) {
      this.props.addMessage({
        type: snackbarMessageTypes.success,
        data: (
          <FormattedMessage
            id='backupPage.snackbar.restoringCompleted'
            defaultMessage='Restore from backup completed!'
          />
        ),
      });
    }
    this.setState({ restoreDialogOpen: false, selected: [] });
  };

  handleDownload = async () => {
    this.setState({ managementMenuOpen: false });
    try {
      await fetcher.get(`backup/${this.state.name}/download`);
    } catch (e) {
      this.props.addMessage({ type: snackbarMessageTypes.failure, data: e });
    }
  };

  handleOpenLinkPopover = event => {
    event.stopPropagation();
    this.setState({ linkPopoverOpen: true, popoverAnchorEl: event.currentTarget });
  };

  handleCloseLinkPopover = () => this.setState({ linkPopoverOpen: false });

  toggleLinkAccess = async () => {
    if (this.state.confirmDisableLinkAccessDialogOpen) {
      this.setState({ confirmDisableLinkAccessDialogOpen: false });
    }
    const currentLink = this.props.backups.find(b => b.filename === CURRENT_BACKUP_SYMLINK_NAME).links[0];
    try {
      if (currentLink) {
        const token = currentLink.split('/').slice(-3)[0];
        await fetcher.delete(`backup/${token}/download/${CURRENT_BACKUP_SYMLINK_NAME}`);
      } else {
        await fetcher.post(`backup/${CURRENT_BACKUP_SYMLINK_NAME}/token`);
      }
    } catch (e) {
      this.props.addMessage({ type: snackbarMessageTypes.failure, data: e });
    }
    try {
      const backups = await fetcher.get('backup');
      this.actions.setBackups(backups);
    } catch (e) {
      this.props.addMessage({ type: snackbarMessageTypes.failure, data: e });
    }
  };

  handleRegenerateLinkClick = () => this.setState({ confirmRegenerateDialogOpen: true, linkPopoverOpen: false });

  handleRegenerateLick = async () => {
    this.setState({ confirmRegenerateDialogOpen: false, linkRegenerating: true });
    await this.toggleLinkAccess();
    await this.toggleLinkAccess();
    this.setState({ linkRegenerating: false });
  };

  handleCloseRegenerateDialog = () => this.setState({ confirmRegenerateDialogOpen: false });

  openFileUploader = () => this.state.dialogFrozen || this.setState({ backupUploaderOpen: true, dialOpen: false });
  closeFileUploader = () => this.state.dialogFrozen || this.setState({ backupUploaderOpen: false });

  // drag events won't toggle dialog
  openFileUploaderFreeze = () => this.setState({ backupUploaderOpen: true, dialOpen: false, dialogFrozen: true });
  closeFileUploaderUnfreeze = () => this.setState({ backupUploaderOpen: false, dialogFrozen: false });

  handleToggleLinkAccessClick = () =>
    this.setState({ confirmDisableLinkAccessDialogOpen: true, linkPopoverOpen: false });

  handleCloseDisableLinkAccessDialog = () => this.setState({ confirmDisableLinkAccessDialogOpen: false });

  handleClickDial = () => this.setState({ dialOpen: !this.state.dialOpen });

  handleCloseDial = e => {
    if (e.type !== 'mouseleave') {
      this.setState({ dialOpen: false });
    }
  };

  handleOpenDial = () => this.setState({ dialOpen: true });

  static mapStateToProps({ backups }) {
    return { backups };
  }

  handleDeleteCorrupted = fileName => () => this.setState({ selected: [fileName] }, this.handleDeleteSelected);

  render() {
    const { backups, classes, width } = this.props;
    const {
      dialOpen,
      restoreDialogOpen,
      deleteDialogOpen,
      deleteSelectedDialogOpen,
      linkPopoverOpen,
      confirmRegenerateDialogOpen,
      confirmDisableLinkAccessDialogOpen,
      linkRegenerating,
      backupUploaderOpen,
      page,
      rowsPerPage,
      name,
      selected,
      managementMenuOpen,
      anchorEl,
      popoverAnchorEl,
      permissions = {},
    } = this.state;

    const { w } = permissions;
    const { loadBackups } = this.actions || {};
    const xs = width === 'xs';
    const mdUp = width !== 'xs' && width !== 'sm';
    const lgUp = width === 'lg' || width === 'xl';
    const mobile = !mdUp || isMobile;

    let lastBackup;
    let backupsToBeDisplayed;
    let selectedCorrupted;
    let displayPagination;
    let currentBackup;
    let linkToDownloadCurrentBackup;
    if (backups) {
      const sortedBackups = backups
        .slice()
        .sort(
          (
            { corrupted: corruptedA, filename: filenameA, stat: statA },
            { corrupted: corruptedB, filename: filenameB, stat: statB }
          ) => {
            if (corruptedA) return -1;
            if (corruptedB) return 1;
            return statA.ctime !== statB.ctime
              ? Date.parse(statB.ctime) - Date.parse(statA.ctime)
              : filenameB.localeCompare(filenameA);
          }
        );
      lastBackup = sortedBackups.find(backup => !backup.corrupted);
      const selectedBackup = selected.length === 1 && !!backups.find(({ filename }) => filename === selected[0]);
      selectedCorrupted = selectedBackup && selectedBackup.corrupted;
      displayPagination = lgUp;
      backupsToBeDisplayed = displayPagination
        ? sortedBackups.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : sortedBackups;
      currentBackup = backups.find(b => b.filename === CURRENT_BACKUP_SYMLINK_NAME);
      linkToDownloadCurrentBackup = currentBackup && currentBackup.links[0];
    }

    const linkMessage = (
      <FormattedMessage
        id='backupPage.currentBackupTooltipNew'
        defaultMessage='This is a link to the latest automatic backup file'
      />
    );

    return (
      <div className={classnames(classes.root, mobile ? classes.mobileRoot : '')}>
        <Alerts issuesPermission={parsePermissions(this.props.permissions, 'issues').r} />
        <PageLoadingIndicator open={backups === null} />

        <BootstrapLikeAlert
          open={!!linkToDownloadCurrentBackup || linkRegenerating}
          pageTopMargins
          message={
            <FormattedMessage id='backupPage.alert' defaultMessage='Latest automatic backup download link is enabled' />
          }
          dangerLevel='info'
        />

        <Paper className={classes.paper}>
          {!isEmpty(backups) && (
            <Fragment>
              <TableToolbar
                handleDelete={this.handleDeleteSelected}
                handleRestore={this.handleRestore}
                handleDownload={this.handleDownload}
                numSelected={selected.length}
                selectedCorrupted={selectedCorrupted}
                numTotal={backups.length}
                caption={
                  lastBackup && (
                    <FormattedDate
                      value={new Date(lastBackup.stat.ctime)}
                      day='numeric'
                      month='short'
                      year='numeric'
                      hour='numeric'
                      minute='numeric'
                    />
                  )
                }
                forbidDelete={name === CURRENT_BACKUP_SYMLINK_NAME}
                subheading={'backup'}
                xs={xs}
                isMobile={isMobile}
                w={w}
              />
              <Table size='small'>
                {!xs && (
                  <TableHead>
                    <TableRow>
                      {w && (
                        <TableCell padding='checkbox'>
                          <Checkbox
                            checked={backupsToBeDisplayed.every(({ filename }) => selected.includes(filename))}
                            onChange={this.handleSelectPage(backupsToBeDisplayed)}
                            indeterminate={
                              backupsToBeDisplayed.some(({ filename }) => selected.includes(filename)) &&
                              backupsToBeDisplayed.some(({ filename }) => !selected.includes(filename))
                            }
                            color='primary'
                          />
                        </TableCell>
                      )}
                      <TableCell>
                        <Typography variant='caption'>
                          <FormattedMessage id='backupPage.backupName' defaultMessage='Name' />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='caption'>
                          <FormattedMessage id='backupPage.type' defaultMessage='Type' />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='caption'>
                          <FormattedMessage id='backupPage.size' defaultMessage='Size' />
                        </Typography>
                      </TableCell>
                      {mdUp && (
                        <TableCell>
                          <Typography variant='caption'>
                            <FormattedMessage id='backupPage.created' defaultMessage='Created' />
                          </Typography>
                        </TableCell>
                      )}
                      {w && <TableCell align='right' />}
                    </TableRow>
                  </TableHead>
                )}
                <TableBody>
                  {backupsToBeDisplayed.map(({ filename, stat, isManual, corrupted }) => {
                    const isCurrentBackupSymlink = filename === CURRENT_BACKUP_SYMLINK_NAME;
                    const created = !corrupted && (
                      <FormattedDate
                        value={new Date(stat.ctime)}
                        day='numeric'
                        month='short'
                        year='numeric'
                        hour='numeric'
                        minute='numeric'
                      />
                    );

                    const isSelected = selected.includes(filename);
                    return (
                      <TableRow
                        selected={isSelected}
                        key={filename}
                        className={classnames(classes.tableRow, isCurrentBackupSymlink ? classes.currentBackupRow : '')}
                        onClick={w ? this.handleClick(filename) : null}
                      >
                        {(xs || w) && (
                          <TableCell padding='checkbox'>
                            {xs && selected.length === 0 ? (
                              <IconButton>
                                <FileOutline />
                              </IconButton>
                            ) : (
                              <Checkbox checked={selected.includes(filename)} color='primary' />
                            )}
                          </TableCell>
                        )}
                        <TableCell className={classes.filenameCell}>
                          <div className={classes.cell}>
                            <div className={classes.container}>
                              {!xs && (
                                <TooltipWrapper wrap={isCurrentBackupSymlink} title={linkMessage}>
                                  <Typography
                                    className={classnames(
                                      classes.verticalCentered,
                                      corrupted ? classes.corruptedFile : ''
                                    )}
                                  >
                                    {filename}
                                  </Typography>
                                </TooltipWrapper>
                              )}
                              <div className={classes.toggleButtonContainer}>
                                {xs && (
                                  <div>
                                    <TooltipWrapper wrap={isCurrentBackupSymlink} title={linkMessage}>
                                      <div>
                                        <Typography
                                          className={classnames(
                                            classes.breakAll,
                                            corrupted ? classes.corruptedFile : ''
                                          )}
                                        >
                                          {filename}
                                        </Typography>
                                        {!corrupted && (
                                          <Fragment>
                                            <Typography className={classes.secondaryColor}>
                                              {isCurrentBackupSymlink ? (
                                                <FormattedMessage
                                                  id='backupPage.backupTypeLinkLong'
                                                  defaultMessage='Type: link'
                                                />
                                              ) : (
                                                <FormattedMessage
                                                  id='backupPage.backupTypeLong'
                                                  defaultMessage='Type: {isManual, select, true {manual} other {auto}}'
                                                  values={{ isManual }}
                                                />
                                              )}
                                            </Typography>
                                            <Typography className={classes.secondaryColor}>
                                              <FormattedMessage
                                                id='backupPage.sizeColon'
                                                defaultMessage='Size: {size}'
                                                values={{ size: getSize(stat.size) }}
                                              />
                                            </Typography>
                                            <Typography className={classes.secondaryColor}>
                                              <FormattedMessage
                                                id='backupPage.creationDate'
                                                defaultMessage='Created: {created}'
                                                values={{ created }}
                                              />
                                            </Typography>
                                          </Fragment>
                                        )}
                                      </div>
                                    </TooltipWrapper>
                                  </div>
                                )}
                                {isCurrentBackupSymlink && (
                                  <IconButton
                                    onClick={this.handleOpenLinkPopover}
                                    classes={{ root: classes.toggleButton }}
                                    className={classnames(
                                      classes.toggleButton,
                                      linkToDownloadCurrentBackup ? classes.primary : ''
                                    )}
                                  >
                                    {linkToDownloadCurrentBackup ? <Link /> : <LinkOutline />}
                                  </IconButton>
                                )}
                              </div>
                            </div>
                          </div>
                        </TableCell>
                        {!xs && (
                          <Fragment>
                            <TableCell>
                              {!corrupted &&
                                (isCurrentBackupSymlink ? (
                                  <FormattedMessage id='backupPage.backupTypeLinkShort' defaultMessage='link' />
                                ) : (
                                  <FormattedMessage
                                    id='backupPage.backupType'
                                    defaultMessage='{isManual, select, true {manual} other {auto}}'
                                    values={{ isManual }}
                                  />
                                ))}
                            </TableCell>
                            <TableCell>{!corrupted && getSize(stat.size)}</TableCell>
                            {mdUp && <TableCell>{created}</TableCell>}
                            <TableCell padding='none' align='right'>
                              {w && selected.length === 0 && (
                                <Fragment>
                                  {!corrupted && (
                                    <IconButton onClick={this.handleOpenMenu(filename)}>
                                      <MoreVertIcon className={isMobile ? '' : 'hover-button'} />
                                    </IconButton>
                                  )}
                                  {corrupted && (
                                    <IconButton onClick={this.handleDeleteCorrupted(filename)}>
                                      <DeleteIcon className={isMobile ? '' : 'hover-button'} />
                                    </IconButton>
                                  )}
                                </Fragment>
                              )}
                            </TableCell>
                          </Fragment>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
                {displayPagination && (
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        count={backups.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                        labelDisplayedRows={this.labelDisplayedRows}
                        labelRowsPerPage={<FormattedMessage id='table.rowsPerPage' defaultMessage='Rows per page' />}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                )}
              </Table>
              <ActionsMenu
                open={managementMenuOpen}
                anchorEl={anchorEl}
                handleClose={this.handleCloseMenu}
                items={[
                  {
                    show: w && !xs && name !== CURRENT_BACKUP_SYMLINK_NAME,
                    Icon: DeleteIcon,
                    text: <FormattedMessage id='ActionsMenu.delete' defaultMessage='Delete' />,
                    handler: this.handleDelete,
                  },
                  {
                    show: w,
                    Icon: RestoreIcon,
                    text: <FormattedMessage id='ActionsMenu.restore' defaultMessage='Restore' />,
                    handler: this.handleRestore,
                  },
                  {
                    show: true,
                    Icon: DownloadIcon,
                    text: <FormattedMessage id='ActionsMenu.download' defaultMessage='Download' />,
                    handler: this.handleDownload,
                  },
                ]}
              />
            </Fragment>
          )}
        </Paper>
        {w && (
          <ConfirmDialogWithPhrase
            open={restoreDialogOpen}
            title={name}
            confirmButtonText={<FormattedMessage id='ResponsiveDialog.restore' defaultMessage='Restore' />}
            message={
              <Typography paragraph>
                <FormattedMessage
                  id='ResponsiveDialog.restoreConfirmation.message'
                  defaultMessage='Are you sure you want to restore from {name}? This may be dangerous'
                  values={{ name }}
                />
              </Typography>
            }
            onClose={this.handleClose}
            onConfirm={this.handleRestoreAccept}
          />
        )}
        {w && (
          <ResponsiveDialog
            open={deleteDialogOpen}
            title={name}
            message={
              <FormattedMessage
                id='ResponsiveDialog.deleteConfirmation'
                defaultMessage='Are you sure you want to delete {name}?'
                values={{ name }}
              />
            }
            confirmButtonText={<FormattedMessage id='ResponsiveDialog.delete' defaultMessage='Delete' />}
            onClose={this.handleClose}
            onConfirm={this.handleDeleteAccept}
          />
        )}
        {w && (
          <ResponsiveDialog
            open={deleteSelectedDialogOpen}
            title={
              <FormattedMessage id='ResponsiveDialog.deleteBackupsTitle' defaultMessage='Delete selected backups' />
            }
            message={
              <FormattedMessage
                id='ResponsiveDialog.deleteBackupsMessage'
                defaultMessage={`Are you sure you want to delete {number, plural, one {# backup} other {# backups}}?`}
                values={{ number: selected.length }}
              />
            }
            confirmButtonText={<FormattedMessage id='ResponsiveDialog.delete' defaultMessage='Delete' />}
            onClose={this.handleClose}
            onConfirm={this.handleDeleteSelectedAccept}
          />
        )}
        {w && (
          <ResponsiveDialog
            open={!!confirmRegenerateDialogOpen}
            title={<FormattedMessage id='ResponsiveDialog.resetLink' defaultMessage='Reset link' />}
            message={
              <FormattedMessage
                id='ResponsiveDialog.resetLinkMessage'
                defaultMessage={
                  'Are you sure you want to reset the current backup download link? ' +
                  'Current link will be replaced with a new one'
                }
              />
            }
            confirmButtonText={<FormattedMessage id='ResponsiveDialog.reset' defaultMessage='reset' />}
            onClose={this.handleCloseRegenerateDialog}
            onConfirm={this.handleRegenerateLick}
          />
        )}
        {w && (
          <ResponsiveDialog
            open={!!confirmDisableLinkAccessDialogOpen}
            title={<FormattedMessage id='ResponsiveDialog.disableLinkAccess' defaultMessage='Disable link access' />}
            message={
              <FormattedMessage
                id='ResponsiveDialog.disableLinkAccessConfirmation'
                defaultMessage='Are you sure you want to disable downloading by link?'
              />
            }
            confirmButtonText={<FormattedMessage id='ResponsiveDialog.disable' defaultMessage='disable' />}
            onClose={this.handleCloseDisableLinkAccessDialog}
            onConfirm={this.toggleLinkAccess}
          />
        )}
        {w && (
          <SpeedDial
            ariaLabel='speedDial'
            open={!!dialOpen}
            label={<FormattedMessage id='backupPage.speedDialLabel' defaultMessage='New' />}
            onClick={this.handleClickDial}
            onClose={this.handleCloseDial}
          >
            <SpeedDialAction
              key='make-backup'
              icon={<AddIcon />}
              tooltipTitle={<FormattedMessage id='backupPage.makeBackup' defaultMessage='Create backup' />}
              onClick={this.handleBackup}
            />
            <SpeedDialAction
              key='upload-backup'
              icon={<FileUpload />}
              tooltipTitle={<FormattedMessage id='backupPage.uploadBackup' defaultMessage='Upload backup' />}
              onClick={this.openFileUploaderFreeze}
            />
          </SpeedDial>
        )}
        {w && (
          <FileUploader
            open={backupUploaderOpen}
            onClose={this.closeFileUploaderUnfreeze}
            onOpenDnD={this.openFileUploader}
            onCloseDnD={this.closeFileUploader}
            reloadFiles={loadBackups}
            addMessage={this.props.addMessage}
            resource='backup'
            title={<FormattedMessage id='backupuploader.title' defaultMessage='Backup upload' />}
          />
        )}
        <LinkAccessPopover
          open={linkPopoverOpen}
          anchorEl={popoverAnchorEl}
          onClose={this.handleCloseLinkPopover}
          link={linkToDownloadCurrentBackup}
          toggleLinkAccess={linkToDownloadCurrentBackup ? this.handleToggleLinkAccessClick : this.toggleLinkAccess}
          regenerateLink={this.handleRegenerateLinkClick}
          addMessage={this.props.addMessage}
        />
      </div>
    );
  }
}

BackupPage.propTypes = {
  classes: PropTypes.object.isRequired,
  backups: PropTypes.arrayOf(PropTypes.object),
};

export default connect(BackupPage.mapStateToProps)(compose(withStyles(styles), withWidth())(BackupPage));
