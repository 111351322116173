import { fetcher } from '../../util/deps';
import { SET_SERVICES, SET_SERVICE_STATUS, DELETE_SERVICE, ADD_SERVICE } from '../constants';

const loadServices = () => async dispatch => {
  let data = await fetcher.get('services', undefined, false);
  if (data) {
    dispatch(setServices(data));
  }
};

const setServices = services => {
  const type = SET_SERVICES,
    payload = services;
  return { type, payload };
};

const setServiceStatus = payload => {
  const type = SET_SERVICE_STATUS;
  return { type, payload };
};

const deleteService = payload => {
  const type = DELETE_SERVICE;
  return { type, payload };
};

const addService = payload => {
  const type = ADD_SERVICE;
  return { type, payload };
};

export default {
  setServices,
  loadServices,
  setServiceStatus,
  deleteService,
  addService,
};
