import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

export default createSvgIcon(
  <g>
    <path
      d={
        'm5 3c-1.11 0-2 0.9-2 2v14c0 1.1 0.89 2 2 2h14c1.11 0 2-0.9 ' +
        '2-2v-14c0-1.1-0.89-2-2-2h-14zm-0.066406 ' +
        '1.9336h14.133v14.133h-14.133v-14.133zm5.8828 ' +
        '2.0273c-0.4648 0-0.83789 0.37505-0.83789 ' +
        '0.83984v2.1719h-2.1719c-0.4648 0-0.8418 0.37505-0.8418 ' +
        '0.83984v2.5762 2.8105 0.83984h0.8418 ' +
        '0.37109v-2.1875h1.0332v2.1875h1.1699v-2.1875h1.0352v2.1875h1.' +
        '1699v-2.1875h1.0332v2.1875h1.1699v-2.1875h1.0332v2.1875h0.37305 ' +
        '0.83984v-0.83984-2.8105-2.5762c0-0.4648-0.37505-0.83984-' +
        '0.83984-0.83984h-2.1738v-2.1719c0-0.4648-0.37505-0.83984-0.83984-0.83984h-2.3652z'
      }
    />
  </g>,
  'wired network'
);
