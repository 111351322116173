import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';

const styles = theme => ({
  outer: {
    display: 'flex',
    justifyContent: 'center',

    marginTop: theme.spacing(3),
  },
});

const Page404 = ({ classes }) => (
  <div className={classes.outer}>
    <Typography variant='h4'>
      <FormattedMessage id='errors.notfound' defaultMessage='404 Not Found' />
    </Typography>
  </div>
);

export default withStyles(styles)(Page404);
