import {
  ADD_FIRMWARE,
  DELETE_FIRMWARE,
  SET_FIRMWARE_FILES,
  TOGGLE_PAGE_SELECTION,
  TOGGLE_FIRMWARE_SELECTION,
  UNDO_FIRMWARE_SELECTION,
  SELECT_ONE_FIRMWARE,
} from '../constants';
import { fetcher } from '../../util/deps';

const setFirmwareFiles = (firmwareFiles, fromWebSocket = false) => {
  const type = SET_FIRMWARE_FILES,
    payload = firmwareFiles.sort((a, b) =>
      // place corrupt files at the top of the list
      b.corrupted || a.corrupted
        ? +Boolean(b.corrupted) - +Boolean(a.corrupted)
        : +new Date(b.info.date) - +new Date(a.info.date)
    );
  return { type, payload, fromWebSocket };
};

const addFirmware = (data, fromWebSocket = false) => {
  const type = ADD_FIRMWARE,
    payload = data;
  return { type, payload, fromWebSocket };
};

const deleteFirmwareAction = (data, fromWebSocket = true) => {
  const type = DELETE_FIRMWARE,
    payload = data;
  return { type, payload, fromWebSocket };
};

const toggleFirmwareSelection = filename => {
  const type = TOGGLE_FIRMWARE_SELECTION,
    payload = filename;
  return { type, payload };
};

const togglePageSelection = displayedFirmwareFiles => {
  const type = TOGGLE_PAGE_SELECTION;
  return { type, payload: displayedFirmwareFiles };
};

const undoFirmwareSelection = () => {
  const type = UNDO_FIRMWARE_SELECTION;
  return { type };
};

const selectOneFirmware = filename => {
  const type = SELECT_ONE_FIRMWARE;
  return { type, payload: filename };
};

const loadFirmwareFiles = () => async dispatch => {
  let data = await fetcher.get('firmware', undefined, false);
  if (data) {
    data.sort((a, b) =>
      // place corrupt files at the top of the list
      b.corrupted || a.corrupted
        ? +Boolean(b.corrupted) - +Boolean(a.corrupted)
        : +new Date(b.info.date) - +new Date(a.info.date)
    );
    dispatch(setFirmwareFiles(data));
  }
};

export default {
  setFirmwareFiles,
  toggleFirmwareSelection,
  togglePageSelection,
  undoFirmwareSelection,
  loadFirmwareFiles,
  addFirmware,
  deleteFirmwareAction,
  selectOneFirmware,
};
