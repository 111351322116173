import { FC, ReactNode } from 'react';
import { Fade, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

interface AddressSummaryProps {
  justCreated?: boolean;
  bond?: string;
  expanded?: boolean;
  addressToShow?: ReactNode;
}

const AddressSummary: FC<AddressSummaryProps> = ({ justCreated, bond, expanded, addressToShow }) => {
  if (justCreated) return null;

  return (
    <Fade in={!expanded}>
      <Typography>
        {bond ? (
          <FormattedMessage id='networkPage.includedInBond' defaultMessage='included in {bond}' values={{ bond }} />
        ) : (
          addressToShow
        )}
      </Typography>
    </Fade>
  );
};

export default AddressSummary;
