import React, { Fragment } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import DrawerComponent from '@material-ui/core/Drawer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

import { drawerActions } from '../redux-stuff/actions';
import DrawerItems from '../components/DrawerItems';
import { havePermission } from '../util/permissions';
import PageErrorSnackbar from '../components/common/PageErrorSnackbar';

const drawerWidth = 256;

const styles = theme => {
  const makeTransition = (props, duration) =>
    theme.transitions.create(props, {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration[duration],
    });
  return {
    drawerPaper: {
      position: 'fixed',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: makeTransition('width', 'enteringScreen'),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: makeTransition('width', 'leavingScreen'),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      ...theme.mixins.toolbar,
    },
    list: {
      [theme.breakpoints.up('lg')]: {
        padding: 0,
      },
    },
  };
};

class Drawer extends React.Component {
  setDrawerState = state => this.props.dispatch(drawerActions.setDrawerState(state));

  handleDrawerToggle = () => this.props.dispatch(drawerActions.toggleDrawerState());

  toggleExpandNested = item => () => {
    const { openNested } = this.props.drawer;
    this.setDrawerState({
      openNested: openNested.includes(item) ? openNested.filter(name => name !== item) : [...openNested, item],
    });
  };

  static mapStateToProps({ drawer, license, services }) {
    return { drawer, license, ...services };
  }

  render() {
    const { classes, drawer, width, permissions, license, services, pageError, location, localError } = this.props;

    const canReadLicense = !!permissions && havePermission(permissions, 'license');
    const licenseIssue = canReadLicense && license && license.state !== undefined && license.state.ok === false;
    const servicesIssue = (services || []).some(s => s.state === 'dead');

    const actualUri = location.pathname.match(/(\/[-\w]*).*/)[1];

    const lgUp = width === 'lg' || width === 'xl';
    const renderNull = () => null;
    const renderDrawer = () =>
      lgUp ? (
        <DrawerComponent
          variant='permanent'
          classes={{ paper: classNames(classes.drawerPaper, !drawer.expanded && classes.drawerPaperClose) }}
          open={drawer.expanded}
        >
          <div className={classes.toolbar} />
          <Divider />
          <List className={classes.list}>
            <DrawerItems
              toggleExpandNested={this.toggleExpandNested}
              openNested={drawer.openNested}
              currentPage={actualUri}
              permissions={permissions}
              licenseIssue={licenseIssue}
              servicesIssue={servicesIssue}
              drawerExpanded={drawer.expanded}
              lgUp
            />
          </List>
          <Divider />
        </DrawerComponent>
      ) : (
        <SwipeableDrawer
          variant='temporary'
          open={drawer.expanded}
          onOpen={this.handleDrawerToggle}
          onClose={this.handleDrawerToggle}
          classes={{ paper: classes.drawerPaper }}
          ModalProps={{ keepMounted: true }} // Better open performance on mobile.
        >
          <div>
            <List className={classes.list}>
              <DrawerItems
                toggleExpandNested={this.toggleExpandNested}
                openNested={drawer.openNested}
                currentPage={actualUri}
                permissions={permissions}
                handleDrawerToggle={this.handleDrawerToggle}
                licenseIssue={licenseIssue}
                drawerExpanded={drawer.expanded}
              />
            </List>
            <Divider />
          </div>
        </SwipeableDrawer>
      );
    return (
      <Fragment>
        {!localError && (
          <Switch>
            <Route exact path='/' render={pageError ? renderDrawer : renderNull} />
            <Route exact path='/login' render={renderNull} />
            <Route render={renderDrawer} />
          </Switch>
        )}
        <PageErrorSnackbar open={localError} />
      </Fragment>
    );
  }
}

Drawer.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
};

export default compose(withRouter, connect(Drawer.mapStateToProps), withStyles(styles), withWidth())(Drawer);
