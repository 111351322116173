import { ComponentType, FC } from 'react';
import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  InputLabelProps,
  FormHelperTextProps,
  TooltipProps,
  Theme,
  makeStyles,
} from '@material-ui/core';
import EndAdornment from './EndAdornment';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 170,
  },
}));

export interface CustomTextFieldProps {
  id: string;
  error?: FormHelperTextProps['children'];
  label: InputLabelProps['children'];
  value?: string | number;
  onChange(v: string): void;
  endAdornment?:
    | {
        tooltip: TooltipProps['title'];
        Icon?: ComponentType;
      }
    | false;
}

const CustomTextField: FC<CustomTextFieldProps> = ({ id, error, label, value = '', onChange, endAdornment }) => {
  const classes = useStyles();
  return (
    <FormControl error={!!error} className={classes.formControl}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Input
        id={id}
        value={value || ''}
        onChange={({ target: { value } }) => onChange(value)}
        endAdornment={<EndAdornment show={!!endAdornment} {...endAdornment} />}
      />
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

export default CustomTextField;
