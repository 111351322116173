import React from 'react';

const SvgComponent = props => (
  <svg
    width={44.529}
    height={48}
    viewBox='0 0 1278 1377'
    shapeRendering='geometricPrecision'
    textRendering='geometricPrecision'
    imageRendering='optimizeQuality'
    fillRule='evenodd'
    clipRule='evenodd'
    {...props}
  >
    <path
      className='svg'
      d='M392 741c8 0 16-3 22-8 14-12 15-33 3-47-55-61-85-140-85-221 0-82 30-161 85-222 12-13 11-34-3-47-13-12-34-11-46 3-66 73-102 168-102 266s36 192 102 265c6 8 15 11 24 11zm522-8c7 5 14 8 22 8 10 0 19-3 25-11 66-73 102-167 102-265s-36-193-102-266c-12-14-33-15-47-3-13 13-15 34-2 47 54 61 84 140 84 222 0 81-30 160-84 221-13 14-11 35 2 47zM227 920c13-12 14-33 2-47C124 762 66 617 66 465s58-298 163-409c12-13 11-34-2-47s-34-12-47 1C64 134 0 296 0 465s64 330 180 454c7 7 15 10 24 10 8 0 17-3 23-9zm922-910c-13-13-34-14-47-1-14 13-14 34-2 47 105 111 162 257 162 409s-57 297-162 408c-12 14-12 35 2 47 6 6 14 9 22 9 9 0 18-3 25-10 55-59 98-127 129-200V210c-31-73-74-141-129-200zM756 1377l157-86c2-1 3-2 5-3l37 89h72L695 585c-5-12-17-20-31-20-13 0-25 8-30 20l-333 792h72l38-89c1 1 3 2 5 3l156 86h184zm-269-123l177-177 178 177-178 98-177-98zm61-293l69 69-169 169 100-238zm116-277l90 214c-5 2-10 5-14 9l-76 76-76-76c-4-4-9-7-14-9l90-214zm216 515l-169-169 69-69 100 238zM764 465c0-55-45-100-100-100s-99 45-99 100 44 100 99 100 100-45 100-100z'
    />
  </svg>
);

export default SvgComponent;
