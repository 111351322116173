import { FC } from 'react';
import { ModuleInfoDialogProps } from './ModuleInfoDialog';
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import emitter from '@/emitter';
import copy from 'copy-to-clipboard';

const ModuleInfoCopyButton: FC<Pick<ModuleInfoDialogProps, 'activeModule'>> = ({ activeModule }) => {
  if (!activeModule) return null;

  const handleCopy = () => {
    let info: { module: Record<string, string>; components?: Record<string, Record<string, string>> } = {
      module: {
        name: activeModule.name,
        revision: activeModule.revision,
        version: activeModule.version,
        build: activeModule.build,
      },
    };
    if (activeModule.buildDate) {
      info.module['build date'] = activeModule.buildDate;
    }

    if (activeModule.components) {
      info.components = {};
      for (const [name, component] of Object.entries(activeModule.components)) {
        const componentInfo: Record<string, string> = {};
        for (const [field, value] of Object.entries(component)) {
          if (!['revision', 'version', 'variant'].includes(field)) continue;
          componentInfo[field] = value;
        }
        info.components[name] = componentInfo;
      }
    }

    copy(JSON.stringify(info, null, 2));

    emitter.emit('addMessage', {
      type: 'success',
      data: <FormattedMessage id='errorDetails.copiedToClipboard' defaultMessage='Copied to clipboard' />,
    });
  };

  return (
    <Button variant='outlined' color='primary' onClick={handleCopy}>
      <FormattedMessage id='copy' defaultMessage='Copy' />
    </Button>
  );
};

export default ModuleInfoCopyButton;
