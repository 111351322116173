import {
  ADD_FIRMWARE,
  DELETE_FIRMWARE,
  SET_FIRMWARE_FILES,
  TOGGLE_PAGE_SELECTION,
  TOGGLE_FIRMWARE_SELECTION,
  UNDO_FIRMWARE_SELECTION,
  SELECT_ONE_FIRMWARE,
} from '../constants';

export default (firmwareFiles = null, action) => {
  switch (action.type) {
    case SET_FIRMWARE_FILES:
      return action.payload;
    case TOGGLE_FIRMWARE_SELECTION:
      return firmwareFiles.map(f => ({
        ...f,
        isSelected: f.filename === action.payload ? !f.isSelected : f.isSelected,
      }));
    case TOGGLE_PAGE_SELECTION: {
      const allSelected = action.payload.every(f => f.isSelected);
      return firmwareFiles.map(f =>
        action.payload.find(file => file === f)
          ? {
              ...f,
              isSelected: !allSelected,
            }
          : f
      );
    }
    case UNDO_FIRMWARE_SELECTION:
      return (
        firmwareFiles &&
        firmwareFiles.map(f => ({
          ...f,
          isSelected: false,
        }))
      );
    case ADD_FIRMWARE:
      return [...firmwareFiles, action.payload].sort((a, b) =>
        // place corrupt files at the top of the list
        b.corrupted || a.corrupted
          ? +Boolean(b.corrupted) - +Boolean(a.corrupted)
          : +new Date(b.info.date) - +new Date(a.info.date)
      );
    case DELETE_FIRMWARE:
      return firmwareFiles.filter(f => f.filename !== action.payload.filename);
    case SELECT_ONE_FIRMWARE:
      return firmwareFiles.map(f => ({
        ...f,
        isSelected: f.filename === action.payload,
      }));
    default:
      return firmwareFiles;
  }
};
