const twoWeeks = 1000 * 60 * 60 * 24 * 14;

const valid = validity => {
  const now = +new Date();
  const from = +new Date(validity.notBefore);
  const till = +new Date(validity.notAfter);

  return from <= now && now <= till;
};

const expires = validity => {
  const now = +new Date();
  const till = +new Date(validity.notAfter);

  return now > till - twoWeeks;
};

const getComponentState = component => {
  let state = 'ok';

  if (!valid(component.validity)) {
    state = 'expired';
  } else {
    if (expires(component.validity)) {
      state = 'expires';
    }
  }

  return state;
};

const getLicenseState = license => {
  let res = {
    missing: false,
    expires: false,
    expired: false,
    aComponentExpires: false,
    aComponentExpired: false,
    aComponentRevoked: false,
    revoked: false,

    get ok() {
      return (
        !this.missing &&
        !this.expires &&
        !this.expired &&
        !this.aComponentExpires &&
        !this.aComponentExpired &&
        !this.revoked &&
        !this.aComponentRevoked
      );
    },
  };

  if (!license.information) {
    res.missing = true;
    return res;
  }

  const state = getComponentState(license.information);
  if (state !== 'ok') {
    res[state] = true;
  }

  if (license.information.revoked) {
    res.revoked = true;
  }

  if (res.expired) {
    return res;
  }

  license.components.forEach(component => {
    const state = getComponentState(component);

    if (!res.aComponentExpires && state === 'expires') {
      res.aComponentExpires = true;
    }

    if (!res.aComponentExpired && state === 'expired') {
      res.aComponentExpired = true;
    }

    if (component.revoked) {
      res.aComponentRevoked = true;
    }
  });

  return res;
};

export { getComponentState, getLicenseState };
