import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import compose from 'recompose/compose';
import classnames from 'classnames';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tooltip from '@material-ui/core/Tooltip';
import ErrorIcon from '@material-ui/icons/Error';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import RemoveIcon from '@material-ui/icons/Remove';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { fetcher } from '../util/deps';
import { FormattedMessage } from 'react-intl';
import PageLoadingIndicator from '../components/PageLoadingIndicator';

import { parsePermissions } from '../util/permissions';
import { connectionsActions } from '../redux-stuff/actions';
import ConnectionEditDialog from '../components/connectionsPage/ConnectionEditDialog';
import '../css/ConnectionsPage.css';
import Alerts from '../components/Alerts';

const styles = theme => ({
  root: {
    margin: 'auto',
    maxWidth: 1100,
  },
  mobileRoot: {
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    height: `calc(100vh - 56px)`,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: `calc(100vh - 48px)`,
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100vh - 64px)`,
    },
  },
  details: {
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  summaryContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '40%',
    [theme.breakpoints.only('xs')]: {
      flexBasis: '80%',
      flexDirection: 'column',
      alignItems: 'start',
    },
  },
  subheading: {
    color: theme.palette.text.secondary,
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(1),
    },
  },
  icon: {
    margin: theme.spacing(1),
  },
  editServiceIcon: {
    display: 'none',
  },
  iconDefault: {
    color: theme.palette.icons.default,
  },
  iconSuccess: {
    color: theme.palette.icons.success,
  },
  iconDanger: {
    color: theme.palette.icons.danger,
  },
  editButtonCell: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  table: {
    '& tr:last-child td': {
      borderBottom: 0,
    },
  },
  tableRow: {
    cursor: 'default',
    '&:hover': {
      backgroundColor: theme.palette.table.hover,
    },
    '&:focus': {
      backgroundColor: theme.palette.table.hover,
    },
    '&:hover $editServiceIcon': {
      display: 'inherit',
    },
  },
  leftCell: {
    paddingLeft: theme.spacing(1),
  },
  addressCell: {
    [theme.breakpoints.only('xs')]: {
      paddingRight: `${theme.spacing(1)}px !important`,
    },
  },
  column: {
    display: 'flex',
    alignItems: 'center',
  },
  noConnectionsContainer: {
    [theme.breakpoints.only('xs')]: {
      margin: theme.spacing(1),
    },
  },
});

const headingInfo = (connected, not_activated, not_connected, classes) => (
  <Typography className={classes.subheading}>
    <FormattedMessage
      id='connectionsPage.LabelDisplayedRows'
      defaultMessage='{connected} connected, {not_connected} disconnected, {not_activated} not used'
      values={{ connected, not_activated, not_connected }}
    />
  </Typography>
);

class ConnectionsPage extends React.Component {
  state = { panelsExpanded: [], editableService: {}, editServiceDialogOpen: false };

  componentDidMount() {
    const { permissions, dispatch } = this.props;
    this.actions = bindActionCreators(connectionsActions, dispatch);
    this.actions.loadConnections();
    this.setState({ permissions: parsePermissions(permissions, 'connections') });
  }

  static mapStateToProps({ connections }) {
    return { connections };
  }

  handleEnterPressed = params => event => {
    let code = event.keyCode || event.which;
    if (code === 13) {
      event.preventDefault();
      this.setState({ editableService: params, editServiceDialogOpen: true });
    }
  };

  handleExpand = type => (event, expanded) =>
    this.setState({
      panelsExpanded: expanded
        ? [...this.state.panelsExpanded, type]
        : this.state.panelsExpanded.filter(item => item !== type),
    });

  handleEdit = params => () => this.setState({ editableService: params, editServiceDialogOpen: true });

  handleApply = serverHost => async () => {
    const { id } = this.state.editableService;
    await fetcher.put(`connections/${id}/${serverHost}`, undefined, false);
    this.setState({ editServiceDialogOpen: false });
    this.actions.loadConnections();
  };

  handleAutodetect = async () => {
    const { id } = this.state.editableService;
    await fetcher.delete(`connections/${id}`, undefined, false);
    this.setState({ editServiceDialogOpen: false });
    this.actions.loadConnections();
  };

  handleClose = () => this.setState({ editServiceDialogOpen: false });

  render() {
    const { classes, connections, width } = this.props;
    const { editableService, editServiceDialogOpen, permissions = {} } = this.state;

    const { w } = permissions;
    const xs = width === 'xs';
    const smDown = width === 'xs' || width === 'sm';
    const mobile = smDown || isMobileOnly;
    const tablet = !mobile && isMobile;

    let body;
    if (connections !== null) {
      if (isEmpty(connections)) {
        body = (
          <div className={classes.noConnectionsContainer}>
            <Typography color='textSecondary' variant='body1' align='center'>
              <FormattedMessage
                id='connectionsPage.noConnections'
                defaultMessage='None of the installed modules require connections'
              />
            </Typography>
          </div>
        );
      } else {
        body = (
          <div>
            {connections.map(({ type, connections, error }) => {
              const {
                connected = 0,
                not_connected = 0,
                not_activated = 0,
              } = connections.reduce(
                (acc, { status }) => ({ ...acc, [status]: acc[status] ? acc[status] + 1 : 1 }),
                {}
              );
              const panelExpanded = this.state.panelsExpanded.includes(type);
              return (
                <Accordion key={type} onChange={this.handleExpand(type)}>
                  <AccordionSummary
                    className={classes.summaryContainer}
                    expandIcon={
                      <Tooltip
                        title={
                          panelExpanded ? (
                            <FormattedMessage
                              id='connectionsPage.expandIconTooltip.collapse'
                              defaultMessage='Collapse'
                            />
                          ) : (
                            <FormattedMessage id='connectionsPage.expandIconTooltip.expand' defaultMessage='Expand' />
                          )
                        }
                      >
                        <ExpandMoreIcon />
                      </Tooltip>
                    }
                  >
                    <div className={classes.heading}>
                      <div className={classes.column}>
                        <Typography>{type}</Typography>
                        {error && (
                          <Tooltip title={<FormattedMessage id='connectionsPage.error' defaultMessage='Error!' />}>
                            <ErrorIcon color='error' />
                          </Tooltip>
                        )}
                      </div>
                      {xs && headingInfo(connected, not_activated, not_connected, classes)}
                    </div>
                    {!xs && (
                      <div className={classes.heading}>
                        <Fade in={!panelExpanded}>{headingInfo(connected, not_activated, not_connected, classes)}</Fade>
                      </div>
                    )}
                  </AccordionSummary>
                  <AccordionDetails className={classes.details}>
                    <Table className={classes.table}>
                      <TableBody>
                        {connections.map(({ type, host, status, login, sid, id, license }) => {
                          const deviceName = `${type}${license ? ` ${license}` : ''}`;
                          return (
                            <TableRow
                              key={id}
                              tabIndex={panelExpanded ? 0 : undefined}
                              className={classes.tableRow}
                              onClick={w ? this.handleEdit({ login, host, sid, id, deviceName }) : null}
                              onKeyPress={w ? this.handleEnterPressed({ login, host, sid, id, deviceName }) : null}
                            >
                              <TableCell padding='none' className={classes.leftCell}>
                                {deviceName}
                              </TableCell>
                              <TableCell padding='none'>
                                {status === 'connected' && (
                                  <div className={classes.container}>
                                    <CheckIcon className={classnames(classes.icon, classes.iconSuccess)} />
                                    {!xs && (
                                      <FormattedMessage id='connectionsPage.connected' defaultMessage='connected' />
                                    )}
                                  </div>
                                )}
                                {status === 'not_activated' && (
                                  <div className={classes.container}>
                                    <RemoveIcon className={classnames(classes.icon, classes.iconDefault)} />
                                    {!xs && <FormattedMessage id='connectionsPage.notUsed' defaultMessage='not used' />}
                                  </div>
                                )}
                                {status === 'not_connected' && (
                                  <div className={classes.container}>
                                    <CloseIcon className={classnames(classes.icon, classes.iconDanger)} />
                                    {!xs && (
                                      <FormattedMessage
                                        id='connectionsPage.disconnected'
                                        defaultMessage='disconnected'
                                      />
                                    )}
                                  </div>
                                )}
                              </TableCell>
                              <TableCell
                                padding='none'
                                align={xs ? 'right' : 'inherit'}
                                className={classes.addressCell}
                              >
                                {host}
                              </TableCell>
                              {!mobile && w && (
                                <TableCell className={classes.editButtonCell} padding='none'>
                                  <IconButton
                                    className={tablet ? '' : classes.editServiceIcon}
                                    onClick={this.handleEdit({ login, host, sid, id, deviceName })}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </AccordionDetails>
                </Accordion>
              );
            })}
            {w && (
              <ConnectionEditDialog
                open={editServiceDialogOpen}
                handleClose={this.handleClose}
                handleApply={this.handleApply}
                handleAutodetect={this.handleAutodetect}
                address={editableService.host || ''}
                deviceName={editableService.deviceName}
              />
            )}
          </div>
        );
      }
    }

    return (
      <div className={classnames(classes.root, mobile ? classes.mobileRoot : '')}>
        <Alerts issuesPermission={parsePermissions(this.props.permissions, 'issues').r} />
        <PageLoadingIndicator open={!body} />

        {body}
      </div>
    );
  }
}

export default connect(ConnectionsPage.mapStateToProps)(compose(withStyles(styles), withWidth())(ConnectionsPage));
