import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

export default createSvgIcon(
  <g>
    <path
      d={
        'm5.4957 22v-20h5.9478q1.2363 0.013736 2.4176 0.32967 1.1813 0.3022 2.1016 ' +
        '0.9478 0.92033 0.6456 1.4698 1.6484 0.54945 1.0027 0.53571 2.4038-0.01374 ' +
        '0.78297-0.26099 1.4286-0.23352 0.6456-0.65934 1.1676-0.41209 0.50824-0.97528 ' +
        '0.89286-0.56319 0.38462-1.1951 0.65934 0.7967 0.23352 1.456 0.67308 0.67308 ' +
        '0.42582 1.1538 1.044 0.48077 0.6044 0.74176 1.3736 0.27473 0.76923 0.27473 ' +
        '1.6621 0.01374 1.4011-0.53572 2.4725-0.54945 1.0714-1.4698 1.7994-0.92033 ' +
        '0.71429-2.1291 1.0989-1.1951 0.38462-2.4725 0.39835zm2.5549-9.3544v7.1978' +
        'h3.9148q0.7967-0.01374 1.511-0.26099 0.71429-0.26099 1.2637-0.71429 0.54945' +
        '-0.4533 0.86538-1.1126 0.32967-0.65934 0.32967-1.4973 0.01374-0.85165' +
        '-0.28846-1.511-0.28846-0.65934-0.81044-1.1126-0.52198-0.46703-1.2225-0.71428' +
        '-0.70055-0.24725-1.511-0.27473zm0-2.1154h3.544q0.71429-0.01374 1.4011-0.21978 ' +
        '0.68681-0.21978 1.2225-0.61813 0.53571-0.41209 0.86538-1.0027 0.32967-0.59066 ' +
        '0.32967-1.3736 0-0.83791-0.32967-1.4286-0.31593-0.59066-0.86538-0.96154-0.53571' +
        '-0.38462-1.25-0.56319-0.70055-0.17857-1.4423-0.19231h-3.4753z'
      }
    />
  </g>,
  'bond'
);
