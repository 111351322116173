import { FC } from 'react';
import { Fade, Typography, useTheme } from '@material-ui/core';
import { MessageDescriptor, defineMessages, useIntl } from 'react-intl';

interface StateCaptionProps {
  show: boolean;
  expanded?: boolean;
  state: string;
}

const states: Record<NonNullable<StateCaptionProps['state']>, MessageDescriptor> = defineMessages({
  UP: { id: 'networkPage.stateIsUp', defaultMessage: 'state is up' },
  DOWN: { id: 'networkPage.stateIsDown', defaultMessage: 'state is down' },
  DISABLED: { id: 'networkPage.expandedPanelSummary.stateIsDisabled', defaultMessage: 'disabled' },
});

const StateCaption: FC<StateCaptionProps> = ({ show, expanded, state }) => {
  const { palette } = useTheme();
  const intl = useIntl();

  const colors: Record<NonNullable<typeof state>, string> = {
    UP: palette.icons.success,
    DOWN: palette.icons.danger,
    DISABLED: palette.text.disabled,
  };

  if (!show) return null;

  return (
    <Fade in={expanded} unmountOnExit>
      <Typography style={{ color: colors[state] || colors['DOWN'], whiteSpace: 'pre' }}>
        {intl.formatMessage(states[state] || states['DOWN'])}
      </Typography>
    </Fade>
  );
};

export default StateCaption;
