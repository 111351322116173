import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';

export default function VlanIdForm(props) {
  const { classes, config, name, vlanIdError, handleEditVlan, w } = props;
  return (
    <div className={classes.container}>
      <FormControl className={classes.formControl} disabled={!w}>
        <InputLabel htmlFor={`vlan-id`} error={!!vlanIdError}>
          VLAN ID
        </InputLabel>
        <Input id={`vlan-id`} value={config.id} onChange={handleEditVlan(name, 'id')} error={!!vlanIdError} />
        <FormHelperText id={`vlan-id-error`} error={!!vlanIdError}>
          {vlanIdError}
        </FormHelperText>
      </FormControl>
    </div>
  );
}
