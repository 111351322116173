import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import compose from 'recompose/compose';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';
import { MuiPickersUtilsProvider, TimePicker, DatePicker } from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import AddIcon from '@material-ui/icons/Add';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';
import DateIcon from '@material-ui/icons/DateRange';
import TimeIcon from '@material-ui/icons/QueryBuilder';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@material-ui/icons';
import { connect } from 'react-redux';

import { parsePermissions } from '../util/permissions';
import { fetcher } from '../util/deps';
import ReactSelect from '../components/ReactSelect';
import AddNtpServerDialog from '../components/timePage/AddNtpServerDialog';
import BootstrapLikeAlert from '../components/BootstrapLikeAlert';
import PageLoadingIndicator from '../components/PageLoadingIndicator';
import Alerts from '../components/Alerts';
import { LocaleContext } from '../context';
import { InputAdornment } from '@material-ui/core';
import ProgressSwitch from '../components/common/ProgressSwitch';

const styles = theme => ({
  root: {
    margin: 'auto',
    maxWidth: 1100,
  },
  preRoot: {
    display: 'flex',
  },
  mobilePreRoot: {
    WebkitOverflowScrolling: 'touch',
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    margin: 'auto',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  timeDateContainer: {
    minHeight: '68px',
    marginBottom: theme.spacing(1),
  },
  textField: {
    '& + &': {
      marginLeft: theme.spacing(3),
    },
    width: `calc((100% - ${theme.spacing(3)}px) / 2)`,
    [theme.breakpoints.only('xs')]: {
      '& + &': {
        marginLeft: theme.spacing(2),
      },
      width: `calc((100% - ${theme.spacing(2)}px) / 2)`,
    },
  },
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  selectRoot: {
    width: '100%',
  },
  tableRow: {
    '& .delete-button': {
      visibility: 'hidden',
    },
    '&:hover .delete-button': {
      visibility: 'inherit',
    },
  },
  alert: {
    marginBottom: theme.spacing(1),
  },
});

class TimePage extends Component {
  static contextType = LocaleContext;

  state = {
    currentDate: null,
    timezonesList: [],
    timezoneName: '',
    apiData: {},
    openAddNtpServerDialog: false,
  };

  async componentDidMount() {
    const { permissions } = this.props;
    await this.updateState();

    // update time on start of each minute
    const secondsToNextMinute = 61 - new Date().getSeconds();
    const updateInterval = setTimeout(() => {
      this.updateTime();
      const updateInterval = setInterval(() => this.updateTime(), 60000);
      this.setState({ updateInterval });
    }, secondsToNextMinute * 1000);

    let timezonesList = [];
    try {
      timezonesList = Object.keys(await fetcher.get('time/timezone/list')).filter(v => !v.startsWith('_'));
    } catch (e) {
      this.props.addMessage({ type: 'failure', data: e });
    }
    const locale = this.context.locale === 'ru' ? ruLocale : enLocale;
    this.setState({
      permissions: parsePermissions(permissions, 'time'),
      timezoneName: this.state.apiData.timezone.name,
      timezonesList,
      updateInterval,
      locale,
    });
  }

  updateTime = () => {
    if (!this.state.timeFocused && !this.state.dateFocused) {
      this.updateState();
    }
  };

  updateState = async () => {
    try {
      const apiData = await fetcher.get('time');
      const { year, month, date: day, hours, minutes, seconds } = apiData.date;
      const now = new Date(year, month, day, hours, minutes, seconds);
      return new Promise(resolve => this.setState({ currentDate: now, apiData }, resolve));
    } catch (e) {
      this.props.addMessage({ type: 'failure', data: e });
    }
  };

  toggleGetAuto = async () => {
    try {
      await fetcher.put(`time/ntp/${this.state.apiData.isNtpActive ? 'off' : 'on'}`);
    } catch (e) {
      this.props.addMessage({ type: 'failure', data: e });
    }
    this.updateState();
  };

  toggleNtpServer = async () => {
    try {
      await fetcher.put(`time/ntp-server/${this.state.apiData.isNtpServerActive ? 'off' : 'on'}`);
    } catch (e) {
      this.props.addMessage({ type: 'failure', data: e });
    }
    this.updateState();
  };

  handleChangeTimezone = async ({ value }) => {
    if (value !== undefined && this.state.timezonesList.includes(value)) {
      try {
        await fetcher.put(`time/timezone`, { timezone: value });
        this.props.addMessage({
          type: 'info',
          data: (
            <FormattedMessage
              id='timePage.infoMessageAfterTimezoneChange'
              defaultMessage='Changes will take effect after you restart system'
            />
          ),
        });
      } catch (e) {
        this.props.addMessage({ type: 'failure', data: e });
      }
      await this.updateState();
      this.setState({ timezoneName: this.state.apiData.timezone.name });
    }
    this.setState({ timezoneName: value });
  };

  handleDateChange = type => async selectedDate => {
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth();
    const date = selectedDate.getDate();
    let hours = selectedDate.getHours();
    let minutes = selectedDate.getMinutes();
    let seconds = selectedDate.getSeconds();
    if (type === 'date') {
      const { currentDate } = this.state;
      hours = currentDate.getHours();
      minutes = currentDate.getMinutes();
      seconds = currentDate.getSeconds();
    }
    try {
      await fetcher.put('time/datetime', { year, month, date, hours, minutes, seconds });
    } catch (e) {
      this.props.addMessage({ type: 'failure', data: e });
    }
    this.updateState();
  };

  handleDeleteNtpServer = name => async () => {
    try {
      await fetcher.delete(`time/ntp/${name}`);
    } catch (e) {
      this.props.addMessage({ type: 'failure', data: e });
    }
    this.updateState();
  };

  handleAddNtpServerClick = () => this.setState({ openAddNtpServerDialog: true });

  handleCloseAddNtpServerDialog = () => this.setState({ openAddNtpServerDialog: false });

  handleSelectMenuClose = () => this.setState({ timezoneName: this.state.apiData.timezone.name });

  handleTimeFocus = () => this.setState({ timeFocused: true });

  handleTimeBlur = () => this.setState({ timeFocused: false });

  handleDateFocus = () => this.setState({ dateFocused: true });

  handleDateBlur = () => this.setState({ dateFocused: false });

  componentWillUnmount = () => clearInterval(this.state.updateInterval);

  render() {
    const { classes, width } = this.props;
    const { currentDate, timezonesList, timezoneName, apiData, openAddNtpServerDialog, locale } = this.state;

    const russianLocale = this.context.locale === 'ru';
    const xs = width === 'xs';
    const mobile = xs || isMobile;
    const dateFormat = russianLocale ? 'dd.MM.yyyy' : 'MM/dd/yyyy';
    return (
      <div className={classes.root}>
        <Alerts issuesPermission={parsePermissions(this.props.permissions, 'issues').r} />
        <PageLoadingIndicator open={!(currentDate && locale)} />

        <div className={classNames(classes.preRoot, mobile ? classes.mobilePreRoot : '')}>
          {currentDate && locale && (
            <Paper className={classes.paper} elevation={1}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                <form className={classes.timeDateContainer} noValidate>
                  <DatePicker
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='start'>
                          <DateIcon />
                        </InputAdornment>
                      ),
                    }}
                    className={classes.textField}
                    disabled={apiData.isNtpActive}
                    label={<FormattedMessage id='timePage.date' defaultMessage='Date' />}
                    okLabel={<FormattedMessage id='timePage.ok' defaultMessage='Ok' />}
                    cancelLabel={<FormattedMessage id='timePage.cancel' defaultMessage='Cancel' />}
                    maxDateMessage={
                      <FormattedMessage
                        id='timePage.maxError'
                        defaultMessage='Date should not be greater than 12/31/2099'
                      />
                    }
                    minDateMessage={
                      <FormattedMessage
                        id='timePage.minError'
                        defaultMessage='Date should not be smaller than 01/01/1900'
                      />
                    }
                    invalidDateMessage={<FormattedMessage id='timePage.invalidDate' defaultMessage='Invalid date' />}
                    rightArrowIcon={<KeyboardArrowRight />}
                    leftArrowIcon={<KeyboardArrowLeft />}
                    value={currentDate}
                    format={dateFormat}
                    onChange={this.handleDateChange('date')}
                    onFocus={this.handleDateFocus}
                    onBlur={this.handleDateBlur}
                    onOpen={this.handleDateFocus}
                    onClose={this.handleDateBlur}
                  />
                  <TimePicker
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='start'>
                          <TimeIcon />
                        </InputAdornment>
                      ),
                    }}
                    className={classes.textField}
                    disabled={apiData.isNtpActive}
                    label={<FormattedMessage id='timePage.time' defaultMessage='Time' />}
                    okLabel={<FormattedMessage id='timePage.ok' defaultMessage='Ok' />}
                    cancelLabel={<FormattedMessage id='timePage.cancel' defaultMessage='Cancel' />}
                    invalidDateMessage={<FormattedMessage id='timePage.invalidTime' defaultMessage='Invalid time' />}
                    value={currentDate}
                    onChange={this.handleDateChange('time')}
                    ampm={!russianLocale}
                    onFocus={this.handleTimeFocus}
                    onBlur={this.handleTimeBlur}
                    onOpen={this.handleTimeFocus}
                    onClose={this.handleTimeBlur}
                  />
                </form>
              </MuiPickersUtilsProvider>
              <FormControl className={classes.formControl}>
                <ReactSelect
                  value={timezoneName}
                  suggestions={timezonesList.sort()}
                  handleChange={this.handleChangeTimezone}
                  onCloseMenu={this.handleSelectMenuClose}
                  placeholder={<FormattedMessage id='timePage.selectTimezone' defaultMessage='Select timezone' />}
                  label={<FormattedMessage id='timePage.timezone' defaultMessage='Timezone' />}
                  rootClass={classes.selectRoot}
                  isMulti={false}
                />
              </FormControl>
              <ProgressSwitch
                label={
                  <FormattedMessage id='timePage.getTimeAndDateAuto' defaultMessage='Get time and date automatically' />
                }
                value={!!apiData && apiData.isNtpActive}
                setValue={this.toggleGetAuto}
                cooldown={2000}
              />
              <ProgressSwitch
                label={<FormattedMessage id='timePage.localNtpServer' defaultMessage='Local NTP server' />}
                value={!!apiData && apiData.isNtpServerActive}
                setValue={this.toggleNtpServer}
                cooldown={2000}
              />
              {apiData.isNtpActive && (
                <>
                  <div className={classes.container}>
                    <Typography>
                      <FormattedMessage id='timePage.ntpServers' defaultMessage='NTP servers: ' />
                    </Typography>
                    <IconButton onClick={this.handleAddNtpServerClick}>
                      <AddIcon />
                    </IconButton>
                  </div>
                  {apiData.ntpServers.length > 0 && <Divider />}
                  <BootstrapLikeAlert
                    open={apiData.ntpServers.length === 0 && apiData.isNtpActive}
                    classes={{ paper: classes.alert }}
                    message={
                      <FormattedMessage
                        id='timePage.alert.defaultSynchronizationMessage'
                        defaultMessage='Date and Time are synchronized with debian.pool.ntp.org by default'
                      />
                    }
                    dangerLevel='info'
                  />
                  <Table>
                    <TableBody>
                      {apiData.ntpServers.map(name => (
                        <TableRow key={name} className={classes.tableRow} hover>
                          <TableCell align='left'>{name}</TableCell>
                          <TableCell padding='none' align='right'>
                            <IconButton onClick={this.handleDeleteNtpServer(name)}>
                              <DeleteIcon className={mobile ? '' : 'delete-button'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </>
              )}
            </Paper>
          )}
          <AddNtpServerDialog
            open={openAddNtpServerDialog}
            handleClose={this.handleCloseAddNtpServerDialog}
            updateState={this.updateState}
            addedHosts={apiData.ntpServers}
            addMessage={this.props.addMessage}
          />
        </div>
      </div>
    );
  }
}

export default connect(TimePage.mapStateToProps)(compose(withStyles(styles), withWidth())(TimePage));
