import { FC } from 'react';
import { Button, ButtonProps, DialogActions, DialogActionsProps, Theme, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import ButtonWithProgress from '@/components/ButtonWithProgress';

const useStyles = makeStyles((theme: Theme) => ({
  dialogActions: {
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export interface PopupDialogActionsProps {
  leftButtons: DialogActionsProps['children'];
  buttons: DialogActionsProps['children'];
  closeMessage: ButtonProps['children'];
  handleConfirm: ButtonProps['onClick'];
  disableConfirm?: boolean;
  confirmButtonText: ButtonProps['children'];
  onClose: ButtonProps['onClick'];
  spinnerIsActive?: boolean;
  withoutConfirm?: boolean;
  forceSpinner?: boolean;
}

const PopupDialogActions: FC<PopupDialogActionsProps> = ({
  leftButtons,
  buttons,
  closeMessage,
  handleConfirm,
  disableConfirm,
  confirmButtonText,
  onClose,
  spinnerIsActive,
  withoutConfirm,
  forceSpinner,
}) => {
  const classes = useStyles();

  return (
    <DialogActions className={classNames(classes.dialogActions, leftButtons ? classes.buttonsContainer : '')}>
      {leftButtons}
      {buttons && <div>{buttons}</div>}
      {!buttons && (
        <Button onClick={onClose} disabled={spinnerIsActive} color='primary' variant='outlined'>
          {closeMessage || <FormattedMessage id='ResponsiveDialog.cancel' defaultMessage='Cancel' />}
        </Button>
      )}

      {!withoutConfirm && !buttons && (
        <ButtonWithProgress showProgress={spinnerIsActive || forceSpinner}>
          <Button
            variant='contained'
            onClick={handleConfirm}
            disabled={spinnerIsActive || disableConfirm}
            color='primary'
            autoFocus
          >
            {confirmButtonText}
          </Button>
        </ButtonWithProgress>
      )}
    </DialogActions>
  );
};

export default PopupDialogActions;
