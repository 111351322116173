import { ComponentType, FC } from 'react';
import { InputAdornment, Theme, Tooltip, TooltipProps, makeStyles } from '@material-ui/core';
import { Help } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.text.secondary,
  },
}));

interface EndAdornmentProps {
  show?: boolean;
  tooltip?: TooltipProps['title'];
  Icon?: ComponentType;
}

const EndAdornment: FC<EndAdornmentProps> = ({ show, tooltip = '', Icon = Help }) => {
  const classes = useStyles();

  if (!show) return null;

  return (
    <InputAdornment position='end'>
      <Tooltip title={tooltip}>
        <Icon className={classes.icon} />
      </Tooltip>
    </InputAdornment>
  );
};

export default EndAdornment;
