import { fetcher } from '../../util/deps';
import { SET_LOAD } from '../constants';

const loadLoad = () => async dispatch => {
  let data = await fetcher.get('load', undefined, false);
  if (data) {
    dispatch(setLoad(data));
  }
};

const setLoad = load => {
  const type = SET_LOAD,
    payload = load;

  return { type, payload };
};

export default {
  setLoad,
  loadLoad,
};
