import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import Done from '@material-ui/icons/Done';
import Close from '@material-ui/icons/Close';
import Add from '@material-ui/icons/Add';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Backdrop from '@material-ui/core/Backdrop';

import { dnsActions, networkActions } from '../../redux-stuff/actions';
import { isIp } from '../../util/ip';
import ButtonWithProgress from '../ButtonWithProgress';

const styles = theme => ({
  panel: {
    marginBottom: theme.spacing(6),
  },
  summary: {
    '&& > div': {
      margin: 0,
    },
    [theme.breakpoints.only('xs')]: {
      padding: `0px ${theme.spacing(1)}px`,
    },
  },
  containerSummary: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  column: {
    flexBasis: 'calc(25% - 12px)',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      flexBasis: '50%',
    },
  },
  addresses: {
    maxWidth: '600px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('sm')]: {
      maxWidth: '400px',
    },
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  panelDetails: {
    [theme.breakpoints.only('xs')]: {
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
    },
  },
  inputChipsContainer: {
    width: '100%',
    minHeight: '84px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'baseline',
  },
  leftContainer: {
    display: 'flex',
    flexBasis: '100%',
    flexDirection: 'column',
    minHeight: '84px',
    [theme.breakpoints.up('sm')]: {
      flexBasis: '35%',
    },
  },
  editingContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '84px',
    flexBasis: '100%',
    [theme.breakpoints.up('sm')]: {
      flexBasis: '35%',
    },
  },
  buttons: {
    display: 'flex',
  },
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    width: 240,
    [theme.breakpoints.only('xs')]: {
      maxWidth: 200,
    },
  },
  chips: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexBasis: '65%',
      flexWrap: 'wrap',
    },
  },
  chip: {
    margin: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px 0px`,
  },
  selected: {
    backgroundColor: '#F8BBD0 !important',
    '&&:hover': {
      backgroundColor: '#F48FB1 !important',
    },
  },
  zIndexTop: {
    zIndex: '10000',
  },
  plusContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
    '& > p': {
      marginRight: theme.spacing(1),
    },
  },
});

class DnsPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = { expanded: false, creatingMode: false };
    this.inputRef = React.createRef();
    this.props.loadDns();
  }

  handleExpand = () => this.setState({ expanded: !this.state.expanded });
  handleSelectChip = ip => () => {
    this.props.selectIp(ip);
    this.activateCreatingMode();
  };
  handleRemoveIp = ip => () => this.props.removeIp(ip);
  handleAddIp = () => {
    this.props.addNewIp();
    this.deactivateCreatingMode();
  };
  activateCreatingMode = () => {
    this.setState({ creatingMode: true });
    this.inputRef.current.focus();
  };
  deactivateCreatingMode = () => this.setState({ creatingMode: false }, this.props.deselectIp);
  handleKeyDown = disableAdding => e => {
    if (e.key === 'Enter' && !disableAdding) {
      this.handleAddIp();
    } else if (e.key === 'Escape') {
      this.deactivateCreatingMode();
    }
  };

  componentDidUpdate() {
    // updating unsavedChanges state
    const { localState = [], lastLoadedState, unsavedChanges = [] } = this.props;
    const showApplyDiscard =
      lastLoadedState.length !== localState.length || lastLoadedState.some((ip, i) => ip !== localState[i]);
    if (unsavedChanges.includes('DNS') !== showApplyDiscard) {
      this.props.setUnsavedChanges('DNS', showApplyDiscard);
    }
  }

  static mapStateToProps({ dns, network }) {
    return { ...dns, unsavedChanges: network.unsavedChanges };
  }

  render() {
    const {
      localState = [],
      lastLoadedState,
      newIp,
      selectedIp,
      pendingBackend,
      unsavedChanges = [],
      w,
      classes,
    } = this.props;
    const { expanded } = this.state;

    const ipError = !isIp(newIp);
    const showApplyDiscard = unsavedChanges.includes('DNS');

    const allowApply = showApplyDiscard && (!ipError || newIp === '');

    const disableAdding = ipError || selectedIp === newIp || localState.includes(newIp);

    return (
      <Accordion key='dns' onChange={this.handleExpand} expanded={expanded} elevation={1} className={classes.panel}>
        <AccordionSummary
          className={classes.summary}
          expandIcon={
            <Tooltip title={<FormattedMessage id='DnsPanel.panelTooltip.showHide' defaultMessage='Show/Hide' />}>
              <ExpandMoreIcon />
            </Tooltip>
          }
        >
          <div className={classes.containerSummary}>
            <div className={classes.column}>
              <Typography>DNS</Typography>
            </div>
            <div className={classes.addresses}>
              {lastLoadedState.length > 0 && (
                <Fade in={!expanded}>
                  <Typography noWrap>{lastLoadedState.join(', ')}</Typography>
                </Fade>
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.panelDetails}>
          <div className={classes.inputChipsContainer}>
            <Backdrop
              open={this.state.creatingMode}
              onClick={this.deactivateCreatingMode}
              style={{ zIndex: `${this.state.creatingMode ? 9999 : -1}` }}
              invisible
            />
            <div
              className={classNames(
                selectedIp ? classes.editingContainer : classes.leftContainer,
                this.state.creatingMode ? classes.zIndexTop : ''
              )}
            >
              <Collapse in={!this.state.creatingMode}>
                <div className={classes.plusContainer}>
                  <Typography>IP</Typography>
                  <IconButton onClick={this.activateCreatingMode}>
                    <Add />
                  </IconButton>
                </div>
              </Collapse>
              <Collapse in={this.state.creatingMode}>
                <div className={classes.container}>
                  <FormControl className={classes.formControl} error={!!ipError && newIp !== ''} disabled={!w}>
                    <InputLabel htmlFor='dns-ip'>IP</InputLabel>
                    <Input
                      id='dns-ip'
                      value={newIp}
                      onChange={this.props.editNewIp}
                      onKeyDown={this.handleKeyDown(disableAdding)}
                      inputRef={this.inputRef}
                    />
                    <FormHelperText>
                      {!!ipError && newIp !== '' && (
                        <FormattedMessage id='dnsPanel.inputError.invalidIp' defaultMessage='Invalid Ip' />
                      )}
                    </FormHelperText>
                  </FormControl>

                  <div className={classes.buttons}>
                    <Tooltip title={<FormattedMessage id='dnsPanel.buttonTooltip.apply' defaultMessage='Apply' />}>
                      <div>
                        <IconButton disabled={disableAdding} onClick={this.handleAddIp}>
                          <Done />
                        </IconButton>
                      </div>
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id='dnsPanel.buttonTooltip.discard' defaultMessage='Discard' />}>
                      <div>
                        <IconButton onClick={this.deactivateCreatingMode}>
                          <Close />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </Collapse>
            </div>

            <div className={classes.chips}>
              {localState.map(ip => (
                <Chip
                  key={ip}
                  label={ip}
                  onClick={w ? this.handleSelectChip(ip) : null}
                  onDelete={w ? this.handleRemoveIp(ip) : null}
                  className={classNames(classes.chip, ip === selectedIp ? classes.selected : '')}
                  variant='outlined'
                />
              ))}
            </div>
          </div>
        </AccordionDetails>
        <Collapse in={showApplyDiscard} unmountOnExit>
          <AccordionActions>
            <Button color='primary' onClick={this.props.resetChanges}>
              <FormattedMessage id='DnsPanel.button.discard' defaultMessage='Discard' />
            </Button>
            <ButtonWithProgress showProgress={pendingBackend}>
              <Button color='primary' disabled={!allowApply || !!pendingBackend} onClick={this.props.saveChanges}>
                <FormattedMessage id='DnsPanel.button.apply' defaultMessage='Apply' />
              </Button>
            </ButtonWithProgress>
          </AccordionActions>
        </Collapse>
      </Accordion>
    );
  }
}

export default connect(DnsPanel.mapStateToProps, { ...dnsActions, ...networkActions })(withStyles(styles)(DnsPanel));
