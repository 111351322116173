import React, { Fragment } from 'react';
import { Tooltip, Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { FormattedMessage } from 'react-intl';

export default (mobile, appBar, handleLogout, handleLogoutMenuOpen, username, classes) =>
  ({ location }) => {
    if (mobile || location.pathname === '/login') return null;

    return (
      <Tooltip title={<FormattedMessage id='appBar.rightButtons.logout' defaultMessage='Logout' />}>
        <Fragment>
          {mobile && (
            <IconButton color='inherit' onClick={handleLogout} className={classes.actionButton}>
              <ExitToAppIcon />
            </IconButton>
          )}
          {!mobile && (
            <Button color='inherit' onClick={handleLogoutMenuOpen} className={classes.logoutButton}>
              {username}
            </Button>
          )}
        </Fragment>
      </Tooltip>
    );
  };
