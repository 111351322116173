import React, { Fragment } from 'react';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { FormattedMessage, injectIntl } from 'react-intl';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import MoreVert from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '../customIcons/Settings';
import RebootIcon from '@material-ui/icons/Loop';
import ChangePassword from '@material-ui/icons/VpnKey';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import ResponsiveDialog from '../responsive-dialog';
import ActionsMenu from '../ActionsMenu';
import SettingsDialog from './SettingsDialog';
import { fetcher } from '../../util/deps';
import { snackbarMessageTypes } from '../../redux-stuff/constants';
import { parsePermissions } from '../../util/permissions';

const styles = theme => ({
  actionButton: {
    marginRight: theme.spacing(1),
    marginLeft: 0,
  },
});

class PowerAndSettings extends React.Component {
  state = {
    rebootDialogOpen: false,
    powerOffDialogOpen: false,
    settingsDialogOpen: false,
    powerMenuOpen: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (!state.permissions && !isEmpty(props.permissions)) {
      return { permissions: parsePermissions(props.permissions, 'power') };
    }
    return null;
  }

  static mapStateToProps({ isSocketConnected }) {
    return { isSocketConnected };
  }

  handleReboot = async () => {
    await this.setStateAsync({ rebooting: true });
    try {
      await fetcher.post('reboot');
    } catch (e) {
      this.props.addMessage({ type: 'failure', data: e });
      this.setState({ rebooting: false, rebootDialogOpen: false });
    }
  };

  handlePowerOff = async () => {
    await this.setStateAsync({ shutting: true, powerOffDialogOpen: false });
    const success = await fetcher.post('poweroff', undefined, false);
    this.setState({ shutting: false });

    if (success) {
      this.props.addMessage({
        type: snackbarMessageTypes.success,
        data: (
          <FormattedMessage
            id='MainPage.snackbar.shuttingDownCompleted'
            defaultMessage='The server has been shut down'
          />
        ),
      });
    } else {
      this.setState({ poweroff: true });
    }
  };

  setStateAsync = state => new Promise(resolve => this.setState(state, resolve));

  openRebootDialog = () => {
    this.setState({ rebootDialogOpen: true });
    this.closePopoverMenus();
  };
  openPowerOffDialog = () => {
    this.setState({ powerOffDialogOpen: true });
    this.closePopoverMenus();
  };
  openSettingsDialog = () => {
    this.setState({ settingsDialogOpen: true });
    this.closePopoverMenus();
  };

  closePopoverMenus = () => this.setState({ powerMenuOpen: false });
  closeAllDialogs = () => {
    this.setState({
      rebootDialogOpen: false,
      powerOffDialogOpen: false,
      settingsDialogOpen: false,
      powerMenuOpen: false,
    });
  };

  openPowerMenu = event => this.setState({ powerMenuOpen: true, anchorEl: event.currentTarget });

  handleChangePassword = () => {
    this.closePopoverMenus();
    this.props.changePassword();
  };
  handleLogout = () => {
    this.closePopoverMenus();
    this.props.logout();
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.isSocketConnected && this.props.isSocketConnected && this.state.rebooting) {
      this.setState({ rebooting: false, rebootDialogOpen: false });
      this.props.addMessage({
        type: 'success',
        data: <FormattedMessage id='MainPage.snackbar.rebootCompleted' defaultMessage='The server has rebooted' />,
      });
    }
  }

  render() {
    const { classes, width, username, changePasswordPermission, location, isSocketConnected } = this.props;

    const xs = width === 'xs';
    const isMainPage = location.pathname === '/';

    const {
      rebootDialogOpen,
      powerOffDialogOpen,
      settingsDialogOpen,
      powerMenuOpen,
      anchorEl,
      rebooting,
      permissions = {},
    } = this.state;

    const { w } = permissions;

    return (
      <Fragment>
        {isSocketConnected && (
          <Fragment>
            {!xs && isMainPage && (
              <IconButton className={classes.actionButton} color='inherit' onClick={this.openSettingsDialog}>
                <SettingsIcon />
              </IconButton>
            )}
            {!xs && w && (
              <IconButton className={classes.actionButton} color='inherit' onClick={this.openPowerMenu}>
                <PowerSettingsNew />
              </IconButton>
            )}

            {xs && (
              <IconButton className={classes.actionButton} color='inherit' onClick={this.openPowerMenu}>
                <MoreVert />
              </IconButton>
            )}
          </Fragment>
        )}

        <ActionsMenu
          open={powerMenuOpen}
          anchorEl={anchorEl}
          handleClose={this.closePopoverMenus}
          items={[
            {
              show: xs && isMainPage,
              Icon: SettingsIcon,
              text: <FormattedMessage id='powerMenu.settings' defaultMessage='Settings' />,
              handler: this.openSettingsDialog,
            },
            {
              show: w,
              Icon: PowerSettingsNew,
              text: <FormattedMessage id='powerMenu.powerOff' defaultMessage='Power off' />,
              handler: this.openPowerOffDialog,
            },
            {
              show: w,
              Icon: RebootIcon,
              text: <FormattedMessage id='powerMenu.reboot' defaultMessage='Reboot' />,
              handler: this.openRebootDialog,
            },
            {
              show: xs && changePasswordPermission,
              Icon: ChangePassword,
              text: <FormattedMessage id='powerMenu.changePasswordItem' defaultMessage='Change password' />,
              handler: this.handleChangePassword,
            },
            {
              show: xs,
              Icon: ExitToAppIcon,
              text: (
                <FormattedMessage id='powerMenu.logout' defaultMessage='Logout ({username})' values={{ username }} />
              ),
              handler: this.handleLogout,
            },
          ]}
        />

        {w && (
          <ResponsiveDialog
            open={rebootDialogOpen}
            title={<FormattedMessage id='powerDialog.titleReboot' defaultMessage='Reboot' />}
            message={
              <FormattedMessage
                id='powerDialog.messageReboot'
                defaultMessage='Are you sure you want to reboot the server?'
              />
            }
            confirmButtonText={<FormattedMessage id='powerDialog.buttonReboot' defaultMessage='Reboot' />}
            onClose={this.closeAllDialogs}
            onConfirm={this.handleReboot}
            withSpinner
            pendingBackendEvent={rebooting}
          />
        )}

        {w && (
          <ResponsiveDialog
            open={powerOffDialogOpen}
            title={<FormattedMessage id='powerDialog.titlePowerOff' defaultMessage='Power off' />}
            message={
              <FormattedMessage
                id='powerDialog.messagePowerOff'
                defaultMessage='Are you sure you want to power off the server?'
              />
            }
            confirmButtonText={<FormattedMessage id='powerDialog.buttonPowerOff' defaultMessage='Power off' />}
            onClose={this.closeAllDialogs}
            onConfirm={this.handlePowerOff}
          />
        )}

        <SettingsDialog open={settingsDialogOpen} onClose={this.closeAllDialogs} />
      </Fragment>
    );
  }
}

export default compose(
  injectIntl,
  connect(PowerAndSettings.mapStateToProps),
  withStyles(styles),
  withWidth(),
  withRouter
)(PowerAndSettings);
