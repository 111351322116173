import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '@/util/store';
import { setDnsServerConfig, DnsServerConfig } from '@/redux-stuff/features/dns-server';
import { FC, useEffect } from 'react';

interface DhcpContainerProps {
  classes: {
    leftContainer?: string;
    formControl?: string;
    dnsFormControl?: string;
  };
  name: string;
  w: boolean;
  config: DnsServerConfig;
}

const DnsContainer: FC<DhcpContainerProps> = ({ classes, name, config, w }) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setDnsServerConfig({ interfaceName: name, value: config }));
  }, [name, config]);
  const value = useAppSelector(({ dnsServer }) => dnsServer[name] && dnsServer[name]!.enabled);

  return (
    <div className={classes.leftContainer}>
      <FormControl className={classes.formControl} disabled={!w}>
        <FormControlLabel
          control={
            <Switch
              color='primary'
              id={`enable-dns-${name}`}
              checked={!!value}
              onChange={({ target: { checked: enabled } }) =>
                dispatch(setDnsServerConfig({ interfaceName: name, value: { enabled } }))
              }
            />
          }
          label={<FormattedMessage id='networkPage.DnsServer' defaultMessage='DNS-server' />}
          className={classes.dnsFormControl}
        />
      </FormControl>
    </div>
  );
};

export default DnsContainer;
