import React from 'react';
import { FormattedMessage } from 'react-intl';
import FirmwarePage from '../containers/FirmwarePage';
import BackupPage from '../containers/BackupPage';
import NetworkPage from '../containers/NetworkPage';
import ConnectionsPage from '../containers/ConnectionsPage';
import MainPage from '../containers/MainPage';
import Page404 from '../components/Page404';
import ModulesAndServicesPage from '../containers/ModulesAndServicesPage';
import LoginPage from '../containers/LoginPage';
import UsersAndRolesPage from '../containers/UsersAndRolesPage';
import RoutingPage from '../containers/RoutingPage';
import LicensePage from '../containers/LicensePage';
import TimePage from '../containers/TimePage';

const uriMap = {
  '/': {
    exact: true,
    pageName: () => <FormattedMessage id='pageNames.home' defaultMessage='Home' />,
    Component: MainPage,
  },
  '/firmware': {
    pageName: () => <FormattedMessage id='pageNames.firmware' defaultMessage='Firmware' />,
    Component: FirmwarePage,
  },
  '/backup': {
    pageName: () => <FormattedMessage id='pageNames.backup' defaultMessage='Backup' />,
    Component: BackupPage,
  },
  '/connections': {
    pageName: () => <FormattedMessage id='pageNames.connections' defaultMessage='Connections' />,
    Component: ConnectionsPage,
  },
  '/network': {
    pageName: () => <FormattedMessage id='pageNames.network' defaultMessage='Network' />,
    Component: NetworkPage,
  },
  '/modules': {
    pageName: () => <FormattedMessage id='pageNames.modules' defaultMessage='Modules' />,
    Component: ModulesAndServicesPage,
  },
  '/login': {
    pageName: () => <FormattedMessage id='pageNames.login' defaultMessage='Login' />,
    Component: LoginPage,
  },
  '/users-roles': {
    pageName: () => <FormattedMessage id='pageNames.usersAndRoles' defaultMessage='Users & Roles' />,
    Component: UsersAndRolesPage,
  },
  '/routing': {
    pageName: () => <FormattedMessage id='pageNames.routing' defaultMessage='Routing' />,
    Component: RoutingPage,
  },
  '/license': {
    pageName: () => <FormattedMessage id='pageNames.license' defaultMessage='License' />,
    Component: LicensePage,
  },
  '/time': {
    pageName: () => <FormattedMessage id='pageNames.time' defaultMessage='Time' />,
    Component: TimePage,
  },
  '': {
    pageName: () => <FormattedMessage id='pageNames.404' defaultMessage='404 Not Found' />,
    Component: Page404,
  },
};

export default uriMap;
