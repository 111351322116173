import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import BootstrapLikeAlert from './BootstrapLikeAlert';
import { issuesActions } from '../redux-stuff/actions';
import MissingRoutesDialog from './MissingRoutesDialog';

class Alerts extends React.Component {
  state = { dialogOpen: false };

  componentDidMount() {
    if (this.props.issuesPermission) {
      this.props.loadIssues();
    }
  }

  handleOpenPopover = () => this.setState({ dialogOpen: true });

  handleClosePopover = () => this.setState({ dialogOpen: false });

  static mapStateToProps({ issues, network }) {
    return { issues, network };
  }

  render() {
    const issues = this.props.issues || [];
    const { network } = this.props;
    const location = this.props.location.pathname;
    if (location === '/login') return null;

    const issue_BACKUP_MISSING = issues.find(issue => issue.message === 'BACKUP_MISSING');

    const issue_BACKUP_TOO_OLD = issues.find(issue => issue.message === 'BACKUP_TOO_OLD');
    const lastBackupDate = issue_BACKUP_TOO_OLD && <FormattedDate value={new Date(issue_BACKUP_TOO_OLD.extra.last)} />;

    const issue_LICENSE_EXPIRED = issues.find(issue => issue.message === 'LICENSE_EXPIRED');
    const issue_LICENSE_EXPIRES = issues.find(issue => issue.message === 'LICENSE_EXPIRES');
    const issue_LICENSE_COMPONENT_EXPIRED = issues.find(issue => issue.message === 'LICENSE_COMPONENT_EXPIRED');
    const issue_LICENSE_COMPONENT_EXPIRES = issues.find(issue => issue.message === 'LICENSE_COMPONENT_EXPIRES');
    const issue_LICENSE_MISSING = issues.find(issue => issue.message === 'LICENSE_MISSING');
    const issue_TIME_DIFFERS = issues.find(issue => issue.message === 'TIME_DIFFERS_TOO_MUCH');
    const issue_LICENSE_REVOKED = issues.find(issue => issue.message === 'LICENSE_REVOKED');
    const issue_LICENSE_COMPONENT_REVOKED = issues.find(issue => issue.message === 'LICENSE_COMPONENT_REVOKED');

    const { missingSystemRoutes = [], missingConfigRoutes = [] } = network || {};

    const issue_LOW_DISK_SPACE = issues.find(issue => issue.message === 'LOW_DISK_SPACE');

    return (
      <Fragment>
        {['/', '/backup'].includes(location) && (
          <Fragment>
            <BootstrapLikeAlert
              open={!!issue_BACKUP_MISSING}
              pageTopMargins
              message={<FormattedMessage id='alert.issue_BACKUP_MISSING' defaultMessage='No backup found' />}
              dangerLevel={'warning'}
              link={location !== '/backup' && '/backup'}
            />
            <BootstrapLikeAlert
              open={!!issue_BACKUP_TOO_OLD}
              pageTopMargins
              message={
                <FormattedMessage
                  id='alert.issue_BACKUP_TOO_OLD'
                  defaultMessage='Last backup is too old. System has not been backed up since {date}'
                  values={{ date: lastBackupDate }}
                />
              }
              dangerLevel={'warning'}
              link={location !== '/backup' && '/backup'}
            />
          </Fragment>
        )}

        <BootstrapLikeAlert
          open={!!issue_TIME_DIFFERS}
          pageTopMargins
          message={
            <FormattedMessage
              id='alter.issue_TIME_DIFFERS'
              defaultMessage='The time on the server differs from the time in your web browser for {delta} sec'
              values={{ delta: issue_TIME_DIFFERS && (issue_TIME_DIFFERS.delta / 1000).toFixed(0) }}
            />
          }
          dangerLevel={'warning'}
          link={location !== '/time' && '/time'}
        />
        <BootstrapLikeAlert
          open={!!issue_LICENSE_REVOKED}
          pageTopMargins
          message={<FormattedMessage id='alert.issue_LICENSE_REVOKED' defaultMessage='License has been revoked' />}
          dangerLevel='error'
          link={location !== '/license' && '/license'}
        />
        <BootstrapLikeAlert
          open={!!issue_LICENSE_COMPONENT_REVOKED}
          pageTopMargins
          message={
            <FormattedMessage
              id='alert.issue_LICENSE_COMPONENT_REVOKED'
              defaultMessage='License for some components has been revoked'
            />
          }
          dangerLevel='warning'
          link={location !== '/license' && '/license'}
        />
        <BootstrapLikeAlert
          open={!!issue_LICENSE_EXPIRED}
          pageTopMargins
          message={<FormattedMessage id='alert.issue_LICENSE_EXPIRED' defaultMessage='License has expired' />}
          dangerLevel={'error'}
          link={location !== '/license' && '/license'}
        />
        <BootstrapLikeAlert
          open={!!issue_LICENSE_MISSING}
          pageTopMargins
          message={<FormattedMessage id='alert.issue_LICENSE_MISSING' defaultMessage='License is missing' />}
          dangerLevel={'error'}
          link={location !== '/license' && '/license'}
        />
        <BootstrapLikeAlert
          open={!!issue_LICENSE_EXPIRES}
          pageTopMargins
          message={<FormattedMessage id='alert.issue_LICENSE_EXPIRES' defaultMessage='License will soon expire' />}
          dangerLevel={'warning'}
          link={location !== '/license' && '/license'}
        />
        <BootstrapLikeAlert
          open={!!issue_LICENSE_COMPONENT_EXPIRED}
          pageTopMargins
          message={
            <FormattedMessage
              id='alert.issue_LICENSE_COMPONENT_EXPIRED'
              defaultMessage='License for some components has expired'
            />
          }
          dangerLevel={'warning'}
          link={location !== '/license' && '/license'}
        />
        <BootstrapLikeAlert
          open={!!issue_LICENSE_COMPONENT_EXPIRES}
          pageTopMargins
          message={
            <FormattedMessage
              id='alert.issue_LICENSE_COMPONENT_EXPIRES'
              defaultMessage='License for some components will soon expire'
            />
          }
          dangerLevel={'warning'}
          link={location !== '/license' && '/license'}
        />
        <BootstrapLikeAlert
          open={missingSystemRoutes.length > 0 || missingConfigRoutes.length > 0}
          pageTopMargins
          message={
            <FormattedMessage
              id='alert.routingConfigDoesntMatchActualRoutingTable'
              defaultMessage="Routing configuration doesn't match the actual routing table"
            />
          }
          dangerLevel={'warning'}
          actionHandler={this.handleOpenPopover}
          action={<FormattedMessage id='alert.savedConfigIsDifferent.button.details' defaultMessage='Details' />}
          link={location !== '/routing' && '/routing'}
        />
        <BootstrapLikeAlert
          open={!!issue_LOW_DISK_SPACE}
          pageTopMargins
          message={<FormattedMessage id='alert.issue_LOW_DISK_SPACE' defaultMessage='Disk is almost full' />}
          dangerLevel={'warning'}
        />
        <MissingRoutesDialog
          open={this.state.dialogOpen}
          onClose={this.handleClosePopover}
          missingSystemRoutes={missingSystemRoutes}
          missingConfigRoutes={missingConfigRoutes}
        />
      </Fragment>
    );
  }
}

export default compose(injectIntl, connect(Alerts.mapStateToProps, { ...issuesActions }), withRouter)(Alerts);
