import { SET_MODULES_UPLOADS } from '../constants';
import { fetcher } from '../../util/deps';

let timerId;

const getModulesUploads = () => async dispatch => {
  clearTimeout(timerId);

  let data = await fetcher.get('modules/upload/list', { active: true }, false);

  if (!data) return;
  dispatch(setModulesUploads(data));

  if (data.length) {
    planUpdate(dispatch);
  }
};

const planUpdate = dispatch => {
  timerId = setTimeout(() => dispatch(getModulesUploads()), 1000);
};

const setModulesUploads = uploads => {
  const type = SET_MODULES_UPLOADS;

  return { type, payload: uploads };
};

const interruptModulesUpload = id => async dispatch => {
  const encodedId = encodeURIComponent(id);
  await fetcher.put(`modules/upload/${encodedId}/interrupt`);

  // deleted, call now so as to not wait next timer update
  dispatch(getModulesUploads());
};

export default {
  getModulesUploads,
  interruptModulesUpload,
};
