import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import FileDrop from 'react-file-drop';
import { connect } from 'react-redux';

import FileUploaderUploadField from './FileUploaderUploadField';
import FileUploaderProgress from './FileUploaderProgress';
import FileUploaderTryingToAbort from './FileUploaderTryingToAbort';
import FileUploaderTryingToHide from './FileUploaderTryingToHide';

import { Uploader, fetcher } from '../util/deps';
import '../css/FileUploader.css';

const FILE_UPLOADER_STATE = {
  PENDING: 0,
  UPLOADING_FROM_LOCAL: 1,
  UPLOADING_FROM_REMOTE: 2,
  DONE: 3,
  TRYING_TO_ABORT: 4,
  TRYING_TO_HIDE: 5,
  isAnUploadingState: state => {
    return (
      state === FILE_UPLOADER_STATE.UPLOADING_FROM_LOCAL ||
      state === FILE_UPLOADER_STATE.UPLOADING_FROM_REMOTE ||
      state === FILE_UPLOADER_STATE.DONE
    );
  },
};

const initialState = {
  state: FILE_UPLOADER_STATE.PENDING,
  percentage: 0,
  error: null,
  uploader: null,
  uploadFinished: false,
  handleInstall: undefined,
  handleDelete: undefined,
};

class FileUploader extends React.Component {
  state = { ...initialState };

  hideIfDropped = e => {
    const el = e.target;

    const dropzone = document.querySelector('.dropzone');
    if (!dropzone) return;

    const hitDropzone = !!(dropzone.compareDocumentPosition(el) & 32);

    if (!hitDropzone) {
      this.props.onCloseDnD();
    }
  };

  uploadByLink = async link => {
    const { resource } = this.props;
    this.setState({
      state: FILE_UPLOADER_STATE.UPLOADING_FROM_REMOTE,
      error: null,
    });

    try {
      if (this.props.resource === 'modules') {
        this.modulesBeforeUpload = this.props.modules;
        this.servicesBeforeUpload = this.props.services;
      }

      const uri = `${resource}/${resource === 'backup' ? 'upload/' : ''}link`;
      const { id } = await fetcher.post(uri, { link });
      this.encodedUploadId = encodeURIComponent(id);
      this.timerId = setInterval(this.updateLinkUploadStatus, 250);
    } catch (e) {
      this.handleUploadError(e);
    }
  };

  updateLinkUploadStatus = async () => {
    try {
      const res = await fetcher.get(`${this.props.resource}/upload/${this.encodedUploadId}/status`);

      // FOUND
      if (res.status !== 'NOT_FOUND') {
        const { estimated, received, uploadFinished } = res.info;
        const percentage = Math.floor((received / estimated) * 100);
        this.setState({ percentage, uploadFinished });
      }

      if (res.status === 'PROCESSING') return;
      // has uploaded, unpacked, installed, loaded

      clearInterval(this.timerId);

      if (res.status === 'RESOLVED') {
        this.setState({ state: FILE_UPLOADER_STATE.DONE });
        this.setInstallButtonHandler(res);
        return;
      }

      if (res.status === 'REJECTED') {
        // error won't be thrown on abort since it stops status updates
        const incomingError = res.error;

        const errorMessage = incomingError.message || incomingError.type;
        const errorDetails = incomingError;

        const error = new Error(errorMessage);
        error.errorMessage = errorMessage;
        error.details = errorDetails;
        error.contentType = 'application/json';
        error.time = new Date();

        throw error;
      }

      throw new Error('NOT_FOUND');
    } catch (e) {
      this.handleUploadError(e);
    }
  };

  handleUploadError = error => {
    if (this.props.open) {
      this.setState({
        state: FILE_UPLOADER_STATE.PENDING,
        error,
      });
    }
    this.props.addMessage({ type: 'failure', data: error });

    if (error.message === 'FIRMWARE_FILE_CORRUPTED') {
      this.setDeleteButtonHandler(error);
    }
  };

  uploadFile = async file => {
    const uploader = new Uploader(file, this.props.resource);
    this.setState({
      state: FILE_UPLOADER_STATE.UPLOADING_FROM_LOCAL,
      uploader,
    });
    this.subscribe(uploader);

    try {
      if (this.props.resource === 'modules') {
        this.modulesBeforeUpload = this.props.modules;
        this.servicesBeforeUpload = this.props.services;
      }

      const res = await uploader.upload(this.props.resource);
      this.setInstallButtonHandler(res);
    } catch (e) {
      this.handleUploadError(e);
    }
  };

  subscribe = uploader => {
    uploader.on('progress', this.handleProgressEvent);
    uploader.on('end', this.handleEndEvent);
  };

  handleProgressEvent = ({ percentage }) => {
    this.setState({ percentage });

    if (percentage === 100) {
      this.setState({ uploadFinished: true, state: FILE_UPLOADER_STATE.UPLOADING_FROM_LOCAL });
    }
  };
  handleEndEvent = () => this.setState({ state: FILE_UPLOADER_STATE.DONE });

  componentDidUpdate(prevProps, prevState) {
    if (this.state.uploader && this.state.uploader !== prevState.uploader) {
      this.subscribe(this.state.uploader);
    }
  }

  tryToAbort = () => {
    const uploadInProgress =
      this.state.state === FILE_UPLOADER_STATE.UPLOADING_FROM_LOCAL ||
      this.state.state === FILE_UPLOADER_STATE.UPLOADING_FROM_REMOTE;

    if (uploadInProgress) {
      this.prevState = this.state.state;
      this.setState({ state: FILE_UPLOADER_STATE.TRYING_TO_ABORT });
      return;
    }

    this.abortUpload();
  };

  abortUpload = () => {
    if (this.prevState === FILE_UPLOADER_STATE.UPLOADING_FROM_REMOTE) {
      this.abortUploadRemote();
    } else if (this.prevState === FILE_UPLOADER_STATE.UPLOADING_FROM_LOCAL) {
      this.abortUploadLocal();
    }

    this.resetState();
    this.props.reloadFiles();
  };

  abortUploadRemote = async () => {
    try {
      await fetcher.put(`${this.props.resource}/upload/${this.encodedUploadId}/interrupt`);
    } catch (e) {
      this.handleUploadError(e);
    }
  };

  abortUploadLocal = () => {
    this.state.uploader.stop();
  };

  tryToClose = () => {
    if (this.state.state === FILE_UPLOADER_STATE.UPLOADING_FROM_REMOTE) {
      this.prevState = this.state.state;
      this.setState({ state: FILE_UPLOADER_STATE.TRYING_TO_HIDE });
      return;
    }

    if (this.state.state === FILE_UPLOADER_STATE.UPLOADING_FROM_LOCAL) {
      this.prevState = this.state.state;

      if (this.state.uploadFinished) {
        this.setState({ state: FILE_UPLOADER_STATE.TRYING_TO_HIDE });
      } else {
        this.setState({ state: FILE_UPLOADER_STATE.TRYING_TO_ABORT });
      }

      return;
    }

    if (this.state.state === FILE_UPLOADER_STATE.TRYING_TO_ABORT) return;

    this.resetState();
    this.props.onClose();
  };

  returnToPrevState = () => {
    this.setState({ state: this.prevState });
  };

  resetState = () => {
    this.setState({ ...initialState });
    clearInterval(this.timerId);
  };

  resetError = () => {
    this.setState({ error: null });
  };

  setInstallButtonHandler = uploadResponse => {
    const isRemote = uploadResponse.meta && uploadResponse.meta.post === false;

    let handleInstall;
    let installAvailable;
    switch (this.props.resource) {
      case 'firmware': {
        const filename = isRemote ? uploadResponse.meta.filename : uploadResponse.filename;

        handleInstall = () => this.props.handleInstall(filename);
        installAvailable = true;
        break;
      }

      case 'modules': {
        const imagesUploaded = isRemote ? uploadResponse.value : uploadResponse;

        const imagesUploadedParsed = imagesUploaded.map(image_ => {
          const imageAlreadyExists = this.modulesBeforeUpload.find(image => image.id === image_.id);
          if (!imageAlreadyExists) {
            installAvailable = true;
            return { ...image_, selected: true };
          }

          const imageAlreadyInstantiated = this.servicesBeforeUpload.find(s => s.module === image_.id);
          if (!imageAlreadyInstantiated) {
            installAvailable = true;
            return { ...image_, duplicate: true };
          }

          return { ...image_, duplicate: true, installingPhase: 'DONE' };
        });

        handleInstall = () => this.props.handleInstall(imagesUploadedParsed);
        break;
      }
      default:
        console.error('unknown resourse', this.props.resource);
        break;
    }

    this.setState({ handleInstall, installAvailable });
  };

  setDeleteButtonHandler = error => {
    switch (this.props.resource) {
      case 'firmware':
        this.setState({
          handleDelete: () => {
            this.props.handleDelete(error.details.extra[0].file);
          },
        });
        break;
      default:
        this.setState({ handleDelete: undefined });
    }
  };

  static mapStateToProps = ({ modules, services }) => {
    return { modules: modules.modules, services: services.services };
  };

  render() {
    const { state, percentage, uploadFinished, error } = this.state;

    // remote:
    // (% known) 1..100 => uploadFinished (comes from backend)
    // (% unknown) NaN..NaN => uploadFinished (comes from backend)
    // local:
    // (% known) 1..100 => uploadFinished is bound to %
    const isAnUploadingState = FILE_UPLOADER_STATE.isAnUploadingState(state);
    const abortAvailable = !uploadFinished;

    let content;
    switch (state) {
      case FILE_UPLOADER_STATE.PENDING:
        content = (
          <FileUploaderUploadField
            error={error}
            pageName={this.props.resource}
            resetError={this.resetError}
            submitDropzone={this.uploadFile}
            submitLink={this.uploadByLink}
            allowLinkUpload={!this.props.withOutLink}
            handleDelete={this.state.handleDelete}
          />
        );
        break;
      case FILE_UPLOADER_STATE.UPLOADING_FROM_LOCAL:
      case FILE_UPLOADER_STATE.UPLOADING_FROM_REMOTE:
      case FILE_UPLOADER_STATE.DONE:
        content = (
          <FileUploaderProgress
            percentage={percentage}
            processing={uploadFinished}
            finished={state === FILE_UPLOADER_STATE.DONE}
            handleInstall={this.state.handleInstall}
            installAvailable={this.state.installAvailable}
          />
        );
        break;
      case FILE_UPLOADER_STATE.TRYING_TO_ABORT:
        content = <FileUploaderTryingToAbort abort={this.tryToAbort} back={this.returnToPrevState} />;
        break;
      case FILE_UPLOADER_STATE.TRYING_TO_HIDE:
        content = <FileUploaderTryingToHide ok={this.tryToClose} cancel={this.returnToPrevState} />;
        break;
      default:
        throw new Error('UNKNOWN STATE');
    }

    return (
      <FileDrop
        onFrameDragEnter={this.props.onOpenDnD}
        onFrameDragLeave={this.props.onCloseDnD}
        onFrameDrop={this.hideIfDropped}
      >
        <Dialog
          open={this.props.open}
          onClose={this.tryToClose}
          TransitionProps={{ onExited: this.resetState }}
          fullWidth
        >
          <DialogTitle>
            {!isAnUploadingState && this.props.title}

            {isAnUploadingState && <FormattedMessage id='fileuploader.title' defaultMessage='Upload status' />}
          </DialogTitle>

          <DialogContent>{content}</DialogContent>

          <DialogActions>
            {(state === FILE_UPLOADER_STATE.UPLOADING_FROM_LOCAL ||
              state === FILE_UPLOADER_STATE.UPLOADING_FROM_REMOTE) &&
              abortAvailable && (
                <Button onClick={this.tryToAbort} color='primary'>
                  <FormattedMessage id='fileuploader.abort' defaultMessage='Abort' />
                </Button>
              )}

            {([
              FILE_UPLOADER_STATE.PENDING,
              FILE_UPLOADER_STATE.UPLOADING_FROM_REMOTE,
              FILE_UPLOADER_STATE.DONE,
            ].includes(state) ||
              (state === FILE_UPLOADER_STATE.UPLOADING_FROM_LOCAL && !abortAvailable)) && (
              <Button onClick={this.tryToClose} color='primary'>
                <FormattedMessage id='fileuploader.close' defaultMessage='Close' />
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </FileDrop>
    );
  }
}

export default connect(FileUploader.mapStateToProps)(FileUploader);
