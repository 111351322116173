import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

export default createSvgIcon(
  <g>
    <path
      d={
        'm4 1c-1.11 0-2 0.89-2 2v4c0 1.11 0.89 2 2 2h-3v2h12v-2h-3c1.11 0 2-0.89 2-2v-4c0-1.11-0.89-2-2-2h-6m0 2h6v4h-6v-4m-1 10v7h7v-2h-5v-5h-2m11 0c-1.11 0-2 0.89-2 2v4c0 1.11 0.89 2 2 2h-3v2h12v-2h-3c1.11 0 2-0.89 2-2v-4c0-1.11-0.89-2-2-2h-6m0 2h6v4h-6z'
      }
    />
  </g>,
  'lan connect'
);
