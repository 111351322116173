import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(),
    },
  },
  container: {
    minWidth: '200px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginRight: 0,
    },
    [theme.breakpoints.only('lg')]: {
      minWidth: '250px',
    },
    [theme.breakpoints.only('xl')]: {
      minWidth: '275px',
    },
    height: theme.spacing(3),
    borderRadius: 6,
    backgroundColor: '#eee',
    marginRight: theme.spacing(1),
  },
  progressContainer: {
    overflow: 'hidden',
    height: '100%',
    backgroundColor: '#eee',
    borderRadius: '4px',
  },
  progressProgress: {
    backgroundColor: '#ccc',
    height: '100%',
  },
  content: {
    whiteSpace: 'nowrap',
    fontFamily: "'Roboto mono', monospace",
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  label: {
    [theme.breakpoints.only('xs')]: {
      fontSize: 13,
    },
  },
});

function ProgressBar(props) {
  const { classes, title, value, content, label } = props;
  return (
    <div className={classes.root}>
      <Tooltip title={title}>
        <div className={classes.container}>
          <div className={classes.progressContainer}>
            <div className={classes.progressProgress} style={{ width: `${value}%` }}>
              <Typography className={classes.content}>{content}</Typography>
            </div>
          </div>
        </div>
      </Tooltip>
      <Typography className={classes.label}>{label}</Typography>
    </div>
  );
}

export default withStyles(styles)(ProgressBar);
