import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Tooltip from '@material-ui/core/Tooltip';
import StopIcon from '@material-ui/icons/Stop';
import PlayIcon from '@material-ui/icons/PlayArrow';
import ErrorIcon from '@material-ui/icons/Error';
import classnames from 'classnames';
import compose from 'recompose/compose';
import { FormattedMessage } from 'react-intl';
import ServiceStateFactory from '../util/ServiceStateFactory';

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    tableLayout: 'fixed',
  },
  firstColumn: {
    whiteSpace: 'normal',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
  },
  buttonRoot: {
    padding: theme.spacing(1),
  },
  hide: {
    visibility: 'hidden',
  },
  state: {
    textTransform: 'uppercase',
    '&.running': {
      color: theme.palette.icons.success,
    },
    '&:not(.running)': {
      color: theme.palette.text.secondary,
    },
  },
  row: {
    '&:hover $buttonRoot': {
      visibility: 'inherit',
    },
  },
  start: {
    color: theme.palette.icons.success,
  },
  stop: {
    color: theme.palette.icons.danger,
  },
  stateContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

class Services extends React.Component {
  state = { id: null, open: false, name: '', startable: false };

  openDialog = (id, name, startable) => () => {
    this.setState({ id, open: true, name, startable });
  };

  closeDialog = () => {
    this.setState({ open: false });
  };

  toggleService = () => {
    if (this.state.startable) {
      this.props.start(this.state.id);
    } else {
      this.props.stop(this.state.id);
    }

    this.closeDialog();
  };

  render() {
    const { services, classes, width, permissions, modulesWithBanOnActions } = this.props;
    const { w } = permissions;
    const isSmallScreen = width === 'xs';
    const isSmallOrMedScreen = width === 'xs' || width === 'sm';
    const states = ServiceStateFactory();

    return (
      <Paper className={classes.root}>
        <Table className={classes.table} padding={isSmallScreen ? 'checkbox' : 'default'}>
          {!w ? (
            <colgroup>
              <col style={{ width: '70%' }} />
              <col style={{ width: '30%' }} />
            </colgroup>
          ) : isSmallScreen ? (
            <colgroup>
              <col style={{ width: '50%' }} />
              <col style={{ width: '25%' }} />
              <col style={{ width: '25%' }} />
            </colgroup>
          ) : (
            <colgroup>
              <col style={{ width: '65%' }} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '15%' }} />
            </colgroup>
          )}
          <TableHead>
            <TableRow>
              <TableCell className={classes.firstColumn}>
                <FormattedMessage id='modules.services.tableHeaders.serviceName' defaultMessage='Service name' />
              </TableCell>
              <TableCell>
                <FormattedMessage id='modules.services.tableHeaders.status' defaultMessage='Status' />
              </TableCell>
              {w && (
                <TableCell align='right'>
                  <FormattedMessage id='modules.services.tableHeaders.action' defaultMessage='Action' />
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {services.map(service => {
              const { id, name, state } = service;

              const startable = ['created', 'exited'].includes(state);
              const stoppable = ['running', 'restarting'].includes(state);

              const isDead = state === 'dead';

              return (
                <TableRow key={id} className={classes.row} hover>
                  <TableCell component='th' scope='row' className={classes.firstColumn}>
                    {name}
                  </TableCell>

                  <TableCell className={classnames(classes.state, state)}>
                    <div className={classes.stateContainer}>
                      {states[state] || states.notAvailable}
                      {isDead && <ErrorIcon color='error' />}
                    </div>
                  </TableCell>

                  {w && (
                    <TableCell align='right'>
                      {!modulesWithBanOnActions.includes(name) && (
                        <Fragment>
                          {startable && (
                            <Tooltip
                              title={
                                <FormattedMessage id='modules.services.startService' defaultMessage='Start service' />
                              }
                            >
                              <IconButton
                                classes={{
                                  root: classnames(classes.buttonRoot, !isSmallOrMedScreen && classes.hide),
                                }}
                                onClick={this.openDialog(id, name, true)}
                              >
                                <PlayIcon className={classes.start} />
                              </IconButton>
                            </Tooltip>
                          )}

                          {stoppable && (
                            <Tooltip
                              title={
                                <FormattedMessage id='modules.services.stopService' defaultMessage='Stop service' />
                              }
                            >
                              <IconButton
                                classes={{
                                  root: classnames(classes.buttonRoot, !isSmallOrMedScreen && classes.hide),
                                }}
                                onClick={this.openDialog(id, name, false)}
                              >
                                <StopIcon className={classes.stop} />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Fragment>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <Dialog open={this.state.open} onClose={this.closeDialog} maxWidth='sm' fullWidth>
          <DialogTitle>
            <FormattedMessage
              id='modules.services.startStopServiceDialog.title'
              defaultMessage='{startable, select, true {Start} other {Stop}} service'
              values={{ startable: this.state.startable }}
            />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <FormattedMessage
                id='modules.services.startStopServiceDialog.contentText'
                defaultMessage='Are you sure you want to {startable, select, true {start} other {stop}} {name}?'
                values={{ startable: this.state.startable, name: this.state.name }}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog}>
              <FormattedMessage id='modules.services.startStopServiceDialog.buttons.cancel' defaultMessage='Cancel' />
            </Button>
            <Button color='primary' onClick={this.toggleService}>
              <FormattedMessage
                id='modules.services.startStopServiceDialog.buttons.startStop'
                defaultMessage='{startable, select, true {Start} other {Stop}}'
                values={{ startable: this.state.startable }}
              />
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }
}

Services.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withWidth())(Services);
