import { pick, omit } from 'lodash';
import { fetcher } from '../../util/deps';
import { omitAdditionalFields } from '../../util/commonHelpers';
import {
  SET_INIT_STATE,
  RESET_CHANGES,
  REMOVE_IFACE,
  ADD_VTUN,
  SET_OPTION,
  SET_VALIDATION_ERROR,
  SET_PENDING_IFACE,
  CLEAR_PENDING_IFACE,
  OMIT_OPTION,
  netupSettings,
} from '../constants';
import { getVtunError } from '../../util/networkHelpers';

const initialLoad = () => async dispatch => {
  const res = await fetcher.get('vtun', undefined, false);
  if (res) {
    const vtunState = omitAdditionalFields(res);
    dispatch(setInitialState(vtunState));
  }
};

const saveChanges = ifaceName => async (dispatch, getState) => {
  dispatch({ type: SET_PENDING_IFACE, payload: ifaceName });
  const ifaceState = getState().vtun.localState[ifaceName];
  let request;
  if (ifaceName === 'netup') {
    request = { netup: pick(ifaceState, netupSettings) };
  } else if (ifaceState.name === ifaceName) {
    request = { [ifaceName]: omit(ifaceState, ['name', 'device']) };
  } else {
    // in this case user changed iface name
    request = { [ifaceState.name]: omit(ifaceState, ['name', 'device']), [ifaceName]: null };
  }

  const res = await fetcher.put('vtun', request, false);
  dispatch({ type: CLEAR_PENDING_IFACE });
  if (res) {
    const newState = omitAdditionalFields(res);
    dispatch(setInitialState(newState));
  }
};

const saveNew = () => async (dispatch, getState) => {
  dispatch({ type: SET_PENDING_IFACE, payload: 'VTUN' });
  const ifaceState = getState().vtun.localState.VTUN;
  const ifaceName = ifaceState.name;
  const request = { [ifaceName]: omit(ifaceState, 'name') };
  const res = await fetcher.put('vtun', request, false);
  dispatch({ type: CLEAR_PENDING_IFACE });
  if (res) {
    const newState = omitAdditionalFields(res);
    dispatch(setInitialState(newState));
  }
};

const removeIface = ifaceName => async dispatch => {
  dispatch({ type: SET_PENDING_IFACE, payload: ifaceName });
  const res = await fetcher.put('vtun', { [ifaceName]: null }, false);
  dispatch({ type: CLEAR_PENDING_IFACE });
  if (res) {
    dispatch({ type: REMOVE_IFACE, payload: ifaceName });
  }
};

const resetChanges = ifaceName => ({ type: RESET_CHANGES, payload: ifaceName });

const setInitialState = loadedState => ({ type: SET_INIT_STATE, payload: loadedState });

const setFieldValue = (ifaceName, field, enteredValue) => {
  if (ifaceName === 'netup' && (field === 'host' || field === 'port') && enteredValue === 'default') {
    return { type: OMIT_OPTION, payload: ['netup', field] };
  } else {
    let value = enteredValue;
    if ((field === 'port' || field === 'timeout') && !isNaN(enteredValue) && enteredValue.length > 0) {
      value = +value;
    } else if (field === 'remote_ip' || field === 'local_ip') {
      value = value.replace(',', '.');
    }
    return {
      type: SET_OPTION,
      payload: [ifaceName, field, value],
    };
  }
};

const validateFieldValue = (ifaceName, field, value) => {
  const error = getVtunError(field, value);
  return { type: SET_VALIDATION_ERROR, payload: [ifaceName, field, error] };
};

const addVtun = vtunConfig => ({ type: ADD_VTUN, payload: vtunConfig });

export default {
  initialLoad,
  addVtun,
  setFieldValue,
  setInitialState,
  validateFieldValue,
  saveChanges,
  resetChanges,
  removeIface,
  saveNew,
};
