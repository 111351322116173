import { SET_MODULES, ADD_UPDATING_MODULE, REMOVE_UPDATING_MODULE, CLEAR_UPDATING_MODULES } from '../constants';

export default (modules = { modules: null, updatingModules: [] }, action) => {
  switch (action.type) {
    case SET_MODULES:
      return { ...modules, modules: action.payload.modules };
    case ADD_UPDATING_MODULE:
      return { ...modules, updatingModules: [...modules.updatingModules, action.payload] };
    case REMOVE_UPDATING_MODULE:
      return { ...modules, updatingModules: modules.updatingModules.filter(m => m !== action.payload) };
    case CLEAR_UPDATING_MODULES:
      return { ...modules, updatingModules: [] };
    default:
      return modules;
  }
};
