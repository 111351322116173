import { SET_SERVICES, SET_SERVICE_STATUS, DELETE_SERVICE, ADD_SERVICE } from '../constants';

export default (services = { services: null }, action) => {
  switch (action.type) {
    case SET_SERVICES:
      return action.payload;

    case SET_SERVICE_STATUS: {
      const list = services.services;
      const idx = list.findIndex(service => service.id === action.payload.id);
      list[idx] = { ...list[idx], running: action.payload.state };

      return services;
    }

    case DELETE_SERVICE: {
      const list = services.services;
      const list_ = list.filter(service => service.id !== action.payload.id);

      return { ...services, services: list_ };
    }

    case ADD_SERVICE: {
      const list = services.services;
      return { ...services, services: [...list, action.payload] };
    }

    default:
      return services;
  }
};
