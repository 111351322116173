import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';

class FileUploaderTryingToAbort extends React.Component {
  render() {
    const { back, abort } = this.props;

    return (
      <Fragment>
        <div className='dropzone'>
          <div className='dropzone-contents'>
            <DialogContentText>
              <Typography variant='h6'>
                <FormattedMessage
                  id='fileUploader.abortUpload.confirmationMessage'
                  defaultMessage='Are you sure you want to abort the upload?'
                />
              </Typography>

              <div>
                <Button onClick={back} className='dropzone-button'>
                  <FormattedMessage id='fileUploader.abortUpload.buttons.back' defaultMessage='Back' />
                </Button>
                <Button onClick={abort} className='dropzone-button'>
                  <FormattedMessage id='fileUploader.abortUpload.buttons.abort' defaultMessage='Abort' />
                </Button>
              </div>
            </DialogContentText>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default FileUploaderTryingToAbort;
