import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';

export default (handleDrawerToggle, classes) => () => (
  <IconButton
    color='inherit'
    id='app-bar-menu-button'
    aria-label='Open/expand menu'
    onClick={handleDrawerToggle}
    className={classes.menuButton}
  >
    <MenuIcon />
  </IconButton>
);
