import { FC, useState } from 'react';
import {
  Accordion,
  AccordionActions,
  AccordionActionsProps,
  AccordionDetails,
  AccordionDetailsProps,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  Theme,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  alwaysExpanded: {
    borderStyle: 'dotted',
    borderWidth: 3,
    borderRadius: `${theme.spacing(1)}px !important`,
    borderColor: theme.palette.action.hover,
    backgroundColor: theme.palette.action.hover,
    '& > [role="button"]:hover': {
      cursor: 'auto',
    },
  },
  summary: {
    '&& > div': {
      margin: 0,
    },
    [theme.breakpoints.only('xs')]: {
      padding: `0px ${theme.spacing(1)}px`,
    },
  },
  details: {
    width: '100%',
    display: 'block',
    [theme.breakpoints.only('xs')]: {
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
    },
  },
}));

interface CustomAccordionProps {
  onChange: AccordionProps['onChange'];
  expanded?: boolean;
  alwaysExpanded?: boolean;
  summary: AccordionSummaryProps['children'];
  details: AccordionDetailsProps['children'];
  actions: AccordionActionsProps['children'];
}

const CustomAccordion: FC<CustomAccordionProps> = ({
  onChange,
  expanded,
  alwaysExpanded,
  summary,
  details,
  actions,
}) => {
  const classes = useStyles();
  const [internalExpanded, setInternalExpanded] = useState(alwaysExpanded);

  const handleChange: AccordionProps['onChange'] = (e, expanded) => {
    if (alwaysExpanded) return;
    setInternalExpanded(expanded);
    if (onChange) {
      onChange(e, expanded);
    }
  };

  return (
    <Accordion
      onChange={handleChange}
      expanded={!!expanded || !!internalExpanded || !!alwaysExpanded}
      elevation={alwaysExpanded ? 0 : 1}
      className={alwaysExpanded ? classes.alwaysExpanded : ''}
    >
      <AccordionSummary
        className={classes.summary}
        expandIcon={
          !alwaysExpanded && (
            <Tooltip title={<FormattedMessage id='vtunPanel.showHide' defaultMessage='Show/Hide' />}>
              <ExpandMore />
            </Tooltip>
          )
        }
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails className={classes.details}>{details}</AccordionDetails>
      <AccordionActions>{actions}</AccordionActions>
    </Accordion>
  );
};

export default CustomAccordion;
