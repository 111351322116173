import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import WarningIcon from '@material-ui/icons/Warning';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { FormattedMessage } from 'react-intl';

import { isIp } from '../../util/ip';
import Transition from '../util/DialogTransition';

const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  toolbar: {
    padding: `0 ${theme.spacing(1.5)}px`,
  },
  cancelButton: {
    marginRight: theme.spacing(1),
  },
  spacer: {
    height: theme.spacing(3),
  },
  toolBarTypography: {
    flex: 1,
  },
  dialogContentText: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
  },
  error: {
    color: theme.palette.error.main,
  },
});

class ConnectionEditDialog extends React.Component {
  state = {
    autodetect: false,
    selectedAddress: this.props.address,
    initialAddress: this.props.address,
    showError: false,
  };

  handleEnterPressed = event => {
    let code = event.keyCode || event.which;
    if (code === 13) {
      event.preventDefault();
      if (this.state.autodetect) {
        this.props.handleAutodetect();
      } else {
        this.props.handleApply(this.state.selectedAddress)();
      }
    }
  };

  handleDialogEnter = () => {
    this.setState({
      selectedAddress: this.props.address,
      initialAddress: this.props.address,
    });
  };

  handleChange = event => {
    const value = event.target.value.replace(',', '.');
    this.setState({ selectedAddress: value, showError: value !== '' && !isIp(value) });
  };

  toggleAutodetect = () => {
    const { autodetect, selectedAddress } = this.state;
    this.setState({
      autodetect: !autodetect,
      showError: autodetect && selectedAddress !== '' && !isIp(selectedAddress),
    });
  };

  handleExited = () => this.setState({ autodetect: false, showError: false });

  render() {
    const { classes, open, handleClose, handleApply, handleAutodetect, deviceName, fullScreen } = this.props;
    const { autodetect, selectedAddress, showError, initialAddress } = this.state;
    const addressChanged = initialAddress !== selectedAddress;
    const disabled = (!autodetect && !addressChanged) || showError;
    const title = <FormattedMessage id='connectionEditDialog.title' defaultMessage='Edit service address' />;

    return (
      <Dialog
        open={open}
        onKeyPress={this.handleEnterPressed}
        onClose={handleClose}
        TransitionProps={{
          onEnter: this.handleDialogEnter,
          onExited: this.handleExited,
        }}
        fullScreen={fullScreen}
        TransitionComponent={fullScreen ? Transition : undefined}
      >
        {fullScreen && (
          <AppBar className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
              <IconButton color='inherit' onClick={handleClose} aria-label='Close' className={classes.cancelButton}>
                <CloseIcon />
              </IconButton>
              <Typography variant='h6' color='inherit' className={classes.toolBarTypography}>
                {title}
              </Typography>
              {!disabled && (
                <IconButton
                  color='inherit'
                  onClick={autodetect ? handleAutodetect : handleApply(selectedAddress)}
                  aria-label='Ok'
                >
                  <CheckIcon />
                </IconButton>
              )}
            </Toolbar>
          </AppBar>
        )}
        {fullScreen && <div className={classes.spacer} />}
        {!fullScreen && <DialogTitle>{title}</DialogTitle>}
        <DialogContentText className={classes.dialogContentText}>
          <FormattedMessage
            id='connectionEditDialog.message'
            defaultMessage={
              `Select autodetect to try to autodetect {deviceName} address.` +
              `Otherwise you can enter its address manually.`
            }
            values={{ deviceName }}
          />
        </DialogContentText>
        <DialogContent>
          <FormControlLabel
            control={<Checkbox checked={autodetect} color='primary' />}
            onChange={this.toggleAutodetect}
            label='Autodetect'
          />
          <FormControl className={classes.formControl}>
            <Input
              value={selectedAddress}
              disabled={autodetect}
              onChange={this.handleChange}
              error={showError}
              endAdornment={
                showError && (
                  <InputAdornment position='end'>
                    <WarningIcon className={classes.error} />
                  </InputAdornment>
                )
              }
            />
            <FormHelperText className={classes.error}>
              {showError ? (
                <FormattedMessage id='ConnectionEditDialog.invalidIpAddress' defaultMessage='Invalid IP address' />
              ) : (
                ''
              )}
            </FormHelperText>
          </FormControl>
        </DialogContent>
        {!fullScreen && (
          <DialogActions>
            <Button color='primary' onClick={handleClose}>
              <FormattedMessage id='connectionEditDialog.cancel' defaultMessage='Cancel' />
            </Button>
            <Button
              color='primary'
              onClick={autodetect ? handleAutodetect : handleApply(selectedAddress)}
              disabled={disabled}
              aria-label='Save'
            >
              <FormattedMessage id='connectionEditDialog.Save' defaultMessage='Save' />
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  }
}

ConnectionEditDialog.propTypes = {
  address: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleApply: PropTypes.func.isRequired,
  handleAutodetect: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withMobileDialog())(ConnectionEditDialog);
