import { FC, ReactNode, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { licenseActions } from '@/redux-stuff/actions';
import { useAppDispatch, useAppSelector } from '@/util/store';
import MenuCard from './MenuCard';

interface ServiceCardProps {
  moduleName: string;
  licenseName?: string;
  uri: string;
  label: ReactNode;
  SvgComponent?: ReactNode;
  type?: string;
}
const ServiceCard: FC<ServiceCardProps> = ({ moduleName, licenseName, uri, label, SvgComponent, type }) => {
  const services = useAppSelector(({ services }) => services.services);
  const license = useAppSelector(({ license }) => license);
  const dispatch = useAppDispatch();
  useEffect(() => {
    // Load license information
    if (!license && licenseName) {
      dispatch(licenseActions.loadLicense());
    }
  }, [license]);
  const haveLicense = useMemo(() => {
    // Disable this check we don't have license information
    if (!licenseName || !license) {
      return true;
    }
    if (!license.components) {
      return false;
    }
    const component = license.components.find((c: { title: string }) => c.title === licenseName);
    if (!component) {
      return false;
    }
    if (component.revoked) {
      return false;
    }
    try {
      return (
        Number(new Date(component.validity.notAfter)) > Date.now() &&
        Number(new Date(component.validity.notBefore)) < Date.now()
      );
    } catch {
      return false;
    }
  }, [license, licenseName]);

  const service = services && services.find((s: { name: string }) => s.name === moduleName);

  if (!service) {
    return null;
  }

  return (
    <MenuCard
      uri={uri}
      name={label}
      SvgComponent={SvgComponent}
      type={type}
      greyout={!service.running || !haveLicense}
      customTitle={
        !haveLicense ? (
          <FormattedMessage
            id='menuCard.noLicense'
            defaultMessage='There is no active license for {component}'
            values={{ component: licenseName }}
          />
        ) : undefined
      }
    />
  );
};

export default ServiceCard;
