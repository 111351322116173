import { fetcher } from './deps';

export async function loadTimeIssues() {
  const info = await fetcher.get('info');
  const serverTime = info.system.time * 1000;
  const delta = Math.abs(serverTime - Date.now());

  if (delta > 30e3) {
    return [{ message: 'TIME_DIFFERS_TOO_MUCH', delta }];
  }
  return [];
}
