import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import MobileStepper from '@material-ui/core/MobileStepper';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import LeftArrowIcon from '@material-ui/icons/KeyboardArrowLeft';
import RightArrowIcon from '@material-ui/icons/KeyboardArrowRight';
import withWidth from '@material-ui/core/withWidth';
import SwipeableViews from 'react-swipeable-views';
import { isMobile } from 'react-device-detect';
import classnames from 'classnames';

import { widgetsActions } from '../../redux-stuff/actions';
import ResponsiveDialog from '../responsive-dialog';
import tutorialSteps from '../../util/tutorial';

const styles = theme => ({
  stepContainer: {
    // display: 'grid',
    // flexDirection: 'column',
    // justifyItems: 'center',
    position: 'relative',
  },
  instructions: {
    maxWidth: '640px',
    margin: '0 auto',
    marginTop: theme.spacing(1),

    textAlign: 'left',
  },
  imageContainer: {
    // padding: '2px',
    maxWidth: '640px',
    margin: '0 auto',
    '& img': {
      maxWidth: '100%',
    },
    display: 'grid',
    justifyItems: 'center',
  },

  progressDots: {
    backgroundColor: 'inherit',
  },
  progressDotsMobile: {
    position: 'absolute',
    // fluid | static | fluid | static
    // <----368------600-----690----->

    // fluid
    [theme.breakpoints.down(368)]: {
      paddingTop: '94%',
      top: 0,
      left: '50%',
      transform: 'translate(-50%)',
    },

    // static
    [theme.breakpoints.up(368)]: {
      [theme.breakpoints.only('xs')]: {
        top: '320px',
        left: '50%',
        transform: 'translate(-50%, -125%)',
      },
    },

    // fluid
    [theme.breakpoints.up(600)]: {
      [theme.breakpoints.down(688)]: {
        paddingTop: '70%',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
      },
    },

    // static
    [theme.breakpoints.up(688)]: {
      // [theme.breakpoints.down('sm')]: {
      top: '480px',
      left: '50%',
      transform: 'translate(-50%, -125%)',
      // },
    },
  },

  dot: {
    backgroundColor: 'rgba(255,255,255,0.54)',
  },
  dotActive: {
    backgroundColor: theme.palette.primary.main,
  },

  button: {
    color: '#64DD17',
    paddingLeft: theme.spacing(2),
  },
  arrowButton: {
    fontSize: 60,
  },
});

const STEPS_COUNT = tutorialSteps.length;

class Tutorial extends React.Component {
  state = { openTutorialDialog: false, activeStep: 0 };

  handleCloseSnackBar = () => this.props.setWidgetState('tutorial', false);

  handleOpenTutorial = () => this.setState({ openTutorialDialog: true }, this.handleCloseSnackBar);

  handleCloseTutorial = () => this.setState({ openTutorialDialog: false });

  handleNext = () => this.setState({ activeStep: this.state.activeStep + 1 });

  handleBack = () => this.setState({ activeStep: this.state.activeStep - 1 });

  handleReset = () => this.setState({ activeStep: 0 });

  render() {
    const { widgets, width, classes } = this.props;
    const xs = width === 'xs';

    return (
      <Fragment>
        <Snackbar
          open={widgets.tutorial && !widgets.settingsDialogOpen}
          onClose={this.handleCloseSnackBar}
          message={
            <FormattedMessage
              id='tutorial.offerSnackbarMessage'
              defaultMessage='Hi! It seems like you are here for the first time. How about a short tutorial?'
            />
          }
          action={[
            <Button key='no' size='small' onClick={this.handleCloseSnackBar} className={classes.button}>
              <FormattedMessage id='tutorial.button.dismiss' defaultMessage='Dismiss' />
            </Button>,
            <Button key='yes' size='small' onClick={this.handleOpenTutorial} className={classes.button}>
              <FormattedMessage id='tutorial.button.openTutorial' defaultMessage='Open tutorial' />
            </Button>,
          ]}
        />

        <ResponsiveDialog
          open={this.state.openTutorialDialog}
          onClose={this.handleCloseTutorial}
          onExited={this.handleReset}
          buttons={[
            <Button key='back' color='inherit' onClick={this.handleCloseTutorial}>
              {this.state.activeStep < STEPS_COUNT - 1 ? (
                <FormattedMessage id='tutorialDialog.button.skip' defaultMessage='skip' />
              ) : (
                <FormattedMessage id='tutorialDialog.button.finish' defaultMessage='Finish' />
              )}
            </Button>,
          ]}
          fullWidth
          maxWidth='md'
        >
          <div className={classes.stepContainer}>
            {isMobile && <div className={classes.test} />}
            <SwipeableViews index={this.state.activeStep} onChangeIndex={i => this.setState({ activeStep: i })}>
              {tutorialSteps.map(([title, caption, image], i) => {
                return (
                  <div key={i}>
                    <div className={classes.imageContainer}>{xs ? image.mobile : image.desktop}</div>
                    <Typography variant='body1' color='textSecondary' className={classes.instructions} align='center'>
                      {caption}
                    </Typography>
                  </div>
                );
              })}
            </SwipeableViews>
            <MobileStepper
              steps={STEPS_COUNT}
              position='static'
              activeStep={this.state.activeStep}
              className={classnames(classes.progressDots, isMobile && classes.progressDotsMobile)}
              classes={isMobile ? { dot: classes.dot, dotActive: classes.dotActive } : null}
              backButton={
                !isMobile && (
                  <IconButton color='primary' disabled={this.state.activeStep === 0} onClick={this.handleBack}>
                    <LeftArrowIcon className={classes.arrowButton} />
                  </IconButton>
                )
              }
              nextButton={
                !isMobile && (
                  <IconButton
                    color='primary'
                    disabled={this.state.activeStep === STEPS_COUNT - 1}
                    onClick={this.handleNext}
                  >
                    <RightArrowIcon className={classes.arrowButton} />
                  </IconButton>
                )
              }
            />
          </div>
        </ResponsiveDialog>
      </Fragment>
    );
  }
}

export default compose(
  connect(({ widgets }) => ({ widgets }), { ...widgetsActions }),
  withWidth(),
  withStyles(styles)
)(Tutorial);
