import React, { Fragment } from 'react';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

const TRANSMIT_HASH_POLICY_MODES = ['layer2', 'layer3+4', 'layer2+3', 'encap2+3', 'encap3+4'];

const MODES = ['balance-rr', 'balance-tlb', 'balance-alb', 'balance-xor', 'broadcast', 'active-backup', '802.3ad'];

export default function BondContainer(props) {
  const {
    classes,
    name: bondName,
    iface,
    propsIfaces,
    handleEditBondInterfaces,
    handleEditBondParams,
    miiError,
    w,
  } = props;
  const { miiMonitorSec = '', lacpTransmitRate = '', transmitHashPolicy = '', mode = '' } = iface.config;
  const { newlyIncludedIfaces = [], newlyExcludedIfaces = [] } = iface;

  const includedInVlanIfaces = Object.values(propsIfaces)
    .filter(({ type }) => type === 'vlan')
    .reduce((acc, { config }) => [...acc, ...config.ifaces], []);

  const relevantInterfaces = Object.values(propsIfaces).filter(
    ({ type, name }) => name.includes('eth') && type !== 'vlan' && !includedInVlanIfaces.includes(name)
  );

  const includedInterfaces = [
    ...new Set([
      ...relevantInterfaces
        .filter(({ name, config = {} }) => config && config.bond === bondName && !newlyExcludedIfaces.includes(name))
        .map(({ name }) => name),
      ...newlyIncludedIfaces,
    ]),
  ];
  const availableToIncludeInterfaces = [
    ...new Set([
      ...relevantInterfaces
        .filter(({ name, config = {} }) => (!config || !config.bond) && !includedInterfaces.includes(name))
        .map(({ name }) => name),
      ...newlyExcludedIfaces,
    ]),
  ];

  return (
    <Fragment>
      <div className={classes.inputChipsContainer}>
        <div className={classnames(classes.leftContainer, classes.mode)}>
          <FormControl className={classes.formControl} disabled={!w}>
            <InputLabel htmlFor='mode'>Mode</InputLabel>
            <Select value={mode} onChange={handleEditBondParams(bondName, 'mode')} inputProps={{ id: 'mode' }}>
              {MODES.map(mode => (
                <MenuItem key={mode} value={mode}>
                  {mode}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText />
          </FormControl>
        </div>
        <div className={classes.threeElementsContainer}>
          <FormControl className={classes.formControl} disabled={!w}>
            <InputLabel htmlFor={`miiMonitorSec`} error={!!miiError}>
              MIIMonitorSec
            </InputLabel>
            <Input
              id={`miiMonitorSec`}
              value={miiMonitorSec}
              onChange={handleEditBondParams(bondName, 'miiMonitorSec')}
              error={!!miiError}
            />
            <FormHelperText id={`miiMonitorSec-error`} error={!!miiError}>
              {miiError}
            </FormHelperText>
          </FormControl>
        </div>
        <div className={classes.threeElementsContainer}>
          <FormControl className={classes.formControl} disabled={!w}>
            <InputLabel htmlFor='LACPTransmitRate'>LACPTransmitRate</InputLabel>
            <Select
              value={lacpTransmitRate}
              onChange={handleEditBondParams(bondName, 'lacpTransmitRate')}
              inputProps={{ id: 'LACPTransmitRate' }}
            >
              <MenuItem key={'slow'} value={'slow'}>
                {'slow'}
              </MenuItem>
              <MenuItem key={'fast'} value={'fast'}>
                {'fast'}
              </MenuItem>
            </Select>
            <FormHelperText />
          </FormControl>
        </div>
        <div className={classes.threeElementsContainer}>
          <FormControl className={classes.formControl} disabled={!w}>
            <InputLabel htmlFor='transmitHashPolicy'>TransmitHashPolicy</InputLabel>
            <Select
              value={transmitHashPolicy}
              onChange={handleEditBondParams(bondName, 'transmitHashPolicy')}
              inputProps={{ id: 'transmitHashPolicy' }}
            >
              {TRANSMIT_HASH_POLICY_MODES.map(mode => (
                <MenuItem key={mode} value={mode}>
                  {mode}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText />
          </FormControl>
        </div>
        <div className={classes.leftContainer}>
          <FormControl className={classes.formControl} disabled={!w}>
            <InputLabel htmlFor='bond-interfaces'>
              <FormattedMessage id='networkPage.networkInterfaces' defaultMessage='Network interfaces' />
            </InputLabel>
            <Select
              value={''}
              onChange={handleEditBondInterfaces(bondName, 'include')}
              inputProps={{ id: 'bond-interfaces' }}
              disabled={!availableToIncludeInterfaces.length || !w}
            >
              {availableToIncludeInterfaces.sort().map(name => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText />
          </FormControl>
        </div>
        <div className={classes.chips}>
          {includedInterfaces.map(ifaceName => (
            <Chip
              key={ifaceName}
              label={ifaceName}
              onDelete={w ? handleEditBondInterfaces(bondName, 'exclude', ifaceName) : null}
              className={classes.chip}
              variant='outlined'
            />
          ))}
        </div>
      </div>
    </Fragment>
  );
}
