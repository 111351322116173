import { omit } from 'lodash';
import { getNewName } from '../../util/networkHelpers';
import {
  ADD_VTUN,
  SET_INIT_STATE,
  SET_PENDING_IFACE,
  CLEAR_PENDING_IFACE,
  RESET_CHANGES,
  REMOVE_IFACE,
  SET_OPTION,
  OMIT_OPTION,
  SET_VALIDATION_ERROR,
  DEFAULT_VTUN_CONFIG,
  settingsToExclude,
} from '../constants';

export default (
  vtun = {
    lastLoadedState: {},
    localState: {},
    validationErrors: {},
    selectedToRemoveIface: null,
    pendingChangesIface: null,
  },
  action
) => {
  switch (action.type) {
    case ADD_VTUN: {
      const newIface = action.payload;
      return {
        ...vtun,
        localState: { VTUN: { ...newIface.VTUN, name: getNewName(vtun.localState) }, ...vtun.localState },
      };
    }
    case SET_INIT_STATE: {
      const loadedState = action.payload;
      // We should add name field to non-default vtun ifaces
      const localState = Object.entries(loadedState).reduce(
        (acc, [ifaceName, config]) => {
          // User can't see and edit 'persist' and 'timeout' from UI
          const convertedConfig = omit(config, settingsToExclude);
          return {
            ...acc,
            [ifaceName]: ifaceName !== 'netup' ? { ...convertedConfig, name: ifaceName } : convertedConfig,
          };
        },
        loadedState.netup ? {} : { netup: DEFAULT_VTUN_CONFIG }
      );
      return { ...vtun, localState, lastLoadedState: loadedState };
    }
    case SET_PENDING_IFACE:
      return { ...vtun, pendingChangesIface: action.payload };
    case CLEAR_PENDING_IFACE:
      return { ...vtun, pendingChangesIface: null };
    case RESET_CHANGES: {
      const ifaceName = action.payload;
      const newIfaceState = vtun.lastLoadedState[ifaceName]
        ? omit(vtun.lastLoadedState[ifaceName], settingsToExclude)
        : DEFAULT_VTUN_CONFIG;
      const newState = { ...vtun, validationErrors: { ...vtun.validationErrors, [ifaceName]: {} } };
      if (ifaceName === 'VTUN') {
        return { ...newState, localState: { ...omit(vtun.localState, 'VTUN') } };
      } else if (ifaceName === 'netup') {
        return { ...newState, localState: { ...vtun.localState, netup: newIfaceState } };
      } else {
        return {
          ...newState,
          localState: { ...omit(vtun.localState, ifaceName), [ifaceName]: { ...newIfaceState, name: ifaceName } },
        };
      }
    }
    case REMOVE_IFACE: {
      const ifaceName = action.payload;
      return {
        ...vtun,
        localState: omit(vtun.localState, ifaceName),
        lastLoadedState: omit(vtun.lastLoadedState, ifaceName),
      };
    }
    case SET_OPTION: {
      const [ifaceName, field, value] = action.payload;
      return {
        ...vtun,
        localState: { ...vtun.localState, [ifaceName]: { ...vtun.localState[ifaceName], [field]: value } },
      };
    }
    case OMIT_OPTION: {
      const [ifaceName, field] = action.payload;
      return { ...vtun, localState: { ...vtun.localState, [ifaceName]: omit(vtun.localState[ifaceName], field) } };
    }
    case SET_VALIDATION_ERROR: {
      const [ifaceName, field, error] = action.payload;
      return {
        ...vtun,
        validationErrors: {
          ...vtun.validationErrors,
          [ifaceName]: { ...(vtun.validationErrors[ifaceName] || {}), [field]: error },
        },
      };
    }
    default:
      return vtun;
  }
};
