export const havePermission = (permissions, page, action = 'read') => {
  let arrToCheck;
  switch (page) {
    case 'users-roles':
      arrToCheck = ['users', 'roles'];
      break;
    case 'routing':
      arrToCheck = ['network'];
      break;
    case 'modules':
      // case 'modules/modules':
      // case 'modules/services':
      arrToCheck = ['modules', 'services'];
      break;
    default:
      arrToCheck = [page];
  }

  const checkPermission = segments => segments.some(v => permissions[v] && permissions[v][action]);
  return checkPermission(arrToCheck);
};

export const parsePermissions = (permissions, segment) => ({
  r: permissions[segment] && permissions[segment].read,
  w: permissions[segment] && permissions[segment].write,
});
