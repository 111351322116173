import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { compareIpv4, isIpv4Match, isValid } from '@/util/ip';

type ErrorType = MessageDescriptor;

const errorMessages = defineMessages({
  ip: { id: 'errors.ipv4', defaultMessage: 'Invalid IPv4 address' },
  noAddresses: { id: 'dhcp.noAddresses', defaultMessage: 'No interface addresses' },
  outOfRange: { id: 'dhcp.outOfRange', defaultMessage: 'IP is out of range' },
});

export type DhcpServerConfig = {
  address: string;
  ranges: {
    from: string;
    to: string;
  }[];
  dns_servers: string[];
  ntp_servers?: string[];
  gateway: string;
  timezone?: string;
} | null;

interface DhcpServerFormOptions {
  options: {
    enabled: boolean;
    from: string;
    to: string;
  };
  errors: {
    from?: ErrorType;
    to?: ErrorType;
  };
  valid: boolean;
  modified: boolean;
  addresses: string[];
  newConfig: DhcpServerConfig;
  config: DhcpServerConfig;
}

const config2options = (config: DhcpServerConfig): DhcpServerFormOptions['options'] => {
  if (!config) {
    return {
      enabled: false,
      from: '',
      to: '',
    };
  }
  return {
    ...(config && config.ranges.length > 0 ? config.ranges[0] : { from: '', to: '' }),
    enabled: true,
  };
};

const sliceName = 'dhcpServer';

const dhcpServerSlice = createSlice({
  name: sliceName,
  initialState: {} as Record<string, DhcpServerFormOptions>,
  reducers: {
    setDhcpConfig: (
      state,
      {
        payload: { name, config, addresses },
      }: PayloadAction<{ name: string; config: DhcpServerConfig; addresses: string[] }>
    ) => {
      state[name] = {
        config: config,
        newConfig: config,
        errors: {},
        valid: true,
        modified: false,
        options: config2options(config),
        addresses,
      };
    },
    resetDhcpConfig: (state, { payload: name }: PayloadAction<string>) => {
      if (!(name in state)) {
        return;
      }
      const newConfig = state[name].config;
      state[name].newConfig = newConfig;
      state[name].valid = true;
      state[name].options = config2options(newConfig);
    },
    setDhcpIfaceAddresses: (
      state,
      { payload: { name, addresses } }: PayloadAction<{ name: string; addresses: string[] }>
    ) => {
      if (!(name in state)) {
        return;
      }
      state[name].addresses = addresses;
    },
    setDhcpBoolOption: (
      state,
      { payload: { name, option, value } }: PayloadAction<{ name: string; option: 'enabled'; value: boolean }>
    ) => {
      if (!(name in state)) {
        return;
      }
      state[name].options[option] = value;
    },
    setDhcpRangeOption: (
      state,
      { payload: { name, option, value } }: PayloadAction<{ name: string; option: 'from' | 'to'; value: string }>
    ) => {
      if (!(name in state)) {
        return;
      }
      state[name].options[option] = value;
    },
  },
  extraReducers: builder =>
    builder.addMatcher(
      (action): action is PayloadAction<{ name: string }> =>
        typeof action.type === 'string' && action.type.startsWith(sliceName) && typeof action.payload.name === 'string',
      (state, { payload: { name } }) => {
        if (!(name in state)) {
          return;
        }
        let valid = true;
        const errors: DhcpServerFormOptions['errors'] = {};
        const { from, to, enabled } = state[name].options;
        check: if (enabled) {
          if (!isValid('ipv4', from)) {
            errors.from = from ? errorMessages.ip : undefined;
            valid = false;
            break check;
          }
          const { addresses } = state[name];
          if (addresses.length === 0) {
            errors.from = errorMessages.noAddresses;
            valid = false;
            break check;
          }
          let matchedNet: string | undefined;
          for (const net of addresses) {
            if (isIpv4Match(from, net)) {
              matchedNet = net;
              break;
            }
          }
          if (!matchedNet) {
            errors.from = errorMessages.outOfRange;
            valid = false;
            break check;
          }
          if (!isValid('ipv4', to)) {
            errors.to = to ? errorMessages.ip : undefined;
            valid = false;
            break check;
          }
          if (!isIpv4Match(to, matchedNet) || compareIpv4(from, to) !== -1) {
            errors.to = errorMessages.outOfRange;
            valid = false;
            break check;
          }
          const serverAddress = matchedNet.split('/')[0];
          state[name].newConfig = {
            address: matchedNet,
            ranges: [{ from, to }],
            dns_servers: [serverAddress],
            ntp_servers: [serverAddress],
            gateway: serverAddress,
            timezone: state[name].config ? state[name].config!.timezone : undefined,
          };
        } else {
          state[name].newConfig = null;
        }
        state[name].modified = !valid || !isEqual(state[name].config, state[name].newConfig);
        state[name].errors = errors;
        state[name].valid = valid;
      }
    ),
});

export const { setDhcpConfig, resetDhcpConfig, setDhcpIfaceAddresses, setDhcpBoolOption, setDhcpRangeOption } =
  dhcpServerSlice.actions;

export default dhcpServerSlice;
