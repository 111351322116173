import { fetcher } from '../../util/deps';
import { SET_MODULES, ADD_UPDATING_MODULE, REMOVE_UPDATING_MODULE, CLEAR_UPDATING_MODULES } from '../constants';

const loadModules = () => async dispatch => {
  let data = await fetcher.get('modules', undefined, false);
  if (data) {
    dispatch(setModules(data));
  }
};

const setModules = modules => {
  const type = SET_MODULES,
    payload = modules;

  return { type, payload };
};

const addUpdatingModule = module => ({ type: ADD_UPDATING_MODULE, payload: module });

const removeUpdatingModule = module => ({ type: REMOVE_UPDATING_MODULE, payload: module });

const clearUpdatingModules = () => ({ type: CLEAR_UPDATING_MODULES });

export default {
  setModules,
  loadModules,
  addUpdatingModule,
  removeUpdatingModule,
  clearUpdatingModules,
};
