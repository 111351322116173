import { FC } from 'react';
import {
  DialogTitle,
  IconButton,
  IconButtonProps,
  Theme,
  Typography,
  TypographyProps,
  makeStyles,
} from '@material-ui/core';
import { ContentCopy } from '@/components/customIcons';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
  },
  button: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

interface PopupDialogTitleProps {
  children: TypographyProps['children'];
  onCopy: IconButtonProps['onClick'];
}

const PopupDialogTitle: FC<PopupDialogTitleProps> = ({ children, onCopy }) => {
  const classes = useStyles();

  return (
    <DialogTitle disableTypography className={onCopy ? classes.root : ''}>
      <Typography variant='h6'>{children}</Typography>
      {onCopy && (
        <IconButton className={classes.button} onClick={onCopy}>
          <ContentCopy />
        </IconButton>
      )}
    </DialogTitle>
  );
};

export default PopupDialogTitle;
