import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputLabelProps,
  SelectProps,
  Theme,
  makeStyles,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) => ({
  selectField: {
    marginBottom: '20px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 170,
  },
}));

interface CustomSelectProps<T> {
  id: string;
  label: InputLabelProps['children'];
  options: T[];
  value?: T;
  onChange(newValue?: T): void;
}

function CustomSelect<T extends string | number>({ id, label, options, value, onChange }: CustomSelectProps<T>) {
  const classes = useStyles();

  const handleChange: SelectProps['onChange'] = ({ target }) => {
    if (target.value && target.value !== 'default') {
      onChange(target.value as T);
      return;
    }
    onChange(undefined);
  };

  return (
    <FormControl className={classes.selectField}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Select value={value || 'default'} onChange={handleChange} inputProps={{ id }}>
        {options.map(name => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
        {/*In case of previously created config with unusual values*/}
        {value && !options.includes(value) && (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        )}
        <MenuItem key='default' value='default'>
          <FormattedMessage id='vtunPanel.selectForm.defaultMenuItem' defaultMessage='Default' />
        </MenuItem>
      </Select>
    </FormControl>
  );
}

export default CustomSelect;
