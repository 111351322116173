import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Switch from '@material-ui/core/Switch';

import { widgetsActions } from '../../redux-stuff/actions';
import ResponsiveDialog from '../responsive-dialog';
import { LocaleContext } from '../../context';

const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    cursor: 'pointer',
    padding: theme.spacing(2),
    paddingRight: 0,
  },
  icon: {
    cursor: 'pointer',
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
});

class SettingsDialog extends React.Component {
  static contextType = LocaleContext;
  state = { anchorEl: null };

  componentDidMount() {
    this.actions = bindActionCreators(widgetsActions, this.props.dispatch);
  }

  handleLanguageClick = ({ currentTarget }) => this.setState({ anchorEl: currentTarget });

  handleCloseMenu = () => this.setState({ anchorEl: null });

  switchLanguage = language => () => {
    this.context.switchLanguage(language);
    this.setState({ anchorEl: null });
  };

  switchWidgetState = name => () => this.actions.toggleWidgetState(name);

  toggleAlias = (_, checked) => {
    this.actions.setSaveAliasToLocalStorage(checked);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.actions.setSettingsDialogOpen(this.props.open);
    }
  }

  static mapStateToProps({ widgets }) {
    return { widgets };
  }

  render() {
    const { open, onClose, classes, widgets } = this.props;
    const { languages, locale } = this.context;
    const { anchorEl } = this.state;

    return (
      <ResponsiveDialog
        open={open}
        onClose={onClose}
        fullWidth
        withoutConfirm
        closeMessage={<FormattedMessage id='settingsDialog.buttons.close' defaultMessage='Close' />}
        title={<FormattedMessage id='settingsDialog.title' defaultMessage='Settings' />}
      >
        <div className={classes.container}>
          <Typography>
            <FormattedMessage id='settingsDialog.interfaceLanguage' defaultMessage='Interface language' />
          </Typography>
          <div onClick={this.handleLanguageClick} className={classes.container}>
            <Typography className={classes.button}>{locale === 'ru' ? 'Русский' : 'English'}</Typography>
            <ArrowDropDown className={classes.icon} />
          </div>
          <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={this.handleCloseMenu}>
            {languages.map(item => (
              <MenuItem
                value={item.locale}
                key={item.locale}
                onClick={this.switchLanguage(item.locale)}
                selected={locale === item.locale}
              >
                <Typography>{item.lang}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </div>

        <div className={classes.container}>
          <Typography>
            <FormattedMessage id='settingsDialog.showHardwareInfoWidget' defaultMessage='Show hardware info widget' />
          </Typography>
          <div className={classes.container}>
            <Switch color='primary' checked={widgets.hardware} onChange={this.switchWidgetState('hardware')} />
          </div>
        </div>

        <div className={classes.container}>
          <Typography>
            <FormattedMessage id='settingsDialog.showDisksInfoWidget' defaultMessage='Show disks info widget' />
          </Typography>
          <div className={classes.container}>
            <Switch color='primary' checked={widgets.disks} onChange={this.switchWidgetState('disks')} />
          </div>
        </div>

        <div className={classes.container}>
          <Typography>
            <FormattedMessage id='settingsDialog.enableTutorial' defaultMessage='Enable interface tutorial' />
          </Typography>
          <div className={classes.container}>
            <Switch color='primary' checked={widgets.tutorial} onChange={this.switchWidgetState('tutorial')} />
          </div>
        </div>

        <div className={classes.container}>
          <Typography>
            <FormattedMessage
              id='settingsDialog.saveAliasOnlyForThisBrowser'
              defaultMessage='Save alias only for this browser'
            />
          </Typography>
          <div className={classes.container}>
            <Switch color='primary' checked={widgets.saveAliasToLocalStorage} onChange={this.toggleAlias} />
          </div>
        </div>
      </ResponsiveDialog>
    );
  }
}

SettingsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default compose(injectIntl, connect(SettingsDialog.mapStateToProps), withStyles(styles))(SettingsDialog);
