import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import compose from 'recompose/compose';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import ChangePassword from '@material-ui/icons/VpnKey';
import DeleteIcon from '@material-ui/icons/Delete';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { isEmpty } from 'lodash';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Zoom from '@material-ui/core/Zoom';
import { Route, withRouter } from 'react-router-dom';

import ResponsiveDialog from '../components/responsive-dialog';
import CustomFab from '../components/CustomFab';
import CreateDialog from '../components/usersAndRolesPage/CreateDialog';
import ChangePasswordDialog from '../components/ChangePasswordDialog';
import ActionsMenu from '../components/ActionsMenu';
import { rolesActions, usersActions } from '../redux-stuff/actions';
import { parsePermissions } from '../util/permissions';
import PageLoadingIndicator from '../components/PageLoadingIndicator';
import { fetcher } from '../util/deps';
import Alerts from '../components/Alerts';
import { unloadMessage } from '../redux-stuff/constants';

import Users from '../components/usersAndRolesPage/Users';
import Roles from '../components/usersAndRolesPage/Roles';

const styles = theme => ({
  root: {
    margin: 'auto',
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(-3),
    },
    maxWidth: 1100,
    overflow: 'auto',
  },
  mobileRoot: {
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    height: `calc(100vh - 56px)`,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: `calc(100vh - 48px)`,
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100vh - 64px)`,
    },
  },
  tabsBar: {
    backgroundColor: '#FFF',
    width: 1100,
    position: 'fixed',
    '& + *': {
      marginTop: theme.spacing(8),
    },
  },
});

const messages = defineMessages({
  read: {
    id: 'usersAndRolesPage.Read',
    defaultMessage: 'privilege.read',
  },
  write: {
    id: 'usersAndRolesPage.Write',
    defaultMessage: 'privilege.write',
  },
});

class RolesPage extends React.Component {
  constructor(props) {
    super(props);
    const { permissions } = props;

    this.state = {
      searchRoles: {},
      searchUsers: {},
      expandedRoles: [],
      expandedUsers: [],
      createDialogOpen: false,
      actionsMenuOpen: false,
      selectMenuOpen: false,
      deleteDialogOpen: false,
      anchorEl: null,
      permissionsRoles: parsePermissions(permissions, 'roles'),
      permissionsUsers: parsePermissions(permissions, 'users'),
    };

    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    this.actions = {
      ...bindActionCreators(rolesActions, dispatch),
      ...bindActionCreators(usersActions, dispatch),
    };
    this.actions.loadRoles();
    this.actions.loadUsers();
  }

  static mapStateToProps({ roles, users }) {
    return { roles, users };
  }

  componentWillUnmount() {
    this.props.dispatch(rolesActions.clearUnsavedChanges());
    this.props.dispatch(usersActions.clearUnsavedChanges());
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  handleBeforeUnload = event => {
    const unsavedRoles = this.props.roles && (this.props.roles.unsavedChanges || []);
    const unsavedUsers = this.props.users && (this.props.users.unsavedChanges || []);
    if (unsavedRoles.length > 0 || unsavedUsers.length > 0) {
      const message = this.props.intl.formatMessage(unloadMessage.message);
      const confirmationMessage = `${message}: ${[unsavedRoles, unsavedUsers].join(', ')}`;
      (event || window.event).returnValue = confirmationMessage;
      return confirmationMessage;
    }
  };

  setPermission = (role, resource, prop, value) => () => this.actions.setPermission(role, resource, prop, !value);

  handleChangeSearchRoles = role => event =>
    this.setState({ searchRoles: { ...this.state.searchRoles, [role]: event.target.value } });

  handleChangeSearchUsers = user => event =>
    this.setState({ searchUsers: { ...this.state.searchUsers, [user]: event.target.value } });

  handleExpandRoles = role => () => {
    const { expandedRoles } = this.state;
    this.setState({
      expandedRoles: expandedRoles.includes(role) ? expandedRoles.filter(u => u !== role) : [...expandedRoles, role],
    });
  };

  handleExpandUsers = user => () => {
    const { expandedUsers } = this.state;
    this.setState({
      expandedUsers: expandedUsers.includes(user) ? expandedUsers.filter(u => u !== user) : [...expandedUsers, user],
    });
  };

  handleChangeAncestors = role => values => {
    const newAncestors = values.map(v => v.value);
    this.actions.changeAncestors(role, newAncestors);
  };

  handleApplyRolesChanges = role => async e => {
    e.stopPropagation();
    const { lastLoadedRoles, editedRoles } = this.props.roles;
    const prevState = lastLoadedRoles[role];
    const newState = editedRoles[role];

    // ancestors changes
    const reqsToDelete = (prevState.$extend || [])
      .filter(ancestor => !newState.$extend.includes(ancestor))
      .map(ancestor => fetcher.delete(`role/extends/${role}/${ancestor}`));
    const reqsToAdd = (newState.$extend || [])
      .filter(ancestor => !prevState.$extend || !prevState.$extend.includes(ancestor))
      .map(ancestor => fetcher.put(`role/extends/${role}/${ancestor}`));

    // permissions changes
    const reqsToChange = Object.entries(newState)
      .filter(([name]) => name !== '$extend')
      .filter(([name, { read, write }]) =>
        prevState[name] ? read !== prevState[name].read || write !== prevState[name].write : read || write
      )
      .reduce((acc, [name, { read, write }]) => {
        const newAcc = [...acc];
        if (!prevState[name] || read !== prevState[name].read) {
          const req = fetcher[read ? 'put' : 'delete'](`role/grants/${role}/${name}/read`);
          newAcc.push(req);
        }
        if (!prevState[name] || write !== prevState[name].write) {
          const req = fetcher[write ? 'put' : 'delete'](`role/grants/${role}/${name}/write`);
          newAcc.push(req);
        }
        return newAcc;
      }, []);
    const reqs = [...reqsToDelete, ...reqsToAdd, ...reqsToChange];

    try {
      await Promise.all(reqs);
      this.actions.loadRoles(role);
      this.props.addMessage({
        type: 'success',
        data: <FormattedMessage id='rolesTab.snackbar.changesWereApplied' defaultMessage='Applied successfully' />,
      });
    } catch (e) {
      this.props.addMessage({ type: 'failure', data: e });
    }
  };

  handleDiscardRolesChanges = role => e => {
    e.stopPropagation();
    this.actions.discardRoleChanges(role);
  };

  handleChangeUserRoles = username => values =>
    this.actions.updateUser(
      username,
      values.map(v => v.value)
    );

  handleApplyUsersChanges = username => async e => {
    e.stopPropagation();
    try {
      const { roles } = this.props.users.editedUsers.find(u => u.username === username);
      await fetcher.put(`user`, { username, roles }, false);
      this.props.addMessage({
        type: 'success',
        data: <FormattedMessage id='usersTab.snackbar.changesWereApplied' defaultMessage='Applied successfully' />,
      });
      this.actions.loadUsers(username);
    } catch (e) {
      this.props.addMessage({ type: 'failure', data: e });
    }
  };

  handleDiscardUsersChanges = username => e => {
    e.stopPropagation();
    this.actions.discardUserChanges(username);
  };

  handleDelete = type => () => {
    this.actions[type === 'role' ? 'deleteRole' : 'deleteUser'](this.state.anchorName);
  };

  openDeleteDialog = () => this.setState({ deleteDialogOpen: true, actionsMenuOpen: false });
  closeDeleteDialog = () => this.setState({ deleteDialogOpen: false });

  handleCreateDialogOpen = () => this.setState({ createDialogOpen: true });

  handleCreateDialogClose = () => this.setState({ createDialogOpen: false });

  handleCloseActionsMenu = () => this.setState({ actionsMenuOpen: false });

  handleCreate = (name, password) => {
    const tabIndex = this.props.location.pathname === '/users-roles/roles' ? 1 : 0;
    if (tabIndex === 1) {
      this.actions.createRole(name);
    } else {
      this.actions.createUser(name, password);
    }
    this.setState({ createDialogOpen: false });
  };

  handleOpenMenu = anchorName => event => {
    event.stopPropagation();
    this.setState({ actionsMenuOpen: true, anchorEl: event.currentTarget, anchorName });
  };

  handleChangeTab = (event, tabIndex) => {
    if (tabIndex === 0) {
      this.actions.loadUsers();
    }
    const nextUri = tabIndex === 1 ? '/users-roles/roles' : '/users-roles/users';
    if (this.props.history.location !== nextUri) {
      this.props.history.push(nextUri);
    }
  };

  handleChangePasswordClick = () => this.setState({ changePasswordDialogOpen: true, actionsMenuOpen: false });

  handleCloseChangePasswordDialog = () => this.setState({ changePasswordDialogOpen: false });

  usersWrapper = () => {
    const { roles, users, intl, width } = this.props;
    const { editedRoles } = roles || {};
    const { searchUsers, expandedUsers, permissionsUsers, permissionsRoles } = this.state;
    const rolesDisabled = !permissionsRoles.r;
    const xs = width === 'xs';

    return (
      users && (
        <Users
          roles={editedRoles}
          rolesDisabled={rolesDisabled}
          expandedUsers={expandedUsers}
          permissionsUsers={permissionsUsers}
          messages={messages}
          searchUsers={searchUsers}
          intl={intl}
          xs={xs}
          handleExpandUsers={this.handleExpandUsers}
          handleOpenMenu={this.handleOpenMenu}
          handleChangeUserRoles={this.handleChangeUserRoles}
          handleChangeSearchUsers={this.handleChangeSearchUsers}
          handleApplyChanges={this.handleApplyUsersChanges}
          handleDiscardChanges={this.handleDiscardUsersChanges}
        />
      )
    );
  };

  rolesWrapper = () => {
    const { roles, intl, width } = this.props;
    const { searchRoles, permissionsRoles, expandedRoles } = this.state;
    const xs = width === 'xs';

    return isEmpty(roles) ? (
      <div />
    ) : (
      <Roles
        messages={messages}
        intl={intl}
        xs={xs}
        permissionsRoles={permissionsRoles}
        expandedRoles={expandedRoles}
        searchRoles={searchRoles}
        handleExpandRoles={this.handleExpandRoles}
        handleOpenMenu={this.handleOpenMenu}
        handleChangeAncestors={this.handleChangeAncestors}
        handleChangeSearchRoles={this.handleChangeSearchRoles}
        setPermission={this.setPermission}
        handleApplyChanges={this.handleApplyRolesChanges}
        handleDiscardChanges={this.handleDiscardRolesChanges}
      />
    );
  };

  render() {
    const { roles, users, classes, width, theme, location, addMessage } = this.props;
    const {
      createDialogOpen,
      changePasswordDialogOpen,
      anchorName,
      actionsMenuOpen,
      anchorEl,
      permissionsRoles,
      permissionsUsers,
    } = this.state;

    const tabIndex = location.pathname === '/users-roles/roles' ? 1 : 0;

    const mdUp = width !== 'xs' && width !== 'sm';
    const mobile = !mdUp || isMobile;
    const transitionDuration = {
      enter: theme.transitions.duration.enteringScreen,
      exit: theme.transitions.duration.leavingScreen,
    };
    const usersDisabled = !permissionsUsers.r;
    const rolesDisabled = !permissionsRoles.r;
    return (
      <div className={classNames(classes.root, mobile ? classes.mobileRoot : '')}>
        <AppBar className={classes.tabsBar} position='static' color='default'>
          <Tabs value={tabIndex} onChange={this.handleChangeTab} indicatorColor='primary' textColor='primary'>
            <Tab label={<FormattedMessage id='rolesPage.users' defaultMessage='Users' />} disabled={usersDisabled} />
            <Tab label={<FormattedMessage id='rolesPage.roles' defaultMessage='Roles' />} disabled={rolesDisabled} />
          </Tabs>
        </AppBar>

        <Alerts issuesPermission={parsePermissions(this.props.permissions, 'issues').r} />
        <PageLoadingIndicator open={tabIndex === 1 ? users === null : roles === null} />

        <Route path={['/users-roles', '/users-roles/users']} exact render={this.usersWrapper} />
        <Route path='/users-roles/roles' exact render={this.rolesWrapper} />

        {permissionsRoles.w && (
          <Zoom
            in={tabIndex === 1}
            timeout={transitionDuration}
            style={{
              transitionDelay: `${tabIndex === 1 ? transitionDuration.exit : 0}ms`,
            }}
            unmountOnExit
          >
            <CustomFab
              label={<FormattedMessage id='users.fabLabel.add' defaultMessage='Add' />}
              onClick={this.handleCreateDialogOpen}
            />
          </Zoom>
        )}
        {permissionsUsers.w && (
          <Zoom
            in={tabIndex === 0}
            timeout={transitionDuration}
            style={{
              transitionDelay: `${tabIndex === 0 ? transitionDuration.exit : 0}ms`,
            }}
            unmountOnExit
          >
            <CustomFab
              label={<FormattedMessage id='roles.fabLabel.add' defaultMessage='Add' />}
              onClick={this.handleCreateDialogOpen}
            />
          </Zoom>
        )}

        <ActionsMenu
          open={actionsMenuOpen}
          anchorEl={anchorEl}
          handleClose={this.handleCloseActionsMenu}
          items={[
            {
              show: anchorName !== 'admin',
              Icon: DeleteIcon,
              text: <FormattedMessage id='ActionsMenu.delete' defaultMessage='Delete' />,
              handler: this.openDeleteDialog,
            },
            {
              show: tabIndex === 0,
              Icon: ChangePassword,
              text: <FormattedMessage id='ActionsMenu.changePassword' defaultMessage='Change password' />,
              handler: this.handleChangePasswordClick,
            },
          ]}
        />
        <ResponsiveDialog
          open={this.state.deleteDialogOpen}
          title={
            tabIndex === 1 ? (
              <FormattedMessage id='UR.deleteDialog.title.roles' defaultMessage='Delete role' />
            ) : (
              <FormattedMessage id='UR.deleteDialog.title.users' defaultMessage='Delete user' />
            )
          }
          message={
            tabIndex === 1 ? (
              <FormattedMessage
                id='UR.deleteDialog.message.roles'
                defaultMessage='Are you sure you want to delete role {roleToBeDeleted}?'
                values={{ roleToBeDeleted: this.state.anchorName }}
              />
            ) : (
              <FormattedMessage
                id='UR.deleteDialog.message.users'
                defaultMessage='Are you sure you want to delete user {userToBeDeleted}?'
                values={{ userToBeDeleted: this.state.anchorName }}
              />
            )
          }
          confirmButtonText={<FormattedMessage id='UR.deleteDialog.button.delete' defaultMessage='Delete' />}
          onClose={this.closeDeleteDialog}
          onConfirm={this.handleDelete(tabIndex === 1 ? 'role' : 'user')}
          closeOnConfirm
          fullWidth
        />

        <CreateDialog
          open={createDialogOpen}
          handleClose={this.handleCreateDialogClose}
          handleCreate={this.handleCreate}
          rolesTab={tabIndex === 1}
          usersTab={tabIndex === 0}
          occupiedNames={
            tabIndex === 1
              ? roles && Object.keys(roles.lastLoadedRoles)
              : users && users.editedUsers.map(v => v.username)
          }
          type={tabIndex === 1 ? 'role' : 'user'}
        />
        <ChangePasswordDialog
          open={!!changePasswordDialogOpen}
          anchorName={anchorName}
          onClose={this.handleCloseChangePasswordDialog}
          addMessage={addMessage}
        />
      </div>
    );
  }
}

export default compose(
  connect(RolesPage.mapStateToProps),
  withWidth(),
  withStyles(styles, { withTheme: true }),
  injectIntl,
  withRouter
)(RolesPage);
