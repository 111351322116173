import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import ErrorIcon from '@material-ui/icons/Error';
import { isMobile } from 'react-device-detect';
import Dropzone from 'react-dropzone';
import { FormattedMessage } from 'react-intl';

import FileUpload from './customIcons/FileUpload';
import { getErrorMessage } from '../util/getErrorMessage';

class UploadField extends React.Component {
  state = { linkField: '', linkFieldVisible: false, redirectTarget: '' };
  fileToUpload = null;
  linkToUpload = null;

  getRedirectTarget = name => {
    let redirectTarget = '';

    switch (this.props.pageName) {
      case 'modules':
        if (name.startsWith('firmware')) {
          redirectTarget = 'firmware';
        }
        break;
      case 'firmware':
        if (name.endsWith('.nsp.xz')) {
          redirectTarget = 'modules';
        }
        break;
      default:
        break;
    }
    return redirectTarget;
  };

  handleSubmitDropzone = ([file]) => {
    const redirectTarget = this.getRedirectTarget(file.name);
    if (redirectTarget === '') {
      this.setState({ linkField: '', linkFieldVisible: false });
      this.props.resetError();
      this.props.submitDropzone(file);
    } else {
      this.fileToUpload = file;
      this.setState({ redirectTarget });
    }
  };

  handleSubmitLink = () => {
    const nameArr = this.state.linkField.split('/');
    const name = nameArr[nameArr.length - 1];
    const redirectTarget = this.getRedirectTarget(name);

    if (redirectTarget === '') {
      this.setState(
        prev => ({ linkField: prev.linkField.trim() }),
        () => this.props.submitLink(this.state.linkField)
      );
    } else {
      this.linkToUpload = this.state.linkField;
      this.setState({ redirectTarget });
    }
  };

  pasteTextFromClipboard = () => {
    let regExp;
    switch (this.props.pageName) {
      case 'modules':
        regExp = /https?:\/\/.*nsp\.xz$/;
        break;
      case 'firmware': {
        regExp = /https?:\/\/.*firmware.*/;
        break;
      }
      default:
        console.error('unknown page', this.props.pageName);
        break;
    }

    if (navigator.clipboard && navigator.clipboard.readText && regExp) {
      navigator.clipboard
        .readText()
        .then(text => {
          if (text.match(regExp)) {
            this.setState({ linkField: text });
          }
        })
        .catch(() => {});
    }
  };

  handleToggleButton = e => {
    e.stopPropagation();
    this.setState(prev => ({ linkFieldVisible: !prev.linkFieldVisible }), this.pasteTextFromClipboard);
    this.props.resetError();
  };

  handleDeleteButton = e => {
    e.stopPropagation();
    this.props.handleDelete();
  };

  handleCloseButton = () => this.setState({ linkFieldVisible: false });

  handleLinkChange = e => {
    this.setState({ linkField: e.target.value });
    this.props.resetError();
  };

  handleUploadButton = async () => {
    if (this.fileToUpload) {
      await this.props.submitDropzone(this.fileToUpload);
      this.fileToUpload = null;
    } else if (this.linkToUpload) {
      await this.props.submitLink(this.linkToUpload);
      this.linkToUpload = null;
    }
  };

  submitOnEnter = e => {
    if (e.key !== 'Enter') return;

    this.handleSubmitLink();
  };

  render() {
    const { linkField, linkFieldVisible, redirectTarget } = this.state;
    const { error, handleDelete, allowLinkUpload } = this.props;

    let mainContent;
    if (error) {
      mainContent = (
        <Fragment>
          <div className='text-error'>{getErrorMessage(error.errorMessage)}</div>
          <br />
          <ErrorIcon color='error' className='icon' />
        </Fragment>
      );
    } else if (redirectTarget) {
      mainContent = (
        <Fragment>
          <FormattedMessage
            id='uploader.redirectProposal'
            defaultMessage='Filename is incorrect. Redirect to {redirectTarget} page?'
            values={{ redirectTarget }}
          />
          <Grid container direction='row' justify='space-around' alignItems='center'>
            <Grid item>
              <Link to={`/${redirectTarget}`}>
                <Button color='primary' className='dropzone-button'>
                  <FormattedMessage id='uploader.button.redirect' defaultMessage='Redirect' />
                </Button>
              </Link>
            </Grid>
            <Grid item>
              <Button color='primary' className='dropzone-button' onClick={this.handleUploadButton}>
                <FormattedMessage id='uploader.button.upload' defaultMessage='Proceed to upload' />
              </Button>
            </Grid>
          </Grid>
        </Fragment>
      );
    } else {
      mainContent = (
        <Fragment>
          {isMobile ? (
            <FormattedMessage
              id='uploader.tooltipMobile'
              defaultMessage='Click this area to select a file for upload'
            />
          ) : (
            <FormattedMessage
              id='uploader.tooltip'
              defaultMessage='Drag and drop file here, or click this area to select a file for upload'
            />
          )}
          <br />
          <FileUpload color='action' className='icon' />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Dropzone
          onDrop={this.handleSubmitDropzone}
          className='dropzone'
          acceptClassName='stripes'
          disabled={!!redirectTarget}
        >
          <div className='dropzone-contents'>
            {mainContent}

            {!handleDelete && allowLinkUpload && !redirectTarget && (
              <Button onClick={this.handleToggleButton} className='dropzone-button'>
                <FormattedMessage id='uploader.useLink' defaultMessage='Use link' />
              </Button>
            )}

            {handleDelete && (
              <Button onClick={this.handleDeleteButton} className='dropzone-button'>
                <FormattedMessage id='fileuploader.delete' defaultMessage=' Delete' />
              </Button>
            )}
          </div>
        </Dropzone>

        {allowLinkUpload && !redirectTarget && (
          <Collapse in={linkFieldVisible} unmountOnExit>
            <div className='linkContainer'>
              <TextField
                label={<FormattedMessage id='uploaderLinkTextField.label' defaultMessage='Link' />}
                margin='normal'
                value={linkField}
                onChange={this.handleLinkChange}
                onKeyPress={this.submitOnEnter}
                fullWidth
                autoFocus
              />
              <IconButton onClick={this.handleSubmitLink}>
                <CheckIcon color='primary' />
              </IconButton>
              <IconButton onClick={this.handleCloseButton}>
                <CloseIcon />
              </IconButton>
            </div>
          </Collapse>
        )}
      </Fragment>
    );
  }
}

export default UploadField;
