import React, { Fragment } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

import { withStyles } from '@material-ui/core/styles';

import { infoActions, firmwareActions, modulesActions, servicesActions } from '../redux-stuff/actions';
import { drawVersionChange } from '../util/semverUtils';

const styles = theme => ({
  root: {
    '&:not(:last-child)': { marginBottom: '20px' },
    boxSizing: 'border-box',
    '& div': {
      boxSizing: 'inherit',
    },
    'font-family': `"Roboto", "Helvetica", "Arial", sans-serif`,
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 400,
    fontSize: '0.8125rem',
    paddingBottom: '4px',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    margin: '8px 0',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    lineHeight: '15px',

    display: 'grid',
    // gridTemplateColumns: '1fr 2fr 3fr 4fr 3fr 3fr',
    gridAutoFlow: 'dense',
    gap: '8px',
  },

  header: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
    fontWeight: '500',
    lineHeight: '1.3125rem',
  },

  subheader: {
    // color: 'rgba(0, 0, 0, 0.54)',
  },

  column12: { gridColumn: '1 / span 2' },
  column34: { gridColumn: '3 / span 2' },
  column56: { gridColumn: '5 / span 2' },

  column3: { gridColumn: '3 / span 1' },
  column4: { gridColumn: '4 / span 1' },
});

class SemverTable extends React.Component {
  render() {
    let { packages, nextPackages, nextBrokenPackages, classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={cx(classes.column12, classes.header)}>
          <FormattedMessage defaultMessage='component' id='semver.table.header.component' />
        </div>
        <div className={cx(classes.column34, classes.header)}>
          <FormattedMessage defaultMessage='constraints' id='semver.table.header.constraints' />
        </div>
        <div className={cx(classes.column56, classes.header)}>
          <FormattedMessage defaultMessage='constraint version' id='semver.table.header.constraintVersion' />
        </div>

        {nextBrokenPackages.map(([packageName, { deps, conflicts, invalidDeps, invalidConflicts }]) => {
          deps = deps && Object.entries(deps);
          conflicts = conflicts && Object.entries(conflicts);

          let depsCount = deps ? deps.length : 0;
          let conflictsCount = conflicts ? conflicts.length : 0;

          return (
            <Fragment key={packageName}>
              <div className={classes.column12} style={{ gridRow: `span ${depsCount + conflictsCount}` }}>
                {packageName}
                <br />
                {drawVersionChange(packages[packageName], nextPackages[packageName])}
              </div>

              {invalidDeps && (
                <div className={cx(classes.column3, classes.subheader)} style={{ gridRow: `span ${depsCount}` }}>
                  <FormattedMessage
                    defaultMessage='Dependencies parsing failed'
                    id='semver.table.subheader.depsParsingFailed'
                  />
                </div>
              )}
              {invalidConflicts && (
                <div className={cx(classes.column3, classes.subheader)} style={{ gridRow: `span ${depsCount}` }}>
                  <FormattedMessage
                    defaultMessage='Conflicts parsing failed'
                    id='semver.table.subheader.conflictsParsingFailed'
                  />
                </div>
              )}

              {deps && (
                <Fragment>
                  <div className={cx(classes.column3, classes.subheader)} style={{ gridRow: `span ${depsCount}` }}>
                    <FormattedMessage defaultMessage='dependencies' id='semver.table.subheader.deps' />
                  </div>
                  {deps.map(([depName, dep]) => {
                    return (
                      <div key={depName} className={classes.column4}>
                        {depName} {dep.requiredRange}
                      </div>
                    );
                  })}
                </Fragment>
              )}
              {conflicts && (
                <Fragment>
                  <div className={cx(classes.column3, classes.subheader)} style={{ gridRow: `span ${conflictsCount}` }}>
                    <FormattedMessage defaultMessage='conflicts' id='semver.table.subheader.conflicts' />
                  </div>
                  {conflicts.map(([conflictName, conflict]) => {
                    return (
                      <div key={conflictName} className={classes.column4}>
                        {conflictName}
                        {conflict.conflictRange}
                      </div>
                    );
                  })}
                </Fragment>
              )}

              {deps && (
                <Fragment>
                  {deps.map(([depName, dep]) => {
                    return (
                      <div key={depName} className={classes.column56}>
                        {drawVersionChange(packages[depName], nextPackages[depName])}
                      </div>
                    );
                  })}
                </Fragment>
              )}
              {conflicts && (
                <Fragment>
                  {conflicts.map(([conflictName, conflict]) => {
                    return (
                      <div key={conflictName} className={classes.column56}>
                        {drawVersionChange(packages[conflictName], nextPackages[conflictName])}
                      </div>
                    );
                  })}
                </Fragment>
              )}
            </Fragment>
          );
        })}
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(SemverTable.mapStateToProps, {
    ...infoActions,
    ...firmwareActions,
    ...modulesActions,
    ...servicesActions,
  })
)(SemverTable);
