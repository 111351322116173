import { fetcher } from '../../util/deps';
import {
  SET_NETWORK,
  SET_UNSAVED_NETWORK_CHANGES,
  CLEAR_UNSAVED_NETWORK_CHANGES,
  UPDATE_MISSING_ROUTES,
} from '../constants';

const loadNetwork = () => async (dispatch, getState) => {
  let data = await fetcher.get('network', undefined, false);
  const { network } = getState();
  if (data) {
    dispatch(setNetwork(data));
    if (!network) {
      // if initial load
      dispatch(updateMissingRoutes());
    }
  }
};

const setNetwork = network => {
  const type = SET_NETWORK,
    payload = network;

  return { type, payload };
};

const setUnsavedChanges = (ifaceName, unsavedChangesExist) => ({
  type: SET_UNSAVED_NETWORK_CHANGES,
  payload: [ifaceName, unsavedChangesExist],
});

const clearUnsavedChanges = () => ({ type: CLEAR_UNSAVED_NETWORK_CHANGES });

const updateMissingRoutes = () => ({ type: UPDATE_MISSING_ROUTES });

export default {
  setNetwork,
  loadNetwork,
  setUnsavedChanges,
  clearUnsavedChanges,
  updateMissingRoutes,
};
