import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const styles = theme => ({
  margins: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1.5),
    },
    '&:last-child': {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
      '&:first-child': {
        marginTop: theme.spacing(2),
      },
    },
  },
  paper: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.borderColor,
    padding: theme.spacing(2),
  },
  warning: {
    backgroundColor: theme.palette.alerts.warning,
  },
  error: {
    backgroundColor: theme.palette.alerts.danger,
  },
  info: {
    backgroundColor: theme.palette.alerts.info,
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
  },
  negMargin: {
    margin: '-12px',
  },
  messageContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  actionButton: {
    marginLeft: theme.spacing(1),
  },
});

class BootstrapLikeAlert extends React.Component {
  render() {
    const {
      classes,
      open = false,
      message,
      dangerLevel = 'info',
      pageTopMargins = false,
      link,
      action,
      actionHandler,
    } = this.props;

    return (
      <Collapse in={open} className={classes.root}>
        <Paper
          className={classNames(classes.paper, classes[dangerLevel], pageTopMargins ? classes.margins : '')}
          elevation={0}
        >
          <div className={classes.gridContainer}>
            <div className={classes.messageContainer}>
              <Typography component='p'>{message}</Typography>
              {action && (
                <Button variant='outlined' className={classes.actionButton} onClick={actionHandler}>
                  {action}
                </Button>
              )}
            </div>
            {link && (
              <Link to={link}>
                <IconButton className={classes.negMargin}>
                  <KeyboardArrowRight />
                </IconButton>
              </Link>
            )}
          </div>
        </Paper>
      </Collapse>
    );
  }
}

BootstrapLikeAlert.propTypes = {
  classes: PropTypes.object.isRequired,
  pageTopMargins: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  dangerLevel: PropTypes.oneOf(['info', 'warning', 'error']),
};

export default withStyles(styles)(BootstrapLikeAlert);
