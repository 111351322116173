import React from 'react';
import { FormattedMessage } from 'react-intl';

export const tooltips = {
  routeMissingInActualRules: (
    <FormattedMessage
      id='routingTable.tooltip.missingInActualRules'
      defaultMessage='This route is missing from actual routes'
    />
  ),
  routeMissingInRoutingConfiguration: (
    <FormattedMessage
      id='routingTable.tooltip.missingFromSystemRoutes'
      defaultMessage='This route is missing from routing configuration'
    />
  ),
  someRoutesMissingInActual: (
    <FormattedMessage
      id='routingTable.tooltip.someRoutesMissingInActualRules'
      defaultMessage='Some routes are missing from actual routes'
    />
  ),
  someRoutesMissingInConfig: (
    <FormattedMessage
      id='routingTable.tooltip.someRoutesMissingInRoutingConfiguration'
      defaultMessage='Some routes are missing from routing configuration'
    />
  ),
  multicastGatewaySpecified: (destination, gateway) => (
    <FormattedMessage
      id='routingTable.tooltip.multicastGateway'
      defaultMessage='All multicast packets sent to {destination} will be routed to the specified address ({gateway}). Please make sure this is what you intended'
      values={{ destination: destination === 'default multicast gateway' ? '224.0.0.0/4' : destination, gateway }}
    />
  ),
};

export const panelTitles = {
  routingConfiguration: (
    <FormattedMessage id='routingPage.routesPanel.routingConfiguration' defaultMessage='Routing configuration' />
  ),
  actualRoutingTable: (
    <FormattedMessage id='routingPage.routesPanel.actualRoutingTable' defaultMessage='Actual routing table' />
  ),
};
