import { FC } from 'react';
import ResponsiveDialog, { ResponsiveDialogProps } from '@/components/responsive-dialog';
import { FormattedMessage } from 'react-intl';

interface RemoveInterfaceConfigurationDialogProps {
  open: boolean;
  name: string;
  onClose: ResponsiveDialogProps['onClose'];
  onConfirm: ResponsiveDialogProps['onConfirm'];
  removingIface?: boolean;
}

const RemoveInterfaceConfigurationDialog: FC<RemoveInterfaceConfigurationDialogProps> = ({
  open,
  name,
  onClose,
  onConfirm,
  removingIface,
}) => {
  return (
    <ResponsiveDialog
      open={open}
      title={
        <FormattedMessage
          id='networkPage.removeInterfaceConfigurationTitle'
          defaultMessage='Remove interface configuration'
        />
      }
      message={
        <FormattedMessage
          id='networkPage.confirmRemoveInterfaceConfigurationMessage'
          defaultMessage='Are you sure you want to remove {name} interface configuration?'
          values={{ name }}
        />
      }
      confirmButtonText={<FormattedMessage id='networkPage.remove' defaultMessage='Remove' />}
      onClose={onClose}
      onConfirm={onConfirm}
      withSpinner
      pendingBackendEvent={removingIface}
    />
  );
};

export default RemoveInterfaceConfigurationDialog;
