import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';

const drawerWidth = 240;

const styles = theme => {
  const makeTransition = (props, duration) =>
    theme.transitions.create(props, {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration[duration],
    });
  return {
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      margin: 'auto',
      [theme.breakpoints.up('lg')]: {
        margin: theme.spacing(3),
        marginLeft: theme.spacing(12),
      },
    },
    contentTransition: {
      transition: makeTransition(['width', 'margin'], 'leavingScreen'),
    },
    contentShift: {
      margin: theme.spacing(3),
      marginLeft: drawerWidth + theme.spacing(3),
    },
    contentShiftTransition: {
      transition: makeTransition(['width', 'margin'], 'leavingScreen'),
    },
    main: {
      marginBottom: 96,
    },
  };
};

class Main extends React.Component {
  state = { routerHistory: [] };

  componentDidMount() {
    this.setState({ routerHistory: [this.props.location.pathname] });
  }

  static getDerivedStateFromProps(props, state) {
    const { location, pagesWithoutDrawer } = props;
    const { routerHistory, firstRenderAfterPageWithoutDrawer } = state;
    const prevPage = routerHistory[routerHistory.length - 1];
    if (location.pathname !== prevPage) {
      return {
        routerHistory: [...routerHistory, location.pathname],
        firstRenderAfterPageWithoutDrawer:
          pagesWithoutDrawer.includes(prevPage) && !pagesWithoutDrawer.includes(location.pathname),
      };
    } else if (firstRenderAfterPageWithoutDrawer) {
      return { firstRenderAfterPageWithoutDrawer: false };
    }
    return null;
  }

  static mapStateToProps({ drawer }) {
    return { drawer };
  }

  render() {
    const { classes, drawer, width, children, pagesWithoutDrawer, pageError, location } = this.props;
    const { firstRenderAfterPageWithoutDrawer } = this.state;
    const actualUri = location.pathname;
    const lgUp = width === 'lg' || width === 'xl';
    const shifted = drawer.expanded && lgUp;
    const contentClasses = classNames(
      classes.content,
      firstRenderAfterPageWithoutDrawer ? '' : classes.contentTransition
    );
    const shiftClasses = classNames(
      classes.contentShift,
      firstRenderAfterPageWithoutDrawer ? '' : classes.contentShiftTransition
    );
    const mainClasses =
      !pagesWithoutDrawer.includes(actualUri) || pageError ? classNames(contentClasses, shifted && shiftClasses) : '';
    return (
      <main className={classNames(mainClasses, classes.main)}>
        <div className={classes.toolbar} />
        {pageError ? (
          <Typography color='error' variant='body1' align='center'>
            <FormattedMessage id='errorMessage' defaultMessage='An error occurred..' />
          </Typography>
        ) : (
          children
        )}
      </main>
    );
  }
}

Main.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
};

export default compose(withRouter, connect(Main.mapStateToProps), withStyles(styles), withWidth())(Main);
