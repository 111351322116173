const decorate = name => reducer => (data, action) => {
  if (data === undefined) {
    return {
      [name]: reducer(undefined, action),
      _lastUpdate: -1,
      _bootId: null,
    };
  }

  if (
    action.fromWebSocket &&
    action.payload &&
    data._lastUpdate > action.payload._counter &&
    data._bootId !== action.payload._bootId
  ) {
    return data;
  }

  if (action.payload) {
    delete action.payload._counter;
    delete action.payload._bootId;
  }

  let next = reducer(data[name], action);
  let { _lastUpdate, _bootId } = data;

  if (!action.fromWebSocket && action.payload) {
    _lastUpdate = action.payload._lastUpdate || _lastUpdate;
    _bootId = action.payload._bootId || _bootId;
  }

  return {
    [name]: next,
    _lastUpdate,
    _bootId,
  };
};

export default decorate;
