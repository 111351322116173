import { FC, useEffect } from 'react';
import VtunSocketStatusLabel from './VtunSocketStatusLabel';
import { Button } from '@material-ui/core';
import { useSocketStatus, vtunSocketsConnect, vtunSocketsDisconnect } from '@/VtunProxy';
import { FormattedMessage, defineMessages } from 'react-intl';
import { useAppSelector } from '@/util/store';

const labels = defineMessages({
  netup: { id: 'netupSocketStatus', defaultMessage: 'NetUP socket status:' },
  firmware: { id: 'firmwareSocketStatus', defaultMessage: 'Firmware socket status:' },
});

interface WsProxyControlProps {
  loadNetwork?(): void;
  tunnelState?: string;
}

const WsProxyControl: FC<WsProxyControlProps> = ({ loadNetwork, tunnelState }) => {
  const firmwareSocketStatus = useSocketStatus('firmware');
  const netupSocketStatus = useSocketStatus('netup');
  const { host, enabled } = useAppSelector(({ vtun }) => vtun.lastLoadedState.netup) || {};

  useEffect(() => {
    if (
      !loadNetwork ||
      (tunnelState !== 'UP' && (firmwareSocketStatus === 'disconnected' || netupSocketStatus === 'disconnected'))
    ) {
      return;
    }
    const intervalId = setInterval(loadNetwork, 1000);
    return () => clearInterval(intervalId);
  }, [firmwareSocketStatus, netupSocketStatus, loadNetwork, tunnelState]);

  // disconnect socket on umount
  useEffect(() => () => vtunSocketsDisconnect(), []);

  const show = enabled && host === '127.0.0.1';

  useEffect(() => {
    if (!show) {
      vtunSocketsDisconnect();
    }
  }, [show]);

  const handleStatusChange = () => {
    if (netupSocketStatus === 'disconnected') {
      vtunSocketsConnect();
    } else {
      vtunSocketsDisconnect();
    }
  };

  if (!show) return null;

  return (
    <>
      <VtunSocketStatusLabel label={labels.firmware} status={firmwareSocketStatus} />
      <VtunSocketStatusLabel label={labels.netup} status={netupSocketStatus} />
      <Button variant='contained' color='primary' onClick={handleStatusChange}>
        {netupSocketStatus === 'disconnected' && firmwareSocketStatus === 'disconnected' ? (
          <FormattedMessage id='connect' defaultMessage='Connect' />
        ) : (
          <FormattedMessage id='disconnect' defaultMessage='Disconnect' />
        )}
      </Button>
    </>
  );
};

export default WsProxyControl;
