import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, Switch, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

interface ProgressSwitchProps {
  label: ReactNode;
  value: boolean;
  setValue(newValue: boolean): void;
  cooldown?: number;
}

const ProgressSwitch: FC<ProgressSwitchProps> = ({ label, value, setValue, cooldown }) => {
  const [showSwitchProgress, setShowSwitchProgress] = useState(false);
  const cooldownTimerId = useRef<ReturnType<typeof setTimeout>>();
  const [cooldownActive, setCoolCodownActive] = useState(false);

  useEffect(() => () => void (cooldownTimerId.current && clearTimeout(cooldownTimerId.current)), []);

  useEffect(() => {
    setShowSwitchProgress(false);
  }, [value]);

  const toggleValue = () => {
    setShowSwitchProgress(true);

    if (cooldown) {
      setCoolCodownActive(true);
      if (cooldownTimerId.current) clearTimeout(cooldownTimerId.current);
      cooldownTimerId.current = setTimeout(() => setCoolCodownActive(false), cooldown);
    }

    setValue(!value);
  };

  return (
    <Box display='flex' justifyContent='space-between' alignItems='center'>
      <Typography>{label}</Typography>
      <Box position='relative' width='62'>
        <Switch checked={value} onChange={toggleValue} disabled={showSwitchProgress || cooldownActive} />
        {showSwitchProgress && (
          <CircularProgress
            size={24}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              color: green['500'],
              marginTop: -12,
              marginLeft: -12,
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default ProgressSwitch;
