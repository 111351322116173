import React from 'react';
import generateUUID from '../../util/generateUUID';
import MySnackbar from './MySnackbar';
import deepEqual from 'fast-deep-equal';
import { errorsActions } from '../../redux-stuff/actions';
import { connect } from 'react-redux';

class ImmutableQueue {
  constructor(data = []) {
    this._queue = Object.freeze([...data]);
  }

  push(entry) {
    return new ImmutableQueue([...this._queue, entry]);
  }

  pushOnTop(entry) {
    return new ImmutableQueue([entry, ...this._queue]);
  }

  get top() {
    return this._queue[0];
  }

  get queue() {
    return this._queue;
  }

  pop() {
    return new ImmutableQueue(this._queue.slice(1));
  }

  get length() {
    return this._queue.length;
  }

  [Symbol.iterator]() {
    return this._queue[Symbol.iterator]();
  }
}

class SnackbarContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: new ImmutableQueue(),
    };
  }

  handleLeaking = () => {
    if (this.state.messages.top && !this.state.messages.top.hasAppeared && !this.state.messages.top.open) {
      this.handleExited();
    }
  };

  componentDidMount() {
    this.props.events.on('addMessage', this.addMessage);
  }

  componentWillUnmount() {
    this.props.events.removeListener('addMessage', this.addMessage);
  }

  addMessage = message => {
    message.id = generateUUID();
    message.open = true;
    const isRepeatingMessage =
      !message.isUndo &&
      this.state.messages.queue.length > 0 &&
      this.state.messages.queue.some(
        ({ data }) =>
          deepEqual(data, message.data) ||
          (data &&
            data.errorMessage &&
            data.errorMessage === message.data.errorMessage &&
            data.contentType === message.data.contentType &&
            data.details === message.data.details)
      );

    if (isRepeatingMessage) {
      return;
    }
    if (message.notRequired) {
      if (this.state.messages.length === 0) {
        this.setState(({ messages }) => ({
          messages: messages.push(message),
        }));

        setTimeout(() => this.deleteById(message.id), 2000);
      }
    } else if (!(message.data instanceof Error) || !this.props.isRebooting) {
      let { messages } = this.state;
      if (message.highPriority) {
        messages = messages.pop().pushOnTop(message);
      } else {
        messages = messages.push(message);
      }
      this.setState({ messages });
    }

    if (message.data instanceof Error && !this.props.isRebooting) {
      this.props.addError(message.data);
    }
  };

  removeMessage = () => {
    this.setState(({ messages }) => {
      const [first, ...rest] = [...messages];
      return { messages: new ImmutableQueue([{ ...first, open: false }, ...rest]) };
    }, this.handleLeaking);
  };

  handleExited = () => {
    this.setState(({ messages }) => ({
      messages: messages.pop(),
    }));
  };

  markEntering = () => {
    this.setState(({ messages }) => {
      const [top, ...rest] = [...messages];
      if (top) {
        top.hasAppeared = true;
      } else {
        return { messages: new ImmutableQueue() };
      }
      return { messages: new ImmutableQueue([top, ...rest]) };
    });
  };

  deleteById = id => {
    const stateDeleteById = ({ messages }) => {
      let [top, ...rest] = [...messages];
      if (top === undefined) return { messages: new ImmutableQueue() };
      if (top.id === id) top.open = false;
      rest = rest.filter(message => message.id !== id);

      return { messages: new ImmutableQueue([top, ...rest]) };
    };

    this.setState(stateDeleteById, this.handleLeaking);
  };

  static mapStateToProps({ isRebooting }) {
    return { isRebooting };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isRebooting && !this.props.isRebooting) {
      const { top = {} } = this.state.messages;
      if (top.rebootingMessage) {
        this.removeMessage();
      }
    }
  }

  render() {
    const { top = {} } = this.state.messages;
    const { isReload, rebootingMessage, id } = top;
    const forbidCloseOnClick = rebootingMessage || isReload;
    return (
      <MySnackbar
        {...top}
        key={id}
        onEntering={this.markEntering}
        onExited={this.handleExited}
        handleClose={this.removeMessage}
        undo={this.props.recoverError}
        openDetails={this.props.openDetails}
        forbidCloseOnClick={forbidCloseOnClick}
      />
    );
  }
}

export default connect(SnackbarContainer.mapStateToProps, { ...errorsActions })(SnackbarContainer);
