import { combineReducers } from 'redux';
import info from './info';
import issues from './issues';
import smart from './smart';
import load from './load';
import firmwareFiles from './firmware';
import firmwareUploads from './firmwareUploads';
import backups from './backup';
import network from './network';
import connections from './connections';
import drawer from './drawer';
import appBar from './appBar';
import modules from './modules';
import modulesUploads from './modulesUploads';
import services from './services';
import roles from './roles';
import users from './users';
import license from './license';
import widgets from './widgets';
import isAuth from './auth';
import isSocketConnected from './socket';
import errors from './errors';
import vtun from './vtun';
import diskUsage from './diskUsage';
import dns from './dns';
import isRebooting from './reboot';
import decorate from '../../util/reducer-decorator';
import { USER_LOGOUT } from '../constants';
import dhcpServerSlice from '../features/dhcp-server';
import dnsServerSlice from '../features/dns-server';

const appReducer = combineReducers({
  info,
  issues,
  load,
  smart,
  firmwareFiles: decorate('data')(firmwareFiles),
  firmwareUploads,
  backups: decorate('data')(backups),
  network,
  connections: decorate('data')(connections),
  drawer,
  appBar,
  modules,
  modulesUploads,
  services,
  roles,
  users,
  license,
  widgets,
  isAuth,
  isSocketConnected,
  errors,
  vtun,
  diskUsage,
  dns,
  isRebooting,
  [dhcpServerSlice.name]: dhcpServerSlice.reducer,
  [dnsServerSlice.name]: dnsServerSlice.reducer,
});

const rootReducer = (state, action) => {
  // In case of logout we should reset store
  if (action.type === USER_LOGOUT) {
    state = { isAuth: false };
  }
  return appReducer(state, action);
};

export default rootReducer;
