import { FC } from 'react';
import { VtunSocketStatus } from '@/VtunProxy';
import { Box, Typography, useTheme } from '@material-ui/core';
import { MessageDescriptor, defineMessages, useIntl } from 'react-intl';

interface VtunSocketStatusLabelProps {
  label: MessageDescriptor;
  status: VtunSocketStatus;
}

const statuses: Record<VtunSocketStatus, MessageDescriptor> = defineMessages({
  connected: { id: 'socket.connected', defaultMessage: 'socket.connected' },
  disconnected: { id: 'socket.disconnected', defaultMessage: 'socket.disconnected' },
  connecting: { id: 'connecting', defaultMessage: 'connecting' },
  reconnecting: { id: 'reconnecting', defaultMessage: 'reconnecting' },
});

const VtunSocketStatusLabel: FC<VtunSocketStatusLabelProps> = ({ label, status }) => {
  const intl = useIntl();
  const { palette } = useTheme();

  const colors: Partial<Record<VtunSocketStatus, string>> = {
    connected: palette.icons.success,
    disconnected: palette.icons.danger,
    connecting: palette.icons.info,
    reconnecting: palette.icons.info,
  };

  return (
    <Box display='flex' style={{ gap: '1ch' }} my={1}>
      <Typography>{intl.formatMessage(label)}</Typography>
      <Typography style={{ color: colors[status] }}>{intl.formatMessage(statuses[status])}</Typography>
    </Box>
  );
};

export default VtunSocketStatusLabel;
