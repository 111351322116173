import React, { Fragment } from 'react';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { connect } from 'react-redux';

class Alias extends React.Component {
  state = {
    aliasField: '',
    isEditing: false,
  };

  handleAliasFieldChange = e => this.setState({ aliasField: e.target.value });

  getAlias() {
    return (this.props.saveAliasToLocalStorage ? localStorage.getItem('alias') : this.props.alias) || '';
  }

  handleEditStart = () => {
    const aliasField = this.getAlias();
    this.setState({ isEditing: true, aliasField });
  };

  handleEditEnd = () => {
    this.setState({ isEditing: false });

    const newAlias = this.state.aliasField.trim();

    if (this.props.saveAliasToLocalStorage) {
      localStorage.setItem('alias', newAlias);
    } else {
      this.props.updateAlias(newAlias);
    }
  };

  handleKeyDown = e => {
    if (e.key === 'Enter') {
      this.handleEditEnd();
    } else if (e.key === 'Escape') {
      const aliasField = this.props.alias === null ? '' : this.props.alias;
      this.setState({ isEditing: false, aliasField });
    }
  };

  render() {
    const { classes, mobile } = this.props;
    const alias = this.getAlias();

    return this.state.isEditing ? (
      <Input
        value={this.state.aliasField}
        onChange={this.handleAliasFieldChange}
        onBlur={this.handleEditEnd}
        className={classNames(
          classes.aliasEditField,
          classes.leftMarginText,
          isMobile ? classes.aliasEditFieldMobile : ''
        )}
        autoFocus
        onKeyDown={this.handleKeyDown}
      />
    ) : (
      <Fragment>
        {alias ? (
          <Typography
            variant='h6'
            classes={{ root: classes.leftMarginText }}
            color='inherit'
            noWrap
            onClick={mobile ? this.handleEditStart : null}
          >
            {alias}
          </Typography>
        ) : (
          mobile && (
            <div
              className={classNames(classes.leftMarginText, classes.emptyAliasEditTrigger)}
              onClick={this.handleEditStart}
            />
          )
        )}
        {!mobile && (
          <IconButton
            color='inherit'
            onClick={this.handleEditStart}
            className={classNames(classes.hover, classes.leftMarginButton)}
          >
            <EditIcon className={classes.hideIcon} />
          </IconButton>
        )}
      </Fragment>
    );
  }

  static mapStateToProps({ widgets: { saveAliasToLocalStorage } }) {
    return { saveAliasToLocalStorage };
  }
}

export default connect(Alias.mapStateToProps)(Alias);
