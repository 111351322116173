import React, { Fragment } from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Collapse from '@material-ui/core/Collapse';
import classnames from 'classnames';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Checkbox from '@material-ui/core/Checkbox';
import InfoIcon from '@material-ui/icons/Info';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
  moduleContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto auto',
    gridAutoFlow: 'column',
    alignItems: 'center',
    gridGap: '10px',
    borderBottom: `1px solid ${theme.palette.borderColor}`,
    // alling status icon with content
    marginLeft: -theme.spacing(1.5),
  },
  greyout: {
    opacity: '0.6',
  },
  breakWord: {
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
  },
  statusIcon: {
    padding: theme.spacing(1.5),
    color: theme.palette.icons.default,
  },
  expand: {
    transform: 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(0deg)',
  },
  moduleDetailsTable: {
    tableLayout: 'fixed',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    '& td:first-child': {
      width: '100px',
    },
  },

  firmwareDetailsTable: {
    tableLayout: 'fixed',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    '& td:first-child': {
      width: '100px',
    },
    '&:not(:last-child)': { marginBottom: theme.spacing(2) },
  },

  componentsHeader: {
    marginTop: theme.spacing(2),
  },
});

const moduleComponentFields = {
  name: <FormattedMessage id='moduleComponents.component.name' defaultMessage='Name' />,
  revision: <FormattedMessage id='moduleComponents.component.revision' defaultMessage='Revision' />,
  version: <FormattedMessage id='moduleComponents.component.version' defaultMessage='Version' />,
  variant: <FormattedMessage id='moduleComponents.component.variant' defaultMessage='Variant' />,
};

class BatchInstallerImage extends React.Component {
  setChecked = e => {
    this.props.toggleModuleSelection(this.props.image.id, e.target.checked);
  };

  toggleDetails = () => {
    this.props.toggleModuleDetails(this.props.image.id);
  };

  render() {
    const moduleInfoFieldNames = {
      name: <FormattedMessage id='firmwareInfoTable.moduleInfo.name.' defaultMessage='module.name' />,
      version: <FormattedMessage id='firmwareInfoTable.moduleInfo.version.' defaultMessage='module.version' />,
      build: <FormattedMessage id='firmwareInfoTable.moduleInfo.build' defaultMessage='module.build' />,
      revision: <FormattedMessage id='firmwareInfoTable.moduleInfo.revision' defaultMessage='module.revision' />,
      build_datetime: (
        <FormattedMessage id='firmwareInfoTable.moduleInfo.build_datetime' defaultMessage='module.build_datetime' />
      ),
    };

    const { classes, image } = this.props;

    const tagline = image.tags.join(' -- ');
    const isGreyedOut = image.duplicate || image.disabled || image.installingPhase === 'DONE';

    let symbol;
    if (image.disabled) {
      symbol = (
        <Tooltip
          title={
            <FormattedMessage
              id='batchInstaller.tooltip.disabled'
              defaultMessage='This module cannot be installed from this dialog'
            />
          }
        >
          <InfoIcon className={classes.statusIcon} />
        </Tooltip>
      );
    } else if (image.installingPhase === 'INSTALLING') {
      symbol = <CircularProgress size={24} className={classes.statusIcon} />;
    } else if (image.installingPhase === 'DONE') {
      symbol = <CheckIcon className={classes.statusIcon} />;
    } else if (image.installingPhase === 'ERROR') {
      symbol = <WarningIcon className={classes.statusIcon} />;
    } else {
      symbol = (
        <Checkbox
          className={classes.statusIcon}
          checked={!!image.selected}
          disabled={this.props.lockSelection}
          onChange={this.setChecked}
          color='primary'
        />
      );
    }

    let symbol2;
    if (image.duplicate) {
      symbol2 = (
        <Tooltip
          title={<FormattedMessage id='batchInstaller.tooltip.duplicate' defaultMessage='This module already exists' />}
        >
          <InfoIcon className={classes.statusIcon} />
        </Tooltip>
      );
    }

    return (
      <Fragment>
        <div className={classnames(classes.moduleContainer, isGreyedOut && classes.greyout)}>
          {symbol}

          <Typography className={classes.breakWord}>{tagline}</Typography>

          {symbol2}
          <IconButton
            className={classnames(
              classes.expand,
              {
                [classes.expandOpen]: image.detailsVisible,
              },
              classes.iconButton
            )}
            onClick={this.toggleDetails}
            aria-expanded={image.detailsVisible}
            // disabled={status}
          >
            <ExpandLessIcon />
          </IconButton>
        </div>

        <Collapse in={image.detailsVisible} className={classnames(isGreyedOut && classes.greyout)}>
          <Table padding='none' className={classes.moduleDetailsTable}>
            <TableBody>
              <TableRow hover>
                <TableCell>{moduleInfoFieldNames.name}</TableCell>
                <TableCell>{image.name}</TableCell>
              </TableRow>

              <TableRow hover>
                <TableCell>{moduleInfoFieldNames.version}</TableCell>
                <TableCell>{image.version}</TableCell>
              </TableRow>

              <TableRow hover>
                <TableCell>{moduleInfoFieldNames.build}</TableCell>
                <TableCell>{image.build}</TableCell>
              </TableRow>

              <TableRow hover>
                <TableCell>{moduleInfoFieldNames.revision}</TableCell>
                <TableCell>{image.revision}</TableCell>
              </TableRow>

              <TableRow hover>
                <TableCell>{moduleInfoFieldNames.build_datetime}</TableCell>
                <TableCell>
                  <FormattedDate
                    value={new Date(image.buildDate)}
                    day='numeric'
                    month='short'
                    year='numeric'
                    hour='numeric'
                    minute='numeric'
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          {image && image.components && (
            <Fragment>
              <Typography className={classes.componentsHeader}>
                <FormattedMessage id='moduleComponents.header' defaultMessage='Components' />
              </Typography>

              {Object.entries(image.components).map(([componentName, componentFields]) => {
                return (
                  <Table key={componentName} padding='none' className={classes.firmwareDetailsTable}>
                    <TableBody>
                      <TableRow hover>
                        <TableCell>{moduleComponentFields.name}</TableCell>
                        <TableCell>{componentName}</TableCell>
                      </TableRow>

                      {Object.entries(componentFields).map(([fieldName, fieldValue]) => {
                        if (!['revision', 'version', 'variant'].includes(fieldName)) return null;
                        if (fieldName === 'revision') {
                          fieldValue = fieldValue.slice(0, 14);
                        }

                        return (
                          <TableRow key={fieldName} hover>
                            <TableCell>{moduleComponentFields[fieldName]}</TableCell>
                            <TableCell>{fieldValue}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                );
              })}
            </Fragment>
          )}
        </Collapse>
      </Fragment>
    );
  }
}

export default withStyles(styles)(BatchInstallerImage);
