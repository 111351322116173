import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Switch from '@material-ui/core/Switch';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '@/util/store';
import {
  DhcpServerConfig,
  setDhcpBoolOption,
  setDhcpConfig,
  setDhcpIfaceAddresses,
  setDhcpRangeOption,
} from '@/redux-stuff/features/dhcp-server';
import { useIntl } from 'react-intl';
import { FC, useEffect } from 'react';

interface DhcpContainerProps {
  classes: {
    leftContainer?: string;
    formControl?: string;
    dhcpFormControl?: string;
  };
  name: string;
  w: boolean;
  config: DhcpServerConfig;
  addresses: string[];
}

const DhcpContainer: FC<DhcpContainerProps> = ({ classes, name, config, addresses, w }) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setDhcpConfig({ name, config, addresses }));
  }, [name, config]); // don't reset config if addresses changed
  useEffect(() => {
    dispatch(setDhcpIfaceAddresses({ name, addresses }));
  }, [name, addresses]);
  const formOptions = useAppSelector(({ dhcpServer }) => dhcpServer[name]);
  const intl = useIntl();

  if (!formOptions) {
    return null;
  }

  const {
    options: { enabled, from, to },
    errors,
  } = formOptions;

  return (
    <>
      <div className={classes.leftContainer}>
        <FormControl className={classes.formControl} disabled={!w}>
          <FormControlLabel
            control={
              <Switch
                color='primary'
                id={`enable-dhcp-${name}`}
                checked={enabled}
                onChange={({ target: { checked: value } }) =>
                  dispatch(setDhcpBoolOption({ name, option: 'enabled', value }))
                }
              />
            }
            label={<FormattedMessage id='networkPage.DhcpServer' defaultMessage='DHCP-server' />}
            className={classes.dhcpFormControl}
          />
        </FormControl>
      </div>
      <div>
        <FormControl className={classes.dhcpFormControl} disabled={!enabled || !w}>
          <InputLabel htmlFor={`dhcp-from-${name}`} error={!!errors.from}>
            <FormattedMessage id='networkPage.addressRangeFrom' defaultMessage='Address range from' />
          </InputLabel>
          <Input
            onChange={({ target: { value } }) => dispatch(setDhcpRangeOption({ name, option: 'from', value }))}
            id={`dhcp-from-${name}`}
            value={from}
            error={!!errors.from}
          />
          <FormHelperText id={`dhcp-from-error-${name}`} error={!!errors.from}>
            {errors.from && intl.formatMessage(errors.from)}
          </FormHelperText>
        </FormControl>
        <FormControl className={classes.dhcpFormControl} disabled={!enabled || !w}>
          <InputLabel htmlFor={`dhcp-to-${name}`} error={!!errors.to}>
            <FormattedMessage id='networkPage.addressRangeTo' defaultMessage='to' />
          </InputLabel>
          <Input
            onChange={({ target: { value } }) => dispatch(setDhcpRangeOption({ name, option: 'to', value }))}
            id={`dhcp-to-${name}`}
            value={to}
            error={!!errors.to}
          />
          <FormHelperText id={`dhcp-to-error-${name}`} error={!!errors.to}>
            {errors.to && intl.formatMessage(errors.to)}
          </FormHelperText>
        </FormControl>
      </div>
    </>
  );
};

export default DhcpContainer;
