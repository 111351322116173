import { FC } from 'react';
import { Fade, Typography, useTheme } from '@material-ui/core';
import { MessageDescriptor, defineMessages, useIntl } from 'react-intl';

interface StateSummaryProps {
  justCreated?: boolean;
  expanded?: boolean;
  state: string;
}

const states: Record<NonNullable<StateSummaryProps['state']>, MessageDescriptor> = defineMessages({
  UP: { id: 'networkPage.up', defaultMessage: 'UP' },
  DOWN: { id: 'networkPage.down', defaultMessage: 'DOWN' },
  DISABLED: { id: 'vtunPanel.panelSummary.state.disabled', defaultMessage: 'DISABLED' },
});

const StateSummary: FC<StateSummaryProps> = ({ justCreated, expanded, state }) => {
  const { palette } = useTheme();
  const intl = useIntl();

  const colors: Record<NonNullable<typeof state>, string> = {
    UP: palette.icons.success,
    DOWN: palette.icons.danger,
    DISABLED: palette.text.disabled,
  };

  if (justCreated || !state) return null;

  return (
    <Fade in={!expanded}>
      <Typography style={{ color: colors[state] || colors['DOWN'] }}>
        {intl.formatMessage(states[state] || states['DOWN'])}
      </Typography>
    </Fade>
  );
};

export default StateSummary;
