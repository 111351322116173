import { fetcher } from '../../util/deps';
import {
  SET_DNS,
  SET_NEW_IP,
  REMOVE_IP,
  ADD_NEW_IP,
  SET_PENDING_BACKEND,
  RESET_DNS_CHANGES,
  SELECT_IP,
  DESELECT_IP,
} from '../constants';

const loadDns = () => async dispatch => {
  let data = await fetcher.get('dns', undefined, false);
  if (data) {
    dispatch(setDns(data));
  }
};

const saveChanges = () => async (dispatch, getState) => {
  const { localState } = getState().dns;
  dispatch({ type: SET_PENDING_BACKEND, payload: true });
  const res = await fetcher.put('dns', localState);
  dispatch({ type: SET_PENDING_BACKEND, payload: false });
  if (res) {
    dispatch(setDns(res));
  }
};

const resetChanges = () => ({ type: RESET_DNS_CHANGES });

const setDns = dns => ({ type: SET_DNS, payload: dns });

const editNewIp = event => ({ type: SET_NEW_IP, payload: event.target.value.replace(',', '.') });

const addNewIp = () => ({ type: ADD_NEW_IP });

const removeIp = ip => ({ type: REMOVE_IP, payload: ip });

const selectIp = ip => ({ type: SELECT_IP, payload: ip });

const deselectIp = () => ({ type: DESELECT_IP });

export default { loadDns, saveChanges, editNewIp, addNewIp, removeIp, resetChanges, selectIp, deselectIp };
