import {
  TOGGLE_WIDGET_STATE,
  SET_WIDGET_STATE,
  SET_SETTINGS_DIALOG_OPEN,
  SET_SAVE_ALIAS_TO_LOCALSTORAGE,
} from '../constants';

export default (widgets = {}, action) => {
  switch (action.type) {
    case TOGGLE_WIDGET_STATE: {
      const name = action.payload;
      localStorage.setItem(`${name}WidgetIsActive`, !widgets[name]);
      return { ...widgets, [name]: !widgets[name] };
    }
    case SET_WIDGET_STATE: {
      const [name, state] = action.payload;
      localStorage.setItem(`${name}WidgetIsActive`, state);
      return { ...widgets, [name]: state };
    }
    case SET_SETTINGS_DIALOG_OPEN: {
      const state = action.payload;
      return { ...widgets, settingsDialogOpen: state };
    }
    case SET_SAVE_ALIAS_TO_LOCALSTORAGE: {
      const saveAliasToLocalStorage = action.payload;

      const aliasSet = localStorage.getItem(`alias`) !== null;
      if (saveAliasToLocalStorage) {
        aliasSet || localStorage.setItem('alias', '');
      } else {
        aliasSet && localStorage.removeItem('alias');
      }

      return { ...widgets, saveAliasToLocalStorage };
    }
    default:
      return widgets;
  }
};
