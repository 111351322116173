import React from 'react';

const styleContent = `
  .fil0 {
    fill:#FEFEFE;
    fill-opacity:0.560784
  }
`;

const pathAttr =
  'M2 44l6 0c1,0 2,-1 2,-2l0 -16c0,-1 -1,-2 -2,-2l-6 0c-1,0 -2,' +
  '1 -2,2l0 16c0,1 1,2 2,2zm50 0l10 0 29 0 10 0c1,0 1,-1 2,-2l-' +
  '9 0 -36 0 -8 0c0,1 1,2 2,2zm43 -6l0 -26 -37 0 0 26 37 0zm-37' +
  ' -28l0 0 0 0 37 0 0 0 0 0c1,0 1,1 1,2l0 28 -1 0 -37 0 -2 0 0' +
  ' -28c0,-1 1,-2 2,-2zm-18 34l10 0c-1,-1 -1,-3 -1,-4l0 0 1 0 5' +
  ' 0 0 -2 -10 0 -1 4 0 0 0 0 -3 0 -1 2zm-11 -7l26 0 0 -4 -26 0' +
  ' 0 4zm-6 -35l53 0 0 7 2 0 0 -8c0,0 -1,-1 -1,-1l-54 0c-1,0 -2' +
  ',1 -2,1l0 13 2 0 0 -12zm32 28l-26 0 0 2 26 0 0 -2zm-45 14l15' +
  ' 0c1,0 2,-1 2,-2l0 -24c0,-1 -1,-2 -2,-2l-16 0c-2,0 -3,1 -3,2' +
  'l0 5 2 0 0 -4 17 0 0 22 -13 0 0 1c0,1 -1,2 -2,2zm6 -2l1 0c0,' +
  '0 0,0 0,0l0 0c0,0 0,0 0,0l0 1c0,0 0,0 0,0l0 0c0,0 0,0 0,0l-1' +
  ' 0c0,0 0,0 0,0l0 0c0,0 0,0 0,0l0 -1c0,0 0,0 0,0 0,0 0,0 0,0z' +
  'm1 -24c0,0 0,0 0,-1 0,0 0,0 0,0 0,0 -1,0 -1,0 0,1 1,1 1,1zm-' +
  '12 24l0 0c0,0 0,0 0,0l0 0c1,0 1,0 1,0l0 1c0,0 0,0 -1,0l0 0c0' +
  ',0 0,0 0,0l0 0c-1,0 -1,0 -1,0l0 0c0,0 0,0 0,0l0 -1c0,0 0,0 0' +
  ',0 0,0 0,0 1,0zm-3 -17c0,0 0,1 0,1 0,0 0,0 0,0 0,0 -1,0 -1,0' +
  ' 0,0 1,-1 1,-1zm7 2l0 14 -8 0 0 -14 8 0z';

export const StreamProcessorSvg = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width={92} height={48} version='1.1' viewBox='0 0 85 44' {...props}>
    <defs>
      <style type='text/css'>{styleContent}</style>
    </defs>
    <g id='layer_x0020_1'>
      <metadata id='CorelCorpID_0Corel-Layer' />
      <path className='fil0' d={pathAttr} />
    </g>
  </svg>
);
