import { FC, ReactNode } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import ErrorIcon from '@material-ui/icons/Error';
import classNames from 'classnames';

import AddressSummary from './AddressSummary';
import StateSummary from './StateSummary';
import LinkSummary from './LinkSummary';
import InterfaceActionsMenu from './InterfaceActionsMenu';
import StateCaption from './StateCaption';
import InterfaceErrorTooltipMessage from './InterfaceErrorTooltipMessage';

const useStyles = makeStyles((theme: Theme) => ({
  containerSummary: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  moreActionsContainer: {
    width: '48px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  badge: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    top: '50%',
    right: -theme.spacing(1.5),
  },
  column: {
    flexBasis: '25%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      flexBasis: '50%',
    },
  },
  stateColumn: {
    flexBasis: '25%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      flexBasis: '33%',
    },
  },
  block: {
    display: 'block',
  },
  colorDisabled: {
    color: theme.palette.text.disabled,
  },
  desktopOnlyField: {
    flexBasis: '25%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
}));

interface InterfaceSummaryProps {
  iface: { type: string; actual?: { stats?: { speed?: number; carrier?: boolean } } };
  config: unknown;
  name: string;
  expanded: boolean;
  state: string;
  initialBond?: string;
  addressToShow?: ReactNode;
  justCreated?: boolean;
  showMismatchBadge?: boolean;
  type: unknown;
  removingIface?: boolean;
  updatingIface?: boolean;
  forbidDelete?: boolean;
  handleRemoveInterface(name: string): void;
  handleCreateEmptyConfig?(name: string): void;
}

const InterfaceSummary: FC<InterfaceSummaryProps> = ({
  iface,
  config,
  name,
  expanded,
  state,
  initialBond,
  addressToShow,
  justCreated,
  showMismatchBadge,
  type,
  removingIface,
  updatingIface,
  forbidDelete,
  handleRemoveInterface,
  handleCreateEmptyConfig,
}) => {
  const classes = useStyles();

  const showConfiguringBadge = (iface.type === 'bond' || iface.type === 'vlan') && iface.actual === null;
  const showAbsentBadge = iface.type === 'phys' && iface.actual === null;
  const { speed, carrier } = (iface.actual || {}).stats || {};

  return (
    <div className={classes.containerSummary}>
      {(state || showConfiguringBadge || showAbsentBadge) && (
        <div className={classNames(classes.column, classes.block)}>
          <div className={classes.column}>
            <Badge
              overlap='rectangular'
              classes={{ badge: classes.badge }}
              invisible={!showConfiguringBadge && !showAbsentBadge && (expanded || !showMismatchBadge)}
              badgeContent={
                <Tooltip
                  title={
                    <InterfaceErrorTooltipMessage
                      showAbsentBadge={showAbsentBadge}
                      showConfiguringBadge={showConfiguringBadge}
                      showMismatchBadge={!!showMismatchBadge}
                    />
                  }
                >
                  <ErrorIcon color='error' />
                </Tooltip>
              }
            >
              <Typography>{name}</Typography>
            </Badge>
          </div>
          <StateCaption show={!showConfiguringBadge && !showAbsentBadge} expanded={expanded} state={state} />
        </div>
      )}
      {!state && !showConfiguringBadge && !showAbsentBadge && (
        <div className={classes.column}>
          <Typography className={justCreated ? classes.colorDisabled : ''}>{name}</Typography>
        </div>
      )}
      <div className={classes.desktopOnlyField}>
        <AddressSummary
          justCreated={justCreated}
          bond={initialBond}
          expanded={expanded}
          addressToShow={addressToShow}
        />
      </div>
      <div className={classes.stateColumn}>
        <StateSummary justCreated={justCreated} expanded={expanded} state={state} />
      </div>
      <div className={classes.desktopOnlyField}>
        <LinkSummary show={!justCreated && type !== 'vtun'} expanded={expanded} carrier={carrier} speed={speed} />
      </div>
      <div className={classes.moreActionsContainer}>
        <InterfaceActionsMenu
          show={!justCreated && !forbidDelete}
          expanded={expanded}
          name={name}
          haveConfig={!!config}
          handleRemoveInterface={() => handleRemoveInterface(name)}
          handleCreateEmptyConfig={handleCreateEmptyConfig && (() => handleCreateEmptyConfig(name))}
          removingIface={removingIface}
          updatingIface={updatingIface}
        />
      </div>
    </div>
  );
};

export default InterfaceSummary;
