import React, { Fragment } from 'react';
import compose from 'recompose/compose';
import { FormattedMessage } from 'react-intl';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import ButtonWithProgress from '../ButtonWithProgress';
import Transition from '../util/DialogTransition';

const styles = theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.appBarBackgroundColor,
  },
  toolbar: {
    padding: `0 ${theme.spacing(1.5)}px`,
  },
  cancelButton: {
    marginRight: theme.spacing(1),
  },
  spacer: {
    height: theme.spacing(3),
  },
  toolBarTypography: {
    flex: 1,
  },
  block: {
    display: 'block',
  },
  button: {
    color: '#fff',
  },
});

function CidrIntersectionDialog({
  handleCloseCidrIntersectionDialog,
  handleUndoAddressesChange,
  open,
  fullScreen,
  pendingUndoChange,
  intersections = [],
  classes,
}) {
  const title = (
    <FormattedMessage
      id='cidrIntersectionDialog.title'
      defaultMessage='CIDR {number, plural, one {intersection} other {intersections}}'
      values={{ number: intersections.length }}
    />
  );
  return (
    <Dialog
      open={open}
      onClose={pendingUndoChange ? null : handleCloseCidrIntersectionDialog}
      fullScreen={fullScreen}
      TransitionComponent={fullScreen ? Transition : undefined}
    >
      {fullScreen && (
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              color='inherit'
              onClick={handleCloseCidrIntersectionDialog}
              disabled={pendingUndoChange}
              aria-label='Close'
              className={classes.cancelButton}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant='h6' color='inherit' className={classes.toolBarTypography}>
              {title}
            </Typography>
            <ButtonWithProgress showProgress={pendingUndoChange}>
              <Button onClick={handleUndoAddressesChange} disabled={pendingUndoChange} className={classes.button}>
                <FormattedMessage id='cidrIntersectionDialog.undoChanges' defaultMessage='Undo changes' />
              </Button>
            </ButtonWithProgress>
          </Toolbar>
        </AppBar>
      )}
      {fullScreen && <div className={classes.spacer} />}
      {!fullScreen && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText>
          <Fragment>
            <FormattedMessage
              id='cidrIntersectionDialog.message'
              defaultMessage={
                'There {number, plural, one {is an intersection} other ' +
                '{are intersections}} between the following CIDRs: '
              }
              values={{ number: intersections.length }}
            />
            {intersections.map((pair, i) => (
              <span className={classes.block} key={i}>
                {pair.join(' - ')}
              </span>
            ))}
          </Fragment>
        </DialogContentText>
      </DialogContent>
      {!fullScreen && (
        <DialogActions>
          <ButtonWithProgress showProgress={pendingUndoChange}>
            <Button onClick={handleUndoAddressesChange} disabled={pendingUndoChange} color='primary'>
              <FormattedMessage id='cidrIntersectionDialog.undoChanges' defaultMessage='Undo changes' />
            </Button>
          </ButtonWithProgress>
          <Button onClick={handleCloseCidrIntersectionDialog} color='primary' autoFocus disabled={pendingUndoChange}>
            <FormattedMessage id='cidrIntersectionDialog.ok' defaultMessage='Ok' />
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default compose(withStyles(styles), withMobileDialog())(CidrIntersectionDialog);
