import { ChangeEvent, FC, memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import { isEmpty, isEqual, omit } from 'lodash';

import { InterfaceSummary, PanelDetails } from '.';
import ButtonWithProgress from '@/components/ButtonWithProgress';
import { Theme } from '@material-ui/core';
import { DhcpServerConfig, resetDhcpConfig } from '@/redux-stuff/features/dhcp-server';
import { useAppDispatch, useAppSelector } from '@/util/store';
import { DnsServerConfig, setDnsServerConfig } from '@/redux-stuff/features/dns-server';

const useStyles = makeStyles((theme: Theme) => ({
  summary: {
    '&& > div': {
      margin: 0,
    },
    [theme.breakpoints.only('xs')]: {
      padding: `0px ${theme.spacing(1)}px`,
    },
  },
  details: {
    [theme.breakpoints.only('xs')]: {
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
    },
  },
  newPanel: {
    borderStyle: 'dotted',
    borderWidth: 3,
    borderRadius: `${theme.spacing(1)}px !important`,
    borderColor: theme.palette.action.hover,
    backgroundColor: theme.palette.action.hover,
    '& > [role="button"]:hover': {
      cursor: 'auto',
    },
  },
  removeSeparator: {
    '&:before': {
      display: 'none',
    },
  },
}));

interface InterfaceProps {
  mac: string;
  state: string;
  flags: string[];
  addressInfo: unknown;
  stats?: {
    speed?: number;
    carrier?: boolean;
  };
}

export interface InterfaceConfig {
  addresses: string[];
  dhcp: DhcpServerConfig;
  dns: DnsServerConfig;
  bond: string;
  ifaces: string[];
}

interface InterfacePanelProps {
  w: boolean;
  iface: {
    name: string;
    type: string;
    actual: InterfaceProps;
    config?: InterfaceConfig;
    expanded: boolean;
    showActions: boolean;
    allowApply: boolean;
    errors?: Record<'ipv4Error' | 'ipv6Error' | 'vlanIdError' | 'miiError', JSX.Element | undefined>;
    newIpv4?: string;
    newIpv6?: string;
    selectedIpv6?: string;
    selectedIpv4?: string;
    justCreated?: boolean;
    ipv4Mismatch?: unknown[];
    ipv6Mismatch?: unknown[];
  };
  removingIface: unknown;
  updatingIface: unknown;
  synchronizingIface: unknown;
  propsIfaces: Record<
    string,
    {
      actual: InterfaceProps;
      config: InterfaceConfig;
    }
  >;
  handleRemoveInterface(name: string): void;
  handleCreateEmptyConfig(name: string): void;
  handleDiscard: (name: string) => () => unknown;
  handleApply: (name: string) => () => unknown;
  handleExpand: (name: string) => (e: ChangeEvent<{}>, expanded: boolean) => unknown;
  handleEditParams: unknown;
  handleBondSwitch: (name: string) => (e: ChangeEvent<HTMLInputElement>) => unknown;
  handleEditBondInterfaces: unknown;
  handleChangeInput: unknown;
  handleAddAddress: unknown;
  handleDeselectChip: unknown;
  handleSelectChip: unknown;
  handleDeleteChip: unknown;
  handleSynchronizeConfig: unknown;
  index: unknown;
}

const InterfacePanel: FC<InterfacePanelProps> = props => {
  const {
    iface,
    removingIface,
    updatingIface,
    synchronizingIface,
    propsIfaces,
    handleRemoveInterface,
    handleCreateEmptyConfig,
    handleDiscard,
    handleApply,
    handleExpand,
    index,
  } = props;
  const {
    name,
    type,
    actual,
    config,
    expanded,
    showActions,
    allowApply = true,
    errors,
    newIpv4 = '',
    newIpv6 = '',
    selectedIpv6 = '',
    selectedIpv4 = '',
    justCreated = false,
    ipv4Mismatch = [],
    ipv6Mismatch = [],
  } = iface;
  const { valid: dhcpValid, modified: dhcpModified } = useAppSelector(({ dhcpServer }) => dhcpServer)[name] || {};
  const dnsServer = useAppSelector(({ dnsServer }) => dnsServer[name]);
  const dnsModified = !!(dnsServer && dnsServer.enabled) !== !!(config && config.dns && config.dns.enabled);
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const pendingChangesIface = updatingIface || synchronizingIface || removingIface;

  const { ipv4Error, ipv6Error, vlanIdError, miiError } = errors || {};
  const { addresses, bond } = config || {};
  const { state } = actual || {};

  const addressToShow = !isEmpty(addresses) && addresses && addresses[0] + (addresses.length > 1 ? ', ...' : '');
  const actualAddresses = iface.actual && iface.actual.addressInfo;
  const { flags } = iface.actual || {};

  const isVlan = type === 'vlan';
  const isBond = type === 'bond';
  const { mac } = (isVlan && config && config.ifaces[0] ? propsIfaces[config.ifaces[0]].actual : actual) || {};
  const { bond: initialBond } = (propsIfaces[name] && propsIfaces[name].config) || {};
  const showMismatchBadge = (ipv4Mismatch.length > 0 || ipv6Mismatch.length > 0) && !initialBond;

  return (
    <Accordion
      key={name}
      onChange={justCreated ? undefined : handleExpand(name)}
      expanded={!!expanded}
      elevation={justCreated ? 0 : 1}
      className={justCreated ? classes.newPanel : ''}
      // We should override unwanted :before separator, because first interface doesn't have panel directly above it
      classes={index === 0 ? { root: classes.removeSeparator } : {}}
    >
      <AccordionSummary
        className={classes.summary}
        expandIcon={
          !justCreated && (
            <Tooltip title={<FormattedMessage id='networkPage.showHide' defaultMessage='Show/Hide' />}>
              <ExpandMoreIcon />
            </Tooltip>
          )
        }
      >
        <InterfaceSummary
          {...{
            iface,
            config,
            name,
            expanded,
            state,
            initialBond,
            addressToShow,
            justCreated,
            showMismatchBadge,
            type,
            removingIface: removingIface === name,
            updatingIface: updatingIface === name,
            handleRemoveInterface,
            handleCreateEmptyConfig,
          }}
        />
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <PanelDetails
          {...{
            mac,
            justCreated,
            isVlan,
            isBond,
            config,
            initialBond,
            bond,
            addresses,
            actualAddresses,
            flags,
            miiError,
            vlanIdError,
            selectedIpv4,
            ipv4Error,
            newIpv4,
            ipv4Mismatch,
            selectedIpv6,
            ipv6Error,
            newIpv6,
            ipv6Mismatch,
            name,
            // Prevent passing objects with varying links to better performance
            ...omit(props, isBond ? [] : ['iface']),
          }}
        />
      </AccordionDetails>
      <Collapse in={!!showActions || dhcpModified || dnsModified} unmountOnExit>
        <AccordionActions>
          <Button
            color='primary'
            disabled={pendingChangesIface === name && !config}
            onClick={() => {
              if (config) {
                dispatch(resetDhcpConfig(name));
                dispatch(setDnsServerConfig({ interfaceName: name, value: config.dns }));
                handleDiscard(name)();
              }
            }}
          >
            <FormattedMessage id='networkPage.discard' defaultMessage='Discard' />
          </Button>
          <ButtonWithProgress showProgress={updatingIface === name}>
            <Button
              color='primary'
              disabled={!allowApply || !!pendingChangesIface || !dhcpValid}
              onClick={handleApply(name)}
            >
              {justCreated ? (
                <FormattedMessage id='networkPage.create' defaultMessage='Create' />
              ) : (
                <FormattedMessage id='networkPage.apply' defaultMessage='Apply' />
              )}
            </Button>
          </ButtonWithProgress>
        </AccordionActions>
      </Collapse>
    </Accordion>
  );
};

export default memo(
  InterfacePanel,
  (prevProps, nextProps) =>
    prevProps.removingIface === nextProps.removingIface &&
    prevProps.updatingIface === nextProps.updatingIface &&
    prevProps.synchronizingIface === nextProps.synchronizingIface &&
    isEqual(prevProps.iface, nextProps.iface)
);
