import { SET_FIRMWARE_UPLOADS } from '../constants';
import { fetcher } from '../../util/deps';

let timerId;

const getFirmwareUploads = () => async dispatch => {
  let data = await fetcher.get('firmware/upload/list', { active: true }, false);

  dispatch(setFirmwareUploads(data));

  if (data.length) {
    planUpdate(dispatch);
  }
};

const planUpdate = dispatch => {
  clearTimeout(timerId);
  timerId = setTimeout(() => dispatch(getFirmwareUploads()), 1000);
};

const setFirmwareUploads = uploads => {
  const type = SET_FIRMWARE_UPLOADS;

  return { type, payload: uploads };
};

const interruptFirmwareUpload = id => async dispatch => {
  const encodedId = encodeURIComponent(id);
  await fetcher.put(`firmware/upload/${encodedId}/interrupt`);

  // deleted, call now so as to not wait next timer update
  dispatch(getFirmwareUploads());
};

export default {
  getFirmwareUploads,
  interruptFirmwareUpload,
};
