import { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    padding: theme.spacing(0.5),
  },
  tempContainer: {
    display: 'flex',
    padding: '8px 12px',
    width: '100%',
    backgroundColor: '#EAEAEA',
  },
  tempEntry: {
    '& ~ &': {
      marginLeft: theme.spacing(3),
    },
  },
  redColor: {
    color: 'rgb(217,83,79)',
  },
  yellowColor: {
    color: 'rgb(240,173,78)',
  },
}));

interface TemperatureEntryProps {
  resource: string;
  value: number;
  color: 'red' | 'yellow' | 'grey';
}

const TemperatureEntry: FC<TemperatureEntryProps> = ({ resource, value, color }) => {
  const classes = useStyles();
  return (
    <div className={classes.tempEntry}>
      <Typography
        variant='subtitle2'
        color='textSecondary'
        className={color === 'grey' ? '' : classes[`${color}Color`]}
      >
        {resource}
      </Typography>
      <Typography
        className={classnames(classes.tempEntry, color === 'grey' ? '' : classes[`${color}Color`])}
        variant='h5'
        color='textSecondary'
      >
        {value}&deg;C
      </Typography>
    </div>
  );
};

interface TemperatureWidgetProps {
  load: {
    cpuTemperature: {
      value: number;
      high: number;
      critical: number;
    };
    disksTemperature: Record<string, number>;
  };
}

const TemperatureWidget: FC<TemperatureWidgetProps> = ({ load }) => {
  const classes = useStyles();
  const { value: cpuTemp, high, critical } = load.cpuTemperature || {};
  const cpuColor = cpuTemp > critical ? 'red' : cpuTemp > high ? 'yellow' : 'grey';
  if (load.cpuTemperature === null && Object.keys(load.disksTemperature).length === 0) {
    return null;
  }
  return (
    <div className={classes.container}>
      <div className={classes.tempContainer}>
        {load.cpuTemperature && <TemperatureEntry resource='CPU' value={cpuTemp} color={cpuColor} />}
        {Object.entries(load.disksTemperature).map(([disk, temp]) => (
          <TemperatureEntry
            key={disk}
            resource={disk}
            value={temp}
            color={temp > 50 ? 'red' : temp > 40 ? 'yellow' : 'grey'}
          />
        ))}
      </div>
    </div>
  );
};

export default TemperatureWidget;
