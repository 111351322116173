import React from 'react';
import { FormattedMessage } from 'react-intl';

const compareStrings = (a, b) => (b > a ? -1 : b < a ? 1 : 0);

export const compareModules = ({ version: a_version, build: a_build }, { version: b_version, build: b_build }) => {
  if (a_version === b_version) {
    return b_build - a_build;
  }
  const aDetailed = a_version.split('.');
  const bDetailed = b_version.split('.');
  const aFullVersionIsNumber = aDetailed.every(v => !isNaN(v));
  const bFullVersionIsNumber = bDetailed.every(v => !isNaN(v));
  if (aFullVersionIsNumber && bFullVersionIsNumber) {
    return +b_version - +a_version;
  }
  const aFirstPartIsNumber = !isNaN(aDetailed[0]);
  const bFirstPartIsNumber = !isNaN(bDetailed[0]);
  if (!aFullVersionIsNumber && !bFullVersionIsNumber) {
    if (aFirstPartIsNumber && bFirstPartIsNumber) {
      return +bDetailed[0] - +aDetailed[0];
    } else if (aFirstPartIsNumber || bFirstPartIsNumber) {
      return +bFirstPartIsNumber - +aFirstPartIsNumber;
    }
    return compareStrings(a_version, b_version);
  }
  return +aFullVersionIsNumber - bFullVersionIsNumber;
};

export const getActiveVersions = services => {
  let activeVersions = {};

  services.forEach(s => {
    activeVersions[s.name] = s.module;
  });

  return activeVersions;
};
export const getLatestVersions = modules => {
  let uniqueNames = new Set(modules.map(m => m.name));

  let latestVersions = {};
  uniqueNames.forEach(uniqueName => {
    let latest_ = modules.filter(m => m.name === uniqueName).sort(compareModules)[0].id;
    latestVersions[uniqueName] = latest_;
  });

  return latestVersions;
};

export function getActiveModules(modules, services, activeVersions) {
  activeVersions = activeVersions || getActiveVersions(services);
  let activeModules = modules.filter(m => activeVersions[m.name] === m.id);

  return activeModules;
}

export const isAnyUpdatable = (active, latest) => {
  for (let name of Object.keys(active)) {
    let activeId = active[name];
    let latestId = latest[name];

    if (activeId && activeId !== latestId) return true;
  }
};
export const isAnyInstallable = (active, latest) => {
  for (let name of Object.keys(latest)) {
    if (!active[name]) return true;
  }
};
export const getModulesToInstall = (active, latest, modules) => {
  let ids = [];

  for (let name of Object.keys(latest)) {
    let activeId = active[name];
    let latestId = latest[name];

    if (activeId !== latestId) {
      ids.push(latestId);
    }
  }

  const modules_ = modules.filter(m => ids.includes(m.id));
  return modules_;
};

export const installUpdateMessages = {
  title: {
    install: <FormattedMessage id='modulesPage.installUpdateAllDialog.title.install' defaultMessage='Install' />,
    update: <FormattedMessage id='modulesPage.installUpdateAllDialog.title.update' defaultMessage='Update' />,
    'install/update': (
      <FormattedMessage id='modulesPage.installUpdateAllDialog.title.installUpdate' defaultMessage='Install/update' />
    ),
  },
  message: {
    install: <FormattedMessage id='modulesPage.installUpdateAllDialog.message.install' defaultMessage='install' />,
    update: <FormattedMessage id='modulesPage.installUpdateAllDialog.message.update' defaultMessage='update' />,
    'install/update': (
      <FormattedMessage id='modulesPage.installUpdateAllDialog.message.installUpdate' defaultMessage='install/update' />
    ),
  },
  button: {
    install: <FormattedMessage id='modulesPage.button.install' defaultMessage='Install all' />,
    update: <FormattedMessage id='modulesPage.button.update' defaultMessage='Update all' />,
    'install/update': (
      <FormattedMessage
        id='modulesPage.installUpdateAllDialog.button.installUpdate'
        defaultMessage='Install/update all'
      />
    ),
  },
};
