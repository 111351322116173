import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import MuiTextField from '@material-ui/core/TextField';

const styles = theme => ({
  input: {
    '& input[readonly]': {
      padding: '6px 4px 7px 4px',
      marginBottom: '2px',
      backgroundColor: theme.palette.grey[100],
    },
  },
});

function TextField(props) {
  const { classes, InputProps = {}, ...other } = props;

  if (InputProps.readOnly) {
    InputProps.disableUnderline = true;
  }

  return (
    <MuiTextField
      InputProps={{
        ...InputProps,
        className: classNames(classes.input, InputProps.className),
      }}
      {...other}
    />
  );
}

const CustomTextField = withStyles(styles)(TextField);

const onFocus = event => event.target.select();

export default function CopyPasteField(props) {
  return (
    <CustomTextField
      onFocus={onFocus}
      InputProps={{
        readOnly: true,
      }}
      {...props}
    />
  );
}
