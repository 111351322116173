import { fetcher } from '../../util/deps';
import { SET_BACKUPS, ADD_BACKUP, REMOVE_BACKUP } from '../constants';

const setBackups = backups => {
  const type = SET_BACKUPS,
    payload = backups;
  return { type, payload };
};

const addBackup = (backup, fromWebSocket = false) => {
  const type = ADD_BACKUP,
    payload = backup;
  return { type, payload, fromWebSocket };
};

const removeBackup = (filename, fromWebSocket = false) => {
  const type = REMOVE_BACKUP,
    payload = filename;
  return { type, payload, fromWebSocket };
};

const loadBackups = () => async dispatch => {
  let data = await fetcher.get('backup', undefined, false);
  if (data) {
    dispatch(setBackups(data));
  }
};

export default {
  setBackups,
  loadBackups,
  addBackup,
  removeBackup,
};
