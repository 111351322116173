import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

import ProgressBar from '../ProgressBarMultiple';

const styles = theme => ({
  container: {
    display: 'flex',
    flexBasis: '50%',
    padding: '18px 24px',
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
      padding: '12px',
    },
  },
  subContainer1: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.only('xs')]: {
      flexWrap: 'wrap',
    },
  },
  subContainer2: {
    width: 'calc(50% - 4px)',
    '& ~ &': {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      '& ~ &': {
        marginLeft: 0,
        marginTop: theme.spacing(2),
      },
    },
  },
});

const TX_RGB = '29,148,255';
const RX_RGB = '92,184,92';

const LinesContainer = ({ classes, load, ifaces }) => (
  <div className={classes.container}>
    <div className={classes.subContainer1}>
      <div className={classes.subContainer2}>
        {Object.entries(load.networkStats || {}).map(([iface, stats]) => {
          const { speed } = (ifaces[iface].actual || {}).stats || {};
          const { rxSpeed, txSpeed } = stats || {};
          const notAvailable = !speed || !stats;
          const rx = !notAvailable && rxSpeed ? (rxSpeed / speed) * 100 : 0;
          const tx = !notAvailable && txSpeed ? (txSpeed / speed) * 100 : 0;
          const message = !speed && stats && (
            <FormattedMessage
              id='mainPage.loadCard.message.RxTx'
              defaultMessage='Rx {rx},Tx {tx} Mb/s'
              values={{ rx: rxSpeed ? rxSpeed.toFixed(0) : 0, tx: txSpeed ? txSpeed.toFixed(0) : 0 }}
            />
          );
          return stats ? (
            <ProgressBar
              key={iface}
              notAvailable={notAvailable}
              multiple
              firstValue={rx}
              secondValue={tx}
              firstColor={RX_RGB}
              secondColor={TX_RGB}
              message={message}
              tooltipMessage={
                <FormattedMessage
                  id='mainPage.loadCard.progressTooltip.RxTxSpeed'
                  defaultMessage='Rx speed: {rx}Mb/s, Tx speed: {tx}Mb/s'
                  values={{ rx: rxSpeed ? rxSpeed.toFixed(0) : 0, tx: txSpeed ? txSpeed.toFixed(0) : 0 }}
                />
              }
              label={iface}
            />
          ) : null;
        })}
      </div>
      <div className={classes.subContainer2}>
        {Object.entries(load.disksIO || {}).map(([disk, { ioPercent }]) => (
          <ProgressBar
            key={disk}
            firstValue={ioPercent}
            tooltipMessage={
              <FormattedMessage
                id='mainPage.loadCard.progressTooltip.diskLoad'
                defaultMessage='Disk load: {ioPercent}%'
                values={{ ioPercent }}
              />
            }
            label={disk}
          />
        ))}
      </div>
    </div>
  </div>
);

export default withStyles(styles)(LinesContainer);
