import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.appBarBackgroundColor,
  },
  toolBar: {
    paddingLeft: theme.spacing(1.5),
  },
  button: {
    marginRight: theme.spacing(),
  },
});

const Transition = props => <Slide direction='up' {...props} />;
class InfoDialog extends React.Component {
  render() {
    const { open, handleClose, mobile, classes, fields, title, subtitle } = this.props;
    let title_ = title ? title : <FormattedMessage id='infoDialog.info' defaultMessage='Info' />;
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={mobile}
        TransitionComponent={mobile ? Transition : undefined}
        maxWidth='sm'
        fullWidth
      >
        {mobile && (
          <AppBar className={classes.appBar}>
            <Toolbar className={classes.toolBar}>
              <IconButton className={classes.button} color='inherit' onClick={handleClose} aria-label='Cancel'>
                <CloseIcon />
              </IconButton>
              <Typography variant='h6' color='inherit'>
                {title_}
              </Typography>
            </Toolbar>
          </AppBar>
        )}
        {!mobile && <DialogTitle>{title_}</DialogTitle>}
        <DialogContent>
          {subtitle && <DialogContentText>{subtitle}</DialogContentText>}
          <Table>
            <TableBody>
              {fields.map(([key, a, b]) => (
                <TableRow key={key}>
                  <TableCell padding='none'>{a}</TableCell>
                  <TableCell padding='none'>{b}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={handleClose}>
            <FormattedMessage id='infoDialogWithTable.buttons.close' defaultMessage='Close' />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(InfoDialog);
