import semver from 'semver';

// state === firmware + modules
// let state | currentState | nextState = {
//   firmware
//   modules
// }

// let nextModules = getNextModules(modules, changes: { upgrade: [module1, module2], remove: [module0] })

// let thingsThatWillBreak = findBrokenPackages(nextState);

// // how to get warnings in module list
// let brokenThings = findBrokenPackages(currentState);

// // how to get warning on firmware change
// let thingsThatWillBreak = findBrokenPackages({firmware: nextFirmware, modules});

// // how to get warnings on module deletion
// let nextModules = getNextModules(modules, changes: {remove: [moduleA] })
// let thingsThatWillBreak = findBrokenPackages({firmware, nextModules});

// // how to get warnings on install, bundle install, install all/upgrade all
// let nextModules = getNextModules(modules, changes: {upgrade: [moduleA, moduleB] })
// let thingsThatWillBreak = findBrokenPackages({firmware, nextModules});

function getFirmwarePackage(build, nextFirmware) {
  if (nextFirmware) {
    return {
      firmware: {
        version: nextFirmware.info.firmware_version,
        deps: nextFirmware.info.deps,
      },
      linux_kernel: {
        version: nextFirmware.info.kernel_version,
      },
    };
  }

  if (build) {
    return {
      firmware: {
        version: build.firmware_version,
        deps: build.deps,
      },
      linux_kernel: {
        version: build.kernel_version,
      },
    };
  }
}

function getModulePackages(modules) {
  let container = {};

  modules.forEach(m => {
    container[m.name] = {
      version: `${m.version}.${m.build}`,
      deps: m.dependencies,
      conflicts: m.conflicts,
    };
  });

  return container;
}

function getNextModules(modules, { upgrade = [], remove = [] }) {
  let modules_ = modules.map(m => upgrade.find(m_ => m_.name === m.name) || m);
  modules_ = [...modules_, ...upgrade.filter(m => !modules_.includes(m))];

  modules_ = modules_.filter(m => !(m in remove));

  return modules_;
}

function getBrokenPackages(packages) {
  let brokenPackages = {};

  getMissingDeps(packages, brokenPackages);
  getConflicts(packages, brokenPackages);

  return brokenPackages;
}

function getMissingDeps(packages, brokenPackages) {
  brokenPackages = brokenPackages || {};

  for (let packageName in packages) {
    let package_ = packages[packageName];

    let deps = package_.deps;

    if (deps === false) {
      brokenPackages[packageName] = {
        ...brokenPackages[packageName],
        invalidDeps: true,
      };
    }

    for (let depName in deps) {
      let requiredRange = deps[depName];

      let availableVersion = packages[depName] && packages[depName].version;
      if (!availableVersion) {
        brokenPackages[packageName] = brokenPackages[packageName] || { version: package_.version };
        brokenPackages[packageName] = {
          ...brokenPackages[packageName],
          deps: {
            ...brokenPackages[packageName].deps,
            [depName]: { requiredRange, availableVersion },
          },
        };
        continue;
      }

      let requiredRangeCoerced = coerceRange(requiredRange);
      let availableVersionCoerced = coerce(availableVersion.replace('-', '.'));

      if (!semver.validRange(requiredRangeCoerced)) {
        continue;
      }

      if (!semver.valid(availableVersionCoerced)) {
        continue;
      }

      try {
        let isSatisfied = semver.satisfies(availableVersionCoerced, requiredRangeCoerced);
        if (isSatisfied === false) {
          brokenPackages[packageName] = brokenPackages[packageName] || { version: package_.version };
          brokenPackages[packageName] = {
            ...brokenPackages[packageName],
            deps: {
              ...brokenPackages[packageName].deps,
              [depName]: { requiredRange, availableVersion },
            },
          };
        }
      } catch (e) {
        // prettier
      }
    }
  }

  return brokenPackages;
}

function getConflicts(packages, brokenPackages) {
  brokenPackages = brokenPackages || {};

  for (let packageName in packages) {
    let package_ = packages[packageName];

    let conflicts = package_.conflicts;

    if (conflicts === false) {
      brokenPackages[packageName] = {
        ...brokenPackages[packageName],
        invalidConflicts: true,
      };
    }

    for (let conflictName in conflicts) {
      let conflictRange = conflicts[conflictName];

      let conflictVersion = packages[conflictName] && packages[conflictName].version;
      if (!conflictVersion) continue;

      let conflictRangeCoerced = coerceRange(conflictRange);
      let conflictVersionCoerced = coerce(conflictVersion);

      if (!semver.validRange(conflictRangeCoerced)) {
        continue;
      }

      if (!semver.valid(conflictVersionCoerced)) {
        continue;
      }

      try {
        let isSatisfied = semver.satisfies(conflictVersionCoerced, conflictRangeCoerced);
        if (isSatisfied === true) {
          brokenPackages[packageName] = brokenPackages[packageName] || { version: package_.version };
          brokenPackages[packageName] = {
            ...brokenPackages[packageName],
            conflicts: {
              ...brokenPackages[packageName].conflicts,
              [conflictName]: { conflictRange, conflictVersion },
            },
          };
        }
      } catch (e) {
        // prettier
      }
    }
  }

  return brokenPackages;
}

function filterUnchangedIssues(brokenPackages, nextBrokenPackages) {
  let res = {};

  for (let packageName in nextBrokenPackages) {
    let pkg = brokenPackages[packageName];
    let nextPkg = nextBrokenPackages[packageName];

    if (nextPkg.invalidDeps || nextPkg.invalidConflicts) {
      res[packageName] = nextPkg;
      continue;
    }

    if (JSON.stringify(pkg) === JSON.stringify(nextPkg)) continue;

    if (!pkg || pkg.version !== nextPkg.version) {
      res[packageName] = nextPkg;
      continue;
    }

    if (!pkg.deps && nextPkg.deps) {
      res[packageName] = res[packageName] || {};
      res[packageName].deps = nextPkg.deps;
    } else {
      for (let depName in nextPkg.deps) {
        let dep = pkg.deps[depName];
        let nextDep = nextPkg.deps[depName];
        if (JSON.stringify(dep) === JSON.stringify(nextDep)) continue;

        res[packageName] = res[packageName] || {};
        res[packageName].deps = res[packageName].deps || {};
        res[packageName].deps[depName] = nextDep;
      }
    }

    if (!pkg.conflicts && nextPkg.conflicts) {
      res[packageName] = res[packageName] || {};
      res[packageName].conflicts = nextPkg.conflicts;
    } else {
      for (let conflictName in nextPkg.conflicts) {
        let conflict = pkg.conflicts[conflictName];
        let nextConflict = nextPkg.conflicts[conflictName];
        if (JSON.stringify(conflict) === JSON.stringify(nextConflict)) continue;

        res[packageName] = res[packageName] || {};
        res[packageName].conflicts = res[packageName].conflicts || {};
        res[packageName].conflicts[conflictName] = nextConflict;
      }
    }

    if (res[packageName] && (res[packageName].deps || res[packageName].conflicts)) {
      res[packageName].version = nextPkg.version;
    }
  }

  return res;
}

function drawVersionChange({ version: v0 } = { version: '—' }, { version: v1 } = { version: '—' }) {
  if (v0 === v1) return v0;

  return `${v0} ⟶ ${v1}`;
}

function coerce(str) {
  str = str.replace(/^trunk\./, '1000.0.');
  str = str.replace(/^master\./, '2000.0.');
  return str;
}

function coerceRange(str) {
  str = str.replace(/([^\d]|^)(trunk)\./g, (_, c) => `${c}1000.0.`);
  str = str.replace(/([^\d]|^)(master)\./g, (_, c) => `${c}2000.0.`);
  return str;
}

export {
  getFirmwarePackage,
  getModulePackages,
  getNextModules,
  getBrokenPackages,
  filterUnchangedIssues,
  drawVersionChange,
  coerce,
  coerceRange,
};
