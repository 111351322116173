import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import classnames from 'classnames';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(),
    },
  },
  container: {
    height: theme.spacing(2),
    marginLeft: theme.spacing(),
    width: '100%',
  },
  progressContainer: {
    overflow: 'hidden',
    height: '100%',
    backgroundColor: '#eee',
    borderRadius: '4px',
  },
  multipleProgress: {
    height: '50%',
    '&:nth-child(1)': {
      borderRadius: '4px 4px 0px 0px',
    },
    '&:nth-child(2)': {
      borderRadius: '0px 0px 4px 4px',
    },
  },
  progressProgress: {
    backgroundColor: '#ccc',
    height: '100%',
    width: '100%',
    transition: 'width 0.4s linear',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    whiteSpace: 'nowrap',
    fontFamily: "'Roboto mono', monospace",
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    fontSize: '0.75em',
  },
});

function ProgressBarMultiple(props) {
  const {
    classes,
    message,
    firstValue,
    firstTitle,
    firstColor = '92,184,92',
    tooltipMessage,
    secondValue,
    secondTitle,
    secondColor,
    label,
    multiple,
    notAvailable,
  } = props;

  return (
    <div className={classes.root}>
      <Typography>{label}</Typography>
      <Tooltip
        title={tooltipMessage}
        disableFocusListener={notAvailable}
        disableTouchListener={notAvailable}
        disableHoverListener={notAvailable}
      >
        <div className={classes.container}>
          <div
            className={classnames(classes.progressContainer, multiple && !notAvailable ? classes.multipleProgress : '')}
            style={notAvailable ? {} : { backgroundColor: `rgba(${firstColor}, 0.12)` }}
          >
            <div
              className={classes.progressProgress}
              style={
                notAvailable
                  ? { width: '100%', backgroundColor: '#eee' }
                  : { width: `${firstValue}%`, backgroundColor: `rgba(${firstColor}, 1)` }
              }
            >
              {firstTitle && <Typography className={classes.title}>{firstTitle}</Typography>}
              {notAvailable && (
                <Typography color='textSecondary' className={classes.title}>
                  {message}
                </Typography>
              )}
            </div>
          </div>
          {multiple && !notAvailable && (
            <div
              className={classnames(classes.progressContainer, classes.multipleProgress)}
              style={{ backgroundColor: `rgba(${secondColor}, 0.12)` }}
            >
              <div
                className={classes.progressProgress}
                style={{ width: `${secondValue}%`, backgroundColor: `rgba(${secondColor}, 1)` }}
              >
                {secondTitle && <Typography className={classes.title}>{secondTitle}</Typography>}
              </div>
            </div>
          )}
        </div>
      </Tooltip>
    </div>
  );
}

export default withStyles(styles)(ProgressBarMultiple);
