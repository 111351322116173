import {
  SET_NETWORK,
  SET_UNSAVED_NETWORK_CHANGES,
  CLEAR_UNSAVED_NETWORK_CHANGES,
  UPDATE_MISSING_ROUTES,
} from '../constants';
import { decorateActualAddresses, getMissingRoutes } from '../../util/networkHelpers';

export default (network = null, action) => {
  switch (action.type) {
    case SET_NETWORK:
      return { ...network, ...decorateActualAddresses(action.payload) };
    case SET_UNSAVED_NETWORK_CHANGES: {
      const { unsavedChanges = [] } = network;
      const [iface, unsavedChangesExist] = action.payload;
      const newUnsavedChanges = [...unsavedChanges.filter(i => i !== iface), ...(unsavedChangesExist ? [iface] : [])];
      return { ...network, unsavedChanges: newUnsavedChanges };
    }
    case CLEAR_UNSAVED_NETWORK_CHANGES: {
      return { ...network, unsavedChanges: [] };
    }
    case UPDATE_MISSING_ROUTES: {
      return { ...network, ...getMissingRoutes(network) };
    }
    default:
      return network;
  }
};
