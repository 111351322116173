import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import CirclesContainer from './CirclesContainer';
import LinesContainer from './LinesContainer';

const styles = theme => ({
  container: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
});

const LoadWidget = ({ classes, load, ifaces, uptime }) => (
  <div className={classes.container}>
    <CirclesContainer load={load} uptime={uptime} />
    {ifaces && <LinesContainer load={load} ifaces={ifaces} />}
  </div>
);

export default withStyles(styles)(LoadWidget);
