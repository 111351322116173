import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import { omit } from 'lodash';

const styles = theme => ({
  actionsContainer: {
    alignItems: 'flex-end',
  },
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonIcon: {
    display: 'flex',
    marginRight: theme.spacing(),
  },
});

const CustomSpeedDial = props => {
  const { open, hidden, label, children: actions, classes } = props;
  return (
    <SpeedDial
      className={classes.speedDial}
      classes={{ directionUp: classes.actionsContainer }}
      hidden={hidden}
      icon={
        <span className={classes.buttonContainer}>
          <SpeedDialIcon className={classes.buttonIcon} />
          {label}
        </span>
      }
      open={open}
      FabProps={{ variant: 'extended' }}
      {...omit(props, 'classes')}
    >
      {actions}
    </SpeedDial>
  );
};

export default withStyles(styles)(CustomSpeedDial);
