import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import compose from 'recompose/compose';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { getErrorMessage } from '../util/getErrorMessage';

import { fetcher } from '../util/deps';
import store from '../util/store';
import { authActions } from '../redux-stuff/actions';
import { LocaleContext } from '../context';

const FIELD_WIDTH = 240;

const styles = theme => ({
  root: {
    margin: 'auto',
    marginTop: theme.spacing(3),
    maxWidth: 1100,
    display: 'flex',
  },
  mobileRoot: {
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    height: `calc(100vh - 56px)`,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: `calc(100vh - 48px)`,
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100vh - 64px)`,
    },
  },
  formControl: {
    width: FIELD_WIDTH,
    '& ~ &': {
      minHeight: '92px',
    },
  },
  form: {
    margin: 'auto',
    padding: theme.spacing(4),
    width: FIELD_WIDTH,
  },
  button: {
    marginTop: theme.spacing(2),
    width: FIELD_WIDTH,
    margin: 'auto',
  },
});

function handleRedirectOnLogin() {
  const url = new URL(window.location.href);
  const params = url.searchParams;
  if (params.get('from') !== null) {
    window.location.href = decodeURIComponent(params.get('from'));
  }
}
class LoginPage extends Component {
  static contextType = LocaleContext;
  state = {
    username: '',
    password: '',
    anchorEl: null,
    error: false,
  };

  handleChange = type => event => this.setState({ [type]: event.target.value, error: false });

  handleChangeLanguage = event => this.context.switchLanguage(event.target.value);

  handleClickShowPassword = () => this.setState({ showPassword: !this.state.showPassword });

  handleLogin = async () => {
    const { username, password } = this.state;
    try {
      await fetcher.post('login', { username, password });
      handleRedirectOnLogin();
      store.dispatch(authActions.setIsAuth(true));
      const { referrer = '/' } = this.props.location.state || {};
      this.props.history.push(referrer);
    } catch (e) {
      this.setState({ error: getErrorMessage(e.message) });
    }
  };

  handleEnterPressed = event => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      this.handleLogin();
    }
  };

  render() {
    const { classes, width } = this.props;
    const { locale, languages } = this.context;
    const { username, password, showPassword, error } = this.state;
    const xs = width === 'xs';
    const mobile = xs || isMobile;
    const disableLogin = username.length === 0 || password.length === 0;
    return (
      <div className={classNames(classes.root, mobile ? classes.mobileRoot : '')}>
        <Paper className={classes.form} onKeyPress={this.handleEnterPressed}>
          <form id='netup-system-login-form'>
            <FormControl className={classes.formControl}>
              <TextField
                autoFocus
                error={!!error}
                id='system-username'
                label={<FormattedMessage id='loginPage.username' defaultMessage='Username' />}
                margin='normal'
                autoComplete='username'
                value={username}
                onChange={this.handleChange('username')}
                inputProps={{
                  autoCapitalize: 'none',
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                error={!!error}
                id='system-password'
                label={<FormattedMessage id='loginPage.password' defaultMessage='Password' />}
                helperText={error}
                type={showPassword ? 'text' : 'password'}
                margin='normal'
                autoComplete='current-password'
                value={password}
                onChange={this.handleChange('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton aria-label='Toggle password visibility' onClick={this.handleClickShowPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <FormControl>
              <TextField
                id='select-language'
                select
                label={<FormattedMessage id='loginPage.language' defaultMessage='Language' />}
                value={locale}
                onChange={this.handleChangeLanguage}
                margin='normal'
              >
                {languages.map(({ lang, locale }) => (
                  <MenuItem key={lang} value={locale}>
                    {lang}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <Button
              variant='outlined'
              size='large'
              color='primary'
              className={classes.button}
              disabled={disableLogin}
              onClick={this.handleLogin}
            >
              <FormattedMessage id='loginPage.login' defaultMessage='Login' />
            </Button>
          </form>
        </Paper>
      </div>
    );
  }
}

export default compose(withRouter, withStyles(styles))(LoginPage);
