import { fetcher } from '../../util/deps';
import { SET_CONNECTIONS } from '../constants';

const setConnections = info => {
  const type = SET_CONNECTIONS,
    payload = info;
  return { type, payload };
};

const loadConnections = () => async dispatch => {
  let data = await fetcher.get('connections', undefined, false);
  if (data) {
    dispatch(setConnections(data));
  }
};

export default {
  setConnections,
  loadConnections,
};
