import { FormControlLabel, Switch, SwitchProps, makeStyles } from '@material-ui/core';
import { FC } from 'react';
import { FormattedMessage, MessageDescriptor, defineMessages, useIntl } from 'react-intl';
import CustomTextField, { CustomTextFieldProps } from './form-elements/CustomTextField';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
  },
}));

const labels: Record<string, MessageDescriptor> = defineMessages({
  host: { id: 'vtunPanels.label.host', defaultMessage: 'Host' },
  port: { id: 'vtunPanels.label.port', defaultMessage: 'Port' },
  timeout: { id: 'vtunPanels.label.timeout', defaultMessage: 'Timeout' },
  name: { id: 'vtunPanels.label.name', defaultMessage: 'Name' },
  session: { id: 'vtunPanels.label.session', defaultMessage: 'Session' },
  password: { id: 'vtunPanels.label.password', defaultMessage: 'Password' },
  local_ip: { id: 'vtunPanels.label.local_ip', defaultMessage: 'Local IP' },
  remote_ip: { id: 'vtunPanels.label.remote_ip', defaultMessage: 'Remote IP' },
});

const adornmentMessages: Record<string, MessageDescriptor> = defineMessages({
  name: {
    id: 'vtunPanels.adornmentMessage.name',
    defaultMessage:
      'Name may include letters, numbers or dashes, should not start or end with a dash, should be 1-10 symbols long',
  },
  session: {
    id: 'vtunPanels.adornmentMessage.session',
    defaultMessage:
      'Session name may include letters, numbers, underscores and dashes, and should not start or end with a dash',
  },
  password: {
    id: 'vtunPanels.adornmentMessage.password',
    defaultMessage: 'Password should be all alpha characters or a mixture of alpha and numeric characters',
  },
});

interface CustomTunnelDetailsProps {
  enabled: boolean;
  handleEnabledChange: SwitchProps['onChange'];
  errors: Record<string, CustomTextFieldProps['error']>;
  localState: Record<string, string | number>;
  getChangeHandler(field: string): (newValue: string) => void;
}

const CustomTunnelDetails: FC<CustomTunnelDetailsProps> = ({
  enabled,
  handleEnabledChange,
  localState,
  getChangeHandler,
  errors,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const renderInputForm = (field: string) => (
    <CustomTextField
      id={field}
      key={field}
      label={intl.formatMessage(labels[field])}
      error={errors[field]}
      value={localState[field]}
      onChange={getChangeHandler(field)}
      endAdornment={field in adornmentMessages && { tooltip: intl.formatMessage(adornmentMessages[field]) }}
    />
  );

  return (
    <>
      <>
        <FormControlLabel
          control={<Switch checked={enabled} onChange={handleEnabledChange} color='primary' />}
          label={<FormattedMessage id='vtunPanel.label.enableTunnel' defaultMessage='Enable tunnel' />}
        />
        <div className={classes.container}>{['host', 'port'].map(renderInputForm)}</div>
        <div className={classes.container}>
          {['name', 'session', 'password', 'local_ip', 'remote_ip'].map(renderInputForm)}
        </div>
      </>
    </>
  );
};

export default CustomTunnelDetails;
