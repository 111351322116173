import { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { isEmpty } from 'lodash';
import { FormattedMessage, FormattedRelativeTime } from 'react-intl';
import { getSize } from '@/util/commonHelpers';
import classnames from 'classnames';
import LoadIndicator from '@/components/util/LoadIndicator';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexBasis: '50%',
    padding: '12px 24px',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
    },
    [theme.breakpoints.only('xs')]: {
      padding: '12px 8px',
    },
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  notSupported: {
    opacity: 0.8,
  },
  cardHeading: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
    fontSize: 30,
    [theme.breakpoints.only('xs')]: {
      fontSize: 20,
    },
  },
}));

interface CirclesContainerProps {
  width: string;
  load: {
    cpu: number;
    gpu: {
      usage: Record<string, number>;
    };
    mem: {
      total: number;
      available: number;
    };
    swap?: {
      total: {
        size: number;
        used: number;
        usage: number;
      };
    };
  };
  uptime: number;
}

const CirclesContainer: FC<CirclesContainerProps> = ({ width, load, uptime }) => {
  const classes = useStyles();
  const size = width === 'xs' ? 60 : 90;
  const cpuPercentage = load && load.cpu;
  const gpu = load && !isEmpty(load.gpu) && load.gpu;
  const gpuPercentage = gpu && gpu.usage && Math.max(...Object.values(gpu.usage));
  const mem = (load && load.mem) || {};
  const memPercentage = ((mem.total - mem.available) / mem.total) * 100;
  const swapExists = load && load.swap;
  const {
    size: swapSize = 0,
    used: swapUsed = 0,
    usage: swapPercentage = 0,
  } = (load && load.swap && load.swap.total) || {};

  return (
    <div className={classes.container}>
      <Tooltip
        title={
          (uptime > 0 && (
            <>
              <FormattedMessage id='uptime' defaultMessage='Uptime' />{' '}
              <FormattedRelativeTime value={uptime} numeric='always' updateIntervalInSeconds={1}>
                {v => {
                  // remove "in" word
                  const space = v.indexOf(' ');
                  if (space === -1) {
                    return v;
                  }
                  return v.substring(space + 1);
                }}
              </FormattedRelativeTime>
            </>
          )) ||
          ''
        }
      >
        <div className={classes.cardContent}>
          <Typography variant='h5' className={classes.cardHeading}>
            CPU
          </Typography>
          <LoadIndicator size={size} percentage={cpuPercentage} />
        </div>
      </Tooltip>

      <Tooltip
        title={
          (!gpu && (
            <FormattedMessage
              id='mainPage.gpuTooltip.qsvIsNotSupported'
              defaultMessage='QSV is not supported on this device'
            />
          )) ||
          ''
        }
      >
        <div className={classnames(classes.cardContent, !gpu && classes.notSupported)}>
          <Typography variant='h5' className={classes.cardHeading}>
            GPU
          </Typography>
          <LoadIndicator disabled={!gpu} size={size} percentage={gpuPercentage || 0} />
        </div>
      </Tooltip>

      <Tooltip
        title={
          <FormattedMessage
            id='mainPage.ramTooltip.totalAvailable'
            defaultMessage='Total: {total}, available: {available}'
            // convert kB to bytes
            values={{ total: getSize(mem.total * 1000), available: getSize(mem.available * 1000) }}
          />
        }
      >
        <div className={classes.cardContent}>
          <Typography variant='h5' className={classes.cardHeading}>
            RAM
          </Typography>
          <LoadIndicator size={size} percentage={memPercentage} isRam />
        </div>
      </Tooltip>

      {swapExists && (
        <Tooltip
          title={
            <FormattedMessage
              id='mainPage.swapTooltip.totalSizeUsed'
              defaultMessage='Size: {size}, used: {used}'
              // convert kB to bytes
              values={{ size: getSize(swapSize * 1000), used: getSize(swapUsed * 1000) }}
            />
          }
        >
          <div className={classes.cardContent}>
            <Typography variant='h5' className={classes.cardHeading}>
              SWAP
            </Typography>
            <LoadIndicator size={size} percentage={swapPercentage} isSwap />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default withWidth()(CirclesContainer);
