import { FC, useEffect, useState } from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent, { DialogContentProps } from '@material-ui/core/DialogContent';
import DialogContentText, { DialogContentTextProps } from '@material-ui/core/DialogContentText';
import classNames from 'classnames';
import PopupDialogTitle from './PopupDialogTitle';
import FullscreenDialogTitle, { FullscreenDialogTitleProps } from './FullscreenDialogTitle';
import PopupDialogActions, { PopupDialogActionsProps } from './PopupDialogActions';
import Transition from '@/components/util/DialogTransition';
import { makeStyles, Theme } from '@material-ui/core';
import { useMobile } from '@/hooks';

const useStyles = makeStyles((theme: Theme) => ({
  spacer: {
    height: theme.spacing(3),
  },
  disablePaddingForMobile: {
    [theme.breakpoints.only('xs')]: {
      padding: 0,
    },
  },
  dialogContent: {
    willChange: 'transform',
  },
}));

export interface ResponsiveDialogProps {
  buttons?: PopupDialogActionsProps['buttons'];
  leftButtons?: PopupDialogActionsProps['leftButtons'];
  onConfirm?(): void;
  onClose(): void;
  onCopy?: FullscreenDialogTitleProps['onCopy'];
  onExited?: NonNullable<DialogProps['TransitionProps']>['onExited'];
  title: FullscreenDialogTitleProps['children'];
  noTitle?: boolean;
  message?: DialogContentTextProps['children'];
  children?: DialogContentProps['children'];
  open: boolean;
  confirmButtonText?: PopupDialogActionsProps['confirmButtonText'];
  closeMessage?: PopupDialogActionsProps['closeMessage'];
  disablePaddingForMobile?: boolean;
  fullWidth?: boolean;
  ConfirmIcon?: FullscreenDialogTitleProps['ConfirmIcon'];
  maxWidth?: DialogProps['maxWidth'];
  colored?: boolean;
  disableButtons?: boolean;
  withoutConfirm?: boolean;
  disableConfirm?: boolean;
  withSpinner?: boolean;
  forceSpinner?: boolean;
  closeOnConfirm?: boolean;
  dontVanish?: boolean;
  pendingBackendEvent?: boolean;
}

/** If we want to add spinner, we should not set 'open' value to false in onConfirm handler */

const ResponsiveDialog: FC<ResponsiveDialogProps> = ({
  buttons,
  leftButtons,
  onConfirm,
  onClose,
  onCopy,
  onExited,
  title,
  noTitle,
  message,
  children,
  open,
  confirmButtonText,
  closeMessage,
  disablePaddingForMobile,
  fullWidth,
  ConfirmIcon,
  maxWidth = 'sm',
  colored,
  disableButtons,
  withoutConfirm,
  disableConfirm,
  withSpinner,
  forceSpinner,
  closeOnConfirm,
  dontVanish,
  pendingBackendEvent,
}) => {
  const [spinnerIsActive, setSpinnerIsActive] = useState(false);
  const classes = useStyles();
  const isMobile = useMobile();

  useEffect(() => {
    if (!withSpinner || !spinnerIsActive || pendingBackendEvent) return;
    if (!dontVanish) {
      onClose();
    }
    setSpinnerIsActive(false);
  }, [withSpinner, spinnerIsActive, pendingBackendEvent, dontVanish]);

  const handleConfirm: PopupDialogActionsProps['handleConfirm'] = event => {
    event.stopPropagation();
    if (onConfirm) {
      onConfirm();
    }
    if (withSpinner) {
      setSpinnerIsActive(true);
      return;
    }
    if (closeOnConfirm) {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={spinnerIsActive ? undefined : onClose}
      fullScreen={isMobile}
      TransitionComponent={isMobile ? Transition : undefined}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      TransitionProps={{
        onExited: onExited,
      }}
    >
      {!noTitle &&
        (isMobile ? (
          <FullscreenDialogTitle
            colored={colored}
            spinnerIsActive={spinnerIsActive}
            forceSpinner={forceSpinner}
            disableButtons={disableButtons}
            withoutConfirm={withoutConfirm}
            disableConfirm={disableConfirm}
            ConfirmIcon={ConfirmIcon}
            handleConfirm={handleConfirm}
            onClose={onClose}
            onCopy={onCopy}
            buttons={buttons}
          >
            {title}
          </FullscreenDialogTitle>
        ) : (
          <PopupDialogTitle onCopy={onCopy}>{title}</PopupDialogTitle>
        ))}
      {isMobile && <div className={classes.spacer} />}

      <DialogContent
        className={classNames(disablePaddingForMobile && classes.disablePaddingForMobile, classes.dialogContent)}
      >
        {message && <DialogContentText>{message}</DialogContentText>}
        {children}
      </DialogContent>

      {!isMobile && !disableButtons && (
        <PopupDialogActions
          leftButtons={leftButtons}
          buttons={buttons}
          closeMessage={closeMessage}
          handleConfirm={handleConfirm}
          disableConfirm={disableConfirm}
          confirmButtonText={confirmButtonText}
          onClose={onClose}
          spinnerIsActive={spinnerIsActive}
          withoutConfirm={withoutConfirm}
          forceSpinner={forceSpinner}
        />
      )}
    </Dialog>
  );
};

export default ResponsiveDialog;
