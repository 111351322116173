import React, { Fragment } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { Tooltip } from '@material-ui/core';
import AppBarComponent from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LinearProgress from '@material-ui/core/LinearProgress';
import SyncDisabled from '@material-ui/icons/SyncDisabled';
import ChangePassword from '@material-ui/icons/VpnKey';
import ResponsiveDialog from '../components/responsive-dialog';

import { authActions, infoActions, drawerActions } from '../redux-stuff/actions';
import { USER_LOGOUT } from '../redux-stuff/constants';
import uriMap from '../util/uriMap';
import { fetcher } from '../util/deps';
import { havePermission, parsePermissions } from '../util/permissions';
import ActionsMenu from '../components/ActionsMenu';
import ChangePasswordDialog from '../components/ChangePasswordDialog';
import { Error403, makeAlias, makeTitle, makeLogout, makeExpandDrawerIcon, makeButtons } from '../components/mainPage';
import PageErrorSnackbar from '../components/common/PageErrorSnackbar';
import ErrorBellContainer from '../components/AppBarErrorBellContainer';

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover $hideIcon': {
      color: 'rgba(255, 255, 255, .5)',
    },
  },
  homePage: {
    backgroundColor: theme.palette.homeAppBarColor,
  },
  hideIcon: {
    color: 'rgba(0, 0, 0, 0)',
  },
  hover: {
    '&:hover svg': {
      color: 'inherit !important',
    },
  },
  aliasEditField: {
    color: 'inherit',

    '&&&&:before': {
      borderBottom: '2px solid rgba(255, 255, 255, .12)',
    },
    // hover
    '&&&&:hover:before': {
      borderBottom: '2px solid rgba(255, 255, 255, .50)',
    },
    // selected
    '&&&&:after': {
      borderBottom: '2px solid rgba(255, 255, 255, .9)',
    },

    width: '600px',
    [theme.breakpoints.down('sm')]: {
      width: '220px',
    },
  },
  emptyAliasEditTrigger: {
    alignSelf: 'stretch',
    width: '220px',
  },
  appBarSelectedItems: {
    backgroundColor: theme.palette.appBarBackgroundColor,
    transition: 'all 0.2s cubic-bezier(.25,.8,.25,1)',
  },
  leftMarginText: {
    marginLeft: theme.spacing(1),
    '&:first-child': {
      marginLeft: theme.spacing(3),
    },
  },
  leftMarginButton: {
    marginLeft: theme.spacing(2),
    '&:first-child': {
      marginLeft: theme.spacing(1.5),
    },
  },
  midPlaceholder: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(1),
    },
  },
  actionButton: {
    marginRight: theme.spacing(1),
    marginLeft: 0,
  },
  logoutButton: {
    marginRight: theme.spacing(1),
    textTransform: 'none',
  },
  linearProgress: {
    marginBottom: -3,
    height: 3,
  },
  disconnectedTooltip: {
    marginRight: theme.spacing(1),
    '&:last-child': {
      marginRight: theme.spacing(3),
    },
  },
});

class AppBar extends React.Component {
  state = { userMenuOpen: false, logoutDialogOpen: false, anchorEl: null };

  openUserMenu = event => this.setState({ userMenuOpen: true, anchorEl: event.currentTarget });
  closeUserMenu = () => this.setState({ userMenuOpen: false });

  openChangePasswordDialog = () => {
    this.setState({ changePasswordDialogOpen: true });
    this.closeUserMenu();
  };
  openLogoutDialog = () => {
    this.setState({ logoutDialogOpen: true });
    this.closeUserMenu();
  };

  closeChangePasswordDialog = () => this.setState({ changePasswordDialogOpen: false });
  closeLogoutDialog = () => this.setState({ logoutDialogOpen: false });

  handleLogout = () => {
    this.setState({ userMenuOpen: false });
    this.props.setIsAuth(false);
    fetcher.post('logout', undefined, false);
    this.props.history.push('/login');
    this.props.logout();
  };

  static mapStateToProps(store) {
    const { appBar, info, isSocketConnected } = store;
    return { appBar, alias: info.alias, isSocketConnected };
  }

  render() {
    const {
      classes,
      appBar,
      width,
      permissions,
      addMessage,
      pagesWithoutDrawer,
      allPages,
      alias,
      pageError,
      isSocketConnected,
      localError,
      username = '',
    } = this.props;
    const mobile = width === 'xs' || isMobile;
    const ExpandDrawerIcon = makeExpandDrawerIcon(this.props.toggleDrawerState, classes);
    const changePasswordPermission = !!permissions && parsePermissions(permissions, 'users').w;
    const homePage = this.props.location.pathname === '/';

    return (
      <Fragment>
        {!localError && (
          <AppBarComponent
            className={classNames(
              classes.appBar,
              mobile && appBar.text && classes.appBarSelectedItems,
              homePage && classes.homePage
            )}
          >
            {!isSocketConnected && <LinearProgress className={classes.linearProgress} color='primary' />}
            <Toolbar disableGutters className={'toolBar'}>
              {!mobile && homePage && <img src='./appbar_logo.png' style={{ margin: 8, maxHeight: 40 }} />}
              {mobile && appBar.leftButton ? (
                <Tooltip title={appBar.leftButton.title}>
                  <IconButton
                    color='inherit'
                    aria-label='undo select'
                    onClick={appBar.leftButton.action}
                    className={classes.menuButton}
                  >
                    {appBar.leftButton.icon}
                  </IconButton>
                </Tooltip>
              ) : (
                <Switch>
                  <Route exact path='/' render={pageError ? ExpandDrawerIcon : null} />
                  <Route exact path='/login' />
                  <Route render={ExpandDrawerIcon} />
                </Switch>
              )}
              {mobile && appBar.text ? (
                <Typography variant='h6' classes={{ root: classes.leftMarginText }} color='inherit' noWrap>
                  {appBar.text}
                </Typography>
              ) : (
                <Fragment>
                  <Switch>
                    {Object.entries(uriMap).map(([location, { exact, pageName: PageName }]) => {
                      const shouldRender =
                        pagesWithoutDrawer.includes(location) ||
                        !permissions ||
                        havePermission(permissions, location.slice(1)) ||
                        !allPages.includes(location);

                      return (
                        <Route
                          key={location}
                          exact={!!exact}
                          path={location}
                          component={
                            shouldRender
                              ? location === '/' && !pageError
                                ? makeAlias(mobile, classes, alias, this.props.updateAlias)
                                : makeTitle(PageName, classes)
                              : Error403
                          }
                        />
                      );
                    })}
                  </Switch>
                </Fragment>
              )}
              <div className={classes.midPlaceholder} />
              {!isSocketConnected && (
                <Tooltip
                  id='ws-not-connected-tooltip'
                  title={<FormattedMessage id='WS.notConnected' defaultMessage='Connection to server is lost' />}
                  className={classes.disconnectedTooltip}
                >
                  <SyncDisabled />
                </Tooltip>
              )}
              {mobile &&
                appBar.rightButtons &&
                appBar.rightButtons.map(action => (
                  <Tooltip key={action.key} title={action.title}>
                    <IconButton color='inherit' onClick={action.action} className={classes.actionButton}>
                      {action.icon}
                    </IconButton>
                  </Tooltip>
                ))}
              {(!mobile || (mobile && !appBar.rightButtons)) && <Route component={ErrorBellContainer} />}
              <Route
                render={
                  isSocketConnected
                    ? makeLogout(mobile, appBar, this.openLogoutDialog, this.openUserMenu, username, classes)
                    : null
                }
              />
              {(!mobile || !appBar.rightButtons) && (
                <Route
                  render={makeButtons(
                    addMessage,
                    permissions,
                    this.openLogoutDialog,
                    username,
                    this.openChangePasswordDialog,
                    changePasswordPermission
                  )}
                />
              )}
            </Toolbar>
            <ActionsMenu
              open={this.state.userMenuOpen}
              anchorEl={this.state.anchorEl}
              handleClose={this.closeUserMenu}
              items={[
                {
                  show: changePasswordPermission,
                  Icon: ChangePassword,
                  text: <FormattedMessage id='appBar.actionsMenu.changePassword' defaultMessage='Change password' />,
                  handler: this.openChangePasswordDialog,
                },
                {
                  show: true,
                  Icon: ExitToAppIcon,
                  text: (
                    <FormattedMessage id='appBar.logout' defaultMessage='Logout ({username})' values={{ username }} />
                  ),
                  handler: this.openLogoutDialog,
                },
              ]}
            />
            <ChangePasswordDialog
              open={!!this.state.changePasswordDialogOpen}
              anchorName={username}
              onClose={this.closeChangePasswordDialog}
              addMessage={addMessage}
            />

            <ResponsiveDialog
              open={this.state.logoutDialogOpen}
              title={<FormattedMessage id='appBar.logoutDialog.title' defaultMessage='Logout' />}
              message={
                <FormattedMessage
                  id='appBar.logoutDialog.message'
                  defaultMessage='Are you sure you want to logout?'
                  values={{ roleToBeDeleted: this.state.anchorName }}
                />
              }
              confirmButtonText={<FormattedMessage id='appBar.logoutDialog.button.logout' defaultMessage='Logout' />}
              onClose={this.closeLogoutDialog}
              onConfirm={this.handleLogout}
              closeOnConfirm
              fullWidth
            />
          </AppBarComponent>
        )}
        <PageErrorSnackbar open={localError} />
      </Fragment>
    );
  }
}

AppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
};

export default compose(
  injectIntl,
  withRouter,
  connect(AppBar.mapStateToProps, {
    updateAlias: infoActions.updateAlias,
    setIsAuth: authActions.setIsAuth,
    toggleDrawerState: drawerActions.toggleDrawerState,
    logout: () => ({ type: USER_LOGOUT }),
  }),
  withStyles(styles),
  withWidth()
)(AppBar);
