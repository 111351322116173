import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';

class TextContent extends React.Component {
  render() {
    const { cancel, ok } = this.props;

    return (
      <Fragment>
        <div className='dropzone'>
          <div className='dropzone-contents'>
            <DialogContentText>
              <Typography variant='h6'>
                <FormattedMessage id='fileUploader.tryingToHide' defaultMessage='Upload will continue' />
              </Typography>

              <div>
                <Button onClick={cancel} className='dropzone-button'>
                  <FormattedMessage id='fileUploader.back' defaultMessage='Back' />
                </Button>
                <Button onClick={ok} className='dropzone-button'>
                  <FormattedMessage id='fileUploader.ok' defaultMessage='fileUploader.Ok' />
                </Button>
              </div>
            </DialogContentText>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default TextContent;
