import {
  SET_ROLES,
  SET_PERMISSION,
  CHANGE_ANCESTORS,
  DISCARD_ROLE_CHANGES,
  SET_UNSAVED_ROLES_CHANGES,
  CLEAR_UNSAVED_ROLES_CHANGES,
} from '../constants';
import { omit } from 'lodash';

export default (roles = null, action) => {
  switch (action.type) {
    case SET_ROLES: {
      const { newRoles, roleToUpdate } = action.payload;
      let editedRoles;
      if (roleToUpdate) {
        if (newRoles[roleToUpdate]) {
          editedRoles = { ...roles.editedRoles, [roleToUpdate]: newRoles[roleToUpdate] };
        } else {
          // In this case role was removed
          editedRoles = omit(roles.editedRoles, roleToUpdate);
        }
      } else {
        // If nothing passed as roleToUpdate, we should update all roles
        editedRoles = newRoles;
      }
      return { lastLoadedRoles: newRoles, editedRoles };
    }
    case SET_PERMISSION: {
      const { role, resource, prop, value } = action.payload;
      const updatedRole = {
        ...roles.editedRoles[role],
        [resource]: { ...roles.editedRoles[role][resource], [prop]: value },
      };
      return { ...roles, editedRoles: { ...roles.editedRoles, [role]: updatedRole } };
    }
    case CHANGE_ANCESTORS: {
      const { role, newAncestors } = action.payload;
      return {
        ...roles,
        editedRoles: { ...roles.editedRoles, [role]: { ...roles.editedRoles[role], $extend: newAncestors } },
      };
    }
    case DISCARD_ROLE_CHANGES: {
      const role = action.payload;
      return { ...roles, editedRoles: { ...roles.editedRoles, [role]: roles.lastLoadedRoles[role] } };
    }
    case SET_UNSAVED_ROLES_CHANGES: {
      const { unsavedChanges = [] } = roles;
      const [role, unsavedChangesExist] = action.payload;
      const newUnsavedChanges = [...unsavedChanges.filter(i => i !== role), ...(unsavedChangesExist ? [role] : [])];
      return { ...roles, unsavedChanges: newUnsavedChanges };
    }
    case CLEAR_UNSAVED_ROLES_CHANGES: {
      return { ...roles, unsavedChanges: [] };
    }
    default:
      return roles;
  }
};
