import { ChangeEvent, FC } from 'react';
import { makeStyles } from '@material-ui/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import Input from '@material-ui/core/Input';
import { FormattedMessage } from 'react-intl';
import { IpContainer, InterfaceSelectForm, VlanIdForm, BondContainer, DhcpContainer, DnsContainer } from '.';
import { Collapse, Theme, Typography } from '@material-ui/core';
import { getIps } from '@/util/networkHelpers';
import { InterfaceConfig } from './InterfacePanel';

const FIELD_WIDTH = 160;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  inputChipsContainer: {
    width: '100%',
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'baseline',
    },
  },
  leftContainer: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '35%',
  },
  threeElementsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '33%',
  },
  editingContainer: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '35%',
    '@media screen and (max-width: 370px)': {
      flexWrap: 'wrap',
      position: 'relative',
      paddingBottom: 3 * theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    width: FIELD_WIDTH * 1.5,
  },
  dhcpFormControl: {
    margin: theme.spacing(1),
  },
  dnsFormControl: {
    margin: theme.spacing(1),
  },
  chips: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexBasis: '65%',
      flexWrap: 'wrap',
    },
  },
  chip: {
    margin: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px 0px`,
  },
  selected: {
    backgroundColor: '#F8BBD0',
    '&&:hover': {
      backgroundColor: '#F48FB1',
    },
  },
  flag: {
    '&:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  mode: {
    flexBasis: '100%',
  },
}));

// TODO: add unknown types
interface PanelDetailsProps {
  w: boolean;
  mac: string;
  justCreated: boolean;
  isVlan: boolean;
  isBond: boolean;
  config?: InterfaceConfig;
  flags: string[];
  iface?: unknown;
  initialBond: string;
  bond: unknown;
  addresses?: string[];
  actualAddresses: unknown;
  handleEditParams: unknown;
  handleBondSwitch: (name: string) => (e: ChangeEvent<HTMLInputElement>) => unknown;
  handleEditBondInterfaces: unknown;
  handleChangeInput: unknown;
  handleAddAddress: unknown;
  handleDeselectChip: unknown;
  handleSelectChip: unknown;
  handleDeleteChip: unknown;
  handleSynchronizeConfig: unknown;
  propsIfaces: unknown;
  miiError: JSX.Element | undefined;
  vlanIdError: unknown;
  selectedIpv4: unknown;
  ipv4Error: unknown;
  newIpv4: unknown;
  ipv4Mismatch: unknown;
  selectedIpv6: unknown;
  ipv6Error: unknown;
  newIpv6: unknown;
  ipv6Mismatch: unknown;
  synchronizingIface: unknown;
  name: string;
}

const PanelDetails: FC<PanelDetailsProps> = ({
  w,
  mac,
  justCreated,
  isVlan,
  isBond,
  config,
  flags = [],
  iface,
  initialBond,
  bond,
  addresses,
  actualAddresses,
  handleEditParams,
  handleBondSwitch,
  handleEditBondInterfaces,
  handleChangeInput,
  handleAddAddress,
  handleDeselectChip,
  handleSelectChip,
  handleDeleteChip,
  handleSynchronizeConfig,
  propsIfaces,
  miiError,
  vlanIdError,
  selectedIpv4,
  ipv4Error,
  newIpv4,
  ipv4Mismatch,
  selectedIpv6,
  ipv6Error,
  newIpv6,
  ipv6Mismatch,
  synchronizingIface,
  name,
}) => {
  const classes = useStyles();
  const ipv4Configured = (getIps(addresses).ipv4 || []).length > 0;

  return (
    <div className={classes.container}>
      {justCreated && isVlan && (
        <InterfaceSelectForm {...{ classes, config, name, propsIfaces, handleEditVlan: handleEditParams }} />
      )}
      <div className={classes.container}>
        {mac && !justCreated && (
          <>
            <div className={classes.leftContainer}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor={`mac-address-${name}`}>MAC</InputLabel>
                <Input id={`mac-address-${name}`} value={mac} disabled />
                <FormHelperText />
              </FormControl>
            </div>
            <Typography className={classes.flag} color='textSecondary'>
              <FormattedMessage id='networkPage.flagsMessage' defaultMessage='Flags: ' />
            </Typography>
            {flags.map((flag, i) => (
              <Typography className={classes.flag} color='textSecondary' key={i}>
                {flag}
                {i !== flags.length - 1 && ','}
              </Typography>
            ))}
          </>
        )}
        {isBond && (
          <BondContainer
            {...{
              classes,
              name,
              iface,
              propsIfaces,
              miiError,
              w,
              handleEditBondParams: handleEditParams,
              handleEditBondInterfaces,
            }}
          />
        )}
        {initialBond && (
          <div className={classes.container}>
            <Switch color='primary' checked={!!bond} disabled={!w} onChange={handleBondSwitch(name)} />
            <FormattedMessage
              id='networkPage.includedInBond'
              defaultMessage='included in {bond}'
              values={{ bond: initialBond }}
            />
          </div>
        )}
      </div>
      {isVlan && <VlanIdForm {...{ classes, vlanIdError, config, name, w, handleEditVlan: handleEditParams }} />}
      {!initialBond && (
        <>
          <IpContainer
            {...{
              addresses,
              name,
              handleChangeInput,
              handleAddAddress,
              handleDeselectChip,
              handleSelectChip,
              handleDeleteChip,
              handleSynchronizeConfig,
              selectedIp: selectedIpv4,
              ipError: ipv4Error,
              newIp: newIpv4,
              type: 'ipv4',
              ipMismatch: ipv4Mismatch,
              synchronizingIface: synchronizingIface === name,
              w,
            }}
          />
          <IpContainer
            {...{
              addresses,
              name,
              handleChangeInput,
              handleAddAddress,
              handleDeselectChip,
              handleSelectChip,
              handleDeleteChip,
              handleSynchronizeConfig,
              selectedIp: selectedIpv6,
              ipError: ipv6Error,
              newIp: newIpv6,
              actualAddresses,
              type: 'ipv6',
              ipMismatch: ipv6Mismatch,
              synchronizingIface: synchronizingIface === name,
              w,
            }}
          />
          {config && (
            <>
              <Collapse
                classes={{
                  wrapper: classes.container,
                  wrapperInner: classes.container,
                  root: classes.container,
                }}
                in={ipv4Configured}
              >
                <DhcpContainer classes={classes} name={name} config={config.dhcp} addresses={config.addresses} w={w} />
                <DnsContainer classes={classes} name={name} config={config.dns} w={w} />
              </Collapse>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PanelDetails;
