import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import compose from 'recompose/compose';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import ResponsiveDialog from '../components/responsive-dialog';
import CustomFab from '../components/CustomFab';
import { networkActions } from '../redux-stuff/actions';
import { parsePermissions } from '../util/permissions';
import { fetcher } from '../util/deps';
import RouteDialog from '../components/routingPage/RouteDialog';
import RoutesPanel from '../components/routingPage/RoutesPanel';
import { convertDestination, getRoutes } from '../util/networkHelpers';
import { DEFAULT_GATEWAY, DEFAULT_MULTICAST_GATEWAY } from '../redux-stuff/constants';
import PageLoadingIndicator from '../components/PageLoadingIndicator';
import Alerts from '../components/Alerts';

const styles = theme => ({
  root: {
    margin: 'auto',
    maxWidth: 1100,
  },
  mobileRoot: {
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    height: `calc(100vh - 56px)`,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: `calc(100vh - 48px)`,
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100vh - 64px)`,
    },
  },
});

class RoutingPage extends React.Component {
  state = { routeDialogOpen: false, confirmSyncPopoverOpen: false, selectedRoute: null };

  componentDidMount() {
    const { permissions, dispatch } = this.props;
    this.actions = bindActionCreators(networkActions, dispatch);
    this.actions.loadNetwork();
    this.setState({ permissions: parsePermissions(permissions, 'network') });
  }

  static mapStateToProps({ network }) {
    return { ...network, networkStillLoading: network === null };
  }

  handleAddRouteClick = () => this.setState({ routeDialogOpen: true });

  handleEditRouteClick = route => () => this.setState({ routeDialogOpen: true, selectedRoute: route });

  handleRouteDialogClose = () => this.setState({ routeDialogOpen: false });

  handleRouteDialogExited = () => this.setState({ selectedRoute: null });

  openDeleteDialog = route => e => {
    if (e) e.stopPropagation();
    this.routeToBeDeleted = route;
    this.setState({ deleteDialogOpen: true, routeDialogOpen: false });
  };
  closeDeleteDialog = () => this.setState({ deleteDialogOpen: false });

  handleDeleteRoute = route => event => {
    if (event) {
      event.stopPropagation();
    }
    let destinationToDelete = route.destination;
    if (route.destination === 'default gateway') {
      destinationToDelete = DEFAULT_GATEWAY;
    } else if (route.destination === 'default multicast gateway') {
      destinationToDelete = DEFAULT_MULTICAST_GATEWAY;
    }
    const iface = this.props.ifaces[route.iface];
    const newIfaceRoutes = iface.config.routes.filter(v => v.destination !== destinationToDelete);
    fetcher.put(
      'network',
      {
        ifaces: { [route.iface]: { config: { ...iface.config, routes: newIfaceRoutes } } },
      },
      false
    );
  };

  handleAddRoute = (ifaceName, newRoute) => {
    const iface = this.props.ifaces[ifaceName];
    const ifaceRoutes = (iface.config && iface.config.routes) || [];
    fetcher.put(
      'network',
      {
        ifaces: { [ifaceName]: { config: { ...iface.config, routes: [...ifaceRoutes, newRoute] } } },
      },
      false
    );
    this.handleRouteDialogClose();
  };

  handleUpdateRoute = (ifaceName, newRoute) => {
    const { selectedRoute } = this.state;
    const iface = this.props.ifaces[ifaceName];
    const ifaceRoutes = (iface.config && iface.config.routes) || [];
    const destinationToReplace = convertDestination(selectedRoute.destination);
    if (selectedRoute.iface !== ifaceName) {
      /** In this case we need to remove selectedRoute from previous route's interface
       *  (selectedRoute.iface) and add newRoute to newly selected interface (ifaceName) */
      const previousIface = this.props.ifaces[selectedRoute.iface];
      const previousIfaceRoutes = (previousIface.config && previousIface.config.routes) || [];
      fetcher.put(
        'network',
        {
          ifaces: {
            [ifaceName]: { config: { ...iface.config, routes: [...ifaceRoutes, newRoute] } },
            [selectedRoute.iface]: {
              config: {
                ...previousIface.config,
                routes: previousIfaceRoutes.filter(v => v.destination !== destinationToReplace),
              },
            },
          },
        },
        false
      );
    } else {
      const selectedRouteIndex = ifaceRoutes.findIndex(v => v.destination === destinationToReplace);
      const nextIfaceRoutes = [...ifaceRoutes];
      nextIfaceRoutes[selectedRouteIndex] = newRoute;
      fetcher.put(
        'network',
        {
          ifaces: { [ifaceName]: { config: { ...iface.config, routes: nextIfaceRoutes } } },
        },
        false
      );
    }
    this.handleRouteDialogClose();
  };

  render() {
    const { classes, width, ifaces = {}, actualRoutes = [] } = this.props;

    const mdUp = width !== 'xs' && width !== 'sm';
    const mobile = !mdUp || isMobile;
    const ifaceList = Object.entries(ifaces).reduce(
      (acc, [iface, { type, config }]) => (config && config.bond ? acc : [...acc, { name: iface, type }]),
      []
    );
    const { systemRoutes, userRoutes } = getRoutes(ifaces, actualRoutes);
    const occupiedDestinations = [
      DEFAULT_GATEWAY,
      DEFAULT_MULTICAST_GATEWAY,
      ...(this.state.selectedRoute
        ? userRoutes
            .map(({ destination }) => destination)
            .filter(({ destination }) => destination === this.state.selectedRoute.destination)
        : userRoutes.map(({ destination }) => destination)),
    ];
    return (
      <div className={classNames(classes.root, mobile ? classes.mobileRoot : '')}>
        <Alerts issuesPermission={parsePermissions(this.props.permissions, 'issues').r} />
        <PageLoadingIndicator open={this.props.networkStillLoading} />

        <div>
          <RoutesPanel
            type='user rules'
            routes={userRoutes}
            handleEditRoute={this.handleEditRouteClick}
            handleDeleteRoute={this.openDeleteDialog}
            mobile={mobile}
          />
          <RoutesPanel type='system rules' routes={systemRoutes} mobile={mobile} />
        </div>
        <CustomFab
          label={<FormattedMessage id='routingPage.fabLabel.add' defaultMessage='Add' />}
          onClick={this.handleAddRouteClick}
        />
        <RouteDialog
          open={this.state.routeDialogOpen}
          handleClose={this.handleRouteDialogClose}
          handleRouteDialogExited={this.handleRouteDialogExited}
          handleSave={
            !this.state.selectedRoute || this.state.selectedRoute.iface === 'Not set'
              ? this.handleAddRoute
              : this.handleUpdateRoute
          }
          handleDeleteSelectedRoute={this.openDeleteDialog(this.state.selectedRoute)}
          selectedRoute={this.state.selectedRoute}
          ifaceList={ifaceList}
          occupiedDestinations={occupiedDestinations}
        />

        <ResponsiveDialog
          open={!!this.state.deleteDialogOpen}
          title={<FormattedMessage id='RoutingPage.deleteDialog.title' defaultMessage='Delete route' />}
          message={
            <FormattedMessage
              id='RoutingPage.deleteDialog.message'
              defaultMessage='Are you sure you want to delete this route?'
            />
          }
          confirmButtonText={<FormattedMessage id='RoutingPage.deleteDialog.button.delete' defaultMessage='Delete' />}
          onClose={this.closeDeleteDialog}
          onConfirm={this.handleDeleteRoute(this.routeToBeDeleted)}
          closeOnConfirm
          fullWidth
        />
      </div>
    );
  }
}

export default connect(RoutingPage.mapStateToProps)(compose(withStyles(styles), withWidth())(RoutingPage));
