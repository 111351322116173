import { TableCell } from '@material-ui/core';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

const names: Record<string, ReturnType<FC>> = {
  name: <FormattedMessage id='name' defaultMessage='Name' />,
  revision: <FormattedMessage id='revision' defaultMessage='Revision' />,
  version: <FormattedMessage id='version' defaultMessage='Version' />,
  build: <FormattedMessage id='build' defaultMessage='Build' />,
  variant: <FormattedMessage id='variant' defaultMessage='Variant' />,
  size: <FormattedMessage id='size' defaultMessage='Size' />,
  'shared size': <FormattedMessage id='sharedSize' defaultMessage='Shared size' />,
  'unique size': <FormattedMessage id='uniqueSize' defaultMessage='Unique size' />,
  'build date': <FormattedMessage id='buildDatetime' defaultMessage='Build date' />,
};

const ModuleInfoFieldNameCell: FC<{ field: string }> = ({ field }) => {
  return <TableCell padding='none'>{names[field] || field}</TableCell>;
};

export default ModuleInfoFieldNameCell;
