import { fetcher } from '../../util/deps';
import { SET_LICENSE } from '../constants';
import { getLicenseState } from '../../util/licenseHelpers';

const loadLicense = () => async dispatch => {
  let data = await fetcher.get('license', undefined, false);
  if (data) {
    dispatch(setLicense(data));
  }
};

const setLicense = (license = {}) => {
  let state = getLicenseState(license);

  const type = SET_LICENSE;
  const payload = { state, ...license };
  return { type, payload };
};

export default {
  setLicense,
  loadLicense,
};
