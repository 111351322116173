import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

interface InterfaceErrorTooltipMessageProps {
  showConfiguringBadge: boolean;
  showAbsentBadge: boolean;
  showMismatchBadge: boolean;
}

const InterfaceErrorTooltipMessage: FC<InterfaceErrorTooltipMessageProps> = ({
  showConfiguringBadge,
  showAbsentBadge,
  showMismatchBadge,
}) => {
  if (showConfiguringBadge) {
    return (
      <FormattedMessage
        id='networkPage.interfaceIsBeingUpdatedTooltipMessage'
        defaultMessage='Interface configuration is being updated'
      />
    );
  }

  if (showAbsentBadge) {
    return <FormattedMessage id='networkPage.interfaceIsAbsentTooltipMessage' defaultMessage='Interface is absent' />;
  }

  if (showMismatchBadge) {
    return (
      <FormattedMessage
        id='networkPage.mismatchWarning'
        defaultMessage='Saved addresses do not match actual addresses'
      />
    );
  }

  return null;
};

export default InterfaceErrorTooltipMessage;
