import {
  SET_DNS,
  SET_NEW_IP,
  REMOVE_IP,
  ADD_NEW_IP,
  SET_PENDING_BACKEND,
  RESET_DNS_CHANGES,
  SELECT_IP,
  DESELECT_IP,
} from '../constants';

interface DnsStoreType {
  lastLoadedState: string[];
  localState: string[];
  newIp: string;
  selectedIp: string;
  pendingBackend: boolean;
}

type DnsActionType =
  | {
      type: typeof SET_DNS;
      payload: string[];
    }
  | {
      type: typeof SET_NEW_IP;
      payload: string;
    }
  | {
      type: typeof REMOVE_IP;
      payload: string;
    }
  | {
      type: typeof ADD_NEW_IP;
      payload: string;
    }
  | {
      type: typeof SET_PENDING_BACKEND;
      payload: boolean;
    }
  | {
      type: typeof RESET_DNS_CHANGES;
    }
  | {
      type: typeof SELECT_IP;
      payload: string;
    }
  | {
      type: typeof DESELECT_IP;
    };

export default (
  dns: DnsStoreType | undefined = {
    lastLoadedState: [],
    localState: [],
    newIp: '',
    selectedIp: '',
    pendingBackend: false,
  },
  action: DnsActionType
) => {
  switch (action.type) {
    case SET_DNS:
      return { ...dns, lastLoadedState: action.payload, localState: action.payload, newIp: '', selectedIp: '' };
    case SET_NEW_IP:
      return { ...dns, newIp: action.payload };
    case ADD_NEW_IP:
      return {
        ...dns,
        localState: dns.selectedIp
          ? dns.localState.map(ip => (ip === dns.selectedIp ? dns.newIp : ip))
          : [...dns.localState, dns.newIp],
        newIp: '',
        selectedIp: '',
      };
    case REMOVE_IP: {
      return {
        ...dns,
        localState: dns.localState.filter(v => v !== action.payload),
        ...(action.payload === dns.selectedIp ? { selectedIp: '', newIp: '' } : {}),
      };
    }
    case SET_PENDING_BACKEND:
      return { ...dns, pendingBackend: action.payload };
    case RESET_DNS_CHANGES:
      return { ...dns, localState: dns.lastLoadedState, selectedIp: '', newIp: '' };
    case SELECT_IP:
      return { ...dns, selectedIp: action.payload, newIp: action.payload };
    case DESELECT_IP:
      return { ...dns, selectedIp: '', newIp: '' };
    default:
      return dns;
  }
};
