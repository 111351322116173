export const getCookie = name => {
  const regexp = new RegExp(`${name}=([^;]+)`);
  const value = regexp.exec(document.cookie);
  return value != null ? decodeURI(value[1]) : '';
};

export const setCookie = (name, value = '', expiryInYears = 5) => {
  let expiry = new Date();
  expiry.setYear(expiry.getYear() + 1900 + expiryInYears);
  document.cookie = `${name}=${encodeURI(value)}; path=/; expires=${expiry.toGMTString()}`;
};

export const cookieIncludes = (name, ...values) => {
  const cookie = getCookie(name);
  return values.every(v => cookie.includes(v));
};
