import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  card: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'stretch',
    flexGrow: 1,
  },
  header: {
    backgroundColor: theme.palette.widget.headerBackground,
    padding: '16px 12px',
  },
  headerText: {
    color: theme.palette.widget.headerText,
  },
});

const Widget = ({ title, handleClick, classes, children }) => (
  <Grid item xs={12} sm={6} lg={6}>
    <Card className={classes.card}>
      <CardActionArea onClick={handleClick}>
        <CardHeader
          title={
            <Typography variant='h6' className={classes.headerText}>
              {title}
            </Typography>
          }
          className={classes.header}
        />
        <CardContent>{children}</CardContent>
      </CardActionArea>
    </Card>
  </Grid>
);

export default withStyles(styles)(Widget);
