import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import root from '@/redux-stuff/reducers';
import StoreDecorator from './store-decorator';

const getInitialErrors = () => {
  const errors = localStorage.getItem('systemInterfaceErrors');
  return errors ? JSON.parse(errors) : [];
};

const initial = {
  info: {},
  smart: {},
  drawer: { expanded: localStorage.getItem('drawerOpen') !== 'false', openNested: [] },
  appBar: {
    leftButton: undefined,
    rightButtons: undefined,
    text: undefined,
  },
  widgets: {
    hardware: localStorage.getItem('hardwareWidgetIsActive') !== 'false',
    disks: localStorage.getItem('disksWidgetIsActive') !== 'false',
    errors: localStorage.getItem('errorsWidgetIsActive') !== 'false',
    tutorial: localStorage.getItem('tutorialWidgetIsActive') !== 'false',
    settingsDialogOpen: false,
    saveAliasToLocalStorage: localStorage.getItem('alias') !== null,
  },
  isSocketConnected: true,
  errors: getInitialErrors(),
  vtun: {
    lastLoadedState: {},
    localState: {},
    validationErrors: {},
    selectedToRemoveIface: null,
    pendingChangesIface: null,
  },
  dns: {
    lastLoadedState: [],
    localState: [],
    newIp: '',
    selectedIp: '',
    pendingBackend: false,
  },
  isAuth: null,
};

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(root, initial, composeEnhancers(applyMiddleware(thunk)));
// TODO: get decorated types or remove decorators
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const decoratedStore = new StoreDecorator(store);

export default decoratedStore;
