import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import lightBlue from '@material-ui/core/colors/lightBlue';
import orange from '@material-ui/core/colors/orange';
import purple from '@material-ui/core/colors/purple';
import indigo from '@material-ui/core/colors/indigo';
import red from '@material-ui/core/colors/red';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';

import AppWrapper from './core/AppWrapper';
import store from './util/store';
import { LocaleContext } from './context';
import { getCookie, setCookie } from './util/cookie';
import ru from './locale/ru_RU.json';
import en from './locale/en_US.json';

const theme = createTheme({
  palette: {
    primary: {
      light: orange[300],
      main: orange[400],
      dark: orange[500],
      contrastText: '#fff',
    },
    secondary: {
      light: lightBlue['A200'],
      main: lightBlue['A400'],
      dark: lightBlue['A700'],
      contrastText: '#fff',
    },
    error: red,
    borderColor: 'rgba(0, 0, 0, 0.12)',
    appBarBackgroundColor: '#212121',
    homeAppBarColor: '#757575',
    table: {
      hover: 'rgba(0, 0, 0, 0.05)',
    },
    mainPageCards: {
      dvb: blue[500],
      systemSettings: orange[400],
      relay: purple[400],
      streamProcessor: indigo[500],
      middleware: lightBlue[700],
      default: green[700],
      vod: blue[700],
      text: '#fff',
    },
    icons: {
      default: 'rgba(0, 0, 0, 0.54)',
      success: green[500],
      info: lightBlue[400],
      warning: orange[300],
      danger: red[700],
    },
    alerts: {
      warning: 'rgb(255, 243, 205)',
      danger: 'rgb(248, 215, 218)',
      info: '#d1ecf1',
    },
    widget: {
      headerBackground: '#757575',
      headerBackgroundError: '#e53935',
      headerText: '#fff',
    },
  },
});

const languages = [
  { lang: 'English', locale: 'en' },
  { lang: 'Русский', locale: 'ru' },
];

const MESSAGES_RU = {
  locale: 'ru',
  messages: ru,
};
const MESSAGES_EN = {
  locale: 'en',
  messages: en,
};

if (!getCookie('locale')) {
  if (['ru', 'en'].includes(localStorage.systemLanguage)) {
    setCookie('locale', localStorage.systemLanguage);
  } else if (['ru', 'en'].includes(localStorage.interfaceLanguage)) {
    setCookie('locale', localStorage.interfaceLanguage);
  }
  delete localStorage.systemLanguage;
  delete localStorage.interfaceLanguage;
}

const navigatorLanguage = navigator.language.startsWith('ru') ? 'ru' : 'en';
const cookieLocale = ['ru', 'en'].includes(getCookie('locale')) && getCookie('locale');
const initialLocale = cookieLocale || navigatorLanguage;

export default class App extends Component {
  state = {
    messages: initialLocale === 'ru' ? MESSAGES_RU : MESSAGES_EN,
    snackbarDetailsModalContents: null,
  };

  switchLanguage = (language: 'ru' | 'en') => {
    this.setState({ messages: language === 'ru' ? MESSAGES_RU : MESSAGES_EN }, () => {
      setCookie('locale', language);
    });
  };

  render() {
    const { messages } = this.state;
    return (
      <Provider store={store}>
        <IntlProvider locale={messages.locale} messages={messages.messages}>
          <MuiThemeProvider theme={theme}>
            <Helmet htmlAttributes={{ lang: messages.locale }}>
              <meta http-equiv='Content-Language' content={messages.locale} />
            </Helmet>
            <LocaleContext.Provider value={{ locale: messages.locale, switchLanguage: this.switchLanguage, languages }}>
              <AppWrapper />
            </LocaleContext.Provider>
          </MuiThemeProvider>
        </IntlProvider>
      </Provider>
    );
  }
}
