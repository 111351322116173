import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';
import compose from 'recompose/compose';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import { isEqual } from 'lodash';
import { validateDestinationCidr } from 'system-common/dist/iputils';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import Transition from '../util/DialogTransition';
import { isValid, getIpVersion } from '../../util/ip';
import { DEFAULT_GATEWAY, DEFAULT_MULTICAST_GATEWAY } from '../../redux-stuff/constants';
import { tooltips } from './messages';
import { isMulticastRange } from '../../util/networkHelpers';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import WarningIcon from '@material-ui/icons/Warning';
import classnames from 'classnames';

const FIELD_WIDTH = 360;

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    display: 'flex',
    flexGrow: 1,
  },
  appBar: {
    position: 'relative',
  },
  toolbar: {
    padding: `0 ${theme.spacing(1.5)}px`,
  },
  cancelButton: {
    marginRight: theme.spacing(1),
  },
  spacer: {
    height: theme.spacing(3),
  },
  toolBarTypography: {
    flex: 1,
  },
  title: {
    marginLeft: theme.spacing(1),
  },
  ifaceFormControl: {
    width: FIELD_WIDTH / 1.5,
    margin: theme.spacing(1),
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  defaultCursor: {
    cursor: 'default',
  },
  flex: {
    display: 'flex',
  },
  select: {
    maxWidth: 120,
  },
  gateway: {
    maxWidth: 180,
  },
  deleteButton: {
    margin: `0 ${theme.spacing(2.25)}px`,
  },
});

const ipMaskMessage = defineMessages({
  message: {
    id: 'routerDialog.ipMaskInputPlaceholder',
    defaultMessage: 'IP/Mask',
  },
});

const initialState = {
  destination: '',
  gateway: '',
  iface: '',
  destinationError: false,
  gatewayError: false,
  occupiedError: false,
  allowAdd: false,
  ipVersion: '',
  wasEdited: false,
};

class RouteDialog extends React.Component {
  state = { ...initialState };

  static getDerivedStateFromProps(props, state) {
    let newState = null;
    if (isEqual(state, initialState) && props.selectedRoute) {
      newState = {
        ...props.selectedRoute,
        gateway:
          !props.selectedRoute.gateway || props.selectedRoute.gateway === 'Not set' ? '' : props.selectedRoute.gateway,
        ipVersion: getIpVersion(props.selectedRoute.destination),
      };
    }
    if (props.open && !state.iface && !props.selectedRoute) {
      const interfaceToAutocomplete = props.ifaceList.find(({ type }) => type === 'phys');
      newState = interfaceToAutocomplete
        ? { ...newState, iface: interfaceToAutocomplete.name, allowAdd: newState !== null }
        : { ...newState, iface: '' };
    }
    return newState;
  }

  handleChangeDestination = event => {
    const { value } = event.target;
    const occupiedError = this.props.occupiedDestinations.includes(value);
    const ipVersion = getIpVersion(value.split('/')[0]);
    const destinationError = !ipVersion || !isValid(`${ipVersion}/mask`, value) || !validateDestinationCidr(value);
    this.setState(
      { destination: value, occupiedError, destinationError, ...(destinationError ? {} : { ipVersion }) },
      this.updateAllowAdd
    );
  };

  handleChangeIface = event => this.setState({ iface: event.target.value }, this.updateAllowAdd);

  handleChangeGateway = event => {
    const { value } = event.target;
    if (value === '') {
      this.setState({ gateway: value, gatewayError: false }, this.updateAllowAdd);
      return;
    }
    const ipVersion = getIpVersion(value);
    const gatewayError = !ipVersion || !isValid(ipVersion, value);
    this.setState({ gateway: value, gatewayError, ...(gatewayError ? {} : { ipVersion }) }, this.updateAllowAdd);
  };

  updateAllowAdd = () => {
    const { destination, destinationError, gateway, gatewayError, iface } = this.state;
    const { selectedRoute } = this.props;
    const ipVersionsCorrect =
      getIpVersion(gateway) === (destination.includes('default') ? 'ipv4' : getIpVersion(destination.split('/')[0]));
    const allowAdd =
      (selectedRoute === null ||
        selectedRoute.iface !== iface ||
        selectedRoute.destination !== destination ||
        (selectedRoute.gateway || '') !== gateway) &&
      destination !== '' &&
      !destinationError &&
      !gatewayError &&
      iface !== 'Not set' &&
      (gateway === '' || ipVersionsCorrect);

    this.setState({ allowAdd, wasEdited: true });
  };

  handleEnterPressed = event => {
    const code = event.keyCode || event.which;
    if (code === 13 && this.state.allowAdd) {
      this.handleAdd();
    }
  };

  handleExited = () => {
    this.setState(initialState);
    this.props.handleRouteDialogExited();
  };

  handleAdd = () => {
    const { destination, gateway, iface } = this.state;
    let destinationToRequest = destination;
    if (destination === 'default gateway') {
      destinationToRequest = DEFAULT_GATEWAY;
    } else if (destination === 'default multicast gateway') {
      destinationToRequest = DEFAULT_MULTICAST_GATEWAY;
    }
    this.props.handleSave(iface, { destination: destinationToRequest, ...(gateway !== '' ? { gateway } : {}) });
  };

  render() {
    const { classes } = this.props;
    const { destination, gateway, iface, destinationError, gatewayError, occupiedError, allowAdd, wasEdited } =
      this.state;
    let title;
    if (destination === 'default gateway') {
      title = <FormattedMessage id='routeDialog.defaultGateway' defaultMessage='Default gateway' />;
    } else if (destination === 'default multicast gateway') {
      title = <FormattedMessage id='routeDialog.defaultMulticastGateway' defaultMessage='Default multicast gateway' />;
    } else {
      title = <FormattedMessage id='routeDialog.routingRule' defaultMessage='Routing rule' />;
    }
    /** We need to show delete button only when dialog in editing mode
     *  and when it's not first default interface editing */
    const showDeleteButton = this.props.selectedRoute !== null && this.props.selectedRoute.iface !== 'Not set';
    const isMulticastDestination = destination === 'default multicast gateway' || isMulticastRange(destination);
    const multicastGatewayError = isMulticastDestination && gateway;
    const multicastGatewayErrorMessage = tooltips.multicastGatewaySpecified(destination, gateway);
    return (
      <div>
        <Dialog
          open={this.props.open}
          onKeyPress={this.handleEnterPressed}
          onClose={this.props.handleClose}
          TransitionProps={{
            onExited: this.handleExited,
          }}
          fullScreen={this.props.fullScreen}
          TransitionComponent={this.props.fullScreen ? Transition : undefined}
          fullWidth
        >
          {this.props.fullScreen && (
            <AppBar className={classes.appBar}>
              <Toolbar className={classes.toolbar}>
                <IconButton
                  color='inherit'
                  onClick={this.props.handleClose}
                  aria-label='Close'
                  className={classes.cancelButton}
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant='h6' color='inherit' className={classes.toolBarTypography}>
                  {title}
                </Typography>
                {showDeleteButton && !wasEdited && (
                  <IconButton color='inherit' onClick={this.props.handleDeleteSelectedRoute} aria-label='Delete route'>
                    <DeleteIcon />
                  </IconButton>
                )}
                {(wasEdited || !showDeleteButton) && (
                  <IconButton color='inherit' onClick={this.handleAdd} disabled={!allowAdd} aria-label='Ok'>
                    <CheckIcon />
                  </IconButton>
                )}
              </Toolbar>
            </AppBar>
          )}
          {!this.props.fullScreen && <DialogTitle className={classes.title}>{title}</DialogTitle>}
          {this.props.fullScreen && <div className={classes.spacer} />}
          <DialogContent className={classes.flex}>
            {(!this.props.selectedRoute || !this.props.selectedRoute.destination.includes('default')) && (
              <FormControl
                error={occupiedError || destinationError}
                className={classnames(classes.formControl, classes.gateway)}
                fullWidth
              >
                <InputLabel htmlFor='destination'>
                  <FormattedMessage id='routeDialog.destination' defaultMessage='Destination' />
                </InputLabel>
                <Input
                  id='destination'
                  value={destination}
                  onChange={this.handleChangeDestination}
                  placeholder={this.props.intl.formatMessage(ipMaskMessage.message)}
                  autoFocus={this.props.selectedRoute === null}
                  className={classes.gateway}
                />
                <FormHelperText>
                  {destinationError && (
                    <FormattedMessage id='routeDialog.incorrectIpMask' defaultMessage='incorrect IP address/mask' />
                  )}
                  {occupiedError && (
                    <FormattedMessage
                      id='routeDialog.ruleAlreadySet'
                      defaultMessage='Rule for this destination is already set'
                    />
                  )}
                </FormHelperText>
              </FormControl>
            )}
            <FormControl
              error={gatewayError}
              className={classnames(
                classes.formControl,
                (!this.props.selectedRoute || !this.props.selectedRoute.destination.includes('default')) &&
                  classes.gateway
              )}
              fullWidth
            >
              <InputLabel htmlFor='gateway'>
                <FormattedMessage id='routeDialog.gateway' defaultMessage='Gateway' />
              </InputLabel>
              <Input
                endAdornment={
                  multicastGatewayError && (
                    <Tooltip title={multicastGatewayErrorMessage}>
                      <InputAdornment position='end'>
                        <Icon className={classes.defaultCursor} color='primary'>
                          <WarningIcon />
                        </Icon>
                      </InputAdornment>
                    </Tooltip>
                  )
                }
                id='gateway'
                value={gateway}
                onChange={this.handleChangeGateway}
                className={classnames(
                  (!this.props.selectedRoute || !this.props.selectedRoute.destination.includes('default')) &&
                    classes.gateway
                )}
              />
              <FormHelperText>
                {gatewayError && (
                  <FormattedMessage id='routeDialog.incorrectIp' defaultMessage='incorrect IP address' />
                )}
              </FormHelperText>
            </FormControl>
            <FormControl className={classes.ifaceFormControl}>
              <InputLabel htmlFor='iface'>
                <FormattedMessage id='routeDialog.interface' defaultMessage='Interface' />
              </InputLabel>
              <Select
                className={classes.select}
                value={iface}
                onChange={this.handleChangeIface}
                inputProps={{ id: 'iface' }}
              >
                {iface === 'Not set' && (
                  <MenuItem key='not-set' value='Not set' disabled>
                    <FormattedMessage id='routeDialog.notSet' defaultMessage='Not set' />
                  </MenuItem>
                )}
                {this.props.ifaceList.map(({ name }) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText />
            </FormControl>
          </DialogContent>
          {!this.props.fullScreen && (
            <DialogActions className={showDeleteButton ? classes.buttonsContainer : ''}>
              {showDeleteButton && (
                <Button className={classes.deleteButton} onClick={this.props.handleDeleteSelectedRoute} color='primary'>
                  <FormattedMessage id='routeDialog.delete' defaultMessage='Delete' />
                </Button>
              )}
              <div>
                <Button onClick={this.props.handleClose} color='primary'>
                  <FormattedMessage id='routeDialog.cancel' defaultMessage='Cancel' />
                </Button>
                <Button onClick={this.handleAdd} color='primary' disabled={!allowAdd}>
                  <FormattedMessage id='routeDialog.save' defaultMessage='Save' />
                </Button>
              </div>
            </DialogActions>
          )}
        </Dialog>
      </div>
    );
  }
}

export default compose(injectIntl, withStyles(styles), withMobileDialog())(RouteDialog);
