import ReconnectingWS from 'reconnecting-websocket';
import { EventEmitter } from 'events';

export default class WsClient extends EventEmitter {
  static deps = {
    Websocket: ReconnectingWS,
  };

  constructor(address) {
    super();
    this._address = address;
    this.socket = null;
  }

  get address() {
    return this._address;
  }

  set address(address) {
    this._address = address;
    this.connect();
  }

  connect() {
    if (this.socket !== null) this.disconnect();

    let proto = window.location.protocol === 'https:' ? 'wss' : 'ws';
    this.socket = new WsClient.deps.Websocket(`${proto}://${window.location.host}${this._address}`);
    this.socket.addEventListener('error', this.onError);
    this.socket.addEventListener('message', this.onMessage);
    this.socket.addEventListener('open', this.onOpen);
    this.socket.addEventListener('close', this.onClose);

    return this.socket;
  }

  disconnect() {
    if (this.socket === null) return;

    this.socket.close();
    this.socket.removeEventListener('error', this.onError);
    this.socket.removeEventListener('message', this.onMessage);
    this.socket.removeEventListener('open', this.onOpen);
    this.socket.removeEventListener('close', this.onClose);
  }

  onError = data => {
    this.emit('error', data);
  };

  onOpen = () => {
    this.emit('open');
  };

  onClose = () => {
    this.emit('close');
  };

  onMessage = event => {
    let data = JSON.parse(event.data);
    if (data.topic === undefined) {
      this.emit('_unknown', data);
      return;
    }

    let { topic, ...rest } = data;
    rest.data._counter = rest.counter;
    rest.data._bootId = rest.bootId;

    this.emit(topic, rest);
  };
}
