import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import Typography from '@material-ui/core/Typography';
import ButtonWithProgress from './ButtonWithProgress';

const styles = theme => ({
  popoverContent: {
    margin: theme.spacing(2),
    maxWidth: '400px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

/** If we want to add spinner, we should not set 'open' value to false in onConfirm handler */

class ConfirmPopover extends React.Component {
  state = { spinnerIsActive: false };

  componentDidUpdate(prevProps) {
    if (this.props.withSpinner) {
      if (prevProps.pendingBackendEvent && !this.props.pendingBackendEvent) {
        this.props.onClose();
        this.setState({ spinnerIsActive: false });
      }
    }
  }

  handleConfirm = event => {
    event.stopPropagation();
    this.props.onConfirm();
    if (this.props.withSpinner) {
      this.setState({ spinnerIsActive: true });
    }
  };

  render() {
    const { open, anchorEl, message, onClose, onExited, classes } = this.props;
    return (
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={this.state.spinnerIsActive ? null : onClose}
        TransitionProps={{
          onExited: onExited,
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <div className={classes.popoverContent}>
          <Typography>{message}</Typography>
          <div className={classes.buttons}>
            <IconButton onClick={onClose} disabled={this.state.spinnerIsActive} color='primary'>
              <Clear />
            </IconButton>
            <ButtonWithProgress showProgress={this.state.spinnerIsActive}>
              <IconButton onClick={this.handleConfirm} disabled={this.state.spinnerIsActive} color='primary'>
                <Check />
              </IconButton>
            </ButtonWithProgress>
          </div>
        </div>
      </Popover>
    );
  }
}

export default withStyles(styles)(ConfirmPopover);
