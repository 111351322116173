import React from 'react';
import { FormattedMessage } from 'react-intl';
// prettier-ignore
const messages = {
  ACCESS_CONTROL_INVALID_ACTION: (
    <FormattedMessage
      id="error.ACCESS_CONTROL_INVALID_ACTION"
      defaultMessage="error.ACCESS_CONTROL_INVALID_ACTION"
    />
  ),
  ACTION_NAME_INVALID: (
    <FormattedMessage id="error.ACTION_NAME_INVALID" defaultMessage="error.ACTION_NAME_INVALID" />
  ),
  AUTH_LOGIN_OR_PASSOWRD_INCORRECT: (
    <FormattedMessage
      id="error.AUTH_LOGIN_OR_PASSOWRD_INCORRECT"
      defaultMessage="error.AUTH_LOGIN_OR_PASSOWRD_INCORRECT"
    />
  ),
  BACKUP_FILE_CORRUPTED: (
    <FormattedMessage
      id="error.BACKUP_FILE_CORRUPTED"
      defaultMessage="error.BACKUP_FILE_CORRUPTED"
    />
  ),
  BACKUP_NOT_FOUND: (
    <FormattedMessage id="error.BACKUP_NOT_FOUND" defaultMessage="error.BACKUP_NOT_FOUND" />
  ),
  BASIC_AUTH_LOGIN_OR_PASSOWRD_INCORRECT: (
    <FormattedMessage
      id="error.BASIC_AUTH_LOGIN_OR_PASSOWRD_INCORRECT"
      defaultMessage="error.BASIC_AUTH_LOGIN_OR_PASSOWRD_INCORRECT"
    />
  ),
  CONNECTIONS_INVALID_REQUEST_PARAMS: (
    <FormattedMessage
      id="error.CONNECTIONS_INVALID_REQUEST_PARAMS"
      defaultMessage="error.CONNECTIONS_INVALID_REQUEST_PARAMS"
    />
  ),
  CONNECTIONS_NBS_NXT_ERROR: (
    <FormattedMessage
      id="error.CONNECTIONS_NBS_NXT_ERROR"
      defaultMessage="error.CONNECTIONS_NBS_NXT_ERROR"
    />
  ),
  CONNECTIONS_STAFF_NOT_FOUND: (
    <FormattedMessage
      id="error.CONNECTIONS_STAFF_NOT_FOUND"
      defaultMessage="error.CONNECTIONS_STAFF_NOT_FOUND"
    />
  ),
  DNS_SERVERS_VALIDATION_FAILED: (
    <FormattedMessage
      id="error.DNS_SERVERS_VALIDATION_FAILED"
      defaultMessage="error.DNS_SERVERS_VALIDATION_FAILED"
    />
  ),
  FIRMWARE_FILE_ALREADY_EXISTS: (
    <FormattedMessage
      id="error.FIRMWARE_FILE_ALREADY_EXISTS"
      defaultMessage="error.FIRMWARE_FILE_ALREADY_EXISTS"
    />
  ),
  FIRMWARE_FILE_CORRUPTED: (
    <FormattedMessage
      id="error.FIRMWARE_FILE_CORRUPTED"
      defaultMessage="error.FIRMWARE_FILE_CORRUPTED"
    />
  ),
  GEN_INVALID_FILE_NAME: (
    <FormattedMessage
      id="error.GEN_INVALID_FILE_NAME"
      defaultMessage="error.GEN_INVALID_FILE_NAME"
    />
  ),
  GEN_INVALID_MIME_TYPE: (
    <FormattedMessage
      id="error.GEN_INVALID_MIME_TYPE"
      defaultMessage="error.GEN_INVALID_MIME_TYPE"
    />
  ),
  INFO_INVALID_ALIAS: (
    <FormattedMessage id="error.INFO_INVALID_ALIAS" defaultMessage="error.INFO_INVALID_ALIAS" />
  ),
  LICENSE_CORRUPTED: (
    <FormattedMessage id="error.LICENSE_CORRUPTED" defaultMessage="error.LICENSE_CORRUPTED" />
  ),
  LICENSE_FAIL_TO_RESTART_SERVICE: (
    <FormattedMessage
      id="error.LICENSE_FAIL_TO_RESTART_SERVICE"
      defaultMessage="error.LICENSE_FAIL_TO_RESTART_SERVICE"
    />
  ),
  LICENSE_SERIAL_NUMBERS_DIFFER: (
    <FormattedMessage
      id="error.LICENSE_SERIAL_NUMBERS_DIFFER"
      defaultMessage="error.LICENSE_SERIAL_NUMBERS_DIFFER"
    />
  ),
  MAIL_INVALID_CONFIG: (
    <FormattedMessage id="error.MAIL_INVALID_CONFIG" defaultMessage="error.MAIL_INVALID_CONFIG" />
  ),
  MAIL_NO_CONFIG: (
    <FormattedMessage id="error.MAIL_NO_CONFIG" defaultMessage="error.MAIL_NO_CONFIG" />
  ),
  MODULE_DOCKER_API_FAILED: (
    <FormattedMessage
      id="error.MODULE_DOCKER_API_FAILED"
      defaultMessage="error.MODULE_DOCKER_API_FAILED"
    />
  ),
  MODULE_IN_USE: <FormattedMessage id="error.MODULE_IN_USE" defaultMessage="error.MODULE_IN_USE" />,
  MODULE_NOT_FOUND: (
    <FormattedMessage id="error.MODULE_NOT_FOUND" defaultMessage="error.MODULE_NOT_FOUND" />
  ),
  MODULE_UPLOAD_INTERRUPTED: (
    <FormattedMessage
      id="error.MODULE_UPLOAD_INTERRUPTED"
      defaultMessage="error.MODULE_UPLOAD_INTERRUPTED"
    />
  ),
  NET_CIDR_INTERSECTION: (
    <FormattedMessage
      id="error.NET_CIDR_INTERSECTION"
      defaultMessage="error.NET_CIDR_INTERSECTION"
    />
  ),
  NET_CRITICAL_ERROR: (
    <FormattedMessage id="error.NET_CRITICAL_ERROR" defaultMessage="error.NET_CRITICAL_ERROR" />
  ),
  NET_DHCP_SERVER_ADDRESS_OUT_OF_NETWORK: (
    <FormattedMessage
      id="error.NET_DHCP_SERVER_ADDRESS_OUT_OF_NETWORK"
      defaultMessage="error.NET_DHCP_SERVER_ADDRESS_OUT_OF_NETWORK"
    />
  ),
  NET_DHCP_SERVER_RANGES_NOT_FOUND: (
    <FormattedMessage
      id="error.NET_DHCP_SERVER_RANGES_NOT_FOUND"
      defaultMessage="error.NET_DHCP_SERVER_RANGES_NOT_FOUND"
    />
  ),
  NET_DHCP_SERVER_RANGES_OVERLAP: (
    <FormattedMessage
      id="error.NET_DHCP_SERVER_RANGES_OVERLAP"
      defaultMessage="error.NET_DHCP_SERVER_RANGES_OVERLAP"
    />
  ),
  NET_DHCP_SERVER_RANGE_ADDRESSES_ARE_REVERSED: (
    <FormattedMessage
      id="error.NET_DHCP_SERVER_RANGE_ADDRESSES_ARE_REVERSED"
      defaultMessage="error.NET_DHCP_SERVER_RANGE_ADDRESSES_ARE_REVERSED"
    />
  ),
  NET_IFACE_SETUP_FORBIDDEN: (
    <FormattedMessage
      id="error.NET_IFACE_SETUP_FORBIDDEN"
      defaultMessage="error.NET_IFACE_SETUP_FORBIDDEN"
    />
  ),
  NET_INVALID_BOND_SLAVE: (
    <FormattedMessage
      id="error.NET_INVALID_BOND_SLAVE"
      defaultMessage="error.NET_INVALID_BOND_SLAVE"
    />
  ),
  NET_INVALID_VLAN_ID: (
    <FormattedMessage id="error.NET_INVALID_VLAN_ID" defaultMessage="error.NET_INVALID_VLAN_ID" />
  ),
  NET_IPROUTE_ERROR: (
    <FormattedMessage id="error.NET_IPROUTE_ERROR" defaultMessage="error.NET_IPROUTE_ERROR" />
  ),
  NET_ROUTE_GATEWAY_UNREACHABLE: (
    <FormattedMessage
      id="error.NET_ROUTE_GATEWAY_UNREACHABLE"
      defaultMessage="error.NET_ROUTE_GATEWAY_UNREACHABLE"
    />
  ),
  NET_ROUTE_NETWORK_MASK_INVALID: (
    <FormattedMessage
      id="error.NET_ROUTE_NETWORK_MASK_INVALID"
      defaultMessage="error.NET_ROUTE_NETWORK_MASK_INVALID"
    />
  ),
  NET_TRIAL_ERROR: (
    <FormattedMessage id="error.NET_TRIAL_ERROR" defaultMessage="error.NET_TRIAL_ERROR" />
  ),
  NET_VALIDATION_ERROR: (
    <FormattedMessage id="error.NET_VALIDATION_ERROR" defaultMessage="error.NET_VALIDATION_ERROR" />
  ),
  NET_VLAN_ID_INTERSECTION: (
    <FormattedMessage
      id="error.NET_VLAN_ID_INTERSECTION"
      defaultMessage="error.NET_VLAN_ID_INTERSECTION"
    />
  ),
  NET_VLAN_INVALID_MASTER: (
    <FormattedMessage
      id="error.NET_VLAN_INVALID_MASTER"
      defaultMessage="error.NET_VLAN_INVALID_MASTER"
    />
  ),
  RESOURCE_NAME_INVALID: (
    <FormattedMessage
      id="error.RESOURCE_NAME_INVALID"
      defaultMessage="error.RESOURCE_NAME_INVALID"
    />
  ),
  ROLE_HAS_INHERITOR: (
    <FormattedMessage id="error.ROLE_HAS_INHERITOR" defaultMessage="error.ROLE_HAS_INHERITOR" />
  ),
  ROLE_INVALID_CONFIG: (
    <FormattedMessage id="error.ROLE_INVALID_CONFIG" defaultMessage="error.ROLE_INVALID_CONFIG" />
  ),
  SERVICE_ALREADY_STARTED: (
    <FormattedMessage
      id="error.SERVICE_ALREADY_STARTED"
      defaultMessage="error.SERVICE_ALREADY_STARTED"
    />
  ),
  SERVICE_ALREADY_STOPPED: (
    <FormattedMessage
      id="error.SERVICE_ALREADY_STOPPED"
      defaultMessage="error.SERVICE_ALREADY_STOPPED"
    />
  ),
  SERVICE_INVALID_STATE: (
    <FormattedMessage
      id="error.SERVICE_INVALID_STATE"
      defaultMessage="error.SERVICE_INVALID_STATE"
    />
  ),
  SERVICE_NOT_FOUND: (
    <FormattedMessage id="error.SERVICE_NOT_FOUND" defaultMessage="error.SERVICE_NOT_FOUND" />
  ),
  SERVICE_SHUTDOWN_FORBIDDEN: (
    <FormattedMessage
      id="error.SERVICE_SHUTDOWN_FORBIDDEN"
      defaultMessage="error.SERVICE_SHUTDOWN_FORBIDDEN"
    />
  ),
  TIME_CANNOT_CREATE_SYMLINK: (
    <FormattedMessage
      id="error.TIME_CANNOT_CREATE_SYMLINK"
      defaultMessage="error.TIME_CANNOT_CREATE_SYMLINK"
    />
  ),
  TIME_CANNOT_READ_FILE: (
    <FormattedMessage
      id="error.TIME_CANNOT_READ_FILE"
      defaultMessage="error.TIME_CANNOT_READ_FILE"
    />
  ),
  TIME_CANNOT_READ_SYMLINK: (
    <FormattedMessage
      id="error.TIME_CANNOT_READ_SYMLINK"
      defaultMessage="error.TIME_CANNOT_READ_SYMLINK"
    />
  ),
  TIME_CANNOT_RUN_TIMEDATECTL: (
    <FormattedMessage
      id="error.TIME_CANNOT_RUN_TIMEDATECTL"
      defaultMessage="error.TIME_CANNOT_RUN_TIMEDATECTL"
    />
  ),
  TIME_CANNOT_STAT_FILE: (
    <FormattedMessage
      id="error.TIME_CANNOT_STAT_FILE"
      defaultMessage="error.TIME_CANNOT_STAT_FILE"
    />
  ),
  TIME_CANNOT_WRITE_FILE: (
    <FormattedMessage
      id="error.TIME_CANNOT_WRITE_FILE"
      defaultMessage="error.TIME_CANNOT_WRITE_FILE"
    />
  ),
  TIME_FORMAT_INVALID: (
    <FormattedMessage id="error.TIME_FORMAT_INVALID" defaultMessage="error.TIME_FORMAT_INVALID" />
  ),
  TIME_NTP_HOST_INVALID: (
    <FormattedMessage
      id="error.TIME_NTP_HOST_INVALID"
      defaultMessage="error.TIME_NTP_HOST_INVALID"
    />
  ),
  TIME_NTP_SERVICE_STATE_UNKNOWN: (
    <FormattedMessage
      id="error.TIME_NTP_SERVICE_STATE_UNKNOWN"
      defaultMessage="error.TIME_NTP_SERVICE_STATE_UNKNOWN"
    />
  ),
  TIME_TIMEZONE_NOT_EXISTS: (
    <FormattedMessage
      id="error.TIME_TIMEZONE_NOT_EXISTS"
      defaultMessage="error.TIME_TIMEZONE_NOT_EXISTS"
    />
  ),
  TOKEN_DATA_CORRUPTED: (
    <FormattedMessage id="error.TOKEN_DATA_CORRUPTED" defaultMessage="error.TOKEN_DATA_CORRUPTED" />
  ),
  TOKEN_NOT_FOUND: (
    <FormattedMessage id="error.TOKEN_NOT_FOUND" defaultMessage="error.TOKEN_NOT_FOUND" />
  ),
  UPLOAD_ABORTED: (
    <FormattedMessage id="error.UPLOAD_ABORTED" defaultMessage="error.UPLOAD_ABORTED" />
  ),
  UPLOAD_ALREADY_FINISHED: (
    <FormattedMessage
      id="error.UPLOAD_ALREADY_FINISHED"
      defaultMessage="error.UPLOAD_ALREADY_FINISHED"
    />
  ),
  UPLOAD_CANNOT_BE_INTERRUPTED: (
    <FormattedMessage
      id="error.UPLOAD_CANNOT_BE_INTERRUPTED"
      defaultMessage="error.UPLOAD_CANNOT_BE_INTERRUPTED"
    />
  ),
  UPLOAD_NOT_FOUND: (
    <FormattedMessage id="error.UPLOAD_NOT_FOUND" defaultMessage="error.UPLOAD_NOT_FOUND" />
  ),
  UPLOAD_NOT_RUNNING: (
    <FormattedMessage id="error.UPLOAD_NOT_RUNNING" defaultMessage="error.UPLOAD_NOT_RUNNING" />
  ),
  USER_ALREADY_EXISTS: (
    <FormattedMessage id="error.USER_ALREADY_EXISTS" defaultMessage="error.USER_ALREADY_EXISTS" />
  ),
  USER_CANNOT_CHANGE_ROLES: (
    <FormattedMessage
      id="error.USER_CANNOT_CHANGE_ROLES"
      defaultMessage="error.USER_CANNOT_CHANGE_ROLES"
    />
  ),
  USER_NOT_FOUND: (
    <FormattedMessage id="error.USER_NOT_FOUND" defaultMessage="error.USER_NOT_FOUND" />
  ),
  USER_VALIDATION_ERROR: (
    <FormattedMessage
      id="error.USER_VALIDATION_ERROR"
      defaultMessage="error.USER_VALIDATION_ERROR"
    />
  ),
  VTUN_EXEC_FAILED: (
    <FormattedMessage id="error.VTUN_EXEC_FAILED" defaultMessage="error.VTUN_EXEC_FAILED" />
  ),
  VTUN_INVALID_CONFIG: (
    <FormattedMessage id="error.VTUN_INVALID_CONFIG" defaultMessage="error.VTUN_INVALID_CONFIG" />
  ),
  VTUN_INVALID_SETTINGS: (
    <FormattedMessage
      id="error.VTUN_INVALID_SETTINGS"
      defaultMessage="error.VTUN_INVALID_SETTINGS"
    />
  ),
  VTUN_NETUP_TUNNEL_NOT_ALLOWED: (
    <FormattedMessage
      id="error.VTUN_NETUP_TUNNEL_NOT_ALLOWED"
      defaultMessage="error.VTUN_NETUP_TUNNEL_NOT_ALLOWED"
    />
  ),
  WEB_DOWNLOADER_HTTP_ERROR: (
    <FormattedMessage
      id="error.WEB_DOWNLOADER_HTTP_ERROR"
      defaultMessage="error.WEB_DOWNLOADER_HTTP_ERROR"
    />
  ),
  WEB_DOWNLOADER_INFINITE_REDIRECT: (
    <FormattedMessage
      id="error.WEB_DOWNLOADER_INFINITE_REDIRECT"
      defaultMessage="error.WEB_DOWNLOADER_INFINITE_REDIRECT"
    />
  ),
  WEB_DOWNLOADER_INVALID_PROTOCOL: (
    <FormattedMessage
      id="error.WEB_DOWNLOADER_INVALID_PROTOCOL"
      defaultMessage="error.WEB_DOWNLOADER_INVALID_PROTOCOL"
    />
  ),
  WEB_DOWNLOADER_INVALID_URI: (
    <FormattedMessage
      id="error.WEB_DOWNLOADER_INVALID_URI"
      defaultMessage="error.WEB_DOWNLOADER_INVALID_URI"
    />
  ),
};

// prettier-ignore
export function getErrorMessage(error) {
    return messages[error] || error;
}
