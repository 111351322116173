import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type DnsServerConfig =
  | {
      enabled: boolean;
    }
  | undefined;

const dnsServerSlice = createSlice({
  name: 'dnsServer',
  initialState: {} as Record<string, DnsServerConfig>,
  reducers: {
    setDnsServerConfig: (
      state,
      { payload: { interfaceName, value } }: PayloadAction<{ interfaceName: string; value: DnsServerConfig }>
    ) => {
      state[interfaceName] = value;
    },
  },
});

export const { setDnsServerConfig } = dnsServerSlice.actions;

export default dnsServerSlice;
