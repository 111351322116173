import { fetcher } from '../../util/deps';
import {
  SET_ROLES,
  SET_PERMISSION,
  CHANGE_ANCESTORS,
  DISCARD_ROLE_CHANGES,
  SET_UNSAVED_ROLES_CHANGES,
  CLEAR_UNSAVED_ROLES_CHANGES,
} from '../constants';
import { omitAdditionalFields } from '../../util/commonHelpers';

const loadRoles = roleToUpdate => async dispatch => {
  let res = await fetcher.get('role', undefined, false);
  if (res) {
    const roles = omitAdditionalFields(res);
    dispatch({ type: SET_ROLES, payload: { newRoles: roles, roleToUpdate } });
  }
};

const setPermission = (role, resource, prop, value) => ({
  type: SET_PERMISSION,
  payload: { role, resource, prop, value },
});

const changeAncestors = (role, newAncestors) => ({ type: CHANGE_ANCESTORS, payload: { role, newAncestors } });

const discardRoleChanges = role => ({ type: DISCARD_ROLE_CHANGES, payload: role });

const createRole = name => async dispatch => {
  await fetcher.post(`role/grants/${name}`, undefined, false);
  loadRoles(name)(dispatch);
};

const deleteRole = name => async dispatch => {
  await fetcher.delete(`role/grants/${name}`, undefined, false);
  loadRoles(name)(dispatch);
};

const setUnsavedChanges = (role, unsavedChangesExist) => ({
  type: SET_UNSAVED_ROLES_CHANGES,
  payload: [role, unsavedChangesExist],
});

const clearUnsavedChanges = () => ({ type: CLEAR_UNSAVED_ROLES_CHANGES });

export default {
  loadRoles,
  setPermission,
  changeAncestors,
  createRole,
  deleteRole,
  discardRoleChanges,
  setUnsavedChanges,
  clearUnsavedChanges,
};
