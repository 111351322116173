import { omit } from 'lodash';

export const getPermissions = (roles, role) => {
  const own = omit(roles[role], '$extend');
  const getInheritedEntries = role =>
    (roles[role]['$extend'] || []).reduce(
      (acc, parent) => [
        ...acc,
        ...Object.entries(roles[parent])
          .filter(([k, v]) => k !== '$extend' && Object.values(v).some(v => v))
          .map(([k, v]) => [k, { ...v, from: parent }]),
        ...getInheritedEntries(parent),
      ],
      []
    );
  const inherited = getInheritedEntries(role).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: spread(value, acc[key], true) }),
    {}
  );
  return { own, inherited };
};

export const spread = (v1, v2 = {}, rewriteFrom = false) =>
  Object.entries(v1)
    .filter(([key]) => key !== 'from')
    .reduce(
      (acc, [key, value]) =>
        !acc[key]
          ? {
              ...acc,
              [key]: value,
              ...(rewriteFrom && value
                ? {
                    from: !acc.from ? v1.from : acc.from.includes(v1.from) ? acc.from : `${acc.from}, ${v1.from}`,
                  }
                : {}),
            }
          : acc,
      v2
    );

export const transform = value => ({
  r: value.read,
  w: value.write,
  from: value.from,
});
