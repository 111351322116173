import { SET_INFO, SET_INFO_BUILD, SET_INFO_SYSTEM, SET_INFO_ALIAS } from '../constants';

export default (info = {}, action) => {
  switch (action.type) {
    case SET_INFO:
      return Object.assign({}, info, action.payload);
    case SET_INFO_BUILD:
      return Object.assign({}, info, { build: action.payload });
    case SET_INFO_SYSTEM:
      return Object.assign({}, info, { system: action.payload });
    case SET_INFO_ALIAS:
      return Object.assign({}, info, { alias: action.payload });
    default:
      return info;
  }
};
