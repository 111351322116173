import React from 'react';

import ResponsiveDialog from './responsive-dialog';
import ConfirmPhrase from './ConfirmPhrase';

class ConfirmDialogWithPhrase extends React.Component {
  state = { phraseEntered: false };

  setPhraseEntered = () => this.setState({ phraseEntered: true });
  resetPhraseEntered = () => this.setState({ phraseEntered: false });

  render() {
    const {
      open,
      title,
      onClose,
      onConfirm,
      confirmButtonText,
      message,
      children,
      showPhrase = true,
      withSpinner,
      pendingBackendEvent,
    } = this.props;

    return (
      <ResponsiveDialog
        open={open}
        title={title}
        confirmButtonText={confirmButtonText}
        onClose={onClose}
        onConfirm={onConfirm}
        onExited={this.resetPhraseEntered}
        disableConfirm={showPhrase && !this.state.phraseEntered}
        withSpinner={!!withSpinner}
        pendingBackendEvent={pendingBackendEvent}
      >
        {showPhrase && (
          <ConfirmPhrase
            message={message}
            phraseEntered={this.state.phraseEntered}
            setPhraseEntered={this.setPhraseEntered}
            resetPhraseEntered={this.resetPhraseEntered}
          />
        )}
        {children}
      </ResponsiveDialog>
    );
  }
}
export default ConfirmDialogWithPhrase;
