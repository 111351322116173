import { SET_DISK_USAGE } from '../constants';

export default (diskUsage = {}, action) => {
  switch (action.type) {
    case SET_DISK_USAGE:
      return action.payload;
    default:
      return diskUsage;
  }
};
