import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';
import compose from 'recompose/compose';

import { FormattedMessage } from 'react-intl';
import Transition from '../util/DialogTransition';

const styles = theme => ({
  formControl: {
    width: '240px',
    display: 'block',
    marginTop: theme.spacing(1),
  },
  appBar: {
    position: 'relative',
  },
  toolbar: {
    padding: `0 ${theme.spacing(1.5)}px`,
  },
  cancelButton: {
    marginRight: theme.spacing(1),
  },
  spacer: {
    height: theme.spacing(3),
  },
  toolBarTypography: {
    flex: 1,
  },
  error: {
    color: theme.palette.error.main,
  },
});

const initialState = { error: false, name: '', password: '', showPassword: false, allowCreate: false };

class CreateDialog extends React.Component {
  state = { ...initialState };

  handleChangeName = event => {
    const { occupiedNames } = this.props;
    const { value } = event.target;
    const error = value !== '' && occupiedNames.includes(value);
    this.setState({ name: value, error }, this.updateAllowCreate);
  };

  handleChangePassword = event => this.setState({ password: event.target.value }, this.updateAllowCreate);

  updateAllowCreate = () => {
    const { error, password } = this.state;
    const allowCreate = !error && (this.props.type === 'role' || password !== '');
    this.setState({ allowCreate });
  };

  handleClose = () => {
    this.props.handleClose();
    this.setState({ ...initialState });
  };

  handleCreate = () => {
    const { name, password } = this.state;
    this.props.handleCreate(name, password);
    this.setState({ ...initialState });
  };

  handleEnterPressed = event => {
    const code = event.keyCode || event.which;
    if (code === 13 && this.state.allowCreate) {
      this.handleCreate();
    }
  };

  toggleShowPassword = () => this.setState(({ showPassword }) => ({ showPassword: !showPassword }));

  render() {
    const { open, type, fullScreen, classes } = this.props;
    const { name, password, error, showPassword, allowCreate } = this.state;
    const title =
      type === 'role' ? (
        <FormattedMessage id='createDialog.newRole' defaultMessage='New role' />
      ) : (
        <FormattedMessage id='createDialog.newUser' defaultMessage='New user' />
      );
    return (
      <div>
        <Dialog
          open={open}
          onKeyPress={this.handleEnterPressed}
          onClose={this.handleClose}
          fullScreen={fullScreen}
          TransitionComponent={fullScreen ? Transition : undefined}
          fullWidth
        >
          {fullScreen && (
            <AppBar className={classes.appBar}>
              <Toolbar className={classes.toolbar}>
                <IconButton
                  color='inherit'
                  onClick={this.handleClose}
                  aria-label='Close'
                  className={classes.cancelButton}
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant='h6' color='inherit' className={classes.toolBarTypography}>
                  {title}
                </Typography>
                {allowCreate && (
                  <IconButton color='inherit' onClick={this.handleCreate} aria-label='Ok'>
                    <CheckIcon />
                  </IconButton>
                )}
              </Toolbar>
            </AppBar>
          )}
          {!fullScreen && <DialogTitle>{title}</DialogTitle>}
          {fullScreen && <div className={classes.spacer} />}
          <DialogContent>
            <DialogContentText>
              {type === 'role' ? (
                <FormattedMessage id='createDialog.enterRoleName' defaultMessage='Please enter new role name' />
              ) : (
                <FormattedMessage
                  id='createDialog.enterUserName'
                  defaultMessage='Please enter new user name and password'
                />
              )}
            </DialogContentText>
            <FormControl error={error} className={classes.formControl}>
              <InputLabel htmlFor='entries-name'>
                <FormattedMessage id='createDialog.name' defaultMessage='Name' />
              </InputLabel>
              <Input id='entries-name' value={name} onChange={this.handleChangeName} autoFocus fullWidth />
              <FormHelperText>
                {error && <FormattedMessage id='createDialog.nameIsOccupied' defaultMessage='Name is occupied' />}
              </FormHelperText>
            </FormControl>
            {type === 'user' && (
              <FormControl error={error} className={classes.formControl}>
                <InputLabel htmlFor='entries-password'>
                  <FormattedMessage id='createDialog.password' defaultMessage='Password' />
                </InputLabel>
                <Input
                  id='entries-password'
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={this.handleChangePassword}
                  fullWidth
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton onClick={this.toggleShowPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText />
              </FormControl>
            )}
          </DialogContent>
          {!fullScreen && (
            <DialogActions>
              <Button onClick={this.handleClose} color='primary'>
                <FormattedMessage id='createDialog.cancel' defaultMessage='Cancel' />
              </Button>
              <Button onClick={this.handleCreate} color='primary' disabled={!allowCreate}>
                <FormattedMessage id='createDialog.create' defaultMessage='Create' />
              </Button>
            </DialogActions>
          )}
        </Dialog>
      </div>
    );
  }
}

export default compose(withStyles(styles), withMobileDialog())(CreateDialog);
