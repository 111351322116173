import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';

import BatchInstallerImage from './BatchInstallerImage';
import BootstrapLikeAlert from '../BootstrapLikeAlert';
import SemverDialog from '../SemverDialog';

const styles = theme => ({
  grid: {
    display: 'grid',
  },
});

class BatchInstaller extends React.Component {
  render() {
    const { classes, imagesUploaded = [] } = this.props;

    const enableInstallButton =
      !this.props.BIWorking && imagesUploaded.some(image_ => image_.selected && !image_.installingPhase);

    const installableImages = imagesUploaded.filter(
      image_ => !image_.disabled && !image_.duplicate && !image_.installingPhase
    );
    const noInstallableImagesSelected = installableImages.length && installableImages.every(image_ => !image_.selected);

    const someAreDuplicate = imagesUploaded.some(image_ => image_.duplicate);
    const someAreDisabled = imagesUploaded.some(image_ => image_.disabled);

    const selectedImages = imagesUploaded.filter(image_ => image_.selected);

    return (
      <SemverDialog
        open={this.props.open}
        title={<FormattedMessage id='ResponsiveDialog.modules.batchInstaller' defaultMessage='Install modules' />}
        onConfirm={this.props.installSelectedModules}
        disableConfirm={!enableInstallButton}
        confirmButtonText={<FormattedMessage id='ResponsiveDialog.install' defaultMessage='Install' />}
        onClose={this.props.onClose}
        closeMessage={<FormattedMessage id='ResponsiveDialog.close' defaultMessage='Close' />}
        fullWidth
        forceSpinner={this.props.BIWorking}
        dontVanish
        modulesToUpdate={selectedImages}
        alerts={
          <Fragment>
            <BootstrapLikeAlert
              open={!this.props.BIWorking && !!noInstallableImagesSelected}
              message={
                <FormattedMessage
                  id='ResponsiveDialog.modules.batchInstaller.alert.noneSelected'
                  defaultMessage='No modules selected for installation'
                />
              }
              dangerLevel='info'
              pageTopMargins
            />
            <BootstrapLikeAlert
              open={someAreDuplicate}
              message={
                <FormattedMessage
                  id='ResponsiveDialog.modules.batchInstaller.alert.someAreDuplicate'
                  defaultMessage='Some of the modules already exist'
                />
              }
              dangerLevel='info'
              pageTopMargins
            />
            <BootstrapLikeAlert
              open={someAreDisabled}
              message={
                <FormattedMessage
                  id='ResponsiveDialog.modules.batchInstaller.alert.someAreDisabled'
                  defaultMessage='Some of the modules cannot be installed from this dialog'
                />
              }
              dangerLevel='info'
              pageTopMargins
            />
          </Fragment>
        }
        content={
          <div className={classes.grid}>
            {imagesUploaded.map(image_ => {
              return (
                <BatchInstallerImage
                  key={image_.id}
                  image={image_}
                  toggleModuleSelection={this.props.toggleModuleSelection}
                  toggleModuleDetails={this.props.toggleModuleDetails}
                  lockSelection={this.props.BIWorking}
                />
              );
            })}
          </div>
        }
      />
    );
  }
}

export default withStyles(styles)(BatchInstaller);
