import React from 'react';
import { FormattedMessage } from 'react-intl';

function ServiceStateFactory() {
  let state = {};

  state.paused = <FormattedMessage id='modules.services.state.paused' defaultMessage='state.Paused' />;
  state.restarting = <FormattedMessage id='modules.services.state.restarting' defaultMessage='state.Restarting' />;
  state.removing = <FormattedMessage id='modules.services.state.removing' defaultMessage='state.Removing' />;
  state.running = <FormattedMessage id='modules.services.state.running' defaultMessage='state.Running' />;
  state.dead = <FormattedMessage id='modules.services.state.dead' defaultMessage='state.Dead' />;
  state.created = <FormattedMessage id='modules.services.state.created' defaultMessage='state.Created' />;
  state.exited = <FormattedMessage id='modules.services.state.exited' defaultMessage='state.Exited' />;
  state.notAvailable = (
    <FormattedMessage id='modules.services.state.notAvailable' defaultMessage='state.NotAvailable' />
  );

  return state;
}

export default ServiceStateFactory;
