import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ErrorBellIcon from '@material-ui/icons/NotificationImportant';
import DeleteIcon from '@material-ui/icons/Delete';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Badge from '@material-ui/core/Badge';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { errorsActions } from '../redux-stuff/actions';
import ResponsiveDialog from './responsive-dialog';
import ErrorLogEntry from './AppBarErrorBellContainerEntry';
import FileDownload from './customIcons/FileDownload';

const styles = theme => ({
  actionButton: {
    marginRight: theme.spacing(1),
    marginLeft: 0,
  },

  badge: {
    backgroundColor: theme.palette.icons.danger,
  },
});

class ErrorBellContainer extends React.Component {
  state = { errorLogOpen: false, clearAllDialogOpen: false };

  removeError = error => {
    this.props.removeError(error);
  };

  openErrorBellMenu = e => this.setState({ menuAnchor: e.currentTarget });
  closeErrorBellMenu = () => this.setState({ menuAnchor: null });

  openErrorLog = () => {
    this.setState({ errorLogOpen: true });
    this.closeErrorBellMenu();
  };

  openClearAllDialog = () => {
    this.setState({ clearAllDialogOpen: true });
    this.closeErrorBellMenu();
  };

  closeErrorLog = () => this.setState({ errorLogOpen: false });
  closeClearAllDialog = () => this.setState({ clearAllDialogOpen: false });

  clearAll = () => {
    this.props.clearErrors();
    this.closeErrorLog();
  };

  download = () => {
    const { errors } = this.props;

    var a = document.createElement('a');
    var file = new Blob([JSON.stringify(errors, null, 2)], { type: 'application/json' });
    a.href = URL.createObjectURL(file);
    a.download = 'error-log.json';

    document.body.append(a);
    a.click();
    a.remove();

    this.closeErrorBellMenu();
  };

  render() {
    const { classes, errors } = this.props;
    const errorsTotal = errors.length;

    return (
      <Fragment>
        {!!errorsTotal && (
          <IconButton color='inherit' onClick={this.openErrorBellMenu} className={classes.actionButton}>
            <Badge badgeContent={errorsTotal} classes={{ badge: classes.badge }} overlap='rectangular'>
              <ErrorBellIcon />
            </Badge>
          </IconButton>
        )}

        <Menu anchorEl={this.state.menuAnchor} open={!!this.state.menuAnchor} onClose={this.closeErrorBellMenu}>
          <MenuItem onClick={this.openErrorLog}>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText
              className={classes.listItemText}
              primary={<FormattedMessage id='errorsWidget.errorsLog' defaultMessage='Errors log' />}
            />
          </MenuItem>
          <MenuItem onClick={this.openClearAllDialog}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText
              className={classes.listItemText}
              primary={<FormattedMessage id='errorsWidget.clearAllDialog.title' defaultMessage='Clear error log' />}
            />
          </MenuItem>
          <MenuItem onClick={this.download}>
            <ListItemIcon>
              <FileDownload />
            </ListItemIcon>
            <ListItemText
              className={classes.listItemText}
              primary={<FormattedMessage id='errorsWidget.exportLog' defaultMessage='Export error log' />}
            />
          </MenuItem>
        </Menu>

        <ResponsiveDialog
          open={this.state.errorLogOpen}
          title={<FormattedMessage id='errorsWidget.errorsLog' defaultMessage='Errors log' />}
          onClose={this.closeErrorLog}
          withoutConfirm
          disablePaddingForMobile
          fullWidth
          maxWidth='md'
          closeMessage={<FormattedMessage id='ResponsiveDialog.close' defaultMessage='Close' />}
        >
          {/* <div style={{ display: 'grid' }}> */}
          {errors.map(error_ => {
            return (
              <ErrorLogEntry
                key={`${error_.time}${error_.details}${error_.location}`}
                error={error_}
                handleRemoveError={this.removeError}
              />
            );
          })}
          {/* </div> */}
        </ResponsiveDialog>

        <ResponsiveDialog
          open={this.state.clearAllDialogOpen}
          title={<FormattedMessage id='errorsWidget.clearAllDialog.title' defaultMessage='Clear error log' />}
          message={
            <FormattedMessage
              id='errorsWidget.clearAllDialog.conditionalMessage'
              defaultMessage='Are you sure you want to clear the error log?'
            />
          }
          confirmButtonText={<FormattedMessage id='errorsWidget.clearAllDialog.clear' defaultMessage='Clear' />}
          onClose={this.closeClearAllDialog}
          onConfirm={this.clearAll}
          closeOnConfirm
          fullWidth
        />
      </Fragment>
    );
  }
}

export default compose(
  injectIntl,
  connect(({ errors }) => ({ errors }), { ...errorsActions }),
  withStyles(styles)
)(ErrorBellContainer);
