import { SET_LICENSE } from '../constants';

export default (license = null, action) => {
  switch (action.type) {
    case SET_LICENSE:
      return action.payload;
    default:
      return license;
  }
};
