import { FC } from 'react';
import ResponsiveDialog, { ResponsiveDialogProps } from '@/components/responsive-dialog';
import { FormattedMessage } from 'react-intl';

interface CreateInterfaceConfigurationDialogProps {
  open: boolean;
  name: string;
  onClose: ResponsiveDialogProps['onClose'];
  onConfirm: ResponsiveDialogProps['onConfirm'];
  updatingIface?: boolean;
}

const CreateInterfaceConfigurationDialog: FC<CreateInterfaceConfigurationDialogProps> = ({
  open,
  name,
  onClose,
  onConfirm,
  updatingIface,
}) => {
  return (
    <ResponsiveDialog
      open={open}
      title={<FormattedMessage id='networkPage.menuItem.createEmptySettings' defaultMessage='Create empty settings' />}
      message={
        <FormattedMessage
          id='networkPage.confirmCreateInterfaceConfigurationMessage'
          defaultMessage='Are you sure you want to add an empty configuration for {name}?'
          values={{ name }}
        />
      }
      confirmButtonText={<FormattedMessage id='networkPage.button.create' defaultMessage='Create' />}
      onClose={onClose}
      onConfirm={onConfirm}
      withSpinner
      pendingBackendEvent={updatingIface}
    />
  );
};

export default CreateInterfaceConfigurationDialog;
