import { FC, useEffect, useState } from 'react';
import { Fade, Theme, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import LanConnect from '@/components/customIcons/LanConnect';
import LanDisconnect from '@/components/customIcons/LanDisconnect';

const useStyles = makeStyles((theme: Theme) => ({
  linkSpeedContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  linkIcon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
  },
}));

interface LinkSummaryProps {
  show: boolean;
  expanded?: boolean;
  carrier?: boolean;
  speed?: number;
}

const LinkSummary: FC<LinkSummaryProps> = ({ show, expanded, carrier, speed }) => {
  const classes = useStyles();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  useEffect(() => {
    if (expanded) {
      setTooltipOpen(false);
    }
  }, [expanded, setTooltipOpen]);

  if (!show) return null;

  return (
    <Fade in={!expanded}>
      <Typography className={classes.linkSpeedContainer}>
        <Tooltip
          title={
            carrier ? (
              <FormattedMessage id='networkPage.panelSummary.tooltip.linkDetected' defaultMessage='Link detected' />
            ) : (
              <FormattedMessage id='networkPage.panelSummary.tooltip.noLink' defaultMessage='No link' />
            )
          }
          open={tooltipOpen}
          onOpen={() => setTooltipOpen(true)}
          onClose={() => setTooltipOpen(false)}
          disableFocusListener={expanded}
          disableHoverListener={expanded}
          disableTouchListener={expanded}
        >
          {carrier ? <LanConnect className={classes.linkIcon} /> : <LanDisconnect className={classes.linkIcon} />}
        </Tooltip>
        {speed &&
          (speed >= 1000 ? (
            <FormattedMessage
              id='networkPage.panelSummary.speedInGbs'
              defaultMessage='{speed}Gb/s'
              values={{ speed: speed / 1000 }}
            />
          ) : (
            <FormattedMessage
              id='networkPage.panelSummary.speedInMbs'
              defaultMessage='{speed}Mb/s'
              values={{ speed }}
            />
          ))}
      </Typography>
    </Fade>
  );
};

export default LinkSummary;
