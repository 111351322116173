import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Paper from '@material-ui/core/Paper';
import FileUpload from '../components/customIcons/FileUpload';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableToolbar from '../components/TableToolbar';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import InstallIcon from '@material-ui/icons/OpenInNew';
import LaunchIcon from '@material-ui/icons/Launch';
import CancelIcon from '@material-ui/icons/Cancel';
import compose from 'recompose/compose';
import classnames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { firmwareActions, firmwareUploadsActions, infoActions, appBarActions } from '../redux-stuff/actions';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';
import { isEmpty } from 'lodash';
import Collapse from '@material-ui/core/Collapse';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';

import ActionsMenu from '../components/ActionsMenu';
import CustomFab from '../components/CustomFab';
import InfoDialog from '../components/InfoDialogWithTable';
import ResponsiveDialog from '../components/responsive-dialog';
import FileUploader from '../components/FileUploader';
import TablePaginationActions from '../components/TablePaginationActions';
import { snackbarMessageTypes } from '../redux-stuff/constants';
import { fetcher } from '../util/deps';
import { setCookie, getCookie } from '../util/cookie';
import FileOutline from '../components/customIcons/FileOutline';
import store from '../util/store';
import { parsePermissions } from '../util/permissions';
import PageLoadingIndicator from '../components/PageLoadingIndicator';
import { getSize } from '../util/commonHelpers';
import Alerts from '../components/Alerts';
import SemverDialog from '../components/SemverDialog';

const styles = theme => ({
  root: {
    margin: 'auto',
    maxWidth: 1100,
  },
  mobileRoot: {
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    height: `calc(100vh - 56px)`,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: `calc(100vh - 48px)`,
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100vh - 64px)`,
    },
  },
  filenameCell: {
    [theme.breakpoints.only('xs')]: {
      width: 'calc(100vw - 72px)',
      paddingLeft: theme.spacing(1),
    },
  },

  firmwareUploadCheckboxPlaceholder: {
    height: '48px',
  },
  firmwareUploadRowProgressbar: {
    padding: 0,
  },
  firmwareUploadRowProgressbarInsides: {
    marginTop: '-5px',
  },

  tableRow: {
    cursor: 'default',
    '&:hover': {
      backgroundColor: theme.palette.table.hover,
    },
    '& .hover-button': {
      visibility: 'collapse',
    },
    '&:hover .hover-button': {
      visibility: 'visible',
    },
  },
  paper: {
    position: 'relative',
    width: '100%',
  },
  corruptedFile: {
    color: theme.palette.error.main,
  },
  firmwareDetailsTable: {
    tableLayout: 'fixed',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    '& td:first-child': {
      width: '100px',
    },
    '&:not(:last-child)': { marginBottom: '30px' },
  },
  expand: {
    transform: 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(0deg)',
  },
  iconButton: {
    padding: theme.spacing(1),
  },
  modulesInfoSubheading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const datetimeFormat = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

class FirmwarePage extends React.Component {
  state = {
    infoDialogOpen: false,
    installDialogOpen: false,
    deleteDialogOpen: false,
    deleteSelectedDialogOpen: false,
    fileUploadDialogOpen: false,
    managementMenuOpen: false,
    anchorEl: null,
    page: 0,
    rowsPerPage: +getCookie('firmwareFilesPerPage') || 10,
    lastSelectedFirmware: {},
    updating: false,
  };

  static getDerivedStateFromProps(props) {
    const selected = props.firmwareFiles && props.firmwareFiles.filter(f => f.isSelected);

    if (!selected || selected.length !== 1) {
      return null;
    }

    return { lastSelectedFirmware: selected[0] };
  }

  updateAppBarMenu = () => {
    const { permissions } = this.state;
    const { firmwareFiles } = store.getState();
    let selectedCount = firmwareFiles.filter(f => f.isSelected).length;
    let text = null,
      leftButton = null,
      rightButtons = null;

    if (selectedCount) {
      text = (
        <FormattedMessage
          id='firmwarePage.appbarMessage.selected'
          defaultMessage='{selectedCount} selected'
          values={{ selectedCount }}
        />
      );

      leftButton = {
        title: <FormattedMessage id='drawerWrapper.deselectAll' defaultMessage='Undo selection' />,
        icon: <CloseIcon />,
        action: this.undoFirmwareSelection,
      };

      rightButtons = permissions.w
        ? [
            {
              key: 'delete_selected',
              title: <FormattedMessage id='drawerWrapper.deleteSelected' defaultMessage='Delete selected' />,
              icon: <DeleteIcon />,
              action: this.handleDeleteSelected,
            },
          ]
        : [];

      if (permissions.w && selectedCount === 1 && !firmwareFiles.find(f => f.isSelected).corrupted) {
        rightButtons.push({
          key: 'install_selected',
          title: <FormattedMessage id='drawerWrapper.installSelected' defaultMessage='Install selected' />,
          icon: <LaunchIcon />,
          action: this.handleInstallSelected,
        });
      }
    }

    this.actions.setActions({
      text,
      leftButton,
      rightButtons,
    });
  };

  togglePageSelection = displayedFirmwareFiles => () => {
    this.actions.togglePageSelection(displayedFirmwareFiles);
    this.updateAppBarMenu();
  };

  undoFirmwareSelection = () => {
    this.actions.undoFirmwareSelection();
    this.updateAppBarMenu();
  };

  setStateAsync = state =>
    new Promise(resolve => {
      this.setState(state, resolve);
    });

  componentDidMount() {
    const { permissions, dispatch } = this.props;

    this.actions = {
      ...bindActionCreators(firmwareActions, dispatch),
      ...bindActionCreators(firmwareUploadsActions, dispatch),
      ...bindActionCreators(infoActions, dispatch),
      ...bindActionCreators(appBarActions, dispatch),
    };

    this.actions.loadInfo();
    this.actions.loadFirmwareFiles();
    this.actions.getFirmwareUploads();

    this.setState({ permissions: parsePermissions(permissions, 'firmware') });
  }

  componentWillUnmount() {
    this.actions.resetActions();
    this.actions.undoFirmwareSelection();
  }

  handleDeleteSelected = () => this.setState({ deleteSelectedDialogOpen: true });

  handleClose = () =>
    this.setState({
      infoDialogOpen: false,
      installDialogOpen: false,
      deleteDialogOpen: false,
      deleteSelectedDialogOpen: false,
    });

  handleDeleteAccept = () => {
    fetcher.delete(`firmware/${this.state.lastSelectedFirmware.filename}`, undefined, false);
    this.setState({ deleteDialogOpen: false });
  };

  handleDeleteSelectedAccept = async () => {
    const selected = this.props.firmwareFiles.filter(f => f.isSelected);
    await Promise.all(selected.map(f => fetcher.delete(`firmware/${f.filename}`, undefined, false)));
    this.updateAppBarMenu();
    this.setState({ deleteSelectedDialogOpen: false });
  };

  handleToggleSelect = filename => () => {
    this.actions.toggleFirmwareSelection(filename);
    this.updateAppBarMenu();
  };

  handleOpenMenu = firmware => event => {
    event.stopPropagation();
    this.setState({ lastSelectedFirmware: firmware, anchorEl: event.currentTarget, managementMenuOpen: true });
  };

  handleDelete = () => this.setState({ deleteDialogOpen: true, managementMenuOpen: false });

  handleInstall = () => this.setState({ installDialogOpen: true, managementMenuOpen: false });

  handleShowInfo = () => this.setState({ infoDialogOpen: true, managementMenuOpen: false });

  handleCloseMenu = () => this.setState({ managementMenuOpen: false });

  handleChangeRowsPerPage = event => {
    setCookie('firmwareFilesPerPage', event.target.value);
    this.setState({ rowsPerPage: event.target.value });
  };

  handleChangePage = (event, page) => this.setState({ page });

  handleInstallSelected = async () => {
    let [firmware] = this.props.firmwareFiles.filter(f => f.isSelected);
    await this.setStateAsync({ lastSelectedFirmware: firmware });
    this.handleInstall();
  };

  handleInstallAccept = async name => {
    try {
      this.setState({ updating: true });
      await fetcher.put(`firmware/${name}`);
    } catch (e) {
      this.setState({ updating: false });
      this.props.addMessage({
        type: snackbarMessageTypes.failure,
        data: e,
      });
    }
  };

  handleInstallAcceptWrapper = () => this.handleInstallAccept(this.state.lastSelectedFirmware.filename);

  openFileUploader = () => this.state.dialogFrozen || this.setStateAsync({ fileUploadDialogOpen: true });
  closeFileUploader = () => this.state.dialogFrozen || this.setState({ fileUploadDialogOpen: false });

  // drag events won't toggle dialog
  openFileUploaderFreeze = () => this.setStateAsync({ fileUploadDialogOpen: true, dialogFrozen: true });
  closeFileUploaderUnfreeze = () => this.setState({ fileUploadDialogOpen: false, dialogFrozen: false });

  labelDisplayedRows = ({ from, to, count }) => (
    <FormattedMessage
      id='table.paginationLabelDisplayedRows'
      defaultMessage='{from}-{to} of {count}'
      values={{ from, to, count }}
    />
  );

  versionIsOlder = () => {
    if (this.props.buildVersion && this.state.lastSelectedFirmware.info) {
      const currentVersion = this.props.buildVersion;
      const selectedVersion = this.state.lastSelectedFirmware.info.version;

      if (
        (currentVersion.startsWith('trunk') && selectedVersion.startsWith('trunk')) ||
        (currentVersion.startsWith('master') && selectedVersion.startsWith('master'))
      ) {
        const currentVersionNumber = currentVersion.split('.')[1];
        const selectedVersionNumber = selectedVersion.split('.')[1];

        return parseInt(selectedVersionNumber) < parseInt(currentVersionNumber);
      }

      if (currentVersion.startsWith('master') && selectedVersion.startsWith('trunk')) {
        return true;
      }

      if (currentVersion.startsWith('trunk') && selectedVersion.startsWith('master')) {
        return false;
      }

      if (currentVersion.startsWith('trunk') || currentVersion.startsWith('master')) {
        return true;
      }

      return parseFloat(selectedVersion) < parseFloat(currentVersion);
    }

    return false;
  };

  static mapStateToProps({ firmwareFiles, firmwareUploads, info = {}, isSocketConnected }) {
    return {
      firmwareFiles,
      firmwareUploads,
      isSocketConnected,
      buildDate: info.build && info.build.date,
      buildVersion: info.build && info.build.version,
      buildKernelVersion: info.build && info.build.kernel_version,
      buildFirmwareVersion: info.build && info.build.firmware_version,
    };
  }

  showFirmwareUpdateStatus(success) {
    if (success) {
      this.props.addMessage({
        type: snackbarMessageTypes.success,
        data: (
          <FormattedMessage
            id='FirmwarePage.snackbar.firmwareInstallationCompleted'
            defaultMessage='Firmware installation completed'
          />
        ),
      });
    } else {
      this.props.addMessage({
        type: snackbarMessageTypes.failure,
        data: (
          <FormattedMessage
            id='FirmwarePage.snackbar.firmwareInstallationFailed'
            defaultMessage='Firmware installation failed'
          />
        ),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isSocketConnected && this.props.isSocketConnected && this.state.updating) {
      this.setState({ installDialogOpen: false, updating: false }, this.undoFirmwareSelection);
      fetcher
        .get('info')
        .then(info => {
          this.showFirmwareUpdateStatus(
            info && info.build && info.build.version === this.state.lastSelectedFirmware.info.firmware_version
          );
        })
        .catch(() => this.showFirmwareUpdateStatus(false));
    }
  }

  handleInstallByFilename = filename => {
    this.actions.selectOneFirmware(filename);

    this.updateAppBarMenu();
    this.closeFileUploaderUnfreeze();

    this.handleInstall();
  };

  handleDeleteByFilename = filename => {
    this.actions.selectOneFirmware(filename);

    this.updateAppBarMenu();
    this.closeFileUploaderUnfreeze();

    this.handleDeleteSelected();
  };

  handleDeleteCorrupted = fileName => () => this.setState({ lastSelectedFirmware: fileName }, this.handleDelete);

  toggleShowBundledModules = () => this.setState(prev => ({ modulesInfoExpanded: !prev.modulesInfoExpanded }));

  interruptFirmwareUpload = id => {
    this.actions.interruptFirmwareUpload(id);
    this.actions.loadFirmwareFiles();
  };

  getInterruptUploadHandler = id => () => this.interruptFirmwareUpload(id);

  render() {
    const { firmwareUploads, classes, width, addMessage, buildDate, buildVersion, buildKernelVersion, intl } =
      this.props;
    let firmwareFiles = this.props.firmwareFiles;

    if (firmwareFiles && firmwareUploads) {
      const firmwareUploadsFilenames = firmwareUploads.map(upload_ => upload_.meta.partial);
      firmwareFiles = firmwareFiles.filter(({ filename }) => !firmwareUploadsFilenames.includes(filename));
    }

    const {
      infoDialogOpen,
      installDialogOpen,
      deleteDialogOpen,
      deleteSelectedDialogOpen,
      page,
      fileUploadDialogOpen,
      rowsPerPage,
      lastSelectedFirmware,
      managementMenuOpen,
      anchorEl,
      updating,
      permissions = {},
      modulesInfoExpanded,
    } = this.state;

    // what is or was selected last
    const lastSelectedFirmwareFilename = lastSelectedFirmware.filename;
    const lastSelectedFirmwareInfo = lastSelectedFirmware.info || {};
    let lastSelectedFirmwareModules = lastSelectedFirmware.modules || {};
    lastSelectedFirmwareModules = Object.entries(lastSelectedFirmwareModules).map(([mName, mDetails]) => {
      return { name: mName, ...mDetails };
    });

    const { w } = permissions;
    const xs = width === 'xs';
    const mdUp = !xs && width !== 'sm';
    const mobile = !mdUp || isMobile;
    const selected = firmwareFiles ? firmwareFiles.filter(f => f.isSelected) : [];
    const selectedCount = selected.length;
    const displayPagination = width === 'lg' || width === 'xl';
    let firmwareFilesToBeDisplayed = [];
    let selectedCorrupted;
    if (firmwareFiles) {
      firmwareFilesToBeDisplayed = displayPagination
        ? firmwareFiles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : firmwareFiles;
      selectedCorrupted = selectedCount === 1 && firmwareFiles.find(f => f.isSelected).corrupted;
    }
    const { loadFirmwareFiles } = this.actions || {};

    // what is installed and being used right now === active firmware
    let activeFirmwareStr = null;
    let dateStr = null;
    let activeFirmwareBuildDate;
    if (buildDate && buildVersion && buildKernelVersion) {
      activeFirmwareBuildDate = new Date(buildDate);
      dateStr = intl.formatDate(activeFirmwareBuildDate, datetimeFormat);
      activeFirmwareStr = (
        <FormattedMessage
          id='firmwarePage.currentFirmwareInfo.subheadingNew'
          defaultMessage='Version: {version}, date: {date}, kernel version: {kernel}'
          values={{
            version: buildVersion,
            date: dateStr,
            kernel: buildKernelVersion,
          }}
        />
      );
    }

    const firmwareFieldNames = {
      filename: <FormattedMessage id='firmwareInfoTable.firmwareInfo.filename.' defaultMessage='firmware.filename' />,
      version: <FormattedMessage id='firmwareInfoTable.firmwareInfo.version' defaultMessage='firmware.version' />,
      date: <FormattedMessage id='firmwareInfoTable.firmwareInfo.date' defaultMessage='firmware.date' />,
      size: <FormattedMessage id='firmwareInfoTable.firmwareInfo.size' defaultMessage='firmware.size' />,
      kernelVersion: (
        <FormattedMessage id='firmwareInfoTable.firmwareInfo.kernel_version' defaultMessage='firmware.kernelVersion' />
      ),
    };
    const firmwareModulesFieldNames = {
      name: <FormattedMessage id='firmwareInfoTable.moduleInfo.name.' defaultMessage='module.name' />,
      version: <FormattedMessage id='firmwareInfoTable.moduleInfo.version.' defaultMessage='module.version' />,
      build: <FormattedMessage id='firmwareInfoTable.moduleInfo.build' defaultMessage='module.build' />,
      revision: <FormattedMessage id='firmwareInfoTable.moduleInfo.revision' defaultMessage='module.revision' />,
      build_datetime: (
        <FormattedMessage id='firmwareInfoTable.moduleInfo.build_datetime' defaultMessage='module.build_datetime' />
      ),
    };

    const infoDialogFields =
      buildVersion && buildDate && buildKernelVersion
        ? [
            ['version', firmwareFieldNames.version, buildVersion],
            ['date', firmwareFieldNames.date, dateStr],
            ['kernel_version', firmwareFieldNames.kernelVersion, buildKernelVersion],
          ]
        : [];
    const infoDialogTitle = (
      <FormattedMessage id='ResponsiveDialog.firmwareInfoTable.title' defaultMessage='Current firmware info' />
    );

    return (
      <div className={classnames(classes.root, mobile ? classes.mobileRoot : '')}>
        <Alerts issuesPermission={parsePermissions(this.props.permissions, 'issues').r} />
        <PageLoadingIndicator open={firmwareFiles === null} />

        {firmwareFiles !== null && (
          <Paper className={classes.paper}>
            <TableToolbar
              handleDelete={this.handleDeleteSelected}
              handleInstall={this.handleInstallSelected}
              handleShowInfo={this.handleShowInfo}
              numSelected={selectedCount}
              selectedCorrupted={selectedCorrupted}
              numTotal={firmwareFiles.length}
              caption={activeFirmwareStr}
              subheading='firmware'
              xs={xs}
              isMobile={isMobile}
              w={w}
            />
            <Fragment>
              <Table size='small'>
                {!xs && (
                  <TableHead>
                    <TableRow>
                      {w && (
                        <TableCell padding='checkbox'>
                          {firmwareFilesToBeDisplayed.length > 0 && (
                            <Checkbox
                              checked={firmwareFilesToBeDisplayed.every(f => f.isSelected)}
                              onChange={this.togglePageSelection(firmwareFilesToBeDisplayed)}
                              indeterminate={
                                firmwareFilesToBeDisplayed.some(f => f.isSelected) &&
                                firmwareFilesToBeDisplayed.some(f => !f.isSelected)
                              }
                              color='primary'
                            />
                          )}
                        </TableCell>
                      )}
                      <TableCell>
                        <Typography variant='caption'>
                          <FormattedMessage
                            id='firmwarePage.tableHeading.firmwareName'
                            defaultMessage='Firmware Name'
                          />
                        </Typography>
                      </TableCell>
                      <TableCell align='right'>
                        <Typography variant='caption'>
                          <FormattedMessage id='firmwarePage.tableHeading.version' defaultMessage='Firmware Version' />
                        </Typography>
                      </TableCell>
                      <TableCell align='right'>
                        <Typography variant='caption'>
                          <FormattedMessage
                            id='firmwarePage.tableHeading.kernel_version'
                            defaultMessage='Kernel Version'
                          />
                        </Typography>
                      </TableCell>
                      {mdUp && (
                        <TableCell>
                          <Typography variant='caption'>
                            <FormattedMessage id='firmwarePage.tableHeading.created' defaultMessage='Created' />
                          </Typography>
                        </TableCell>
                      )}
                      {mdUp && (
                        <TableCell>
                          <Typography variant='caption'>
                            <FormattedMessage id='firmwarePage.tableHeading.size' defaultMessage='Size' />
                          </Typography>
                        </TableCell>
                      )}
                      {!mobile && w && <TableCell align='right' />}
                    </TableRow>
                  </TableHead>
                )}
                <TableBody>
                  {firmwareUploads &&
                    firmwareUploads.map(firmwareUpload => {
                      const { id, info } = firmwareUpload;
                      const partialFilename = firmwareUpload.meta.partial;

                      const progress = Math.floor((info.received / info.estimated) * 100) || 1;
                      const interruptUploadHandler = this.getInterruptUploadHandler(id);

                      return (
                        <Fragment>
                          <TableRow key={id} className={classes.tableRow}>
                            <TableCell padding='checkbox'>
                              {(mobile && w && (
                                <Tooltip
                                  title={
                                    <FormattedMessage
                                      id='firmwarePage.firmwareUploads.abortTooltip'
                                      defaultMessage='Abort upload'
                                    />
                                  }
                                >
                                  <IconButton onClick={interruptUploadHandler}>
                                    <CancelIcon />
                                  </IconButton>
                                </Tooltip>
                              )) || <div className={classes.firmwareUploadCheckboxPlaceholder} />}
                            </TableCell>

                            <TableCell className={classes.filenameCell}>
                              <Typography>{partialFilename}</Typography>
                            </TableCell>

                            {!xs && (
                              <Fragment>
                                <TableCell align='right'>
                                  {info.uploadFinished ? (
                                    <Typography>
                                      <FormattedMessage
                                        id='inlineUploads.status.processing'
                                        defaultMessage='processing..'
                                      />
                                    </Typography>
                                  ) : (
                                    <Typography>
                                      <FormattedMessage
                                        id='inlineUploads.status.uploading'
                                        defaultMessage='{progress}% complete'
                                        values={{ progress }}
                                      />
                                    </Typography>
                                  )}
                                </TableCell>

                                <TableCell />
                              </Fragment>
                            )}
                            {mdUp && <TableCell padding='none' />}
                            {mdUp && <TableCell padding='none' />}

                            {!mobile && w && (
                              <TableCell padding='none' align='right'>
                                <Tooltip
                                  title={
                                    <FormattedMessage
                                      id='firmwarePage.firmwareUploads.abortTooltip'
                                      defaultMessage='Abort upload'
                                    />
                                  }
                                >
                                  <IconButton onClick={interruptUploadHandler}>
                                    <CancelIcon className={isMobile ? '' : 'hover-button'} />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            )}
                          </TableRow>
                          <tr key={`${id}-progress`} className={classes.tableRow}>
                            <td className={classes.firmwareUploadRowProgressbar} colSpan={6 + mdUp}>
                              <LinearProgress
                                variant='determinate'
                                value={progress}
                                className={classes.firmwareUploadRowProgressbarInsides}
                              />
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}

                  {firmwareFilesToBeDisplayed.map(firmware => {
                    const { filename, stat = {}, info, isSelected = false, corrupted } = firmware;
                    const { version, kernel_version, date } = info || {};
                    const localizedDate = intl.formatDate(date || null, datetimeFormat);
                    return (
                      <TableRow
                        selected={isSelected}
                        key={filename}
                        className={classes.tableRow}
                        onClick={w ? this.handleToggleSelect(filename) : null}
                      >
                        {(xs || w) && (
                          <TableCell padding='checkbox'>
                            {xs && selectedCount === 0 ? (
                              <IconButton className={classes.checkbox}>
                                <FileOutline />
                              </IconButton>
                            ) : (
                              <Checkbox checked={isSelected} className={classes.checkbox} color='primary' />
                            )}
                          </TableCell>
                        )}
                        <TableCell className={classes.filenameCell}>
                          <div className={classes.cell}>
                            <Typography className={corrupted ? classes.corruptedFile : ''}>{filename}</Typography>
                            {xs && !corrupted && (
                              <Fragment>
                                <Typography className={classes.secondaryColor}>
                                  <FormattedMessage
                                    id='firmwarePage.versionWithDate'
                                    defaultMessage='Firmware Version: {version} ({date})'
                                    values={{ version, date: localizedDate }}
                                  />
                                </Typography>
                                <Typography className={classes.secondaryColor}>
                                  <FormattedMessage
                                    id='firmwarePage.kernel_version'
                                    defaultMessage='Kernel Version: {kernel_version}'
                                    values={{ kernel_version }}
                                  />
                                </Typography>
                              </Fragment>
                            )}
                          </div>
                        </TableCell>
                        {!xs && (
                          <Fragment>
                            <TableCell align='right'>
                              {!corrupted && (mdUp ? version : `${version} (${localizedDate})`)}
                            </TableCell>
                            <TableCell align='right'>{!corrupted && kernel_version}</TableCell>
                          </Fragment>
                        )}
                        {mdUp && <TableCell>{!corrupted && localizedDate}</TableCell>}
                        {mdUp && <TableCell>{!corrupted && getSize(stat.size)}</TableCell>}
                        {!mobile && w && (
                          <TableCell padding='none' align='right'>
                            {selectedCount === 0 && !corrupted && (
                              <IconButton onClick={this.handleOpenMenu(firmware)}>
                                <MoreVertIcon className={isMobile ? '' : 'hover-button'} />
                              </IconButton>
                            )}
                            {selectedCount === 0 && corrupted && (
                              <IconButton onClick={this.handleDeleteCorrupted(firmware)}>
                                <DeleteIcon className={isMobile ? '' : 'hover-button'} />
                              </IconButton>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
                {displayPagination && (
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        count={firmwareFiles.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                        labelDisplayedRows={this.labelDisplayedRows}
                        labelRowsPerPage={<FormattedMessage id='table.rowsPerPage' defaultMessage='Rows per page' />}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                )}
              </Table>
              {w && (
                <ActionsMenu
                  open={managementMenuOpen}
                  anchorEl={anchorEl}
                  handleClose={this.handleCloseMenu}
                  items={[
                    {
                      show: w,
                      Icon: InstallIcon,
                      text: <FormattedMessage id='ActionsMenu.install' defaultMessage='Install' />,
                      handler: this.handleInstall,
                    },
                    {
                      show: w,
                      Icon: DeleteIcon,
                      text: <FormattedMessage id='ActionsMenu.delete' defaultMessage='Delete' />,
                      handler: this.handleDelete,
                    },
                  ]}
                />
              )}
            </Fragment>
          </Paper>
        )}

        {w && (
          <SemverDialog
            open={installDialogOpen}
            title={<FormattedMessage id='ResponsiveDialog.installFirmwareTitle' defaultMessage='Install firmware' />}
            confirmButtonText={<FormattedMessage id='ResponsiveDialog.install' defaultMessage='Install' />}
            oldFirmware={new Date(lastSelectedFirmwareInfo.date) < activeFirmwareBuildDate}
            oldVersionFirmware={this.versionIsOlder()}
            onClose={this.handleClose}
            onConfirm={this.handleInstallAcceptWrapper}
            withSpinner
            pendingBackendEvent={updating}
            nextFirmware={lastSelectedFirmware}
            content={
              <Fragment>
                <Typography variant='subtitle1' color='textSecondary'>
                  <FormattedMessage id='firmwareInfoTable.subheading.firmwareInfo' defaultMessage='Firmware details' />
                </Typography>

                <Table padding='none' className={classes.firmwareDetailsTable}>
                  <TableBody>
                    <TableRow>
                      <TableCell>{firmwareFieldNames.filename}</TableCell>
                      <TableCell>{lastSelectedFirmwareFilename}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>{firmwareFieldNames.version}</TableCell>
                      <TableCell>{lastSelectedFirmwareInfo.version}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>{firmwareFieldNames.kernelVersion}</TableCell>
                      <TableCell>{lastSelectedFirmwareInfo.kernel_version}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>{firmwareFieldNames.date}</TableCell>
                      <TableCell>{intl.formatDate(lastSelectedFirmwareInfo.date || null, datetimeFormat)}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>{firmwareFieldNames.size}</TableCell>
                      <TableCell>{lastSelectedFirmware.stat && getSize(lastSelectedFirmware.stat.size)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                {!isEmpty(lastSelectedFirmwareModules) && (
                  <Fragment>
                    <div className={classes.modulesInfoSubheading}>
                      <Typography>
                        <FormattedMessage
                          id='firmwareInfoTable.subheading.modulesInfo'
                          defaultMessage='Module details'
                        />
                      </Typography>
                      <IconButton
                        className={classnames(
                          classes.expand,
                          {
                            [classes.expandOpen]: this.state.modulesInfoExpanded,
                          },
                          classes.iconButton
                        )}
                        onClick={this.toggleShowBundledModules}
                        aria-expanded={modulesInfoExpanded}
                      >
                        <ExpandLessIcon />
                      </IconButton>
                    </div>

                    <Collapse in={modulesInfoExpanded}>
                      {lastSelectedFirmwareModules.map((module_, i) => {
                        return (
                          <Table key={i} padding='checkbox' className={classes.firmwareDetailsTable}>
                            <TableBody>
                              <TableRow>
                                <TableCell>{firmwareModulesFieldNames.name}</TableCell>
                                <TableCell>{module_.name}</TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell>{firmwareModulesFieldNames.version}</TableCell>
                                <TableCell>{module_.version}</TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell>{firmwareModulesFieldNames.build}</TableCell>
                                <TableCell>{module_.build}</TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell>{firmwareModulesFieldNames.revision}</TableCell>
                                <TableCell>{module_.revision}</TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell>{firmwareModulesFieldNames.build_datetime}</TableCell>
                                <TableCell>{intl.formatDate(module_.build_datetime, datetimeFormat)}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        );
                      })}
                    </Collapse>
                  </Fragment>
                )}
              </Fragment>
            }
          />
        )}
        <InfoDialog
          open={infoDialogOpen}
          mobile={mobile}
          fields={infoDialogFields}
          title={infoDialogTitle}
          handleClose={this.handleClose}
        />
        {w && (
          <ResponsiveDialog
            open={deleteDialogOpen}
            title={<FormattedMessage id='ResponsiveDialog.deleteFirmwareTitle' defaultMessage='Delete firmware' />}
            message={
              <FormattedMessage
                id='ResponsiveDialog.deleteFirmwareMessage'
                defaultMessage='Are you sure you want to delete {lastSelectedFirmwareFilename}?'
                values={{ lastSelectedFirmwareFilename }}
              />
            }
            confirmButtonText={<FormattedMessage id='ResponsiveDialog.delete' defaultMessage='Delete' />}
            onClose={this.handleClose}
            onConfirm={this.handleDeleteAccept}
          />
        )}
        {w && (
          <ResponsiveDialog
            open={deleteSelectedDialogOpen}
            title={
              <FormattedMessage
                id='ResponsiveDialog.multipleFirmwareFilesTitle'
                defaultMessage='Delete selected firmware files'
              />
            }
            message={
              <FormattedMessage
                id='ResponsiveDialog.multipleFirmwareFilesMessage'
                defaultMessage={`Are you sure you want to delete {number, plural, one {# firmware file} other {# firmware files}}?`}
                values={{ number: selected.length }}
              />
            }
            confirmButtonText={<FormattedMessage id='ResponsiveDialog.delete' defaultMessage='Delete' />}
            onClose={this.handleClose}
            onConfirm={this.handleDeleteSelectedAccept}
          />
        )}

        {w && firmwareFiles && (
          <CustomFab
            label={<FormattedMessage id='firmwarePage.fabLabel.upload' defaultMessage='Upload' />}
            onClick={this.openFileUploaderFreeze}
            icon={FileUpload}
          />
        )}
        {w && firmwareFiles && (
          <FileUploader
            open={fileUploadDialogOpen}
            onClose={this.closeFileUploaderUnfreeze}
            onOpenDnD={this.openFileUploader}
            onCloseDnD={this.closeFileUploader}
            reloadFiles={loadFirmwareFiles}
            addMessage={addMessage}
            resource='firmware'
            handleInstall={this.handleInstallByFilename}
            handleDelete={this.handleDeleteByFilename}
            title={<FormattedMessage id='firmwareUploader.title' defaultMessage='Firmware upload' />}
          />
        )}
      </div>
    );
  }
}

FirmwarePage.propTypes = {
  classes: PropTypes.object.isRequired,
  firmwareFiles: PropTypes.array,
  current: PropTypes.string,
};

export default connect(FirmwarePage.mapStateToProps)(
  compose(withStyles(styles), withWidth(), injectIntl)(FirmwarePage)
);
