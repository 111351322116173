import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

export default createSvgIcon(
  <g>
    <path
      d={
        'm11.004 2.6328v4.8105h-2.9414l1.9687 1.9629 1.9688 1.9648 ' +
        '1.9688-1.9648 1.9688-1.9629h-2.9434v-4.8105zm-6.3887 ' +
        '6.1523-2.0625 4.7676 0.013672 0.0039a3.4645 3.4645 0 0 0-0.28125 ' +
        '1.3457 3.4645 3.4645 0 0 0 0.019531 0.35742c-0.00712 ' +
        '0.06735-0.019531 0.13186-0.019531 0.20117v3.9902c0 1.06 ' +
        '0.85409 1.916 1.9141 1.916h15.604c1.06 0 1.9121-0.85604 ' +
        '1.9121-1.916v-3.9902c0-0.07409-0.01337-0.14495-0.02148-0.2168a3.4645 ' +
        '3.4645 0 0 0 0.02148-0.3418 3.4645 3.4645 0 0 ' +
        '0-0.51953-1.8125l-1.8613-4.3047h-2.2246l2.0586 ' +
        '4.7637h-14.385l2.0586-4.7637zm13.174 7.1758a1.4974 ' +
        '1.4974 0 0 1 1.4961 1.4961 1.4974 1.4974 0 0 1-1.4961 ' +
        '1.498 1.4974 1.4974 0 0 1-1.498-1.498 1.4974 1.4974 0 0 1 1.498-1.4961z'
      }
    />
  </g>,
  'firmware install'
);
