import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';
import compose from 'recompose/compose';
import { FormattedMessage } from 'react-intl';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';

import { fetcher } from '../util/deps';
import Transition from './util/DialogTransition';

const styles = theme => ({
  formControl: {
    marginTop: theme.spacing(1),
    display: 'block',
    maxWidth: '300px',
  },
  appBar: {
    position: 'relative',
  },
  toolbar: {
    padding: `0 ${theme.spacing(1.5)}px`,
  },
  cancelButton: {
    marginRight: theme.spacing(1),
  },
  spacer: {
    height: theme.spacing(3),
  },
  toolBarTypography: {
    flex: 1,
  },
  error: {
    color: theme.palette.error.main,
  },
});

const initialState = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',

  currentPasswordVisible: false,
  newPasswordVisible: false,

  errorCurrentPasswordIsIncorrect: false,
  errorNewPasswordIsTheSame: false,
  errorConfirmPasswordMismatch: false,

  allowApply: false,
};

class CreateDialog extends React.Component {
  state = { ...initialState };

  updateAllowApply = () => {
    const { currentPassword, newPassword, confirmPassword, newPasswordVisible, errorCurrentPasswordIsIncorrect } =
      this.state;

    const errorNewPasswordIsTheSame = !!(currentPassword && newPassword && currentPassword === newPassword);
    const errorConfirmPasswordMismatch = !!(
      !newPasswordVisible &&
      newPassword &&
      confirmPassword &&
      newPassword !== confirmPassword
    );
    const neccessaryFieldsFilled = !!(currentPassword && newPassword && (confirmPassword || newPasswordVisible));

    const allowApply =
      neccessaryFieldsFilled &&
      !errorCurrentPasswordIsIncorrect &&
      !errorNewPasswordIsTheSame &&
      !errorConfirmPasswordMismatch;

    this.setState({ errorNewPasswordIsTheSame, errorConfirmPasswordMismatch, allowApply });
  };

  onExited = () => this.setState(initialState);

  toggleCurrentPasswordVisible = () =>
    this.setState(({ currentPasswordVisible }) => ({ currentPasswordVisible: !currentPasswordVisible }));
  toggleNewPasswordVisible = () =>
    this.setState(({ newPasswordVisible }) => ({ newPasswordVisible: !newPasswordVisible }), this.updateAllowApply);

  handleChangeCurrentPassword = event =>
    this.setState(
      { currentPassword: event.target.value, errorCurrentPasswordIsIncorrect: false },
      this.updateAllowApply
    );
  handleChangeNewPassword = event => this.setState({ newPassword: event.target.value }, this.updateAllowApply);
  handleChangeConfirmPassword = event => this.setState({ confirmPassword: event.target.value }, this.updateAllowApply);

  handleKeyDown = ({ key }) => {
    if (key === 'Enter' && this.state.allowApply) {
      this.handleAcceptChanges();
      return;
    }

    if (key === 'Escape') {
      this.props.onClose();
    }
  };

  handleAcceptChanges = async () => {
    try {
      await fetcher.put('profile', { password: this.state.currentPassword, newPassword: this.state.newPassword });
      this.props.addMessage({
        type: 'success',
        data: <FormattedMessage id='ChangePasswordDialog.passwordWasChanged' defaultMessage='Password updated' />,
      });

      this.props.onClose();
    } catch (e) {
      if (e.errorMessage === '401 Unauthorized') {
        this.setState({ errorCurrentPasswordIsIncorrect: true }, this.updateAllowApply);
        return;
      }

      this.props.addMessage({
        type: 'failure',
        data: e,
      });
    }
  };

  render() {
    const { open, onClose, fullScreen, classes } = this.props;
    const title = <FormattedMessage id='ChangePasswordDialog.title' defaultMessage='Set new password' />;

    return (
      <div>
        <Dialog
          open={open}
          onKeyDown={this.handleKeyDown}
          onClose={onClose}
          TransitionProps={{
            onExited: this.onExited,
          }}
          fullScreen={fullScreen}
          fullWidth
          TransitionComponent={fullScreen ? Transition : undefined}
        >
          {fullScreen && (
            <AppBar className={classes.appBar}>
              <Toolbar className={classes.toolbar}>
                <IconButton color='inherit' onClick={onClose} aria-label='Close' className={classes.cancelButton}>
                  <CloseIcon />
                </IconButton>
                <Typography variant='h6' color='inherit' className={classes.toolBarTypography}>
                  {title}
                </Typography>
                <IconButton
                  color='inherit'
                  disabled={!this.state.allowApply}
                  onClick={this.handleAcceptChanges}
                  aria-label='Ok'
                >
                  <CheckIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
          )}

          {!fullScreen && <DialogTitle>{title}</DialogTitle>}

          {fullScreen && <div className={classes.spacer} />}

          <DialogContent>
            <FormControl className={classes.formControl}>
              <TextField
                error={this.state.errorCurrentPasswordIsIncorrect}
                label={<FormattedMessage id='ChangePasswordDialog.currentPassword' defaultMessage='Old password' />}
                type={this.state.currentPasswordVisible ? 'text' : 'password'}
                value={this.state.currentPassword}
                onChange={this.handleChangeCurrentPassword}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={this.toggleCurrentPasswordVisible}>
                        {this.state.currentPasswordVisible ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <FormHelperText error={this.state.errorCurrentPasswordIsIncorrect}>
                {this.state.errorCurrentPasswordIsIncorrect && (
                  <FormattedMessage
                    id='snackbar.error.changePasswordDialogWrongCurrentPassword'
                    defaultMessage='Old password is incorrect'
                  />
                )}
              </FormHelperText>
            </FormControl>

            <FormControl className={classes.formControl}>
              <TextField
                error={this.state.errorConfirmPasswordMismatch || this.state.errorNewPasswordIsTheSame}
                label={<FormattedMessage id='ChangePasswordDialog.newPassword' defaultMessage='New password' />}
                type={this.state.newPasswordVisible ? 'text' : 'password'}
                value={this.state.newPassword}
                onChange={this.handleChangeNewPassword}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={this.toggleNewPasswordVisible}>
                        {this.state.newPasswordVisible ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <FormHelperText error={this.state.errorNewPasswordIsTheSame}>
                {this.state.errorNewPasswordIsTheSame && (
                  <FormattedMessage
                    id='ChangePasswordDialog.alert.newPasswordIsTheSame'
                    defaultMessage='New password cannot be the same as the old one'
                  />
                )}
              </FormHelperText>
            </FormControl>

            <Collapse in={!this.state.newPasswordVisible}>
              <FormControl className={classes.formControl}>
                <TextField
                  error={this.state.errorConfirmPasswordMismatch}
                  label={<FormattedMessage id='ChangePasswordDialog.confirmPassword' defaultMessage='Verify' />}
                  type='password'
                  value={this.state.confirmPassword}
                  onChange={this.handleChangeConfirmPassword}
                  fullWidth
                />

                <FormHelperText error={this.state.errorConfirmPasswordMismatch}>
                  {this.state.errorConfirmPasswordMismatch && (
                    <FormattedMessage
                      id='ChangePasswordDialog.alert.passwordsMismatch'
                      defaultMessage="Passwords don't match"
                    />
                  )}
                </FormHelperText>
              </FormControl>
            </Collapse>
          </DialogContent>

          {!fullScreen && (
            <DialogActions>
              <Button onClick={onClose} color='primary'>
                <FormattedMessage id='ChangePasswordDialog.buttons.cancel' defaultMessage='Cancel' />
              </Button>
              <Button onClick={this.handleAcceptChanges} color='primary' disabled={!this.state.allowApply}>
                <FormattedMessage id='ChangePasswordDialog.buttons.set' defaultMessage='Set' />
              </Button>
            </DialogActions>
          )}
        </Dialog>
      </div>
    );
  }
}

export default compose(withStyles(styles), withMobileDialog())(CreateDialog);
