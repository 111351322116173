import { SET_MODULES_UPLOADS } from '../constants';

export default (modulesUploads = null, action) => {
  switch (action.type) {
    case SET_MODULES_UPLOADS:
      return action.payload;
    default:
      return modulesUploads;
  }
};
