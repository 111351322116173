import { SET_DRAWER_STATE, TOGGLE_DRAWER_STATE } from '../constants';

export interface DrawerStateType {
  expanded: boolean;
  openNested: String[];
}

export type DrawerActionType =
  | {
      type: typeof SET_DRAWER_STATE;
      payload: DrawerStateType;
    }
  | { type: typeof TOGGLE_DRAWER_STATE };

export default (
  drawerState: DrawerStateType | undefined = { expanded: false, openNested: [] },
  action: DrawerActionType
) => {
  switch (action.type) {
    case SET_DRAWER_STATE:
      return { ...drawerState, ...action.payload };
    case TOGGLE_DRAWER_STATE:
      localStorage.setItem('drawerOpen', String(!drawerState.expanded));
      return { ...drawerState, expanded: !drawerState.expanded };
    default:
      return drawerState;
  }
};
