import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

interface ButtonWithProgressProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  showProgress?: boolean;
  className?: string;
}

const ButtonWithProgress: FC<ButtonWithProgressProps> = ({ showProgress, children: Button, className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.wrapper, className ? className : '')} {...rest}>
      {Button}
      {showProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
};

export default ButtonWithProgress;
