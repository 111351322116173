import React, { Fragment } from 'react';
import compose from 'recompose/compose';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SettingsInputComposite from '@material-ui/icons/SettingsInputComposite';
import Dns from '@material-ui/icons/Dns';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Home from '@material-ui/icons/Home';
import Time from '@material-ui/icons/QueryBuilder';
import Collapse from '@material-ui/core/Collapse';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import CustomLink from '../components/CustomLink';
import { isEmpty } from 'lodash';
import ErrorIcon from '@material-ui/icons/Error';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';

import FirmwareInstall from '../components/customIcons/FirmwareInstall';
import DataBackup from '../components/customIcons/DataBackup';
import WiredNetwork from '../components/customIcons/WiredNetwork';
import RouterIcon from '../components/customIcons/Router';
import LicenseIcon from '@material-ui/icons/VpnKey';
import { havePermission } from '../util/permissions';
import uriMap from '../util/uriMap';

const makePages = permissions =>
  isEmpty(permissions)
    ? []
    : [
        { uri: '/', icon: <Home /> },
        { uri: '/modules', icon: <Dns /> },
        { uri: '/firmware', icon: <FirmwareInstall /> },
        { uri: '/backup', icon: <DataBackup /> },
        { uri: '/license', icon: <LicenseIcon /> },
        { uri: '/connections', icon: <SettingsInputComposite /> },
        { uri: '/network', icon: <WiredNetwork /> },
        { uri: '/routing', icon: <RouterIcon /> },
        { uri: '/users-roles', icon: <SupervisorAccount /> },
        { uri: '/time', icon: <Time /> },
      ].filter(({ uri }) => uri === '/' || havePermission(permissions, uri.slice(1)));

const styles = theme => ({
  item: {
    transition: 'padding 0.3s',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
  },
  nested: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  selectedListItem: {
    '&, &:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
    },
    '& $primary, & $icon': {
      color: theme.palette.common.white,
    },
  },
  icon: {},
  primary: {},
  chevron: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  listItemText: {
    paddingRight: '0',
  },
  rightIcon: {
    marginRight: 0,
  },
  badge: {
    top: 1,
    right: -15,
    backgroundColor: 'inherit',
  },
  // should remove it when https://github.com/mui-org/material-ui/issues/14021 will be resolved
  listItemIcon: {
    marginLeft: theme.spacing(),
  },
});

class DrawerItems extends React.Component {
  state = { modulesServicesUri: '/modules', usersRolesUri: '/users-roles' };

  componentDidUpdate() {
    const { pathname } = this.props.location;
    if (pathname === '/modules/modules' || pathname === '/modules/services') {
      if (this.state.modulesServicesUri !== pathname) {
        this.setState({ modulesServicesUri: pathname });
      }
    } else if (pathname === '/users-roles/users' || pathname === '/users-roles/roles') {
      if (this.state.usersRolesUri !== pathname) {
        this.setState({ usersRolesUri: pathname });
      }
    }
  }

  render() {
    const {
      classes,
      currentPage,
      openNested,
      toggleExpandNested,
      handleDrawerToggle,
      permissions,
      licenseIssue,
      servicesIssue,
      drawerExpanded,
      location,
      lgUp,
    } = this.props;
    const makeDrawerList = (items, nested = false) =>
      items.map(({ uri, icon, children }) => {
        const displayIssue = (uri === '/license' && licenseIssue) || (uri === '/modules' && servicesIssue);
        const colorIssue = currentPage !== '/license';
        const displayIssueBadge = displayIssue && !drawerExpanded;
        const displayListItemTooltip = !drawerExpanded;

        let linkTo = uri;
        if (linkTo === '/modules') {
          linkTo = this.state.modulesServicesUri;
        } else if (linkTo === '/users-roles') {
          linkTo = this.state.usersRolesUri;
        }
        return !children ? (
          <CustomLink replace={uri === location.pathname} to={linkTo} key={uri}>
            <Tooltip title={(displayListItemTooltip && uriMap[uri].pageName()) || ''} placement='right'>
              <ListItem
                button
                className={classnames(
                  classes.item,
                  nested && classes.nested,
                  currentPage === uri && classes.selectedListItem
                )}
                onClick={handleDrawerToggle}
              >
                <ListItemIcon className={classnames(lgUp ? classes.listItemIcon : '', classes.icon)}>
                  {displayIssueBadge ? (
                    <Badge
                      badgeContent={<ErrorIcon color={colorIssue ? 'error' : 'inherit'} />}
                      color='primary'
                      classes={{ badge: classes.badge }}
                    >
                      {icon}
                    </Badge>
                  ) : (
                    icon
                  )}
                </ListItemIcon>

                <ListItemText
                  className={classes.listItemText}
                  classes={{ primary: classes.primary }}
                  primaryTypographyProps={{ noWrap: true, color: 'textPrimary' }}
                >
                  {uriMap[uri].pageName()}
                </ListItemText>
                {displayIssue && (
                  <ListItemIcon classes={{ root: classes.rightIcon }} className={classes.icon}>
                    <ErrorIcon color={colorIssue ? 'error' : 'inherit'} />
                  </ListItemIcon>
                )}
              </ListItem>
            </Tooltip>
          </CustomLink>
        ) : (
          <Fragment key={uri}>
            <ListItem
              button
              onClick={toggleExpandNested(uri)}
              className={classnames(classes.item, currentPage === uri && classes.selectedListItem)}
            >
              <ListItemIcon className={classnames(lgUp ? classes.listItemIcon : '', classes.icon)}>{icon}</ListItemIcon>
              <ListItemText primary={uriMap[uri].pageName()} />
              {openNested.includes(uri) ? (
                <ExpandLess className={classes.chevron} />
              ) : (
                <ExpandMore className={classes.chevron} />
              )}
            </ListItem>
            <Collapse in={openNested.includes(uri)}>{makeDrawerList(children, true)}</Collapse>
          </Fragment>
        );
      });
    return makeDrawerList(makePages(permissions));
  }
}

export default compose(withStyles(styles), withRouter)(DrawerItems);
