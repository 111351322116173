import { defineMessages } from 'react-intl';
export * from './info';
export * from './issues';
export * from './smart';
export * from './load';
export * from './firmware';
export * from './firmwareUploads';
export * from './backup';
export * from './network';
export * from './connections';
export * from './snackbarMessageTypes';
export * from './drawer';
export * from './appBar';
export * from './modules';
export * from './modulesUploads';
export * from './services';
export * from './roles';
export * from './resources';
export * from './users';
export * from './license';
export * from './widgets';
export * from './auth';
export * from './socket';
export * from './errors';
export * from './vtun';
export * from './diskUsage';
export * from './dns';
export * from './reboot';

export const USER_LOGOUT = Symbol();

export const unloadMessage = defineMessages({
  message: {
    id: 'unloadSnackbarMessage',
    defaultMessage: `Are you sure you want to leave this page? There are unsaved changes`,
  },
});
