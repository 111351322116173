import { FC, MouseEvent, useState } from 'react';
import { Box, Fade, IconButton, IconButtonProps, Tooltip } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Delete, MoreVert } from '@material-ui/icons';
import ActionsMenu from '@/components/ActionsMenu';
import CodeBraces from '@/components/customIcons/CodeBraces';
import CreateInterfaceConfigurationDialog from './CreateInterfaceConfigurationDialog';
import RemoveInterfaceConfigurationDialog from './RemoveInterfaceConfigurationDialog';

interface InterfaceActionsMenuProps {
  show: boolean;
  expanded?: boolean;
  name: string;
  haveConfig: boolean;
  handleRemoveInterface(): void;
  handleCreateEmptyConfig?(): void;
  removingIface?: boolean;
  updatingIface?: boolean;
}

const InterfaceActionsMenu: FC<InterfaceActionsMenuProps> = ({
  show,
  expanded,
  name,
  haveConfig,
  handleRemoveInterface,
  handleCreateEmptyConfig,
  removingIface,
  updatingIface,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const [confirmRemoveDialogOpen, setConfirmRemoveDialogOpen] = useState(false);
  const [confirmCreateDialogOpen, setConfirmCreateDialogOpen] = useState(false);

  const handleOpenMenu: IconButtonProps['onClick'] = e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleCloseActionsMenu = (e: Event) => {
    e.stopPropagation();
    setAnchorEl(undefined);
  };

  const handleOpenConfirmRemoveDialog = (e: MouseEvent) => {
    e.stopPropagation();
    setConfirmRemoveDialogOpen(true);
    setAnchorEl(undefined);
  };

  const handleOpenConfirmCreateDialog = (e: MouseEvent) => {
    e.stopPropagation();
    setConfirmCreateDialogOpen(true);
    setAnchorEl(undefined);
  };

  if (!show) return null;

  return (
    <>
      <Box flexGrow={1} />
      <Fade in={expanded}>
        <Tooltip
          title={<FormattedMessage id='networkPage.moreActions' defaultMessage='More actions' />}
          disableFocusListener={!expanded}
          disableHoverListener={!expanded}
          disableTouchListener={!expanded}
        >
          <span>
            <IconButton onClick={handleOpenMenu} disabled={!expanded}>
              <MoreVert />
            </IconButton>
          </span>
        </Tooltip>
      </Fade>
      <ActionsMenu
        open={!!anchorEl}
        anchorEl={anchorEl}
        handleClose={handleCloseActionsMenu}
        items={[
          {
            show: haveConfig,
            Icon: Delete,
            text: (
              <FormattedMessage
                id='networkPage.removeInterfaceConfigurationTitle'
                defaultMessage='Remove interface configuration'
              />
            ),
            handler: handleOpenConfirmRemoveDialog,
          },
          {
            show: !haveConfig && !!handleCreateEmptyConfig,
            Icon: CodeBraces,
            text: (
              <FormattedMessage id='networkPage.menuItem.createEmptySettings' defaultMessage='Create empty settings' />
            ),
            handler: handleOpenConfirmCreateDialog,
          },
        ]}
      />
      <RemoveInterfaceConfigurationDialog
        open={confirmRemoveDialogOpen}
        onClose={() => setConfirmRemoveDialogOpen(false)}
        onConfirm={handleRemoveInterface}
        name={name}
        removingIface={removingIface}
      />
      {handleCreateEmptyConfig && (
        <CreateInterfaceConfigurationDialog
          open={confirmCreateDialogOpen}
          onClose={() => setConfirmCreateDialogOpen(false)}
          onConfirm={handleCreateEmptyConfig}
          name={name}
          updatingIface={updatingIface}
        />
      )}
    </>
  );
};

export default InterfaceActionsMenu;
