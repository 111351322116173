import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Tooltip } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const styles = theme => ({
  item: {
    display: 'flex',
    alignItems: 'stretch',
    flexGrow: 1,
    maxWidth: 'inherit',
  },
  card: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'stretch',
    flexGrow: 1,
  },
  cardText: {
    color: theme.palette.mainPageCards.text,
  },
  cardContent: {
    padding: '24px 12px',
    flexGrow: 1,
  },
  cardSvg: {
    alignSelf: 'flex-end',
    flexShrink: 0,
    fill: 'rgba(255, 255, 255, .7)',
  },
  link: {
    display: 'flex',
    alignItems: 'stretch',
    flexGrow: 1,
    textDecoration: 'none',
  },
  dvb: {
    backgroundColor: theme.palette.mainPageCards.dvb,
  },
  systemSettings: {
    backgroundColor: theme.palette.mainPageCards.systemSettings,
  },
  relay: {
    backgroundColor: theme.palette.mainPageCards.relay,
  },
  middleware: {
    backgroundColor: theme.palette.mainPageCards.middleware,
  },
  vod: {
    backgroundColor: theme.palette.mainPageCards.vod,
  },
  default: {
    backgroundColor: theme.palette.mainPageCards.default,
  },
  greyout: {
    backgroundColor: '#eee',
    fill: '#ccc',
    color: '#ccc',
  },
  streamProcessor: {
    backgroundColor: theme.palette.mainPageCards.streamProcessor,
  },
});

const MenuCard = ({ classes, uri, type, name, SvgComponent, greyout, customTitle }) => {
  const classActive = classes[type] || classes.default;

  const card = (
    <Card className={classNames(classes.card, !greyout && classActive, greyout && classes.greyout)}>
      <CardContent className={classes.cardContent}>
        <Typography variant='h6' className={classNames(!greyout && classes.cardText, greyout && classes.greyout)}>
          {name}
        </Typography>
      </CardContent>
      {SvgComponent && <SvgComponent className={classNames(classes.cardSvg, greyout && classes.greyout)} />}
    </Card>
  );

  const stoppedMessage = <FormattedMessage id='mainPage.componentStopped' defaultMessage='This component is stopped' />;

  return (
    <Tooltip title={greyout ? customTitle || stoppedMessage : ''} placement='top'>
      <Grid item xs={12} sm={6} className={classes.item}>
        {greyout ? (
          card
        ) : uri === '/modules' ? (
          <Link className={classes.link} to={uri}>
            {card}
          </Link>
        ) : (
          <a className={classes.link} href={uri}>
            {card}
          </a>
        )}
      </Grid>
    </Tooltip>
  );
};

export default withStyles(styles)(MenuCard);
