import { FC, useMemo, useState } from 'react';
import { FormControlLabel, Switch, FormControl, SwitchProps, Theme, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import CopyPasteField from '@/components/CopyPasteField';
import CustomSelect from './form-elements/CustomSelect';
import WsProxyControl from './ws-proxy-control';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 170,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
  },
  copyPasteField: {
    marginTop: 0,
    marginBottom: '20px',
  },
}));

interface NetupTunnelDetailsProps {
  enabled: boolean;
  handleEnabledChange: SwitchProps['onChange'];
  host?: string;
  port?: number;
  setHost(newHost?: string): void;
  setPort(newPort?: number): void;
  ifaceIp?: string;
  loadNetwork?(): void;
  tunnelState?: string;
}

const NetupTunnelDetails: FC<NetupTunnelDetailsProps> = ({
  enabled,
  handleEnabledChange,
  host,
  port,
  setHost,
  setPort,
  ifaceIp,
  loadNetwork,
  tunnelState,
}) => {
  const classes = useStyles();
  const wsProxyEnabled = useMemo(() => host === '127.0.0.1', [host]);
  const [oldHost, setOldHost] = useState(host);
  const [oldPort, setOldPort] = useState(port);

  const handleWsProxyChange: SwitchProps['onChange'] = (_, checked) => {
    if (checked) {
      setOldHost(host);
      setOldPort(port);
      setHost('127.0.0.1');
      setPort(5000);
      return;
    }

    if (oldHost === '127.0.0.1') {
      setHost(undefined);
      setPort(undefined);
      return;
    }

    setHost(oldHost);
    setPort(oldPort);
  };

  return (
    <>
      <FormControlLabel
        control={<Switch checked={enabled} onChange={handleEnabledChange} color='primary' />}
        label={
          <FormattedMessage id='vtunPanel.label.grantSupportAccess' defaultMessage='Grant access to NetUP support' />
        }
      />
      <FormControlLabel
        control={<Switch checked={wsProxyEnabled} onChange={handleWsProxyChange} color='primary' />}
        label={<FormattedMessage id='vtunPanel.label.useBrowserAsProxy' defaultMessage='Use browser as proxy' />}
      />
      <div className={classes.container}>
        {!wsProxyEnabled && (
          <>
            <CustomSelect
              id='host'
              label={<FormattedMessage id='vtunPanels.label.host' defaultMessage='Host' />}
              options={['tuns.netup.tv', '91.213.249.11']}
              value={host}
              onChange={setHost}
            />
            <CustomSelect
              id='port'
              label={<FormattedMessage id='vtunPanels.label.port' defaultMessage='Port' />}
              options={[5000, 443]}
              value={port}
              onChange={setPort}
            />
          </>
        )}
        {ifaceIp && (
          <FormControl className={classes.formControl}>
            <CopyPasteField
              label={<FormattedMessage id='vtunPanels.label.local_ip' defaultMessage='Local IP' />}
              value={ifaceIp}
              className={classes.copyPasteField}
            />
          </FormControl>
        )}
      </div>
      <WsProxyControl loadNetwork={loadNetwork} tunnelState={tunnelState} />
    </>
  );
};

export default NetupTunnelDetails;
