import { SET_BACKUPS, ADD_BACKUP, REMOVE_BACKUP } from '../constants';

export default (backups = null, action) => {
  switch (action.type) {
    case SET_BACKUPS:
      return action.payload;
    case ADD_BACKUP:
      return [action.payload, ...backups];
    case REMOVE_BACKUP:
      return backups.filter(item => item.filename !== action.payload);
    default:
      return backups;
  }
};
