import React from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import stacktrace from 'stacktrace-js';

import uriMap from '../util/uriMap';
import { havePermission } from '../util/permissions';
import { fetcher } from '../util/deps';
import AppBar from './AppBar';
import Drawer from './Drawer';
import Main from './Main';
import { authActions, socketActions, rebootActions, servicesActions } from '../redux-stuff/actions';
import store from '../util/store';
import SnackbarContainer from '../components/common/SnackbarContainer';
import ErrorDetails from '../components/common/ErrorDetails';
import ErrorBoundaryWrapper from '../components/ErrorBoundaryWrapper';
import emitter from '../emitter';
import Listener from '../event-listeners';
import WsClient from '../util/wsclient';

const styles = {
  root: {
    flexGrow: 1,
    zIndex: 1,
  },
};

const allPages = Object.keys(uriMap).filter(Boolean);
const pagesWithoutDrawer = ['/', '/login'];

class AppWrapper extends React.Component {
  state = { pageError: false, snackbarDetailsModalContents: null };

  connectWs = () => {
    const { setIsSocketConnected, setIsRebooting } = this.props;
    this.wsClient = new WsClient(new URL('api/events', window.location).pathname);
    this.listener = new Listener(this.wsClient);
    this.socket = this.wsClient.connect();
    setIsSocketConnected(this.socket.readyState === this.socket.OPEN);
    this.socket.onopen = () => {
      setIsSocketConnected(true);
      setIsRebooting(false);
    };
    this.socket.onclose = () => setIsSocketConnected(false);
    this.listener.enable();
  };

  disconnectWs = () => {
    this.socket.onopen = null;
    this.socket.onclose = null;
    this.listener.disable();
    this.wsClient.disconnect();
  };

  componentDidCatch(error) {
    // prettier-ignore
    stacktrace
      .fromError(error)
      .then(
        stackframes => {
          const errorMessage = error.message || <FormattedMessage id='error.CRITICAL_ERROR' defaultMessage='Something went wrong :('/>;
          const error_ = new Error(errorMessage);
          error_.errorMessage = errorMessage;

          error_.details = stackframes.map(
            frame => {
              return `${frame.functionName} in ${frame.fileName}:${frame.lineNumber},${frame.columnNumber}`;
            }
          );

          error_.time = new Date();

          this.addMessage({ type: 'failure', data: error_ });
        }
      );

    console.error(error); // eslint-disable-line
    this.setState({ pageError: true });
  }

  initialLoad = async () => {
    this.setState({ permissions: undefined });
    let isAuth;
    try {
      const profile = await fetcher.get('profile');
      isAuth = true;
      const { permissions, username } = profile || {};
      if (havePermission(permissions, 'services')) {
        await this.props.loadServices();
      }
      this.connectWs();
      this.setState({ permissions, username });
    } catch (e) {
      isAuth = false;
    }
    this.props.setIsAuth(isAuth);
    return isAuth;
  };

  async componentDidMount() {
    let prevIsAuth = await this.initialLoad();
    store.subscribe(() => {
      const { isAuth } = store.getState();
      if (!isAuth && prevIsAuth) {
        this.disconnectWs();
      } else if (!prevIsAuth && isAuth) {
        this.initialLoad();
      }
      prevIsAuth = isAuth;
    });
  }

  openSnackbarDetailsModal = snackbarDetailsModalContents =>
    this.setState({ snackbarDetailsModalContents, snackbarDetailsOpen: true });

  closeSnackbarDetailsModal = () => this.setState({ snackbarDetailsOpen: false });

  addMessage = message => emitter.emit('addMessage', message);
  addMessageInjectLocationIfError = location => message => {
    if (message.data instanceof Error) {
      message.data.location = location;
    }
    this.addMessage(message);
  };

  componentDidUpdate(prevProps, prevState) {
    // Reset error after page change
    if (prevState.pageError && this.state.pageError) {
      this.setState({ pageError: false });
    }
  }

  render() {
    const { classes, socket, isAuth } = this.props;
    const { permissions, username, snackbarDetailsModalContents, snackbarDetailsOpen, pageError, isConnected } =
      this.state;

    return (
      <div className={classes.root}>
        <ErrorBoundaryWrapper
          allPages={allPages}
          pagesWithoutDrawer={pagesWithoutDrawer}
          permissions={permissions}
          setIsAuth={this.props.setIsAuth}
          addMessage={this.addMessage}
          username={username}
          socket={socket}
          pageError={pageError}
          isConnected={isConnected}
        >
          {AppBar}
        </ErrorBoundaryWrapper>
        <ErrorBoundaryWrapper permissions={permissions} pageError={pageError}>
          {Drawer}
        </ErrorBoundaryWrapper>
        {/*Initial isAuth === null*/}
        {isAuth !== null && (
          <Main pagesWithoutDrawer={pagesWithoutDrawer} pageError={pageError}>
            {(permissions || !isAuth) && (
              <Switch>
                {Object.entries(uriMap).map(([location, { exact, Component }]) => {
                  let res = props => {
                    if (isAuth || location === '/login') {
                      const page = location.slice(1);

                      if (pagesWithoutDrawer.includes(location) || havePermission(permissions, page)) {
                        return (
                          <Component
                            {...props}
                            addMessage={this.addMessageInjectLocationIfError(location)}
                            emitter={emitter}
                            permissions={permissions}
                          />
                        );
                      } else {
                        return allPages.includes(location) ? (
                          <FormattedMessage
                            id='pageNames.accessDeny'
                            defaultMessage="You don't have permission to access this page"
                          />
                        ) : (
                          <FormattedMessage id='pageNames.pageDoesNotExist' defaultMessage="Page doesn't exist" />
                        );
                      }
                    } else {
                      return <Redirect to={{ pathname: '/login', state: { referrer: location } }} />;
                    }
                  };

                  return (
                    <Route key={location} exact={!!exact} path={location.length ? location : undefined} render={res} />
                  );
                })}
              </Switch>
            )}
          </Main>
        )}
        <SnackbarContainer events={emitter} openDetails={this.openSnackbarDetailsModal} />
        <ErrorDetails
          open={snackbarDetailsOpen}
          error={snackbarDetailsModalContents}
          onClose={this.closeSnackbarDetailsModal}
        />
      </div>
    );
  }
}

AppWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  injectIntl,
  withRouter,
  connect(({ isAuth }) => ({ isAuth }), {
    setIsAuth: authActions.setIsAuth,
    setIsSocketConnected: socketActions.setIsSocketConnected,
    setIsRebooting: rebootActions.setIsRebooting,
    loadServices: servicesActions.loadServices,
  }),
  withStyles(styles)
)(AppWrapper);
