import { SET_LOAD } from '../constants';

export default (load = null, action) => {
  switch (action.type) {
    case SET_LOAD:
      return action.payload;
    default:
      return load;
  }
};
