import { ComponentType, FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem, { MenuItemProps } from '@material-ui/core/MenuItem';
import ListItemText, { ListItemTextProps } from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

const useStyles = makeStyles(() => ({
  listItemText: {
    '& span': { whiteSpace: 'initial' },
  },
}));

export interface ActionsMenuProps {
  open: MenuProps['open'];
  anchorEl: MenuProps['anchorEl'];
  handleClose: MenuProps['onClose'];
  items: {
    show: boolean;
    Icon: ComponentType;
    text: ListItemTextProps['primary'];
    handler: MenuItemProps['onClick'];
  }[];
}

const ActionsMenu: FC<ActionsMenuProps> = ({ open, anchorEl, handleClose, items }) => {
  const classes = useStyles();

  return (
    <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
      {items
        .filter(e => e.show)
        .map(({ Icon, text, handler }, i) => (
          <MenuItem key={i} onClick={handler}>
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText className={classes.listItemText} primary={text} />
          </MenuItem>
        ))}
    </Menu>
  );
};

export default ActionsMenu;
