import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import LaunchIcon from '@material-ui/icons/Launch';
import RestoreIcon from '@material-ui/icons/Restore';
import InfoIcon from '@material-ui/icons/Info';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

const toolbarStyles = theme => ({
  root: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    borderBottom: `1px solid ${theme.palette.borderColor}`,
  },
  highlight: {
    color: theme.palette.text.primary,
    backgroundColor: lighten(theme.palette.text.primary, 0.9),
  },
  textContainer: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
  },
  iconButton: {
    marginLeft: theme.spacing(1),
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoTextContainer: {
    display: 'inline-flex',
    flexDirection: 'column',
  },
});

const FirmwareDeleteToolbar = props => {
  const {
    numSelected,
    selectedCorrupted,
    numTotal,
    classes,
    handleDelete,
    handleInstall,
    handleDownload,
    handleRestore,
    handleShowInfo,
    forbidDelete,
    caption,
    subheading,
    xs,
    isMobile,
    w,
    children,
  } = props;
  const changeDisplayOnSelection = !xs && !isMobile && numSelected > 0;
  const oneSelected = numSelected === 1;
  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: changeDisplayOnSelection,
      })}
    >
      <div className={classes.textContainer}>
        {changeDisplayOnSelection ? (
          <Typography color='inherit' variant='subtitle1'>
            <FormattedMessage
              id='firmwareToolbar.selectedOfMessage'
              defaultMessage='{numSelected} selected of {numTotal}'
              values={{ numSelected, numTotal }}
            />
          </Typography>
        ) : (
          <div className={classes.infoContainer}>
            <div className={classes.infoTextContainer}>
              <Typography variant='subtitle1'>
                {subheading === 'firmware' && (
                  <FormattedMessage id='firmwareToolbar.title' defaultMessage='Currently installed firmware' />
                )}
                {subheading === 'backup' && <FormattedMessage id='backupToolbar.title' defaultMessage='Last backup' />}
                {subheading === 'license' && (
                  <FormattedMessage id='licenseToolbar.title' defaultMessage='Active license' />
                )}
              </Typography>
              <Typography variant='caption'>{caption}</Typography>
              {children}
            </div>
            {subheading === 'firmware' && (
              <Tooltip title={<FormattedMessage id='firmwarePage.tableToolbar.info' defaultMessage='Info' />}>
                <IconButton aria-label='Info' onClick={handleShowInfo} className={classes.iconButton}>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        )}
      </div>

      {changeDisplayOnSelection && (
        <Fragment>
          {w && !forbidDelete && (
            <Tooltip title={<FormattedMessage id='firmwarePage.tableToolbar.delete' defaultMessage='Delete' />}>
              <IconButton aria-label='Delete' onClick={handleDelete} className={classes.iconButton}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
          {w && oneSelected && !selectedCorrupted && (
            <Fragment>
              {subheading === 'firmware' && (
                <Tooltip title={<FormattedMessage id='firmwarePage.tableToolbar.install' defaultMessage='Install' />}>
                  <IconButton aria-label='Install' onClick={handleInstall} className={classes.iconButton}>
                    <LaunchIcon />
                  </IconButton>
                </Tooltip>
              )}
              {subheading === 'backup' && (
                <Fragment>
                  <Tooltip title={<FormattedMessage id='firmwarePage.tableToolbar.restore' defaultMessage='Restore' />}>
                    <IconButton aria-label='Restore' onClick={handleRestore} className={classes.iconButton}>
                      <RestoreIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={<FormattedMessage id='firmwarePage.tableToolbar.download' defaultMessage='Download' />}
                  >
                    <IconButton aria-label='Download' onClick={handleDownload} className={classes.iconButton}>
                      <DownloadIcon />
                    </IconButton>
                  </Tooltip>
                </Fragment>
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </Toolbar>
  );
};

FirmwareDeleteToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(toolbarStyles)(FirmwareDeleteToolbar);
