import React from 'react';
import classnames from 'classnames';
import compose from 'recompose/compose';
import { FormattedMessage } from 'react-intl';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import WarningIcon from '@material-ui/icons/Warning';
import { connect } from 'react-redux';
import { tooltips, panelTitles } from './messages';
import { isMulticastRange } from '../../util/networkHelpers';

const StyledBadge = withStyles(theme =>
  createStyles({
    badge: {
      right: -18,
      top: '50%',
      backgroundColor: 'unset',
      padding: 0,
    },
  })
)(Badge);

const styles = theme => ({
  summary: {
    '&& > div': {
      margin: 0,
    },
    [theme.breakpoints.only('xs')]: {
      padding: `0px 12px`,
    },
  },
  summaryBadge: {
    top: '50%',
  },
  badge: {
    top: '50%',
    right: -theme.spacing(2),
  },
  badgeTooltipContent: {
    width: theme.spacing(1.5),
    textAlign: 'center',
  },
  details: {
    [theme.breakpoints.only('xs')]: {
      padding: `0px ${theme.spacing(1)}px`,
    },
  },
  breakAll: {
    wordBreak: 'break-all',
  },
  tableCell: {
    height: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
  },
  tableRow: {
    cursor: 'default',
    '& .hover-button': {
      visibility: 'hidden',
    },
    '&:hover .hover-button': {
      visibility: 'inherit',
    },
  },
  clickableRow: {
    cursor: 'pointer',
  },
  basis30: {
    flexBasis: '30%',
  },
  basis10: {
    flexBasis: '10%',
  },
});

const mapStateToProps = ({ network }) => {
  return { ...network, networkStillLoading: network === null };
};

class RoutesPanel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { isExpanded: this.props.type === 'user rules' };
  }

  handleExpand = (event, isExpanded) => {
    this.setState({ isExpanded });
  };

  render() {
    const { classes, routes, type, handleEditRoute, handleDeleteRoute, mobile } = this.props;
    const notSetMessage = <FormattedMessage id='routeDialog.notSet' defaultMessage='Not set' />;

    let missingRoutes = [];
    let messages = {};
    if (type === 'user rules') {
      const missingConfigRoutes = this.props.missingConfigRoutes || [];
      missingRoutes = missingConfigRoutes.map(r => `${r.destination}${r.gateway}${r.iface}`);
      messages.tooltipCell = tooltips.routeMissingInActualRules;
      messages.tooltipHeading = tooltips.someRoutesMissingInActual;
      messages.panelTitle = panelTitles.routingConfiguration;
    } else {
      const missingSystemRoutes = this.props.missingSystemRoutes || [];
      missingRoutes = missingSystemRoutes.map(r => `${r.destination}${r.gateway}${r.iface}`);
      messages.tooltipCell = tooltips.routeMissingInRoutingConfiguration;
      messages.tooltipHeading = tooltips.someRoutesMissingInConfig;
      messages.panelTitle = panelTitles.actualRoutingTable;
    }
    const missingRoutesSet = new Set(missingRoutes);

    return (
      <Accordion defaultExpanded={type === 'user rules'} onChange={this.handleExpand}>
        <AccordionSummary
          className={classes.summary}
          expandIcon={
            <Tooltip title={<FormattedMessage id='routingPage.showHide' defaultMessage='Show/Hide' />}>
              <ExpandMoreIcon />
            </Tooltip>
          }
        >
          <Badge
            overlap='rectangular'
            badgeContent={
              <Tooltip title={messages.tooltipHeading}>
                <span className={classes.badgeTooltipContent}>{'!'}</span>
              </Tooltip>
            }
            color='error'
            invisible={missingRoutesSet.size === 0 || this.state.isExpanded}
            classes={{ badge: classes.summaryBadge }}
          >
            <Typography>{messages.panelTitle}</Typography>
          </Badge>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          {routes && (
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell className={classnames(classes.basis30, classes.tableCell)}>
                    <Typography variant='caption'>
                      <FormattedMessage id='routingPage.destination' defaultMessage='Destination' />
                    </Typography>
                  </TableCell>
                  <TableCell className={classnames(classes.basis30, classes.tableCell)}>
                    <Typography variant='caption'>
                      <FormattedMessage id='routingPage.gateway' defaultMessage='Gateway' />
                    </Typography>
                  </TableCell>
                  <TableCell className={classnames(classes.basis30, classes.tableCell)}>
                    <Typography variant='caption'>
                      <FormattedMessage id='routingPage.interface' defaultMessage='Interface' />
                    </Typography>
                  </TableCell>
                  <TableCell className={classnames(classes.basis10, classes.tableCell)} />
                </TableRow>
              </TableHead>
              <TableBody>
                {routes.map((route, i) => {
                  const { destination, gateway, iface } = route;
                  const routeId = `${destination}${gateway}${iface}`;
                  const isMissing = missingRoutesSet.has(routeId);
                  const isDestinationMulticast =
                    destination === 'default multicast gateway' || isMulticastRange(destination);
                  const multicastGatewayError =
                    isDestinationMulticast && gateway !== 'Not set' && gateway !== undefined;
                  const multicastGatewayErrorMessage = tooltips.multicastGatewaySpecified(destination, gateway);

                  return (
                    <TableRow
                      className={classnames(
                        mobile ? '' : classes.tableRow,
                        type === 'user rules' ? classes.clickableRow : ''
                      )}
                      hover={type === 'user rules'}
                      key={i}
                      onClick={type === 'user rules' ? handleEditRoute(route) : null}
                    >
                      <TableCell className={classnames(classes.basis30, classes.tableCell, classes.breakAll)}>
                        <Badge
                          overlap='rectangular'
                          badgeContent={
                            <Tooltip title={messages.tooltipCell}>
                              <span className={classes.badgeTooltipContent}>{'!'}</span>
                            </Tooltip>
                          }
                          color='error'
                          invisible={!isMissing || !this.state.isExpanded}
                          classes={{ badge: classes.badge }}
                        >
                          {destination === 'default gateway' && (
                            <FormattedMessage id='routeDialog.defaultGateway' defaultMessage='Default gateway' />
                          )}
                          {destination === 'default multicast gateway' && (
                            <FormattedMessage
                              id='routeDialog.defaultMulticastGateway'
                              defaultMessage='Default multicast gateway'
                            />
                          )}
                          {!destination.startsWith('default') && destination}
                        </Badge>
                      </TableCell>
                      <TableCell className={classnames(classes.basis30, classes.tableCell, classes.breakAll)}>
                        <StyledBadge
                          overlap='rectangular'
                          badgeContent={
                            <Tooltip title={multicastGatewayErrorMessage}>
                              <WarningIcon color='primary' />
                            </Tooltip>
                          }
                          color='error'
                          invisible={!multicastGatewayError || !this.state.isExpanded}
                        >
                          {gateway === 'Not set' || gateway === undefined ? notSetMessage : gateway}
                        </StyledBadge>
                      </TableCell>
                      <TableCell className={classnames(classes.basis30, classes.tableCell, classes.breakAll)}>
                        {iface === 'Not set' ? notSetMessage : iface}
                      </TableCell>
                      <TableCell
                        className={classnames(classes.basis10, classes.tableCell, classes.breakAll)}
                        padding='none'
                        align='right'
                      >
                        {type === 'user rules' &&
                          (iface === 'Not set' ? (
                            <IconButton onClick={handleEditRoute(route)}>
                              <EditIcon className='hover-button' />
                            </IconButton>
                          ) : (
                            <IconButton onClick={handleDeleteRoute(route)}>
                              <DeleteIcon className='hover-button' />
                            </IconButton>
                          ))}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default compose(connect(mapStateToProps), withStyles(styles))(RoutesPanel);
