import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Badge from '@material-ui/core/Badge';
import compose from 'recompose/compose';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import classnames from 'classnames';
import InfoIcon from '@material-ui/icons/Info';

import Transition from '../util/DialogTransition';
import { withWidth } from '@material-ui/core';

const styles = theme => ({
  row: {
    height: '36px',

    '& > td, & > th': {
      padding: '0 1px',
    },
    '& > td:first-child, & > th:first-child': {
      paddingLeft: '10px',
    },
    '& > td:last-child, & > th:last-child': {
      paddingRight: '10px',
    },
  },
  idPadding: {
    paddingRight: '20px !important',

    [theme.breakpoints.only('xs')]: {
      paddingRight: '6px !important',
    },
  },

  tabLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  appBar: {
    position: 'relative',
  },
  toolbar: {
    padding: `0 ${theme.spacing(1.5)}px`,
  },
  cancelButton: {
    marginRight: theme.spacing(1),
  },
  spacer: {
    height: theme.spacing(2),
  },
  toolBarTypography: {
    flex: 1,
  },

  table: {
    tableLayout: 'fixed',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
  },

  dialogTitle: {
    paddingBottom: 0,
    boxShadow: [
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2)',
      '0px 2px 2px 0px rgba(0, 0, 0, 0.14)',
      '0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    ],
  },
  dialogContent: {
    willChange: 'transform',
    backgroundColor: `rgba(0, 0, 0, 0.05)`,
    [theme.breakpoints.only('xs')]: {
      padding: 0,
    },
  },
  dialogActions: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
  },

  yellowBg: {
    backgroundColor: theme.palette.alerts.warning,
  },
  redBg: {
    backgroundColor: theme.palette.alerts.danger,
  },
  infoIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

class SmartDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { tabIndex: 0, scrollButtons: 'off' };
    this.tabsRef = React.createRef();
  }

  componentDidUpdate() {
    /** Hack to prevent unwanted space before first tab for arrow button
     * (https://github.com/mui-org/material-ui/issues/12358) */
    if (this.props.open && this.tabsRef.current && !this.state.scrollButtonsWereChecked) {
      const container = this.tabsRef.current;
      if (!container) {
        return;
      }
      const containerWidth = container.clientWidth;
      const tabs = Array.from(container.getElementsByTagName('button'));
      const tabWidth = tabs.reduce((a, b) => a + b.clientWidth, 0);
      const newScrollButtons = tabWidth > containerWidth ? 'on' : 'off';
      this.setState({ scrollButtons: newScrollButtons, scrollButtonsWereChecked: true });
    }
  }

  handleChangeTab = (event, tabIndex) => this.setState({ tabIndex });

  render() {
    const { open, onClose, fullScreen, smart, classes, width } = this.props;
    const xs = width === 'xs';

    return !smart ? null : (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth='md'
        fullWidth
        fullScreen={fullScreen}
        TransitionComponent={fullScreen ? Transition : undefined}
      >
        {fullScreen && (
          <AppBar className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
              <IconButton
                color='inherit'
                onClick={onClose}
                disabled={this.state.spinnerIsActive}
                aria-label='Close'
                className={classes.cancelButton}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant='h6' color='inherit' className={classes.toolBarTypography}>
                S.M.A.R.T.
              </Typography>
            </Toolbar>
          </AppBar>
        )}
        <DialogTitle className={classes.dialogTitle}>
          {!fullScreen && `S.M.A.R.T.`}
          {!fullScreen && <div className={classes.spacer} />}
          <div ref={this.tabsRef}>
            <Tabs
              value={this.state.tabIndex}
              onChange={this.handleChangeTab}
              indicatorColor='primary'
              textColor='primary'
              variant='scrollable'
              scrollButtons={this.state.scrollButtons}
            >
              {Object.entries(smart).map(([name, { health }]) => (
                <Tab
                  key={name}
                  label={
                    <Tooltip
                      title={
                        <FormattedMessage
                          id='smartDialog.healthBadge'
                          defaultMessage='Health: {health}'
                          values={{ health }}
                        />
                      }
                      disableFocusListener={health === 'passed'}
                      disableHoverListener={health === 'passed'}
                      disableTouchListener={health === 'passed'}
                    >
                      <Badge badgeContent='!' invisible={health === 'passed'} color='error' overlap='rectangular'>
                        {name}
                      </Badge>
                    </Tooltip>
                  }
                />
              ))}
            </Tabs>
          </div>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {Object.values(smart).map((disk, i) => {
            if (this.state.tabIndex !== i) return null;

            const showIconColumn = disk.attributes.some(attributes => attributes.fail !== '-');

            return (
              <Table key={i} padding='none' className={classes.table}>
                <TableHead>
                  <TableRow className={classes.row}>
                    {showIconColumn && <TableCell align='left' style={{ width: '28px' }} />}
                    <TableCell align='right' className={classes.idPadding} style={{ width: xs ? '30px' : '30px' }}>
                      id
                    </TableCell>
                    <TableCell align='left'>attr</TableCell>
                    <TableCell align='right' style={{ width: xs ? '20%' : '120px' }}>
                      raw
                    </TableCell>
                    <TableCell align='right' style={{ width: xs ? '38px' : '70px' }}>
                      thresh
                    </TableCell>
                    <TableCell align='right' style={{ width: xs ? '38px' : '70px' }}>
                      value
                    </TableCell>
                    <TableCell align='right' style={{ width: xs ? '38px' : '70px' }}>
                      worst
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {disk.attributes.map(attributes => {
                    let rowColor;
                    let tooltipMessage = '';
                    switch (attributes.fail) {
                      case '-':
                        break;
                      case 'Past':
                        rowColor = classes.yellowBg;
                        tooltipMessage = (
                          <FormattedMessage
                            id='smartDialog.yellowRow'
                            defaultMessage='This parameter had reached the threshold value in the past'
                          />
                        );
                        break;
                      default:
                        rowColor = classes.redBg;
                        tooltipMessage = (
                          <FormattedMessage
                            id='smartDialog.redRow'
                            defaultMessage='This parameter has reached the threshold value'
                          />
                        );
                    }

                    return (
                      <TableRow className={classnames(classes.row, rowColor)} key={attributes.id}>
                        {showIconColumn && (
                          <TableCell align='left'>
                            {tooltipMessage && (
                              <Tooltip title={tooltipMessage}>
                                <InfoIcon className={classes.infoIcon} />
                              </Tooltip>
                            )}
                          </TableCell>
                        )}
                        <TableCell align='right' className={classes.idPadding}>
                          {attributes.id}
                        </TableCell>
                        <TableCell align='left'>{attributes.attr}</TableCell>
                        <TableCell align='right'>{attributes.raw}</TableCell>
                        <TableCell align='right'>{attributes.thresh}</TableCell>
                        <TableCell align='right'>{attributes.value}</TableCell>
                        <TableCell align='right'>{attributes.worst}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            );
          })}
        </DialogContent>
        {!fullScreen && (
          <DialogActions className={classes.dialogActions}>
            <Button onClick={onClose} color='primary'>
              <FormattedMessage id='smartDialog.close' defaultMessage='Close' />
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  }
}

export default compose(withStyles(styles), withMobileDialog(), withWidth())(SmartDialog);
