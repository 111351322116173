import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ResponsiveDialog from './responsive-dialog';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';

const styles = theme => ({
  table: {
    marginTop: theme.spacing(),
    '&:not(:last-of-type)': {
      marginBottom: theme.spacing(4),
    },
  },
  cell: {
    width: '33%',
  },
  background: {
    backgroundColor: '#FAFAFA',
  },
  gridMargin: {
    marginBottom: theme.spacing(1.5),
  },
});

const RoutesTable = withStyles(styles)(({ routes, title, classes }) => (
  <Fragment>
    <Typography>{title}</Typography>
    <Table className={classes.table}>
      <TableHead>
        <TableCell align='left' className={classes.cell}>
          <FormattedMessage id='missingRoutesDialog.table.destination' defaultMessage='Destination' />
        </TableCell>
        <TableCell align='left' className={classes.cell}>
          <FormattedMessage id='missingRoutesDialog.table.gateway' defaultMessage='Gateway' />
        </TableCell>
        <TableCell align='left' className={classes.cell}>
          <FormattedMessage id='missingRoutesDialog.table.iface' defaultMessage='Interface' />
        </TableCell>
      </TableHead>
      <TableBody>
        {routes.map((route, i) => (
          <TableRow key={i}>
            <TableCell align='left' className={classes.cell}>
              {route.destination}
            </TableCell>
            <TableCell align='left' className={classes.cell}>
              {route.gateway}
            </TableCell>
            <TableCell align='left' className={classes.cell}>
              {route.iface}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Fragment>
));

const MissingRoutesDialog = ({ open, missingConfigRoutes, missingSystemRoutes, onClose }) => (
  <ResponsiveDialog
    open={open}
    title={<FormattedMessage id='missingRoutesDialog.title' defaultMessage='Routing issues' />}
    onClose={onClose}
    withoutConfirm
    fullWidth
    maxWidth='md'
    closeMessage={<FormattedMessage id='missingRoutesDialog.button.close' defaultMessage='Close' />}
  >
    {missingSystemRoutes.length > 0 && (
      <RoutesTable
        title={
          <FormattedMessage
            id='missingRoutesDialog.missingFromRoutingConfiguration'
            defaultMessage='Routes missing from routing configuration'
          />
        }
        routes={missingSystemRoutes}
      />
    )}
    {missingConfigRoutes.length > 0 && (
      <RoutesTable
        routes={missingConfigRoutes}
        title={
          <FormattedMessage
            id='missingRoutesDialog.missingFromActualRoutingTable:'
            defaultMessage='Routes missing from actual routing table'
          />
        }
      />
    )}
  </ResponsiveDialog>
);

export default MissingRoutesDialog;
