import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Refresh from '@material-ui/icons/Autorenew';
import Popover from '@material-ui/core/Popover';
import ContentCopy from '../customIcons/ContentCopy';
import copy from 'copy-to-clipboard';

const styles = theme => ({
  paper: {
    minWidth: '250px',
    padding: theme.spacing(1),
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
  },
});

class LinkAccessPopover extends React.Component {
  handleCopyLink = () => {
    const { origin } = window.location;
    copy(`${origin}${this.props.link}`);
    this.props.addMessage({
      type: 'success',
      data: <FormattedMessage id='linkAccessPopover.copiedToClipboard' defaultMessage='Copied to clipboard' />,
    });
  };

  render() {
    const { open, anchorEl, onClose, link, toggleLinkAccess, regenerateLink, classes } = this.props;

    return (
      <Popover open={open} anchorEl={anchorEl} onClose={onClose} classes={{ paper: classes.paper }}>
        <div className={classes.container}>
          <Typography>
            <FormattedMessage id='linkAccessPopover.linkAccess' defaultMessage='Access by link' />
          </Typography>
          <Switch color='primary' checked={!!link} onChange={toggleLinkAccess} />
        </div>
        <Collapse in={!!link}>
          <div className={classes.container}>
            <Typography>
              <FormattedMessage id='linkAccessPopover.copyLink' defaultMessage='Copy link' />
            </Typography>
            <IconButton onClick={this.handleCopyLink}>
              <ContentCopy />
            </IconButton>
          </div>
          <div className={classes.container}>
            <Typography>
              <FormattedMessage id='linkAccessPopover.refreshLink' defaultMessage='Reset link' />
            </Typography>
            <IconButton onClick={regenerateLink}>
              <Refresh />
            </IconButton>
          </div>
        </Collapse>
      </Popover>
    );
  }
}

export default withStyles(styles)(LinkAccessPopover);
