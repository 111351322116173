// copypaste here from /home/fedor/repos/system/backend/src/core/resources.ts
export const resources = [
  'firmware',
  'backup',
  'modules',
  'services',
  'roles',
  'users',
  'power',
  'network',
  'info',
  'load',
  'smart',
  'connections',
  'mail',
  'time',
  'license',
  'vtun',
  'dns',
  'issues',
];
