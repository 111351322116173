import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { isEqual, omit } from 'lodash';

import { customSettings, netupSettings, settingsToExclude, DEFAULT_VTUN_CONFIG } from '@/redux-stuff/constants';
import { InterfaceSummary } from '..';
import { vtunActions, networkActions } from '@/redux-stuff/actions';
import CustomAccordion from './CustomAccordion';
import ActionButtons from './ActionButtons';
import NetupTunnelDetails from './NetupTunnelDetails';
import CustomTunnelDetails from './CustomTunnelDetails';
import { useAppDispatch } from '@/util/store';
import { useMobile } from '@/hooks';

const messages = defineMessages({ support: { id: 'vtunPanel.summary.netupSupport', defaultMessage: 'NetUP support' } });

interface VtunPanelProps {
  ifaceName: string;
  justCreated: boolean;
  localState: Record<string, string | number>;
  lastLoadedState?: Record<string, string | number>;
  pendingChangesIface?: string;
  selectedToRemoveIface?: string;
  unsavedChanges: string[];
  iface: {
    type: string;
    name: string;
    actual?: { addresses: string[]; flags: string[]; stats?: { speed?: number; carrier?: boolean } };
  };
  errors?: Record<string, ReactNode>;
  defaultExists: boolean;
}

const VtunPanel: FC<VtunPanelProps> = ({
  ifaceName,
  justCreated,
  localState,
  lastLoadedState,
  pendingChangesIface,
  selectedToRemoveIface,
  unsavedChanges = [],
  iface = { type: '', name: '' } as VtunPanelProps['iface'],
  errors = {},
  defaultExists,
}) => {
  const [expanded, setExpanded] = useState(false);
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const mobile = useMobile();

  const nameToDisplay = useMemo(() => {
    if (ifaceName === 'netup') {
      if (mobile) {
        return 'netup';
      }

      return intl.formatMessage(messages.support);
    }

    if (!justCreated) {
      return `VTUN ${ifaceName}`;
    }
    return 'VTUN';
  }, [ifaceName, mobile]);

  useEffect(() => {
    // updating unsavedChanges state
    const isNotSavedDefault = !defaultExists && ifaceName === 'netup';
    const showApplyDiscard =
      (!isEqual(omit(localState, 'name'), omit(lastLoadedState, settingsToExclude)) &&
        (!isNotSavedDefault || !isEqual(localState, DEFAULT_VTUN_CONFIG))) ||
      (!justCreated && ifaceName !== 'netup' && localState.name !== ifaceName);
    if (unsavedChanges.includes(ifaceName) !== showApplyDiscard) {
      dispatch(networkActions.setUnsavedChanges(ifaceName, showApplyDiscard));
    }
  }, [defaultExists, localState, lastLoadedState, justCreated, ifaceName, dispatch, networkActions.setUnsavedChanges]);

  const { actual = { addresses: [''], flags: [] } } = iface;
  const [ifaceIp] = actual.addresses;
  const ifaceState = actual.flags.includes('UP') ? 'UP' : 'DOWN';
  const stateToDisplay = !lastLoadedState || !lastLoadedState.enabled ? 'DISABLED' : ifaceState;
  const isCustom = ifaceName !== 'netup';

  const loadNetwork = useCallback(() => dispatch(networkActions.loadNetwork()), [dispatch, networkActions.loadNetwork]);

  useEffect(() => {
    if (
      (!lastLoadedState?.enabled && stateToDisplay !== 'UP') ||
      (!isCustom && lastLoadedState?.host === '127.0.0.1')
    ) {
      return;
    }
    const intervalId = setInterval(loadNetwork, 1000);
    return () => clearInterval(intervalId);
  }, [lastLoadedState?.enabled, lastLoadedState?.host, stateToDisplay, isCustom]);

  const handleSave = () => dispatch(vtunActions.saveChanges(ifaceName));
  const handleDiscard = () => dispatch(vtunActions.resetChanges(ifaceName));
  const handleEnabledChange = (_: unknown, checked: boolean) =>
    dispatch(vtunActions.setFieldValue(ifaceName, 'enabled', checked));
  const handleRemoveIface = () => dispatch(vtunActions.removeIface(ifaceName));

  const handleFieldChange = (field: string) => (value?: string) => {
    dispatch(vtunActions.setFieldValue(ifaceName, field, value));
    if (ifaceName !== 'netup') {
      dispatch(vtunActions.validateFieldValue(ifaceName, field, value));
    }
  };

  const { enabled } = localState;
  const showApplyDiscard = unsavedChanges.includes(ifaceName) || justCreated;
  const allowApply =
    showApplyDiscard &&
    // check there are no errors
    !Object.values(errors).some(v => !!v) &&
    // check name is not empty if custom mode is on
    (!isCustom || !!localState.name) &&
    // check all string fields are not empty
    [...netupSettings, ...customSettings].every(
      f => localState[f] === undefined || typeof localState[f] !== 'string' || !!localState[f]
    );

  return (
    <CustomAccordion
      onChange={(_, expanded) => setExpanded(expanded)}
      expanded={expanded}
      alwaysExpanded={justCreated}
      summary={
        <InterfaceSummary
          {...{
            iface,
            type: 'vtun',
            config: true,
            name: nameToDisplay,
            expanded,
            state: stateToDisplay,
            addressToShow: ifaceIp,
            justCreated,
            removingIface: !!pendingChangesIface,
            handleRemoveInterface: handleRemoveIface,
            forbidDelete: !isCustom,
          }}
        />
      }
      details={
        isCustom ? (
          <CustomTunnelDetails
            enabled={!!enabled}
            handleEnabledChange={handleEnabledChange}
            errors={errors}
            localState={localState}
            getChangeHandler={handleFieldChange}
          />
        ) : (
          <NetupTunnelDetails
            enabled={!!enabled}
            handleEnabledChange={handleEnabledChange}
            host={localState.host ? `${localState.host}` : undefined}
            port={+localState.port}
            ifaceIp={ifaceIp}
            setHost={handleFieldChange('host')}
            setPort={newValue => handleFieldChange('port')(newValue?.toString())}
            loadNetwork={loadNetwork}
            tunnelState={stateToDisplay}
          />
        )
      }
      actions={
        <ActionButtons
          show={showApplyDiscard}
          allowApply={allowApply}
          inProgress={pendingChangesIface === ifaceName && !selectedToRemoveIface}
          handleDiscard={handleDiscard}
          handleSave={justCreated ? () => dispatch(vtunActions.saveNew()) : handleSave}
          saveButtonText={
            justCreated ? (
              <FormattedMessage id='vtunPanel.create' defaultMessage='Create' />
            ) : (
              <FormattedMessage id='vtunPanel.apply' defaultMessage='Apply' />
            )
          }
        />
      }
    />
  );
};

export default connect(({ network: { unsavedChanges } }) => ({ unsavedChanges }), {
  ...vtunActions,
  ...networkActions,
})(VtunPanel);
