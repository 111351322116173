import qs from 'qs';
import { saveAs } from 'file-saver';
import store from './store';
import { authActions } from '../redux-stuff/actions';
import emitter from '../emitter';

class Fetcher {
  constructor(params) {
    params = params || {};
    this.urlPrefix_ = params.urlPrefix || '.';
  }
  static recompose(result) {
    let _result = result.data;
    if (typeof _result === 'object' && !(_result instanceof Array)) {
      _result._counter = result.counter;
      _result._bootId = result.bootId;
    }
    return _result;
  }
  set prefix(value) {
    this.urlPrefix_ = value;
  }
  async get(method, params, throwError = true) {
    let uri = this.makeUri_(method);
    if (params) {
      uri += '?' + qs.stringify(params);
    }
    let response = await fetch(uri, {
      credentials: 'same-origin',
    });
    const isOk = await Fetcher.validate(response, uri, throwError);
    if (isOk) {
      const contentDisposition = response.headers.get('content-disposition');
      if (contentDisposition && contentDisposition.includes('attachment')) {
        const filename = contentDisposition.split('filename=')[1].slice(1, -1);
        saveAs(await response.blob(), filename);
      } else {
        return Fetcher.extract(response);
      }
    }
  }
  async put(method, params, throwError = true) {
    let uri = this.makeUri_(method);
    let response = await fetch(uri, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(params),
      credentials: 'same-origin',
    });
    const isOk = await Fetcher.validate(response, uri, throwError);
    if (isOk) {
      return Fetcher.extract(response);
    }
  }
  async post(method, params, throwError = true) {
    let uri = this.makeUri_(method);
    let response = await fetch(uri, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(params),
      credentials: 'same-origin',
    });
    const isOk = await Fetcher.validate(response, uri, throwError);
    if (isOk) {
      return Fetcher.extract(response);
    }
  }
  async delete(method, params, throwError = true) {
    let uri = this.makeUri_(method);
    let response = await fetch(uri, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
      body: JSON.stringify(params),
      credentials: 'same-origin',
    });
    const isOk = await Fetcher.validate(response, uri, throwError);
    if (isOk) {
      return Fetcher.extract(response);
    }
  }

  makeUri_(method) {
    return this.urlPrefix_ + method;
  }

  static async extract(response) {
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      return Fetcher.recompose(await response.json());
    } else {
      return await response.text();
    }
  }

  static async validate(response, uri, throwError) {
    if (response.ok) {
      return true;
    }

    if (response.status === 403) {
      store.dispatch(authActions.setIsAuth(false));
      throw new Error('Access denied');
    }

    const contentType = response.headers.get('content-type') || '';
    const error = await parseErrorResponse(response, contentType, uri, response.status);

    if (throwError) {
      throw error;
    } else {
      emitter.emit('addMessage', { type: 'failure', data: error });
      return false;
    }
  }
}

async function parseErrorResponse(res, contentTypeStr, uri, statusCode) {
  let errorMessage;
  let errorDetails;

  if (contentTypeStr.includes('application/json')) {
    let res_ = await res.json();

    errorMessage = res_.message || res_.type;
    errorDetails = res_;
  } else if (contentTypeStr.includes('text/html')) {
    let res_ = await res.text();

    const matches = res_.match(/<title>(.*?)<\/title>/);
    errorMessage = matches[1].trim();
  } else {
    errorMessage = await res.text();
  }

  const error = new Error(errorMessage);
  error.errorMessage = errorMessage;
  error.details = errorDetails;
  error.contentType = contentTypeStr;
  error.time = new Date();
  error.agent = 'fetcher';
  error.uri = uri;
  error.statusCode = statusCode;

  return error;
}

export default Fetcher;
