import { FormattedMessage } from 'react-intl';
import { omit } from 'lodash';
import { ReactNode } from 'react';

const sizeMakers: ((v: string) => ReactNode)[] = [
  value => <FormattedMessage id='fileSizeUnits.B' defaultMessage='{value} B' values={{ value }} />,
  value => <FormattedMessage id='fileSizeUnits.KB' defaultMessage='{value} KB' values={{ value }} />,
  value => <FormattedMessage id='fileSizeUnits.MB' defaultMessage='{value} MB' values={{ value }} />,
  value => <FormattedMessage id='fileSizeUnits.GB' defaultMessage='{value} GB' values={{ value }} />,
  value => <FormattedMessage id='fileSizeUnits.TB' defaultMessage='{value} TB' values={{ value }} />,
];

const getConnectionLossMessage = (reason: string) => {
  switch (reason) {
    case 'POWEROFF':
      return <FormattedMessage id='connectionLoss.snackBarMessage.powerOff' defaultMessage='Server is down' />;
    case 'REBOOT':
      return <FormattedMessage id='connectionLoss.snackBarMessage.reboot' defaultMessage='Server is rebooting' />;
    default:
      return <FormattedMessage id='connectionLoss.snackBarMessage.updating' defaultMessage='Server is updating' />;
  }
};

const getSize = (sizeInBytes: number | undefined = 0) => {
  const i = sizeInBytes === 0 ? 0 : Math.floor(Math.log(sizeInBytes) / Math.log(1024));
  return sizeMakers[i]((sizeInBytes / Math.pow(1024, i)).toFixed(2));
};

const omitAdditionalFields = (res: Record<string, unknown>) => omit(res, '_bootId', '_counter');

export { getSize, omitAdditionalFields, getConnectionLossMessage };
