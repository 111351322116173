import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Fade from '@material-ui/core/Fade';
import SpeedDial from '../components/CustomSpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isEmpty, isEqual, mapValues, omit, pick, findKey } from 'lodash';
import compose from 'recompose/compose';

import { Vtun, Vlan, Bond } from '../components/customIcons';
import { InterfacePanel, CidrIntersectionDialog, VtunPanel, DnsPanel } from '../components/networkPage';
import { networkActions, vtunActions } from '../redux-stuff/actions';
import {
  getErrors,
  compare,
  updateIpMismatch,
  isIp,
  isIpv4,
  decorateActualAddresses,
  getIfaceTemplate,
} from '../util/networkHelpers';
import { fetcher } from '../util/deps';
import { parsePermissions } from '../util/permissions';
import PageLoadingIndicator from '../components/PageLoadingIndicator';
import Alerts from '../components/Alerts';
import { unloadMessage } from '../redux-stuff/constants';
import emitter from '../emitter';

const styles = () => ({
  root: {
    margin: 'auto',
    maxWidth: 1100,
  },
});

class NetworkPage extends React.Component {
  /** interfaces with all properties from backend and additional properties for frontend needs */
  constructor(props) {
    super(props);
    this.state = { ifaces: {}, synchronizingIface: null, updatingIface: null, removingIface: null };
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  componentDidMount() {
    const { permissions, dispatch } = this.props;
    this.actions = { ...bindActionCreators(networkActions, dispatch), ...bindActionCreators(vtunActions, dispatch) };
    this.actions.loadNetwork();
    this.actions.initialLoad();
    this.setState({ permissions: parsePermissions(permissions, 'network') });
  }

  componentWillUnmount() {
    this.actions.clearUnsavedChanges();
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  static getDerivedStateFromProps(props, state) {
    /** getting initial ifaces state from redux */
    if (isEmpty(state.ifaces) && !isEmpty(props.ifaces)) {
      return { ifaces: updateIpMismatch(props.ifaces) };
    }
    return null;
  }

  static mapStateToProps({ network, vtun, dhcpServer, dnsServer }) {
    return { ...network, networkStillLoading: network === null, vtun, dhcpServer, dnsServer };
  }

  handleBeforeUnload = event => {
    if ((this.props.unsavedChanges || []).length > 0) {
      const message = this.props.intl.formatMessage(unloadMessage.message);
      const confirmationMessage = `${message}: ${this.props.unsavedChanges.join(', ')}`;
      (event || window.event).returnValue = confirmationMessage;
      return confirmationMessage;
    }
  };

  handleExpand = name => () => {
    const { ifaces } = this.state;
    const newIfaces = { ...ifaces, [name]: { ...ifaces[name], expanded: !ifaces[name].expanded } };
    this.setState({ ifaces: newIfaces });
  };

  handleChangeInput = (name, type) => event => {
    const { ifaces } = this.state;
    const { value } = event.target;
    const newIfaces = {
      ...ifaces,
      [name]: { ...ifaces[name], [`new${type}`]: value.replace(',', '.') },
    };
    this.setState({ ifaces: newIfaces }, () => this.updateState(name));
  };

  handleSelectChip = (name, type, ip) => () => {
    const { ifaces } = this.state;
    document.getElementById(`${type.toLowerCase()}-${name}`).focus();
    const newIfaces = { ...ifaces, [name]: { ...ifaces[name], [`selected${type}`]: ip, [`new${type}`]: ip } };
    this.setState({ ifaces: newIfaces }, () => this.updateState(name));
  };

  handleDeselectChip = (name, type) => () => {
    const { ifaces } = this.state;
    const newIfaces = { ...ifaces, [name]: { ...ifaces[name], [`selected${type}`]: '', [`new${type}`]: '' } };
    this.setState({ ifaces: newIfaces }, () => this.updateState(name));
  };

  handleDeleteChip = (name, type, ip) => () => {
    const { ifaces } = this.state;
    /** if deleted chip was picked to edit we need to undo editing mode */
    const iface =
      ip === ifaces[name][`selected${type}`]
        ? { ...ifaces[name], [`selected${type}`]: '', [`new${type}`]: '' }
        : ifaces[name];

    const addresses = iface.config.addresses.filter(e => e !== ip);
    const newIfaces = { ...ifaces, [name]: { ...iface, config: { ...iface.config, addresses } } };
    this.setState({ ifaces: newIfaces }, () => this.updateState(name));
  };

  handleAddAddress = (name, type) => () => {
    const { ifaces } = this.state;
    const iface = ifaces[name];
    const { addresses = [] } = iface.config || {};
    const selectedChipIp = iface[`selected${type}`];
    const enteredIp = iface[`new${type}`];
    /** if chip-editing mode turned on (selectedChipIp not empty),
     * we need to replace address, otherwise to add new one */
    const newAddresses = selectedChipIp
      ? addresses.map(e => (e === selectedChipIp ? enteredIp : e))
      : [...addresses, enteredIp];

    const newIfaces = {
      ...ifaces,
      [name]: {
        ...iface,
        config: { ...iface.config, addresses: newAddresses },
        [`new${type}`]: '',
        [`selected${type}`]: '',
      },
    };
    this.setState({ ifaces: newIfaces }, () => this.updateState(name));
  };

  handleBondSwitch = name => () => {
    const { ifaces } = this.state;
    const iface = ifaces[name];
    const { config } = iface;
    const { bond } = config;
    const { bond: initialBond } = this.props.ifaces[name].config;
    const newIfaces = {
      ...ifaces,
      [name]: { ...iface, config: { ...config, bond: bond !== null ? null : initialBond } },
    };
    this.setState({ ifaces: newIfaces }, () => this.updateState(name));
  };

  handleAddInterface = type => () => {
    const ifaces = mapValues(this.state.ifaces, iface => ({ ...iface, expanded: false }));
    this.setState({ dialOpen: false });

    const newIface = getIfaceTemplate(type);
    if (type === 'vtun') {
      this.actions.addVtun(newIface);
    } else {
      this.setState({ ifaces: { ...ifaces, ...newIface } }, () => {
        this.updateApplyDiscard(type.toUpperCase());
      });
    }

    window.scrollTo(0, 0);
  };

  handleEditParams = (name, param) => event => {
    const { ifaces } = this.state;
    const iface = ifaces[name];
    const { config } = iface;
    const convert = v => (v && !isNaN(v) && (param === 'id' || param === 'miiMonitorSec') ? +v : v);
    const newValue = param === 'ifaces' ? [event.target.value] : convert(event.target.value);
    const editedIface = { [name]: { ...iface, config: { ...config, [param]: newValue } } };
    this.setState({ ifaces: { ...ifaces, ...editedIface } }, () => this.updateState(name));
  };

  handleEditBondInterfaces = (bondName, action, ifaceName) => event => {
    const name = ifaceName || event.target.value;
    const { ifaces } = this.state;
    const iface = ifaces[bondName];
    let { newlyIncludedIfaces = [], newlyExcludedIfaces = [] } = iface;
    const includedInterfaces = Object.values(ifaces)
      .filter(({ config = {} }) => config && config.bond === bondName)
      .map(({ name }) => name);

    if (action === 'include') {
      newlyIncludedIfaces = [...newlyIncludedIfaces, ...(!includedInterfaces.includes(name) ? [name] : [])];
      newlyExcludedIfaces = newlyExcludedIfaces.filter(e => e !== name);
    } else if (action === 'exclude') {
      newlyExcludedIfaces = [...newlyExcludedIfaces, ...(includedInterfaces.includes(name) ? [name] : [])];
      newlyIncludedIfaces = newlyIncludedIfaces.filter(e => e !== name);
    }

    const newIfaces = { ...ifaces, [bondName]: { ...iface, newlyIncludedIfaces, newlyExcludedIfaces } };
    this.setState({ ifaces: newIfaces }, () => this.updateState(bondName));
  };

  handleClickDial = () => this.setState({ dialOpen: !this.state.dialOpen });

  handleCloseDial = () => this.setState({ dialOpen: false });

  handleDiscard = name => () => {
    const { ifaces } = this.state;
    const iface = ifaces[name];
    if (!iface.justCreated) {
      const newIfaces = { ...ifaces, [name]: { ...this.props.ifaces[name], expanded: true } };
      this.setState({ ifaces: newIfaces }, () => this.updateState(name));
    } else {
      const newIfaces = omit(ifaces, name);
      this.setState({ ifaces: { ...ifaces, [name]: { ...iface, expanded: false } } });
      setTimeout(() => this.setState({ ifaces: newIfaces }), this.props.theme.transitions.duration.standard);
    }
  };

  handleSynchronizeConfig = async (name, ipVersion) => {
    const { ifaces } = this.props;
    const iface = ifaces[name];

    const { config, actual } = iface;
    const { addresses: configAddresses = [] } = config || {};
    const { addressInfo: actualAddresses = [] } = actual || {};
    /** User can separately synchronize IPv4 and IPv6 configs, so
     * there we putting together IPs of chosen version from
     * "config" field and IPs from another version from "actual" field  */
    const addresses = [
      ...configAddresses.filter(a => (ipVersion === 'ipv4' ? !isIpv4(a.split('/')[0]) : isIpv4(a.split('/')[0]))),
      ...actualAddresses
        .filter(addressBundle => {
          const { address: addressStr, scope, flags } = addressBundle;
          const isAutomatic = scope === 'link' || (scope === 'global' && flags.includes('dynamic'));

          if (!isAutomatic) {
            return ipVersion === 'ipv4' ? isIpv4(addressStr.split('/')[0]) : !isIpv4(addressStr.split('/')[0]);
          }

          return false;
        })
        .map(({ address }) => address),
    ];

    this.setState({ synchronizingIface: name });
    const request = { ifaces: { [name]: { config: { ...config, addresses } } } };
    try {
      const response = await fetcher.put('network', request);
      this.handleResponse(response, request, configAddresses);
    } catch (e) {
      this.props.addMessage({ type: 'failure', data: e });
      this.setState({ synchronizingIface: null });
    }
  };

  handleApply = ifaceName => async () => {
    const { ifaces } = this.state;
    const iface = ifaces[ifaceName];
    let { config, type, justCreated } = iface;
    const { bond = null } = config || {};
    let name = ifaceName;
    const addresses = (config && config.addresses) || [];
    const prevAddresses =
      (!justCreated && this.props.ifaces[name].config && this.props.ifaces[name].config.addresses) || [];
    if (justCreated) {
      if (type === 'vlan') {
        name = `${config.ifaces[0]}.${config.id}`;
      } else if (type === 'bond') {
        /** Defines new bond name (number always increments by 1) */
        const bondNumbers = Object.keys(ifaces)
          .filter(name => name.includes('bond'))
          .map(v => v.slice(4));
        const nextNumber = bondNumbers.length ? Math.max(...bondNumbers) + 1 : 0;
        name = 'bond' + nextNumber;
      }
    }
    const dhcp = this.props.dhcpServer[ifaceName] && this.props.dhcpServer[ifaceName].newConfig;
    const dns = this.props.dnsServer[ifaceName];
    let request = {
      ifaces: {
        [name]: {
          config: { ...config, addresses, dhcp, dns, ...(type === 'vlan' ? {} : { bond }) },
          name,
          ...(justCreated ? { type } : {}),
        },
      },
    };
    if (type === 'bond') {
      /**Property of belonging to the bond stored in slave interfaces,
       * so we need to update it there */
      const { newlyIncludedIfaces = [], newlyExcludedIfaces = [] } = iface;
      for (const i of newlyIncludedIfaces) {
        const { config, name: slaveName } = this.props.ifaces[i];
        request.ifaces[i] = { name: slaveName, config: { ...config, bond: name } };
      }
      for (const i of newlyExcludedIfaces) {
        const { config, name: slaveName } = this.props.ifaces[i];
        request.ifaces[i] = { name: slaveName, config: { ...config, bond: null } };
      }
    }
    const newIfaceState = {
      ...iface,
      name,
      type,
      config: { ...(config === null ? { bond: null, routes: [] } : config), addresses },
      newIpv4: '',
      newIpv6: '',
    };
    this.setState({
      updatingIface: name,
      ifaces: { ...(justCreated ? omit(ifaces, ifaceName) : ifaces), [name]: newIfaceState },
    });
    try {
      // If IP of our connection was removed, we should offer go to another IP
      const actualHost = window.location.host;
      const actualHostWasOnAppliedIface = prevAddresses.some(ipMask => ipMask.split('/')[0] === actualHost);
      const actualHostWasRemovedFromAppliedIface = !addresses.some(ipMask => ipMask.split('/')[0] === actualHost);
      if (
        isIp(actualHost) &&
        actualHostWasOnAppliedIface &&
        actualHostWasRemovedFromAppliedIface &&
        addresses.length > 0
      ) {
        fetcher.put('network', request);
        this.actions.setUnsavedChanges(name, false);
        const addressToRedirect = addresses[0].split('/')[0];
        emitter.emit('addMessage', {
          type: 'info',
          DOMMessage: (
            <FormattedMessage
              id='snackbar.redirectOffer'
              defaultMessage='Redirect to {addressToRedirect}?'
              values={{ addressToRedirect }}
            />
          ),
          highPriority: true,
          actionButtons: (
            <Fragment>
              <Button color='secondary'>
                <FormattedMessage id='snackbar.redirectOffer.button.no' defaultMessage='No' />
              </Button>
              <Button
                color='secondary'
                onClick={
                  () =>
                    (window.location = `${window.location.protocol}//${addressToRedirect}/system/#/network`) /*eslint-disable-line*/
                }
              >
                <FormattedMessage id='snackbar.redirectOffer.button.yes' defaultMessage='Yes' />
              </Button>
            </Fragment>
          ),
        });
      } else {
        const response = await fetcher.put('network', request);
        this.handleResponse(response, request, prevAddresses);
        this.actions.setUnsavedChanges(name, false);
      }
    } catch (e) {
      this.props.addMessage({ type: 'failure', data: e });
      this.setState({ updatingIface: null });
    }
  };

  handleResponse = (response, request, prevIfaceAddresses) => {
    const { ifaces, removingIface, synchronizingIface, updatingIface } = this.state;
    const { newErrors, ifaces: newIfaces } = decorateActualAddresses(response);
    const changedIface = synchronizingIface || updatingIface;
    const { expanded } = ifaces[changedIface] || {};
    if (newErrors.length === 0) {
      let newIfacesState;
      if (removingIface) {
        newIfacesState = Object.entries(newIfaces).reduce(
          (acc, [name, value]) => ({
            ...acc,
            [name]: { ...value, expanded: ifaces[name].expanded },
          }),
          {}
        );
      } else if (ifaces[changedIface].type === 'bond') {
        const { newlyExcludedIfaces = [], newlyIncludedIfaces = [] } = ifaces[changedIface];
        const updatedIfaces = [...newlyExcludedIfaces, ...newlyIncludedIfaces, changedIface].reduce(
          (acc, name) => ({ ...acc, [name]: { ...newIfaces[name], expanded: ifaces[name].expanded } }),
          {}
        );
        newIfacesState = { ...ifaces, ...updatedIfaces };
      } else {
        newIfacesState = { ...ifaces, [changedIface]: { ...newIfaces[changedIface], expanded } };
      }
      this.setState({
        ifaces: updateIpMismatch(newIfacesState),
        updatingIface: null,
        removingIface: null,
        synchronizingIface: null,
      });
    } else if (newErrors.length > 0) {
      const { addresses } = newIfaces[changedIface].config;
      const addedAddresses = addresses.filter(e => !prevIfaceAddresses.includes(e));
      const addedAddressesWithIntersection = addedAddresses.filter(e =>
        newErrors.find(({ extra = [] }) => extra.find(({ intersection = [] }) => intersection.includes(e)))
      );
      if (addedAddressesWithIntersection.length > 0) {
        const undoReq = { ifaces: { [changedIface]: request.ifaces[changedIface] } };
        undoReq.ifaces[changedIface].config.addresses = undoReq.ifaces[changedIface].config.addresses.filter(
          e => !addedAddressesWithIntersection.includes(e)
        );
        const intersections = newErrors
          .map(({ extra = [] }) =>
            extra
              .map(({ intersection = [] }) => intersection)
              .filter(v => v.some(e => addedAddressesWithIntersection.includes(e)))
          )
          .reduce((acc, v) => [...acc, ...v], []);
        this.setState({
          ifaces: updateIpMismatch({ ...newIfaces, [changedIface]: { ...newIfaces[changedIface], expanded } }),
          cidrIntersectionDialogOpen: true,
          undoAddressesChangeRequest: undoReq,
          intersections,
        });
      }
    }
  };

  updateState = async name => {
    await this.updateErrors(name);
    await this.updateApplyDiscard(name);
  };

  updateErrors = name => {
    const { ifaces } = this.state;
    const iface = ifaces[name];
    const newIface = { ...iface, errors: getErrors(iface) };
    const needToUpdateState = !isEqual(iface, newIface);
    if (needToUpdateState) {
      return new Promise(resolve => this.setState({ ifaces: { ...ifaces, [name]: newIface } }, resolve));
    }
  };

  updateApplyDiscard = name => {
    const { ifaces } = this.state;
    const iface = ifaces[name];
    const { config, justCreated } = iface;

    /** compare redux store with react state without additional properties for frontend needs */
    const stateToCompare = pick(iface, ['name', 'type', 'config', 'actual']);
    let showActions = !isEqual(this.props.ifaces[name], stateToCompare);
    let allowApply = !iface.errors || Object.values(iface.errors).every(e => !e);
    if (justCreated && iface.type === 'vlan') {
      allowApply = allowApply && !!config && !!config.ifaces[0] && !!config.id;
    } else if (iface.type === 'bond') {
      showActions = showActions || !isEmpty(iface.newlyIncludedIfaces) || !isEmpty(iface.newlyExcludedIfaces);
      allowApply = allowApply && (!!config.miiMonitorSec || config.miiMonitorSec === 0);
      if (justCreated) {
        allowApply = allowApply && !isEmpty(iface.newlyIncludedIfaces) && !isEmpty(config.addresses);
      }
    }
    const newIface = { ...iface, showActions, allowApply };
    if ((this.props.unsavedChanges || []).includes(name) !== showActions) {
      this.actions.setUnsavedChanges(name, showActions);
    }
    const needToUpdateState = !isEqual(iface, newIface);
    if (needToUpdateState) {
      return new Promise(resolve => this.setState({ ifaces: { ...ifaces, [name]: newIface } }, resolve));
    }
  };

  handleCloseCidrIntersectionDialog = () =>
    this.setState({ cidrIntersectionDialogOpen: false, synchronizingIface: null, updatingIface: null });

  handleUndoAddressesChange = async () => {
    const { undoAddressesChangeRequest } = this.state;
    this.setState({ pendingUndoChange: true });
    try {
      const response = await fetcher.put('network', undoAddressesChangeRequest);
      this.handleResponse(response);
      this.props.addMessage({
        type: 'success',
        data: <FormattedMessage id='networkPage.changesWereUndo' defaultMessage='Changes have been reverted' />,
      });
    } catch (e) {
      this.props.addMessage({ type: 'failure', data: e });
    }
    this.setState({ cidrIntersectionDialogOpen: false, pendingUndoChange: false });
  };

  handleRemoveInterface = async name => {
    this.setState({ removingIface: name });
    try {
      const response = await fetcher.put('network', { ifaces: { [name]: null } });
      this.handleResponse(response);
      this.props.addMessage({
        type: 'success',
        data: (
          <FormattedMessage
            id='networkPage.snackbarMessage.interfaceSettingsWereDeleted'
            defaultMessage='Interface settings were deleted'
          />
        ),
      });
    } catch (e) {
      this.props.addMessage({ type: 'failure', data: e });
      this.setState({ removingIface: null });
    }
  };

  handleCreateEmptyConfig = async name => {
    this.setState({ updatingIface: name });
    try {
      const response = await fetcher.put('network', { ifaces: { [name]: { config: {} } } });
      this.handleResponse(response);
      this.props.addMessage({
        type: 'success',
        data: (
          <FormattedMessage
            id='networkPage.snackbarMessage.emptySettingsWereCreated'
            defaultMessage='Empty settings were created'
          />
        ),
      });
    } catch (e) {
      this.props.addMessage({ type: 'failure', data: e });
      this.setState({ updatingIface: null });
    }
  };

  render() {
    const {
      ifaces,
      dialOpen,
      cidrIntersectionDialogOpen,
      pendingUndoChange,
      intersections,
      updatingIface,
      synchronizingIface,
      removingIface,
      permissions = {},
    } = this.state;
    const { classes } = this.props;
    const { w } = permissions;
    const justCreatedVtunOpen =
      Object.keys(this.props.vtun.localState).filter(i => i !== 'netup').length >
      Object.keys(this.props.vtun.lastLoadedState).filter(i => i !== 'netup').length;
    const newPanelOpen = !!findKey(ifaces, iface => iface.justCreated) || justCreatedVtunOpen;
    const ifacesToShow = Object.values(this.state.ifaces || {}).filter(iface => !iface.name.includes('tun'));

    const { localState, lastLoadedState, pendingChangesIface, validationErrors } = this.props.vtun || {};
    const justCreatedVtun = Object.entries(localState).find(
      ([ifaceName]) => !lastLoadedState[ifaceName] && ifaceName !== 'netup'
    );
    const defaultVtunExists = Object.keys(lastLoadedState).includes('netup');

    return (
      <div className={classes.root}>
        <Alerts issuesPermission={parsePermissions(this.props.permissions, 'issues').r} />
        <PageLoadingIndicator open={this.props.networkStillLoading} />

        {!this.props.networkStillLoading && (
          <div>
            <DnsPanel w={w} />
            {justCreatedVtun && (
              <VtunPanel
                {...{
                  key: justCreatedVtun[0],
                  ifaceName: justCreatedVtun[0],
                  justCreated: true,
                  localState: justCreatedVtun[1],
                  lastLoadedState: lastLoadedState[justCreatedVtun[0]],
                  pendingChangesIface,
                  errors: validationErrors[justCreatedVtun[0]],
                  defaultExists: defaultVtunExists,
                  loadNetwork: this.actions.loadNetwork,
                }}
              />
            )}
            {ifacesToShow.sort(compare).map((iface, index) => (
              <InterfacePanel
                key={iface.name}
                {...{
                  w,
                  index,
                  iface,
                  propsIfaces: this.props.ifaces,
                  updatingIface,
                  synchronizingIface,
                  removingIface,
                  handleExpand: this.handleExpand,
                  handleRemoveInterface: this.handleRemoveInterface,
                  handleEditParams: this.handleEditParams,
                  handleBondSwitch: this.handleBondSwitch,
                  handleEditBondInterfaces: this.handleEditBondInterfaces,
                  handleChangeInput: this.handleChangeInput,
                  handleAddAddress: this.handleAddAddress,
                  handleDeselectChip: this.handleDeselectChip,
                  handleSelectChip: this.handleSelectChip,
                  handleDeleteChip: this.handleDeleteChip,
                  handleSynchronizeConfig: this.handleSynchronizeConfig,
                  handleDiscard: this.handleDiscard,
                  handleApply: this.handleApply,
                  handleCreateEmptyConfig: this.handleCreateEmptyConfig,
                }}
              />
            ))}
            {Object.entries(localState)
              .filter(([ifaceName]) => lastLoadedState[ifaceName] || ifaceName === 'netup')
              .map(([ifaceName, config]) => (
                <VtunPanel
                  {...{
                    key: ifaceName,
                    ifaceName,
                    localState: config,
                    lastLoadedState: lastLoadedState[ifaceName],
                    pendingChangesIface,
                    errors: validationErrors[ifaceName],
                    defaultExists: defaultVtunExists,
                    iface: this.props.ifaces[`vtun-${ifaceName}`],
                    loadNetwork: this.actions && this.actions.loadNetwork,
                  }}
                />
              ))}
          </div>
        )}

        {w && (
          <Fade in={!newPanelOpen} unmountOnExit>
            <SpeedDial
              ariaLabel='speedDial'
              open={!!dialOpen}
              label={<FormattedMessage id='networkPage.speedDialLabel' defaultMessage='Add' />}
              onClick={this.handleClickDial}
              onClose={this.handleCloseDial}
            >
              <SpeedDialAction
                key='vlan'
                icon={<Vlan />}
                tooltipTitle={<FormattedMessage id='networkPage.addVlan' defaultMessage='Add VLAN' />}
                onClick={this.handleAddInterface('vlan')}
                tooltipOpen
              />
              <SpeedDialAction
                key='bond'
                icon={<Bond />}
                tooltipTitle={<FormattedMessage id='networkPage.addBond' defaultMessage='Add bond' />}
                onClick={this.handleAddInterface('bond')}
                tooltipOpen
              />
              <SpeedDialAction
                key='vtun'
                icon={<Vtun />}
                tooltipTitle={<FormattedMessage id='networkPage.speedDialTooltip.addVtun' defaultMessage='Add vtun' />}
                onClick={this.handleAddInterface('vtun')}
                tooltipOpen
              />
            </SpeedDial>
          </Fade>
        )}
        <CidrIntersectionDialog
          open={!!cidrIntersectionDialogOpen}
          handleUndoAddressesChange={this.handleUndoAddressesChange}
          handleCloseCidrIntersectionDialog={this.handleCloseCidrIntersectionDialog}
          intersections={intersections}
          pendingUndoChange={pendingUndoChange}
        />
      </div>
    );
  }
}

export default compose(
  injectIntl,
  connect(NetworkPage.mapStateToProps),
  withStyles(styles, { withTheme: true })
)(NetworkPage);
