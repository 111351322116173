import { Table, TableBody, TableRow, TableCell, Typography, makeStyles } from '@material-ui/core';
import { FC, Fragment, ReactNode } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import ResponsiveDialog, { ResponsiveDialogProps } from '../responsive-dialog';
import { useMobile } from '@/hooks';
import { useAppSelector } from '@/util/store';
import { getSize } from '@/util/commonHelpers';
import ModuleInfoFieldNameCell from './ModuleInfoFieldNameCell';
import ModuleInfoCopyButton from './ModuleInfoCopyButton';

const useStyles = makeStyles(() => ({
  firmwareDetailsTable: {
    tableLayout: 'fixed',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    '& td:first-child': {
      width: '100px',
    },
    '&:not(:last-child)': { marginBottom: '30px' },
  },
}));

export interface ModuleInfoDialogProps {
  open: boolean;
  onClose: ResponsiveDialogProps['onClose'];
  activeModule?: {
    id: string;
    name: string;
    revision: string;
    version: string;
    build: string;
    buildDate?: string;
    components?: Record<string, Record<string, string>>;
  };
}

const ModuleInfoDialog: FC<ModuleInfoDialogProps> = ({ open, onClose, activeModule }) => {
  const classes = useStyles();
  const mobile = useMobile();
  const diskUsage = useAppSelector(({ diskUsage }) => diskUsage);

  if (!activeModule || !open) return null;
  console.log(activeModule);

  const { components } = activeModule;
  const image = diskUsage?.usage?.images?.find((item: { id: unknown }) => item.id === activeModule.id);

  const infoDialogFields: [string, ReactNode][] = [
    ['name', activeModule.name],
    ['revision', activeModule.revision],
    ['version', activeModule.version],
    ['build', activeModule.build],
  ];

  if (image) {
    infoDialogFields.push(
      ['size', getSize(image.size)],
      ['shared size', getSize(image.sharedSize)],
      ['unique size', getSize(image.uniqueSize)]
    );
  }

  if (activeModule.buildDate) {
    infoDialogFields.push([
      'build date',
      <FormattedDate
        value={new Date(activeModule.buildDate)}
        day='numeric'
        month='short'
        year='numeric'
        hour='numeric'
        minute='numeric'
      />,
    ]);
  }

  return (
    <ResponsiveDialog
      open
      title={!mobile && <FormattedMessage id='infoDialog.info' defaultMessage='Info' />}
      closeMessage={<FormattedMessage id='infoDialogWithTable.buttons.close' defaultMessage='Close' />}
      onClose={onClose}
      withoutConfirm
      fullWidth
      leftButtons={<ModuleInfoCopyButton activeModule={activeModule} />}
    >
      <Table padding='checkbox' className={classes.firmwareDetailsTable}>
        <TableBody>
          {infoDialogFields.map(([field, value]) => (
            <TableRow key={field} hover>
              <ModuleInfoFieldNameCell field={field} />
              <TableCell padding='none'>{value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {components && (
        <Fragment>
          <Typography>
            <FormattedMessage id='moduleComponents.header' defaultMessage='Components' />
          </Typography>

          {Object.entries(components).map(([componentName, componentFields]) => {
            return (
              <Table key={componentName} padding='checkbox' className={classes.firmwareDetailsTable}>
                <TableBody>
                  <TableRow hover>
                    <ModuleInfoFieldNameCell field='name' />
                    <TableCell padding='none'>{componentName}</TableCell>
                  </TableRow>

                  {Object.entries(componentFields).map(([field, value]) => {
                    if (!['revision', 'version', 'variant'].includes(field)) return null;
                    return (
                      <TableRow key={field} hover>
                        <ModuleInfoFieldNameCell field={field} />
                        <TableCell padding='none'>{value}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            );
          })}
        </Fragment>
      )}
    </ResponsiveDialog>
  );
};

export default ModuleInfoDialog;
