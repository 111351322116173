export default class StoreDecorator {
  static decorated = ['firmwareFiles', 'backups', 'connections'];

  [Symbol.observable];

  constructor(store) {
    this.store = store;
    this[Symbol.observable] = store[Symbol.observable];
  }

  getState = () => {
    let state = { ...this.store.getState() };
    for (let key of StoreDecorator.decorated) {
      if (state[key]) state[key] = state[key].data;
    }

    return state;
  };

  dispatch = action => this.store.dispatch(action);

  subscribe = listener => this.store.subscribe(listener);

  replaceReducer = nextReducer => this.store.replaceReducer(nextReducer);
}
