import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVert from '@material-ui/icons/MoreVert';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import { FormattedMessage, injectIntl } from 'react-intl';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Fade from '@material-ui/core/Fade';
import { IconButton } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import AccordionActions from '@material-ui/core/AccordionActions';
import Button from '@material-ui/core/Button';
import { isEqual } from 'lodash';
import { connect } from 'react-redux';

import { rolesActions } from '../../redux-stuff/actions';
import ReactSelect from '../../components/ReactSelect';
import { resources } from '../../redux-stuff/constants';
import { getPermissions, transform, spread } from '../../util/rolesHelpers';
import { getResourceMessage } from '../../util/get-resource-message';

const styles = theme => ({
  actionLabel: {
    width: theme.spacing(7),
    textOverflow: 'ellipsis',
    [theme.breakpoints.only('xs')]: {
      width: theme.spacing(6),
    },
  },
  actionLabels: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingRight: `${theme.spacing(1) + 1}px`, // 1px compensates for resource border width
    marginTop: 3 * theme.spacing(1),
  },
  basis100: {
    flexBasis: '100%',
  },
  checkbox: {
    width: theme.spacing(4),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(1),
    },
  },
  column: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    width: '100%',
  },
  details: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  panel: {
    /** workaround to prevent horizontal shadow disappearing on element,
     *  wrapped in element with overflow: hidden
     */
    marginRight: '1px',
    marginLeft: '1px',
    '&:last-child': {
      marginBottom: '2px',
    },
    '&& > div > div': {
      margin: 0,
    },
  },
  resource: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: `0 ${theme.spacing(1)}px`,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.12)',
  },
  resourceInactive: {
    backgroundColor: 'rgba(0, 0, 0, 0.07)',
  },
  resourceInherited: {
    backgroundColor: 'rgba(255, 0, 255, 0.12)',
  },
  resourcesBox: {
    maxHeight: 800,
    overflowY: 'auto',
  },
});

class Roles extends React.Component {
  componentDidUpdate() {
    // updating unsavedChanges state
    const { lastLoadedRoles, editedRoles, unsavedChanges = [] } = this.props.roles || {};
    Object.keys(editedRoles).forEach(role => {
      const lastLoadedRoleState = lastLoadedRoles[role];
      const permissionsWereChanged = Object.entries(editedRoles[role])
        .filter(([name]) => name !== '$extend')
        .some(([name, { read, write }]) =>
          lastLoadedRoleState[name]
            ? read !== lastLoadedRoleState[name].read || write !== lastLoadedRoleState[name].write
            : read || write
        );
      const ancestorsWereChanged =
        editedRoles[role]['$extend'] &&
        (lastLoadedRoleState['$extend']
          ? !isEqual(editedRoles[role]['$extend'].slice().sort(), lastLoadedRoleState['$extend'].slice().sort())
          : editedRoles[role]['$extend'].length > 0);
      const unsavedChangesExist = permissionsWereChanged || ancestorsWereChanged;
      if (unsavedChanges.includes(role) !== !!unsavedChangesExist) {
        this.props.setUnsavedChanges(role, unsavedChangesExist);
      }
    });
  }
  render() {
    const {
      classes,
      roles,
      messages,
      intl,
      xs,
      permissionsRoles,
      expandedRoles,
      searchRoles,
      handleExpandRoles,
      handleOpenMenu,
      handleChangeAncestors,
      handleChangeSearchRoles,
      handleApplyChanges,
      handleDiscardChanges,
      setPermission,
    } = this.props;
    const { lastLoadedRoles, editedRoles, unsavedChanges = [] } = roles || {};

    return (
      <div>
        {Object.keys(editedRoles).map(role => {
          const { own, inherited } = getPermissions(editedRoles, role);
          const panelExpanded = expandedRoles.includes(role);
          const actualAncestors = editedRoles[role]['$extend'] || [];
          const roleHasDescendants = Object.values(editedRoles).some(v => v['$extend'] && v['$extend'].includes(role));
          const possibleAncestors = roleHasDescendants
            ? []
            : Object.entries(lastLoadedRoles)
                .filter(
                  ([k, v]) => k !== role && (!v['$extend'] || v['$extend'].length === 0) && !actualAncestors.includes(k)
                )
                .map(([k]) => k);

          const showApplyDiscard = unsavedChanges.includes(role);
          const showSummaryButtons = !!panelExpanded && !!showApplyDiscard;

          return (
            <Accordion key={role} className={classes.panel} expanded={panelExpanded} onChange={handleExpandRoles(role)}>
              <AccordionSummary
                expandIcon={
                  <Tooltip title={<FormattedMessage id='rolesPage.showHide' defaultMessage='Show/Hide' />}>
                    <ExpandMoreIcon />
                  </Tooltip>
                }
              >
                <div className={classes.container}>
                  <Typography className={classNames(classes.column)}>{role}</Typography>
                  <div className={classes.container}>
                    <div className={classes.grow} />
                    <Fade in={showSummaryButtons}>
                      <div>
                        <IconButton onClick={handleDiscardChanges(role)} disabled={!showSummaryButtons}>
                          <CloseIcon color='primary' />
                        </IconButton>
                        <IconButton onClick={handleApplyChanges(role)} disabled={!showSummaryButtons}>
                          <CheckIcon color='primary' />
                        </IconButton>
                      </div>
                    </Fade>
                    {role !== 'admin' && permissionsRoles.w && (
                      <Fade in={!!panelExpanded}>
                        <Tooltip
                          title={<FormattedMessage id='rolesPage.moreActions' defaultMessage='More actions' />}
                          disableFocusListener={!panelExpanded}
                          disableHoverListener={!panelExpanded}
                          disableTouchListener={!panelExpanded}
                        >
                          <div>
                            <IconButton onClick={handleOpenMenu(role)} disabled={!panelExpanded}>
                              <MoreVert />
                            </IconButton>
                          </div>
                        </Tooltip>
                      </Fade>
                    )}
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.details}>
                  {permissionsRoles.w && (
                    <ReactSelect
                      values={actualAncestors}
                      suggestions={possibleAncestors}
                      handleChange={handleChangeAncestors(role)}
                      placeholder={<FormattedMessage id='rolesPage.selectRoles' defaultMessage='Select roles' />}
                      label={<FormattedMessage id='rolesPage.inheritedFrom' defaultMessage='Inherited from' />}
                      disabledMessage={
                        role === 'admin' ? (
                          <FormattedMessage
                            id='rolesPage.rolesTab.adminRolesSelectorTooltip'
                            defaultMessage='admin cannot inherit from other roles'
                          />
                        ) : (
                          <FormattedMessage
                            id='rolesPage.inheritError'
                            defaultMessage={`Some role inherits from this one. Only one level of inheritance is allowed`}
                          />
                        )
                      }
                      isDisabled={roleHasDescendants || role === 'admin'}
                    />
                  )}
                  <div className={classes.actionLabels}>
                    {!xs && (
                      <TextField
                        label={<FormattedMessage id='rolesPage.search' defaultMessage='Search' />}
                        value={searchRoles[role] || ''}
                        onChange={handleChangeSearchRoles(role)}
                        margin='none'
                      />
                    )}
                    <div className={classes.grow} />
                    <Typography
                      variant='caption'
                      className={classes.actionLabel}
                      align='center'
                      noWrap
                      title={intl.formatMessage({ id: 'usersAndRolesPage.Read' })}
                    >
                      {intl.formatMessage(messages.read)}
                    </Typography>
                    <Typography
                      variant='caption'
                      className={classes.actionLabel}
                      align='center'
                      noWrap
                      title={intl.formatMessage({ id: 'usersAndRolesPage.Write' })}
                    >
                      {intl.formatMessage(messages.write)}
                    </Typography>
                  </div>
                  <div className={classes.resourcesBox}>
                    {resources
                      .filter(name => {
                        const localizedName = intl.formatMessage({ id: `resource.${name}` });
                        return searchRoles[role] === undefined || localizedName.includes(searchRoles[role]);
                      })
                      .map(name => {
                        const inheritedAndChanged = !!own[name] && !!inherited[name];
                        const resource = inheritedAndChanged
                          ? spread(own[name], inherited[name])
                          : own[name] || inherited[name] || {};
                        const { r, w, from } = transform(resource);
                        const inheritedValues = inherited[name] ? transform(inherited[name]) : {};
                        const rDisabled = role === 'admin' || (from && inheritedValues.r);
                        const wDisabled = role === 'admin' || (from && inheritedValues.w);
                        const active = Object.values(resource).some(a => a);
                        let resourceClass = '';
                        if (inherited[name]) {
                          resourceClass = classes.resourceInherited;
                        } else if (!active) {
                          resourceClass = classes.resourceInactive;
                        }

                        const message = (
                          <Typography className={xs ? classes.basis100 : ''} color='textSecondary'>
                            <FormattedMessage
                              id='rolesPage.inheritsFrom'
                              defaultMessage='Inherits from {from}'
                              values={{ from }}
                            />
                          </Typography>
                        );
                        let resourceName = getResourceMessage(name);
                        return (
                          <div key={name} className={classNames(classes.resource, resourceClass)}>
                            <Typography color={active ? 'textPrimary' : 'textSecondary'}>{resourceName}</Typography>
                            <div className={classes.grow} />
                            {!xs && from && message}
                            {[
                              { disabled: rDisabled, checked: r, type: 'read' },
                              { disabled: wDisabled, checked: w, type: 'write' },
                            ].map(({ disabled, checked, type }) =>
                              disabled || !permissionsRoles.w ? (
                                <Tooltip
                                  key={type}
                                  title={
                                    !permissionsRoles.w ? (
                                      <FormattedMessage
                                        id='rolesPage.RolesTab.notEnoughPrivilegesForEditingRoles'
                                        defaultMessage="You don't have permission to edit roles"
                                      />
                                    ) : role === 'admin' ? (
                                      <FormattedMessage
                                        id='rolesPage.RolesTab.adminRoleDetailsTooltip'
                                        defaultMessage='Cannot edit admin roles'
                                      />
                                    ) : (
                                      <FormattedMessage
                                        id='rolesPage.RolesTab.inheritedPermissionsCannotBeRecalledTooltip'
                                        defaultMessage='Inherited permissions cannot be recalled'
                                      />
                                    )
                                  }
                                >
                                  <span>
                                    <Checkbox className={classes.checkbox} color='primary' checked={checked} disabled />
                                  </span>
                                </Tooltip>
                              ) : (
                                <Checkbox
                                  className={classes.checkbox}
                                  key={type}
                                  color='primary'
                                  checked={!!checked}
                                  onChange={setPermission(role, name, type, checked)}
                                />
                              )
                            )}
                            {xs && from && message}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </AccordionDetails>
              <Collapse in={!!showApplyDiscard} unmountOnExit>
                <AccordionActions>
                  <Button color='primary' onClick={handleDiscardChanges(role)}>
                    <FormattedMessage id='rolesTab.panelDetails.button.discard' defaultMessage='Discard' />
                  </Button>
                  <Button color='primary' onClick={handleApplyChanges(role)}>
                    <FormattedMessage id='rolesTab.panelDetails.button.apply' defaultMessage='Apply' />
                  </Button>
                </AccordionActions>
              </Collapse>
            </Accordion>
          );
        })}
      </div>
    );
  }
}

export default compose(
  connect(({ roles }) => ({ roles }), { setUnsavedChanges: rolesActions.setUnsavedChanges }),
  injectIntl,
  withStyles(styles)
)(Roles);
