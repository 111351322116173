import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import compose from 'recompose/compose';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import InstallIcon from '@material-ui/icons/OpenInNew';
import RemoveIcon from '@material-ui/icons/Remove';
import FileUpload from '../components/customIcons/FileUpload';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Zoom from '@material-ui/core/Zoom';
import Badge from '@material-ui/core/Badge';
import { Route, Link, withRouter } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Typography } from '@material-ui/core';

import ResponsiveDialog from '../components/responsive-dialog';
import CustomFab from '../components/CustomFab';
import FileUploader from '../components/FileUploader';
import ActionsMenu from '../components/ActionsMenu';
import { snackbarMessageTypes } from '../redux-stuff/constants';
import { fetcher } from '../util/deps';
import { parsePermissions } from '../util/permissions';
import { installUpdateMessages, getLatestVersions } from '../util/modulesHelpers';
import {
  modulesActions,
  modulesUploadsActions,
  servicesActions,
  appBarActions,
  diskUsageActions,
  firmwareActions,
} from '../redux-stuff/actions';
import Services from '../components/modulesAndServicesPage/Services';
import Modules from '../components/modulesAndServicesPage/Modules';
import BatchInstaller from '../components/modulesAndServicesPage/BatchInstaller.jsx';
import PageLoadingIndicator from '../components/PageLoadingIndicator';
import Alerts from '../components/Alerts';
import SemverDialog from '../components/SemverDialog';
import ModuleInfoDialog from '../components/modulesAndServicesPage/ModuleInfoDialog';

const styles = theme => ({
  root: {
    margin: 'auto',
    maxWidth: 1100,
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(-3),
    },
    overflow: 'auto',
  },
  mobileRoot: {
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    height: `calc(100vh - 56px)`,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: `calc(100vh - 48px)`,
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100vh - 64px)`,
    },
  },
  tabsBar: {
    backgroundColor: '#FFF',
    width: '100%',
    maxWidth: 1100,
    position: 'fixed',
    '& + *': {
      marginTop: theme.spacing(8),
    },
  },
  panel: {
    /** workaround to prevent horizontal shadow disappearing on element,
     *  wrapped in element with overflow: hidden
     */
    marginRight: '1px',
    marginLeft: '1px',
    marginBottom: '2px',
  },
  badge: {
    padding: `0 ${theme.spacing(2)}px`,
  },
});

const modulesWithConfirmation = ['system', 'netup_nginx'];

class ModulesAndServicesPage extends Component {
  constructor(props) {
    super(props);
    const { permissions } = props;

    this.state = {
      expanded: [],
      anchorEl: null,
      menuOpen: false,
      dialogOpen: false,
      modulesUploaderOpen: false,
      BIImagesUploaded: [],
      BIIsOpen: false,
      permissionsModules: parsePermissions(permissions, 'modules'),
      permissionsServices: parsePermissions(permissions, 'services'),
      selectedId: null,
      selectedList: [],
    };

    this.currentlyInstallingRegularModules = [];
  }

  componentDidMount() {
    const { dispatch } = this.props;

    this.actions = {
      ...bindActionCreators(modulesActions, dispatch),
      ...bindActionCreators(modulesUploadsActions, dispatch),
      ...bindActionCreators(servicesActions, dispatch),
      ...bindActionCreators(appBarActions, dispatch),
      ...bindActionCreators(diskUsageActions, dispatch),
      ...bindActionCreators(firmwareActions, dispatch),
    };
    this.actions.loadModules();
    this.actions.getModulesUploads();
    this.actions.loadServices();
    this.actions.loadDiskUsage();
    this.actions.loadFirmwareFiles();

    this.resumeInstall();
  }

  resumeInstall = () => {
    try {
      const BIImagesUploaded = JSON.parse(localStorage.BIImagesUploaded);
      localStorage.removeItem('BIImagesUploaded');

      const BIIsOpen = localStorage.BIIsOpen;
      localStorage.removeItem('BIIsOpen');

      if (BIImagesUploaded) {
        this.setState({ BIIsOpen, BIImagesUploaded }, this.batchInstallerInstallSelectedModules);
      }
    } catch (e) {}
  };

  componentWillUnmount() {
    this.actions.resetActions();
  }

  handleExpand = name => () => {
    const { expanded } = this.state;
    this.setState({ expanded: expanded.includes(name) ? expanded.filter(v => v !== name) : [...expanded, name] });
  };

  handleOpenMenu = selectedId => event => {
    event.stopPropagation();
    const selectedModulesService = this.props.services.find(s => s.module === selectedId);
    const selectedName = this.props.modules.find(({ id }) => id === selectedId).name;
    const modulesServiceId = selectedModulesService && selectedModulesService.id;
    this.setState({
      selectedId,
      selectedModuleIsActive: !!selectedModulesService,
      modulesServiceId,
      anchorEl: event.currentTarget,
      menuOpen: true,
      selectedName,
    });
  };

  handleOpenDialog = selectedId => () => {
    this.setState({ menuOpen: false }, () => this.setState({ selectedId, dialogOpen: true }));
  };

  handleCloseMenuDialog = () => this.setState({ anchorEl: null, dialogOpen: false, menuOpen: false });

  handleInstallDelete = (method, id) => async () => {
    const { selectedId, modulesServiceId, selectedName, installDialogOpen, updateDialogOpen } = this.state;
    const actionWillCauseReconnect = modulesWithConfirmation.includes(selectedName) && method === 'put';
    this.actions.setActions({ text: null, leftButton: null, rightButtons: null });
    this.setState({ menuOpen: false });

    if (actionWillCauseReconnect) {
      if (!installDialogOpen && !updateDialogOpen) {
        this.setState({ installDialogOpen: true });
        return;
      }
      this.setState({ waitingForReconnect: true });
    }

    this.setState({ selectedId: null });

    try {
      if (method === 'delete' && modulesServiceId) {
        // If module is active we have to uninstall its service before deleting
        await fetcher.delete(`services/${modulesServiceId}`);
      }

      await fetcher[method](`modules/${id || selectedId}`);
      if (!actionWillCauseReconnect) {
        this.props.addMessage({
          type: snackbarMessageTypes.success,
          data:
            method === 'delete' ? (
              <FormattedMessage id='modulesPage.moduleDeleted' defaultMessage='Module deleted' />
            ) : (
              <FormattedMessage id='modulesPage.moduleInstalled' defaultMessage='Module installed' />
            ),
        });
      }
      this.undoSelection();
    } catch (e) {
      this.props.addMessage({ type: snackbarMessageTypes.failure, data: e });
      this.setState({ waitingForReconnect: false });
    }
  };

  handleUninstall = async () => {
    const { modulesServiceId } = this.state;
    this.actions.setActions({ text: null, leftButton: null, rightButtons: null });
    this.setState({ selectedId: null, menuOpen: false, uninstallDialogOpen: false });

    try {
      await fetcher.delete(`services/${modulesServiceId}`);
      const services = await fetcher.get('services');
      this.actions.setServices(services);
      this.props.addMessage({
        type: snackbarMessageTypes.success,
        data: <FormattedMessage id='modulesPage.moduleUninstalled' defaultMessage='Module uninstalled' />,
      });
      this.undoSelection();
    } catch (e) {
      this.props.addMessage({
        type: snackbarMessageTypes.failure,
        data: e,
      });
    }
  };

  handleUninstallClick = () => this.setState({ uninstallDialogOpen: true, menuOpen: false });

  handleCloseDialogs = () =>
    this.setState({
      updateDialogOpen: false,
      installDialogOpen: false,
      uninstallDialogOpen: false,
      removeDialogOpen: false,
      installUpdateDialogOpen: false,
      deleteSelectedDialogOpen: false,
    });

  handleInstallUpdateClick = (name, type, dialogOpenViaMenu) => event => {
    if (event) {
      event.stopPropagation();
    }
    this.setState({
      updateDialogOpen: type === 'update',
      installDialogOpen: type === 'install',
      selectedName: name,
      dialogOpenViaMenu,
      menuOpen: false,
    });
  };

  handleUpdate = name => () => {
    let latestVersion = getLatestVersions(this.props.modules)[name];
    this.handleInstallDelete('put', latestVersion)();
    if (modulesWithConfirmation.includes(this.state.selectedName)) {
      this.setState({ waitingForReconnect: true });
    }
  };

  openInstallAllDialog = (BIImagesUploaded, allButtonAction) => () => {
    this.setState({ BIImagesUploaded, allButtonAction, installUpdateDialogOpen: true });
  };

  openFileUploader = () => this.state.dialogFrozen || this.setState({ modulesUploaderOpen: true });
  closeFileUploader = () => this.state.dialogFrozen || this.setState({ modulesUploaderOpen: false });

  // drag events won't toggle dialog
  openFileUploaderFreeze = () => this.setState({ modulesUploaderOpen: true, dialogFrozen: true });
  closeFileUploaderUnfreeze = () => this.setState({ modulesUploaderOpen: false, dialogFrozen: false });

  undoSelection = () => {
    this.setState({ selectedId: null, selectedList: [] });
    this.actions.setActions({ text: null, leftButton: null, rightButtons: null });
  };

  openBatchInstaller = imagesUploaded => {
    this.setState({ BIIsOpen: true, BIImagesUploaded: imagesUploaded });
    this.closeFileUploaderUnfreeze();
  };

  closeBatchInstaller = () => {
    this.setState({ BIIsOpen: false });
  };

  batchInstallerToggleModuleDetails = id => {
    this.setState(prev => {
      const new_ = prev.BIImagesUploaded.map(image => {
        if (image.id !== id) {
          return image;
        } else {
          return { ...image, detailsVisible: !image.detailsVisible };
        }
      });

      return { BIImagesUploaded: new_ };
    });
  };

  batchInstallerToggleModuleSelection = (id, selected) => {
    this.setState(prev => {
      const new_ = prev.BIImagesUploaded.map(image => {
        if (image.id !== id) {
          return image;
        } else {
          return { ...image, selected };
        }
      });

      return { BIImagesUploaded: new_ };
    });
  };

  batchInstallerSetInstallPhaseForModule = (imageToInstall, phase) => {
    return new Promise(res => {
      this.setState(
        ({ BIIsOpen, BIImagesUploaded }) => {
          const new_ = BIImagesUploaded.map(image => {
            if (image.id === imageToInstall.id) {
              return { ...image, installingPhase: phase };
            }

            return image;
          });

          // prettier-ignore
          const someWorkRemains = new_
            .filter(
              image => !BIIsOpen || image.selected
            )
            .some(
              image => !image.installingPhase || image.installingPhase === 'INSTALLING'
            );

          return { BIImagesUploaded: new_, BIWorking: someWorkRemains };
        },

        res
      );
    });
  };

  requestInstall = async image => {
    await fetcher.put(`modules/${image.id}`);
  };

  batchInstallerInstallSelectedModules = async () => {
    this.setState({ BIWorking: true });

    // prettier-ignore
    const imagesToInstall = this.state.BIImagesUploaded
      .filter(
        image => !this.state.BIIsOpen || image.selected
      )
      .filter(
        image => !image.installingPhase || image.installingPhase === 'INSTALLING'
      );

    for (const imageToInstall of imagesToInstall) {
      // won't get a proper response from these modules
      // gotta ask services list once connection is restored
      // system => disconnect => returns 204 no content => reload => resume from CDM
      // nginx => disconnect => returns 204 no content => resume from CDU
      if (['system', 'netup_nginx'].includes(imageToInstall.name)) {
        if (imageToInstall.installingPhase === 'INSTALLING') {
          const installSucceeded = await this.checkIfContainerExists(imageToInstall.id);
          if (installSucceeded) {
            await this.batchInstallerSetInstallPhaseForModule(imageToInstall, 'DONE');

            // why do we have success only for reloading modules? do we even need that message?
            this.props.addMessage({
              type: 'success',
              data: <FormattedMessage id='modulesPage.snackbar.moduleInstalled' defaultMessage='Module installed' />,
            });
          } else {
            await this.batchInstallerSetInstallPhaseForModule(imageToInstall, 'ERROR');

            const errorMessage = (
              <FormattedMessage
                id='error.BATCH_INSTALLER_NO_CONTAINER_AFTER_RECONNECT'
                defaultMessage='{image} service not found'
                values={{ image: imageToInstall.name }}
              />
            );
            const error = new Error(errorMessage);
            error.errorMessage = errorMessage;
            error.time = new Date();
            error.details = JSON.stringify({ image: imageToInstall });

            this.props.addMessage({
              type: snackbarMessageTypes.failure,
              data: error,
            });
          }

          continue;
        }

        await Promise.all(this.currentlyInstallingRegularModules);
        this.currentlyInstallingRegularModules = [];

        await this.batchInstallerSetInstallPhaseForModule(imageToInstall, 'INSTALLING');

        let waitForReload;
        // prettier-ignore
        await this.requestInstall(imageToInstall)
          // 204 backend fell off === install is initiated
          .then(
            () => {
              this.setState({ waitingForReconnect: true });
              waitForReload = true;

              if (imageToInstall.name === 'system') {
                localStorage.BIImagesUploaded = JSON.stringify(this.state.BIImagesUploaded);
                if (this.state.BIIsOpen) {
                  localStorage.BIIsOpen = 'true';
                }

                this.reloadRequred = true;
              }
            }
          )
          // works for 404, should work for other codes too
          .catch(
            (e) => {
              this.batchInstallerSetInstallPhaseForModule(imageToInstall, 'ERROR');
              this.props.addMessage({ type: snackbarMessageTypes.failure, data: e });
            }
          );

        if (waitForReload) return;

        continue;
      }

      await this.batchInstallerSetInstallPhaseForModule(imageToInstall, 'INSTALLING');

      let installStatus = this.requestInstall(imageToInstall)
        .then(() => this.batchInstallerSetInstallPhaseForModule(imageToInstall, 'DONE'))
        .catch(() => this.batchInstallerSetInstallPhaseForModule(imageToInstall, 'ERROR'));
      this.currentlyInstallingRegularModules.push(installStatus);
    }
  };

  checkIfContainerExists = async id => {
    const { services } = await fetcher.get('services', undefined, false);
    return !!(services && services.find(container => container.module === id));
  };

  clickItem = (module, isActive) => () => {
    const { id, version } = module;
    const { selectedList } = this.state;
    if (selectedList.length === 1) {
      if (selectedList[0] === id) {
        this.undoSelection();
        return;
      } else {
        // If selected module is active, we should deselect it
        const selectedName = this.props.modules.find(m => m.id === selectedList[0]).name;
        const selectedService = this.props.services.find(s => s.name === selectedName) || {};
        const selectedIsActive = selectedService.module === selectedList[0];
        if (selectedIsActive) {
          this.undoSelection();
          return;
        }
      }
    }

    if (isActive && selectedList.length > 0) {
      // If clicked module isActive and selectedList is not empty, we shouldn't do anything
      return;
    }

    const leftButton = {
      title: <FormattedMessage id='appBarButtons.title.deselectAll' defaultMessage='Undo selection' />,
      icon: <CloseIcon />,
      action: this.undoSelection,
    };
    if (selectedList.length === 0 || (selectedList.length === 2 && selectedList.includes(id))) {
      // We should show MoreVertIcon only when selectedList contains 1 element
      const newSelectedList = selectedList.length === 0 ? [id] : selectedList.filter(v => v !== id);
      const [newSelectedId] = newSelectedList;
      const menu = {
        key: 'menu',
        title: <FormattedMessage id='appBarButtons.title.openMenu' defaultMessage='Open menu' />,
        icon: <MoreVertIcon />,
        action: this.handleOpenMenu(newSelectedId),
      };
      this.actions.setActions({
        text: version,
        leftButton,
        rightButtons: [menu],
      });
      this.setState({ selectedId: newSelectedId, selectedList: newSelectedList });
    } else {
      // Otherwise, we should show DeleteIcon
      const newSelectedList = selectedList.includes(id) ? selectedList.filter(v => v !== id) : [...selectedList, id];
      const deleteButton = {
        key: 'delete',
        title: <FormattedMessage id='appBarButtons.title.deleteAll' defaultMessage='Delete all' />,
        icon: <DeleteIcon />,
        action: this.handleDeleteSelectedClick(newSelectedList),
      };
      this.actions.setActions({
        text: (
          <FormattedMessage
            id='appBarMessage.selectedCount'
            defaultMessage='{selected} selected'
            values={{ selected: newSelectedList.length }}
          />
        ),
        leftButton,
        rightButtons: [deleteButton],
      });
      this.setState({ selectedId: null, selectedList: newSelectedList });
    }
  };

  handleStartService = async id => {
    try {
      await fetcher.put(`services/${id}/start`);
      this.actions.loadServices();
    } catch (e) {
      this.props.addMessage({ type: snackbarMessageTypes.failure, data: e });
    }
  };

  handleStopService = async id => {
    try {
      await fetcher.put(`services/${id}/stop`);
      this.actions.loadServices();
    } catch (e) {
      this.props.addMessage({ type: snackbarMessageTypes.failure, data: e });
    }
  };

  handleDeleteModuleClick = () =>
    this.setState({ moduleToRemove: this.state.selectedId, removeDialogOpen: true, menuOpen: false });

  handleDeleteSelectedClick = selectedToDelete => () =>
    this.setState({ selectedToDelete, deleteSelectedDialogOpen: true });

  handleDeleteSelected = async () => {
    Promise.all(this.state.selectedToDelete.map(id => fetcher.delete(`modules/${id}`)))
      .then(() => {
        this.props.addMessage({
          type: snackbarMessageTypes.success,
          data: <FormattedMessage id='modulesPage.snackbar.modulesDeleted' defaultMessage='Modules deleted' />,
        });
        this.actions.loadModules();
        this.actions.loadServices();
        this.undoSelection();
        this.setState({ selectedToDelete: [] });
      })
      .catch(e => {
        this.undoSelection();
        this.setState({ selectedToDelete: [] });
        this.props.addMessage({ type: snackbarMessageTypes.failure, data: e });
      });
  };

  handleSetSelected = selectedList => () => this.setState({ selectedList });

  static mapStateToProps({ modules, modulesUploads, services, isSocketConnected, diskUsage, firmwareFiles }) {
    return { ...modules, modulesUploads, ...services, isSocketConnected, diskUsage, firmwareFiles };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isSocketConnected && this.props.isSocketConnected && this.state.waitingForReconnect) {
      this.actions.loadModules();
      this.actions.loadServices();
      this.actions.clearUpdatingModules();
      this.setState({ waitingForReconnect: false, updateDialogOpen: false, installDialogOpen: false });

      if (!this.state.BIWorking) {
        this.props.addMessage({
          type: 'success',
          data: <FormattedMessage id='modulesPage.snackbar.moduleInstalled' defaultMessage='Module installed' />,
        });

        if (this.state.selectedName === 'system') {
          setTimeout(() => window.location.reload(), 1000);
        }
      }

      if (this.state.BIWorking) {
        if (this.reloadRequred) {
          // batchInstallerInstallSelectedModules will be called in CDM
          setTimeout(() => window.location.reload(), 1000);
          this.reloadRequred = false;
        } else {
          this.batchInstallerInstallSelectedModules();
        }
      }
    }

    let { BIWorking, BIIsOpen, installUpdateDialogOpen, BIImagesUploaded } = this.state;
    if (!BIWorking && !BIIsOpen && !installUpdateDialogOpen && BIImagesUploaded.length) {
      this.setState({ BIImagesUploaded: [] });
    }
  }

  modulesWrapper = () => {
    const { classes, modules, modulesUploads, updatingModules, services, diskUsage } = this.props;
    const { selectedList, expanded, permissionsModules, BIImagesUploaded } = this.state;

    if (!modules || !services) return null;

    return (
      <div className={classNames(classes.panel)}>
        <Modules
          permissions={permissionsModules}
          modules={modules}
          modulesUploads={modulesUploads}
          services={services}
          usage={diskUsage.usage || {}}
          updatingModules={updatingModules}
          expanded={expanded}
          handleUpdateAll={this.openInstallAllDialog}
          handleExpand={this.handleExpand}
          handleInstallUpdateClick={this.handleInstallUpdateClick}
          handleOpenMenu={this.handleOpenMenu}
          handleDeleteSelected={this.handleDeleteSelectedClick}
          handleSetSelected={this.handleSetSelected}
          clickItem={this.clickItem}
          selectedList={selectedList}
          BIImagesUploaded={BIImagesUploaded}
        />
      </div>
    );
  };

  servicesWrapper = () => {
    const { classes, services = [] } = this.props;
    const { permissionsServices } = this.state;

    if (!services) return null;

    return (
      <div className={classNames(classes.panel)}>
        <Services
          services={services}
          start={this.handleStartService}
          stop={this.handleStopService}
          modulesWithBanOnActions={modulesWithConfirmation}
          permissions={permissionsServices}
        />
      </div>
    );
  };

  render() {
    const { classes, width, modules, services, addMessage, location, diskUsage, theme } = this.props;

    const {
      dialogOpen,
      removeDialogOpen,
      uninstallDialogOpen,
      updateDialogOpen,
      installDialogOpen,
      installUpdateDialogOpen,
      deleteSelectedDialogOpen,
      dialogOpenViaMenu,
      menuOpen,
      anchorEl,
      selectedId,
      selectedToDelete,
      modulesUploaderOpen,
      permissionsModules,
      permissionsServices,
      moduleToRemove,
      selectedModuleIsActive,
      selectedName,
      allButtonAction,
      waitingForReconnect,
      BIImagesUploaded,
    } = this.state;

    const { w } = permissionsModules;
    const xs = width === 'xs';
    const mobile = xs || isMobile;
    const { loadModules } = this.actions || {};
    const transitionDuration = {
      enter: theme.transitions.duration.enteringScreen,
      exit: theme.transitions.duration.leavingScreen,
    };

    const modulesDisabled = !permissionsModules.r;
    const servicesDisabled = !permissionsServices.r;

    const activeModule = modules?.find(m => m.id === selectedId);

    const tabIndex = location.pathname === '/modules/services' ? 1 : 0;

    const servicesIssue = (services || []).some(s => s.state === 'dead');
    return (
      <div className={classNames(classes.root, mobile ? classes.mobileRoot : '')}>
        <AppBar className={classes.tabsBar} position='static' color='default'>
          <Tabs value={tabIndex} indicatorColor='primary' textColor='primary'>
            <Tab
              label={<FormattedMessage id='modulesPage.modules' defaultMessage='Modules' />}
              disabled={modulesDisabled}
              component={Link}
              to='/modules/modules'
            />
            <Tab
              label={
                <Badge
                  className={classes.badge}
                  color='error'
                  badgeContent='!'
                  invisible={!servicesIssue}
                  overlap='rectangular'
                >
                  <FormattedMessage id='modulesPage.services' defaultMessage='Services' />
                </Badge>
              }
              disabled={servicesDisabled}
              component={Link}
              to='/modules/services'
            />
          </Tabs>
        </AppBar>

        <Alerts issuesPermission={parsePermissions(this.props.permissions, 'issues').r} />
        <PageLoadingIndicator open={services === null || (tabIndex === 0 && modules === null)} />

        <Route path={['/modules', '/modules/modules']} exact render={this.modulesWrapper} />
        <Route path='/modules/services' exact render={this.servicesWrapper} />

        <ModuleInfoDialog open={dialogOpen} onClose={this.handleCloseMenuDialog} activeModule={activeModule} />

        {w && (
          <ActionsMenu
            open={menuOpen}
            anchorEl={anchorEl}
            handleClose={this.handleCloseMenuDialog}
            items={[
              {
                show: w && !selectedModuleIsActive,
                Icon: InstallIcon,
                text: <FormattedMessage id='ActionsMenu.install' defaultMessage='Install' />,
                handler: this.handleInstallUpdateClick(selectedName, 'install', true),
              },
              {
                show: true,
                Icon: InfoIcon,
                text: <FormattedMessage id='ActionsMenu.info' defaultMessage='Info' />,
                handler: this.handleOpenDialog(selectedId),
              },
              {
                show: w && selectedModuleIsActive && !modulesWithConfirmation.includes(selectedName),
                Icon: RemoveIcon,
                text: <FormattedMessage id='ActionsMenu.uninstall' defaultMessage='Uninstall' />,
                handler: this.handleUninstallClick,
              },
              {
                show: w && !selectedModuleIsActive,
                Icon: DeleteIcon,
                text: <FormattedMessage id='ActionsMenu.delete' defaultMessage='Delete' />,
                handler: this.handleDeleteModuleClick,
              },
            ]}
          />
        )}
        {w && (
          <Zoom
            in={tabIndex === 0}
            timeout={transitionDuration}
            style={{
              transitionDelay: `${this.state.value === tabIndex ? transitionDuration.exit : 0}ms`,
            }}
            unmountOnExit
          >
            <CustomFab
              label={<FormattedMessage id='modulesPage.fabLabel.upload' defaultMessage='Upload' />}
              onClick={this.openFileUploaderFreeze}
              icon={FileUpload}
            />
          </Zoom>
        )}
        {w && (
          <FileUploader
            open={modulesUploaderOpen && tabIndex === 0}
            onClose={this.closeFileUploaderUnfreeze}
            onOpenDnD={this.openFileUploader}
            onCloseDnD={this.closeFileUploader}
            reloadFiles={loadModules}
            addMessage={addMessage}
            resource='modules'
            handleInstall={this.openBatchInstaller}
            title={<FormattedMessage id='moduleuploader.title' defaultMessage='Module upload' />}
          />
        )}
        {w && (
          <BatchInstaller
            open={this.state.BIIsOpen}
            onClose={this.closeBatchInstaller}
            imagesUploaded={this.state.BIImagesUploaded}
            toggleModuleDetails={this.batchInstallerToggleModuleDetails}
            toggleModuleSelection={this.batchInstallerToggleModuleSelection}
            installSelectedModules={this.batchInstallerInstallSelectedModules}
            BIWorking={this.state.BIWorking}
          />
        )}

        <ResponsiveDialog
          open={!!removeDialogOpen}
          title={<FormattedMessage id='modulesPage.removeModuleDialog.title' defaultMessage='Remove module' />}
          message={
            <FormattedMessage
              id='modulesPage.removeModuleDialog.newMessage'
              defaultMessage='Are you sure you want to remove {moduleToRemove}?'
              values={{ moduleToRemove: ((modules && modules.find(m => m.id === moduleToRemove)) || {}).name }}
            />
          }
          confirmButtonText={<FormattedMessage id='modulesPage.buttons.remove' defaultMessage='Remove' />}
          onClose={this.handleCloseDialogs}
          onConfirm={this.handleInstallDelete('delete')}
          closeOnConfirm
          withSpinner
          pendingBackendEvent={modules && modules.find(m => m.id === moduleToRemove)}
          fullWidth
        />

        <SemverDialog
          open={!!uninstallDialogOpen}
          title={<FormattedMessage id='modulesPage.uninstallModuleDialog.title' defaultMessage='Uninstall module' />}
          message={
            <FormattedMessage
              id='modulesPage.uninstallModuleDialog.conditionalMessage'
              defaultMessage={
                'Are you sure you want to uninstall {name}? {connectionWillBeLost, select, true ' +
                '{Connection with the server will be lost for a moment} other {}}'
              }
              values={{ connectionWillBeLost: modulesWithConfirmation.includes(selectedName), name: selectedName }}
            />
          }
          confirmButtonText={<FormattedMessage id='modulesPage.buttons.uninstall' defaultMessage='Uninstall' />}
          onClose={this.handleCloseDialogs}
          onConfirm={this.handleUninstall}
          fullWidth
          modulesToRemove={services.find(s => {
            if (s.id === this.state.modulesServiceId) {
              return modules.find(m => m.id === s.module);
            }
            return undefined;
          })}
        />

        <SemverDialog
          open={!!updateDialogOpen}
          title={<FormattedMessage id='modulesPage.updateModuleDialog.title' defaultMessage='Update module' />}
          message={
            <FormattedMessage
              id='modulesPage.updateModuleDialog.conditionalMessage'
              defaultMessage={
                'Are you sure you want to update {name}? {connectionWillBeLost, select, true ' +
                '{Connection with the server will be lost for a moment} other {}}'
              }
              values={{ connectionWillBeLost: modulesWithConfirmation.includes(selectedName), name: selectedName }}
            />
          }
          confirmButtonText={<FormattedMessage id='modulesPage.buttons.Update' defaultMessage='Update' />}
          onClose={this.handleCloseDialogs}
          onConfirm={this.handleUpdate(selectedName)}
          closeOnConfirm
          fullWidth
          withSpinner={modulesWithConfirmation.includes(selectedName)}
          pendingBackendEvent={waitingForReconnect}
          modulesToUpdate={modules && modules.find(m => m.id === getLatestVersions(modules)[selectedName])}
        />

        <SemverDialog
          open={!!installDialogOpen}
          title={<FormattedMessage id='modulesPage.installModuleDialog.title' defaultMessage='Install module' />}
          message={
            <FormattedMessage
              id='modulesPage.installModuleDialog.conditionalMessage'
              defaultMessage={
                'Are you sure you want to install {name}? {connectionWillBeLost, select, true ' +
                '{Connection with the server will be lost for a moment} other {}}'
              }
              values={{ connectionWillBeLost: modulesWithConfirmation.includes(selectedName), name: selectedName }}
            />
          }
          confirmButtonText={<FormattedMessage id='modulesPage.buttons.Install' defaultMessage='Install' />}
          onClose={this.handleCloseDialogs}
          onConfirm={dialogOpenViaMenu ? this.handleInstallDelete('put') : this.handleUpdate(selectedName)}
          closeOnConfirm
          fullWidth
          withSpinner={modulesWithConfirmation.includes(selectedName)}
          pendingBackendEvent={waitingForReconnect}
          modulesToUpdate={
            modules &&
            modules.find(m => m.id === (dialogOpenViaMenu ? selectedId : getLatestVersions(modules)[selectedName]))
          }
        />

        <SemverDialog
          open={!!installUpdateDialogOpen}
          title={
            <FormattedMessage
              id='modulesPage.installUpdateAllDialog.title'
              defaultMessage='{action} all modules'
              values={{ action: installUpdateMessages.title[allButtonAction] }}
            />
          }
          confirmButtonText={
            <FormattedMessage
              id='modulesPage.installUpdateAllDialog.buttonText'
              defaultMessage='{action}'
              values={{ action: installUpdateMessages.button[allButtonAction] }}
            />
          }
          message={
            <FormattedMessage
              id='modulesPage.installUpdateAllDialog.message'
              defaultMessage='Are you sure you want to {action} {isPlural, select, true {all modules} other {{moduleName}}}?'
              values={{
                action: installUpdateMessages.message[allButtonAction],
                isPlural: this.state.BIImagesUploaded && this.state.BIImagesUploaded.length > 1,
                moduleName:
                  this.state.BIImagesUploaded && this.state.BIImagesUploaded[0] && this.state.BIImagesUploaded[0].name,
              }}
            />
          }
          onClose={this.handleCloseDialogs}
          onConfirm={this.batchInstallerInstallSelectedModules}
          closeOnConfirm
          fullWidth
          modulesToUpdate={BIImagesUploaded}
        />

        <ResponsiveDialog
          open={!!deleteSelectedDialogOpen}
          title={
            <FormattedMessage id='modulesPage.deleteSelectedDialog.title' defaultMessage='Delete selected modules' />
          }
          message={
            <FormattedMessage
              id='modulesPage.deleteSelectedDialog.message'
              defaultMessage='Are you sure you want to delete selected modules?'
            />
          }
          confirmButtonText={
            <FormattedMessage id='modulesPage.deleteSelectedDialog.buttonText' defaultMessage='Delete' />
          }
          onClose={this.handleCloseDialogs}
          onConfirm={this.handleDeleteSelected}
          withSpinner
          pendingBackendEvent={
            deleteSelectedDialogOpen && (modules || []).some(({ id }) => selectedToDelete.includes(id))
          }
          fullWidth
        />
      </div>
    );
  }
}

export default connect(ModulesAndServicesPage.mapStateToProps)(
  compose(withWidth(), withStyles(styles, { withTheme: true }), withRouter)(ModulesAndServicesPage)
);
