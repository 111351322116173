import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';

const styles = () => ({
  denySelect: {
    userSelect: 'none',
    '& > span': { display: 'block' },
  },
});

const securePhrase = defineMessages({
  message: {
    id: 'ConfirmDialogWithPhrase.securePhrase',
    defaultMessage: `I understand what I'm doing and take responsibility for my actions`,
  },
});
class ConfirmPhrase extends React.Component {
  onChange = e => {
    const value = e.target.value.toLowerCase();
    if (
      value === this.props.intl.formatMessage(securePhrase.message).toLowerCase() ||
      // shortcut for support
      value === 'ялекс' ||
      value === 'я лекс'
    ) {
      this.props.setPhraseEntered();
    }
  };

  componentWillUnmount() {
    this.props.resetPhraseEntered();
  }

  render() {
    const { message, classes } = this.props;

    return (
      <Fragment>
        {message}
        <Typography variant='subtitle1' color='textSecondary' className={classes.denySelect}>
          <FormattedMessage
            id='ConfirmDialogWithPhrase.newMessage'
            defaultMessage={'Please, type the following text {securePhrase} to continue'}
            values={{ securePhrase: <b>{this.props.intl.formatMessage(securePhrase.message)}</b> }}
          />
        </Typography>

        <TextField
          label={<FormattedMessage id='ConfirmDialogWithPhrase.label' defaultMessage='Secure phrase' />}
          disabled={this.props.phraseEntered}
          fullWidth
          margin='normal'
          onChange={this.onChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Fragment>
    );
  }
}
export default compose(injectIntl, withStyles(styles))(ConfirmPhrase);
