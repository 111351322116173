import { useEffect, useState } from 'react';
import emitter from './emitter';
import ReconnectingWebsocket from 'reconnecting-websocket';

export type VtunSocketStatus = 'disconnected' | 'connecting' | 'connected' | 'reconnecting';
export type VtunSocketType = 'netup' | 'firmware';

interface SocketData {
  socket?: ReconnectingWebsocket;
  status: VtunSocketStatus;
  url: string;
}

const firmwareUrl = new URL('api/vtun/data', document.location.toString());
firmwareUrl.protocol = firmwareUrl.protocol === 'https:' ? 'wss:' : 'ws:';

const sockets: Record<VtunSocketType, SocketData> = {
  netup: { status: 'disconnected', url: 'ws://tuns.netup.tv/vtun' },
  firmware: { status: 'disconnected', url: firmwareUrl.toString() },
};

const getEventName = (type: VtunSocketType) => `${type}SocketStatus`;

const changeSocketStatus = (type: VtunSocketType, newStatus: VtunSocketStatus) => {
  emitter.emit(getEventName(type), newStatus);
  sockets[type].status = newStatus;
};

const socketConnect = (type: VtunSocketType) => {
  if (sockets[type].socket) return;
  changeSocketStatus(type, 'connecting');

  const socket = new ReconnectingWebsocket(sockets[type].url, 'binary');
  sockets[type].socket = socket;

  socket.onopen = () => changeSocketStatus(type, 'connected');

  socket.onclose = () => {
    if (!sockets[type].socket) return;
    changeSocketStatus(type, 'reconnecting');
  };

  socket.onerror = e => {
    if (!sockets[type].socket) return;
    console.error(`${type} socket error`, e);
    changeSocketStatus(type, 'reconnecting');
  };

  socket.onmessage = ({ data }) => {
    const dst = sockets[type === 'netup' ? 'firmware' : 'netup'].socket;
    if (!dst) return;
    dst.send(data);
  };
};

const socketDisconnect = (type: VtunSocketType) => {
  const socket = sockets[type].socket;
  if (socket) {
    socket.close();
    sockets[type].socket = undefined;
  }
  changeSocketStatus(type, 'disconnected');
};

export const vtunSocketsConnect = () => {
  socketConnect('netup');
  socketConnect('firmware');
};

export const vtunSocketsDisconnect = () => {
  socketDisconnect('netup');
  socketDisconnect('firmware');
};

export const useSocketStatus = (type: VtunSocketType) => {
  const [currentStatus, setCurrentStatus] = useState(sockets[type].status);

  useEffect(() => {
    const updateStatus = (newStatus: VtunSocketStatus) => setCurrentStatus(newStatus);
    const event = getEventName(type);
    emitter.on(event, updateStatus);
    return () => void emitter.removeListener(event, updateStatus);
  }, []);

  return currentStatus;
};
