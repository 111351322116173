import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVert from '@material-ui/icons/MoreVert';
import Tooltip from '@material-ui/core/Tooltip';
import { FormattedMessage, injectIntl } from 'react-intl';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Fade from '@material-ui/core/Fade';
import Collapse from '@material-ui/core/Collapse';
import AccordionActions from '@material-ui/core/AccordionActions';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';

import { usersActions } from '../../redux-stuff/actions';
import ReactSelect from '../../components/ReactSelect';
import { resources } from '../../redux-stuff/constants';
import { transform, spread } from '../../util/rolesHelpers';
import { IconButton } from '@material-ui/core';
import { getResourceMessage } from '../../util/get-resource-message';

const styles = theme => ({
  actionLabel: {
    width: theme.spacing(7),
    textOverflow: 'ellipsis',
    [theme.breakpoints.only('xs')]: {
      width: theme.spacing(6),
    },
  },
  actionLabels: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingRight: `${theme.spacing(1) + 1}px`, // 1px compensates for resource border width
    marginTop: 3 * theme.spacing(1),
  },
  basis20: {
    flexBasis: '20%',
  },
  checkbox: {
    width: theme.spacing(4),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(1),
    },
  },
  column: {
    display: 'flex',
    alignItems: 'center',
  },
  rolesColumn: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '40%',
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  buttonsColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexBasis: '40%',
    [theme.breakpoints.only('xs')]: {
      flexBasis: '80%',
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    width: '100%',
  },
  details: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  panel: {
    /** workaround to prevent horizontal shadow disappearing on element,
     *  wrapped in element with overflow: hidden
     */
    marginRight: '1px',
    marginLeft: '1px',
    '&:last-child': {
      marginBottom: '2px',
    },
    '&& > div > div': {
      margin: 0,
    },
  },
  resource: {
    display: 'flex',
    padding: `0 ${theme.spacing(1)}px`,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.12)',
  },
  resourcesBox: {
    maxHeight: 800,
    overflowY: 'auto',
  },
});

class Users extends React.Component {
  componentDidUpdate() {
    // updating unsavedChanges state
    const { editedUsers, lastLoadedUsers, unsavedChanges = [] } = this.props.users;
    editedUsers.forEach(user => {
      const unsavedChangesExist = !isEqual(
        user.roles.sort(),
        lastLoadedUsers.find(u => u.username === user.username).roles.sort()
      );
      if (unsavedChanges.includes(user.username) !== unsavedChangesExist) {
        this.props.setUnsavedChanges(user.username, unsavedChangesExist);
      }
    });
  }
  render() {
    const {
      classes,
      users,
      roles,
      rolesDisabled,
      expandedUsers,
      permissionsUsers,
      messages,
      searchUsers,
      intl,
      xs,
      handleExpandUsers,
      handleOpenMenu,
      handleChangeUserRoles,
      handleChangeSearchUsers,
      handleApplyChanges,
      handleDiscardChanges,
    } = this.props;

    const { editedUsers, unsavedChanges = [] } = users;

    return (
      <div>
        {editedUsers.length > 0 &&
          roles &&
          editedUsers.map(user => {
            const { username, roles: userRoles } = user;
            const panelExpanded = expandedUsers.includes(username);
            const existingUserRoles = rolesDisabled ? userRoles : userRoles.filter(r => roles[r]);
            const restRoles = rolesDisabled ? [] : Object.keys(roles).filter(r => !existingUserRoles.includes(r));
            const rolesToMerge = rolesDisabled
              ? []
              : [
                  ...existingUserRoles,
                  ...existingUserRoles.reduce((acc, r) => [...acc, ...(roles[r]['$extend'] || [])], []),
                ];
            const mergedRoles = rolesDisabled
              ? []
              : rolesToMerge
                  .filter(role => roles[role])
                  .reduce(
                    (acc, role) =>
                      Object.entries(roles[role]).reduce((a, [k, v]) => ({ ...a, [k]: spread(v, acc[k]) }), acc),
                    resources.reduce((a, r) => ({ ...a, [r]: {} }), {})
                  );
            const showApplyDiscard = unsavedChanges.includes(username);
            const showSummaryButtons = showApplyDiscard && !!panelExpanded;
            return (
              <Accordion
                key={username}
                className={classes.panel}
                expanded={panelExpanded}
                onChange={handleExpandUsers(username)}
              >
                <AccordionSummary
                  expandIcon={
                    <Tooltip title={<FormattedMessage id='rolesPage.showHide' defaultMessage='Show/Hide' />}>
                      <ExpandMoreIcon />
                    </Tooltip>
                  }
                >
                  <div className={classes.container}>
                    <div className={classNames(classes.column, classes.basis20)}>
                      <Typography>{username}</Typography>
                    </div>
                    <div className={classes.rolesColumn}>
                      {existingUserRoles.length > 0 && (
                        <Fade in={!panelExpanded} unmountOnExit>
                          <Typography color='textSecondary'>
                            <FormattedMessage
                              id='rolesPage.userRoles'
                              defaultMessage='Roles: {roles}'
                              values={{ roles: existingUserRoles.join(', ') }}
                            />
                          </Typography>
                        </Fade>
                      )}
                    </div>
                    <div className={classes.buttonsColumn}>
                      <Fade in={showSummaryButtons}>
                        <span>
                          <IconButton onClick={handleDiscardChanges(username)} disabled={!showSummaryButtons}>
                            <CloseIcon color='primary' />
                          </IconButton>
                          <IconButton onClick={handleApplyChanges(username)} disabled={!showSummaryButtons}>
                            <CheckIcon color='primary' />
                          </IconButton>
                        </span>
                      </Fade>
                      {permissionsUsers.w && (
                        <Fade in={!!panelExpanded}>
                          <Tooltip
                            title={<FormattedMessage id='rolesPage.moreActions' defaultMessage='More actions' />}
                            disableFocusListener={!panelExpanded}
                            disableHoverListener={!panelExpanded}
                            disableTouchListener={!panelExpanded}
                          >
                            <span>
                              <IconButton onClick={handleOpenMenu(username)} disabled={!panelExpanded}>
                                <MoreVert />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Fade>
                      )}
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={classes.details}>
                    {!rolesDisabled && (
                      <Fragment>
                        <ReactSelect
                          values={existingUserRoles}
                          suggestions={restRoles}
                          handleChange={handleChangeUserRoles(username, existingUserRoles)}
                          placeholder={<FormattedMessage id='rolesPage.selectRoles' defaultMessage='Select roles' />}
                          label={
                            <FormattedMessage
                              id='rolesPage.expanded.userRolesSelectTitle'
                              defaultMessage='User roles'
                            />
                          }
                          disabledMessage={
                            <FormattedMessage
                              id='rolesPage.usersTab.addRolesToAdminErrorMessage'
                              defaultMessage='Cannot assign roles to admin'
                            />
                          }
                          isDisabled={username === 'admin'}
                        />
                        <div className={classes.actionLabels}>
                          {!xs && (
                            <TextField
                              label={<FormattedMessage id='rolesPage.search' defaultMessage='Search' />}
                              value={searchUsers[username] || ''}
                              onChange={handleChangeSearchUsers(username)}
                              margin='none'
                            />
                          )}
                          <div className={classes.grow} />
                          <Typography
                            variant='caption'
                            className={classes.actionLabel}
                            align='center'
                            noWrap
                            title={intl.formatMessage({ id: 'usersAndRolesPage.Read' })}
                          >
                            {intl.formatMessage(messages.read)}
                          </Typography>
                          <Typography
                            variant='caption'
                            className={classes.actionLabel}
                            align='center'
                            noWrap
                            title={intl.formatMessage({ id: 'usersAndRolesPage.Write' })}
                          >
                            {intl.formatMessage(messages.write)}
                          </Typography>
                        </div>
                        <div className={classes.resourcesBox}>
                          {resources
                            .filter(name => {
                              const localizedName = intl.formatMessage({ id: `resource.${name}` });
                              return (
                                searchUsers[username] === undefined || localizedName.includes(searchUsers[username])
                              );
                            })
                            .map(name => {
                              const { r, w } = transform(mergedRoles[name]);
                              let resourceName = getResourceMessage(name);
                              return (
                                <div key={name} className={classes.resource}>
                                  <Typography color='textPrimary'>{resourceName}</Typography>
                                  <div className={classes.grow} />
                                  {[r, w].map((v, i) => (
                                    <Checkbox
                                      key={i}
                                      className={classes.checkbox}
                                      color='primary'
                                      checked={v || username === 'admin'}
                                      disabled
                                    />
                                  ))}
                                </div>
                              );
                            })}
                        </div>
                      </Fragment>
                    )}
                  </div>
                </AccordionDetails>
                <Collapse in={!!showApplyDiscard} unmountOnExit>
                  <AccordionActions>
                    <Button color='primary' onClick={handleDiscardChanges(username)}>
                      <FormattedMessage id='usersTab.panelDetails.button.discard' defaultMessage='Discard' />
                    </Button>
                    <Button color='primary' onClick={handleApplyChanges(username)}>
                      <FormattedMessage id='usersTab.panelDetails.button.apply' defaultMessage='Apply' />
                    </Button>
                  </AccordionActions>
                </Collapse>
              </Accordion>
            );
          })}
      </div>
    );
  }
}

export default compose(
  connect(({ users }) => ({ users }), { setUnsavedChanges: usersActions.setUnsavedChanges }),
  injectIntl,
  withStyles(styles)
)(Users);
