import { SET_FIRMWARE_UPLOADS } from '../constants';

export default (firmwareUploads = null, action) => {
  switch (action.type) {
    case SET_FIRMWARE_UPLOADS:
      return action.payload;
    default:
      return firmwareUploads;
  }
};
