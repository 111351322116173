import { SET_CONNECTIONS } from '../constants';

export default (connections = null, action) => {
  switch (action.type) {
    case SET_CONNECTIONS:
      return action.payload;
    default:
      return connections;
  }
};
