import {
  backupActions,
  firmwareActions,
  firmwareUploadsActions,
  infoActions,
  issuesActions,
  loadActions,
  networkActions,
  modulesActions,
  modulesUploadsActions,
  servicesActions,
  licenseActions,
  vtunActions,
  diskUsageActions,
  rebootActions,
} from '../redux-stuff/actions';
import store from '../util/store';
import { omitAdditionalFields, getConnectionLossMessage } from '../util/commonHelpers';
import emitter from '../emitter';

export default {
  backup: event => {
    switch (event.name) {
      case 'update': {
        store.dispatch(issuesActions.loadIssues());

        /** Backend's data here is without 'links' property.
         * So we need to keep current backup the same to prevent link omitting  */
        let newBackupsWithoutLinks = event.data;
        const currentBackup = store.getState().backups.find(({ filename }) => filename === 'backup_current.tar.bz2');
        if (currentBackup) {
          newBackupsWithoutLinks = [
            ...newBackupsWithoutLinks.filter(({ filename }) => filename !== 'backup_current.tar.bz2'),
            currentBackup,
          ];
        }
        store.dispatch(backupActions.setBackups(newBackupsWithoutLinks), true);
        break;
      }
      case 'upload':
        store.dispatch(issuesActions.loadIssues());

        store.dispatch(backupActions.addBackup(event.data), true);
        break;
      case 'install':
        break;
      case 'delete':
        store.dispatch(issuesActions.loadIssues());

        store.dispatch(backupActions.removeBackup(event.data.filename), true);
        break;
      default:
        console.warn(`Unknown event '${event.name}' in topic 'backup'`); // eslint-disable-line
        break;
    }
  },
  firmware: event => {
    switch (event.name) {
      case 'upload':
        store.dispatch(firmwareActions.addFirmware(event.data, true));
        break;
      case 'delete':
        store.dispatch(firmwareActions.deleteFirmwareAction(event.data, true));
        break;
      case 'install':
        store.dispatch(infoActions.loadInfo());
        break;
      case 'upload_started':
        store.dispatch(firmwareUploadsActions.getFirmwareUploads());
        break;
      default:
        console.warn(`Unknown event '${event.name}' in topic 'firmware'`); // eslint-disable-line
        break;
    }
  },
  info: event => {
    switch (event.name) {
      case 'update':
        // store.dispatch(infoActions.setInfoBuild(event.data.build));
        store.dispatch(infoActions.setInfoSystem(event.data.system));
        store.dispatch(infoActions.setInfoAlias(event.data.alias));
        break;
      default:
        console.warn(`Unknown event '${event.name}' in topic 'info'`); // eslint-disable-line
    }
  },
  load: event => {
    switch (event.name) {
      case 'update':
        store.dispatch(loadActions.setLoad(event.data));
        break;
      default:
        console.warn(`Unknown event '${event.name}' in topic 'load'`); // eslint-disable-line
        break;
    }
  },
  network: event => {
    switch (event.name) {
      case 'trial':
      case 'accept':
      case 'decline':
      case 'setup':
        store.dispatch(networkActions.setNetwork(event.data));
        store.dispatch(networkActions.updateMissingRoutes());
        break;
      default:
        console.warn(`Unknown event '${event.name}' in topic 'network'`); //eslint-disable-line
        break;
    }
  },
  modules: async event => {
    switch (event.name) {
      case 'updating':
        if (event.data.updating) {
          store.dispatch(modulesActions.addUpdatingModule(event.data.id));
        } else {
          store.dispatch(modulesActions.removeUpdatingModule(event.data.id));
        }
        break;
      case 'update':
        store.dispatch(modulesActions.setModules(event.data));
        break;
      case 'remove':
        store.dispatch(modulesActions.loadModules());
        break;
      case 'upload_started':
        store.dispatch(modulesUploadsActions.getModulesUploads());
        break;
      case 'datausage':
        store.dispatch(diskUsageActions.loadDiskUsage());
        break;
      default:
        console.warn(`Unknown event '${event.name}' in topic 'modules'`); //eslint-disable-line
        break;
    }
  },
  services: async event => {
    switch (event.name) {
      case 'update':
        store.dispatch(servicesActions.setServiceStatus(event.data));
        break;
      case 'install':
        store.dispatch(servicesActions.addService(event.data));
        break;
      case 'delete':
        store.dispatch(servicesActions.deleteService(event.data));
        break;
      default:
        console.warn(`Unknown event '${event.name}' in topic 'services'`); //eslint-disable-line
        break;
    }
  },
  license: async event => {
    switch (event.name) {
      case 'update':
        store.dispatch(issuesActions.loadIssues());

        store.dispatch(licenseActions.setLicense(event.data));
        break;
      default:
        console.warn(`Unknown event '${event.name}' in topic 'license'`); //eslint-disable-line
        break;
    }
  },
  vtun: event => {
    switch (event.name) {
      case 'update': {
        const { pendingChangesIface } = store.getState().vtun;
        /* If there is not null in pendingChangesIface, we consider that update was caused by our client, 
          and we will update store in request action, based on response */
        if (pendingChangesIface === null) {
          const newState = omitAdditionalFields(event.data);
          store.dispatch(vtunActions.setInitialState(newState));
        }
        break;
      }
      default:
        break;
    }
  },
  __connection: event => {
    switch (event.name) {
      case 'greeting': {
        if (localStorage.BIImagesUploaded) return;

        let servicesList = store.getState().services.services;
        if (servicesList === null) return;

        let systemDownstream = (servicesList.find(service => service.name === 'system') || {}).id;
        let systemUpstream = event.data.id;

        if (systemDownstream && systemUpstream && systemUpstream !== systemDownstream) {
          emitter.emit('addMessage', { isReload: true });
        }
        break;
      }
      default:
        break;
    }
  },
  _connectivity: event => {
    switch (event.name) {
      case 'connection_loss': {
        store.dispatch(rebootActions.setIsRebooting(true));
        emitter.emit('addMessage', {
          type: 'info',
          data: getConnectionLossMessage(event.data.reason),
          rebootingMessage: true,
          highPriority: true,
        });
        break;
      }
      default:
        break;
    }
  },
};
