import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import Done from '@material-ui/icons/Done';
import Close from '@material-ui/icons/Close';
import Error from '@material-ui/icons/Error';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Collapse from '@material-ui/core/Collapse';
import Add from '@material-ui/icons/Add';
import Backdrop from '@material-ui/core/Backdrop';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { capitalize } from 'lodash';

import ConfirmPopover from '../ConfirmPopover';
import { segregateAddresses } from '../../util/networkHelpers';
import { getIps } from '../../util/networkHelpers';
import { Typography } from '@material-ui/core';
import CollapseWidth from '../CollapseWidth';

const FIELD_WIDTH = 160;

const styles = theme => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
    '& > p': {
      marginRight: theme.spacing(1),
    },
  },
  inputChipsContainer: {
    width: '100%',
    minHeight: '84px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'baseline',
  },
  leftContainer: {
    display: 'flex',
    flexBasis: '100%',
    flexDirection: 'column',
    minHeight: '84px',
    [theme.breakpoints.up('sm')]: {
      flexBasis: '35%',
    },
  },
  editingContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '84px',
    flexBasis: '100%',
    [theme.breakpoints.up('sm')]: {
      flexBasis: '35%',
    },
  },
  buttons: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    width: FIELD_WIDTH * 1.5,
    [theme.breakpoints.only('xs')]: {
      maxWidth: 200,
    },
  },
  chips: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexBasis: '65%',
      flexWrap: 'wrap',
    },
  },
  chip: {
    margin: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px 0px`,
  },
  selected: {
    backgroundColor: '#F8BBD0',
    '&&:hover': {
      backgroundColor: '#F48FB1',
    },
  },
  zIndexTop: {
    zIndex: '10000',
  },
});

class IpContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { synchronizePopoverOpen: false, anchorEl: null, actualIps: '', creatingMode: false };
    this.inputRef = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.actualIps && props.ipMismatch.length > 0) {
      return { actualIps: props.ipMismatch.join(', ') };
    }
    return null;
  }

  handleKeyDown = disableAdding => e => {
    if (e.key === 'Enter' && !disableAdding) {
      this.props.handleAddAddress(this.props.name, capitalize(this.props.type))();
      this.setState({ creatingMode: false });
    } else if (e.key === 'Escape') {
      this.setCreatingModeWrapper(null, false)();
    }
  };

  setCreatingModeWrapper = (cb, creatingMode) => e => {
    this.setState({ creatingMode });
    if (!creatingMode) {
      // We need to reset selectedIp and newIp
      this.props.handleDeselectChip(this.props.name, capitalize(this.props.type))();
    } else {
      this.inputRef.current.focus();
    }
    if (cb) {
      cb(e);
    }
  };

  handleOpenSynchronizePopover = event =>
    this.setState({ synchronizePopoverOpen: true, anchorEl: event.currentTarget });

  handleCloseSynchronizePopover = () => this.setState({ synchronizePopoverOpen: false, anchorEl: null });

  handleSynchronizeConfig = (name, type) => () => this.props.handleSynchronizeConfig(name, type);

  handlePopoverExited = () => this.setState({ actualIps: '' });

  render() {
    const {
      classes,
      selectedIp,
      addresses,
      name: ifaceName,
      ipError,
      newIp,
      actualAddresses,
      type,
      handleChangeInput,
      handleAddAddress,
      handleDeselectChip,
      handleSelectChip,
      handleDeleteChip,
      ipMismatch,
      synchronizingIface,
      w,
    } = this.props;

    const configAddresses = getIps(addresses)[type] || [];
    const disableAdding = !newIp || !!ipError || configAddresses.some(ip => ip === newIp);
    const actualAddressesToRender = segregateAddresses(actualAddresses || [])[type.slice(2)];
    const { synchronizePopoverOpen, anchorEl, actualIps } = this.state;
    const capitalizedType = capitalize(type);
    const version = 'IPv' + type[3];
    return (
      <div className={classes.inputChipsContainer}>
        <Backdrop
          open={this.state.creatingMode}
          onClick={this.setCreatingModeWrapper(null, false)}
          style={{ zIndex: `${this.state.creatingMode ? 9999 : -1}` }}
          invisible
        />
        <div
          className={classNames(
            selectedIp ? classes.editingContainer : classes.leftContainer,
            this.state.creatingMode ? classes.zIndexTop : ''
          )}
        >
          <Collapse in={!this.state.creatingMode}>
            <div className={classes.buttonsContainer}>
              <Typography>{version}</Typography>
              <CollapseWidth in={ipMismatch.length > 0}>
                <Tooltip
                  title={
                    <FormattedMessage
                      id='networkPage.synchronizeConfig'
                      defaultMessage='Synchronize saved and actual {version} addresses ({actualIps})'
                      values={{ actualIps, version }}
                    />
                  }
                  disableFocusListener={this.props.ipMismatch.length === 0}
                >
                  <IconButton onClick={w ? this.handleOpenSynchronizePopover : null}>
                    <Error color='error' />
                  </IconButton>
                </Tooltip>
              </CollapseWidth>
              <IconButton onClick={this.setCreatingModeWrapper(null, true)}>
                <Add />
              </IconButton>
            </div>
          </Collapse>
          <Collapse in={this.state.creatingMode}>
            <div className={classes.container}>
              <FormControl className={classes.formControl} disabled={!w}>
                <InputLabel htmlFor={`${type}-${ifaceName}`} error={!!ipError}>
                  {version}
                </InputLabel>
                <Input
                  onKeyDown={this.handleKeyDown(disableAdding)}
                  inputRef={this.inputRef}
                  id={`${type}-${ifaceName}`}
                  value={newIp}
                  error={!!ipError}
                  onChange={handleChangeInput(ifaceName, capitalizedType)}
                />
                <FormHelperText id={`${type}-error-${ifaceName}`} error={!!ipError}>
                  {ipError}
                </FormHelperText>
              </FormControl>
              <div className={classes.buttons}>
                <Tooltip title={<FormattedMessage id='networkPage.apply' defaultMessage='Apply' />}>
                  <div>
                    <IconButton
                      disabled={disableAdding}
                      onClick={this.setCreatingModeWrapper(handleAddAddress(ifaceName, capitalizedType), false)}
                    >
                      <Done />
                    </IconButton>
                  </div>
                </Tooltip>
                <Tooltip title={<FormattedMessage id='networkPage.discard' defaultMessage='Discard' />}>
                  <div>
                    <IconButton
                      onClick={this.setCreatingModeWrapper(
                        selectedIp ? handleDeselectChip(ifaceName, capitalizedType) : null,
                        false
                      )}
                    >
                      <Close />
                    </IconButton>
                  </div>
                </Tooltip>
              </div>
            </div>
          </Collapse>
        </div>
        <div className={classes.chips}>
          {configAddresses.map(ip => (
            <Chip
              key={ip}
              label={ip}
              onClick={w ? this.setCreatingModeWrapper(handleSelectChip(ifaceName, capitalizedType, ip), true) : null}
              onDelete={w ? handleDeleteChip(ifaceName, capitalizedType, ip) : null}
              className={classNames(classes.chip, ip === selectedIp ? classes.selected : '')}
              variant='outlined'
            />
          ))}

          {actualAddressesToRender.map(addressBundle => {
            const { address, scope, flags } = addressBundle;

            const isAutomatic = scope === 'link' || (scope === 'global' && flags.includes('dynamic'));

            if (!isAutomatic) return null;
            // Auto-assigned addresses. They need to be displayed here since they can't go into config.

            return (
              <Tooltip
                key={address}
                title={
                  <FormattedMessage
                    id='networkPage.tooltip.dynamicallyAssignedAddress'
                    defaultMessage='Dynamically assigned address'
                  />
                }
                placement='top'
              >
                <Chip
                  label={address}
                  className={classNames(classes.chip, address === selectedIp ? classes.selected : '')}
                  variant='outlined'
                />
              </Tooltip>
            );
          })}
        </div>
        {w && (
          <ConfirmPopover
            open={synchronizePopoverOpen}
            anchorEl={anchorEl}
            onClose={this.handleCloseSynchronizePopover}
            onExited={this.handlePopoverExited}
            onConfirm={this.handleSynchronizeConfig(ifaceName, type)}
            message={
              <FormattedMessage
                id='networkPage.confirmSynchronize'
                defaultMessage='Do you want to synchronize saved and actual {version} addresses ({actualIps})?'
                values={{ actualIps, version }}
              />
            }
            withSpinner
            pendingBackendEvent={synchronizingIface}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(IpContainer);
