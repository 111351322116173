import {
  TOGGLE_WIDGET_STATE,
  SET_WIDGET_STATE,
  SET_SETTINGS_DIALOG_OPEN,
  SET_SAVE_ALIAS_TO_LOCALSTORAGE,
} from '../constants';

const toggleWidgetState = name => ({ type: TOGGLE_WIDGET_STATE, payload: name });

const setWidgetState = (name, state) => ({ type: SET_WIDGET_STATE, payload: [name, state] });

const setSettingsDialogOpen = state => ({ type: SET_SETTINGS_DIALOG_OPEN, payload: state });

const setSaveAliasToLocalStorage = state => ({ type: SET_SAVE_ALIAS_TO_LOCALSTORAGE, payload: state });

export default { toggleWidgetState, setWidgetState, setSettingsDialogOpen, setSaveAliasToLocalStorage };
