import {
  SET_USERS,
  UPDATE_USER,
  DISCARD_USER_CHANGES,
  SET_UNSAVED_USERS_CHANGES,
  CLEAR_UNSAVED_USERS_CHANGES,
} from '../constants';

export default (users = null, action) => {
  switch (action.type) {
    case SET_USERS: {
      const { newUsers, userToUpdate } = action.payload;
      let editedUsers;
      if (userToUpdate) {
        const newUser = newUsers.find(u => u.username === userToUpdate);
        if (newUser) {
          editedUsers = users.editedUsers.find(u => u.username === userToUpdate)
            ? users.editedUsers.map(u => (u.username !== userToUpdate ? u : newUser))
            : [...users.editedUsers, newUser];
        } else {
          // In this case user was removed
          editedUsers = users.editedUsers.filter(({ username }) => username !== userToUpdate);
        }
      } else {
        // If nothing passed as userToUpdate, we should update all users
        editedUsers = newUsers;
      }
      return { ...users, lastLoadedUsers: newUsers, editedUsers };
    }
    case UPDATE_USER: {
      const { username, roles } = action.payload;
      return { ...users, editedUsers: users.editedUsers.map(u => (u.username === username ? { ...u, roles } : u)) };
    }
    case DISCARD_USER_CHANGES: {
      const username = action.payload;
      const lastLoadedState = users.lastLoadedUsers.find(u => u.username === username);
      return { ...users, editedUsers: users.editedUsers.map(u => (u.username === username ? lastLoadedState : u)) };
    }
    case SET_UNSAVED_USERS_CHANGES: {
      const { unsavedChanges = [] } = users;
      const [user, unsavedChangesExist] = action.payload;
      const newUnsavedChanges = [...unsavedChanges.filter(i => i !== user), ...(unsavedChangesExist ? [user] : [])];
      return { ...users, unsavedChanges: newUnsavedChanges };
    }
    case CLEAR_UNSAVED_USERS_CHANGES: {
      return { ...users, unsavedChanges: [] };
    }
    default:
      return users;
  }
};
