import React, { Fragment } from 'react';
import compose from 'recompose/compose';
import { withRouter, Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import emitter from '../emitter';
import { unloadMessage } from '../redux-stuff/constants';

const styles = {
  snackbarContent: {
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    padding: '8px 16px',
  },
  link: {
    textDecoration: 'none',
  },
};

class CustomLink extends React.Component {
  handleLinkClick = e => {
    e.preventDefault();
    let message;
    if (this.props.location.pathname === '/network') {
      message = `${this.props.intl.formatMessage(unloadMessage.message)}: ${this.props.network.unsavedChanges.join(
        ', '
      )}`;
    } else if (this.props.location.pathname.includes('users-roles')) {
      const unsavedRoles = this.props.roles && (this.props.roles.unsavedChanges || []);
      const unsavedUsers = this.props.users && (this.props.users.unsavedChanges || []);
      message = `${this.props.intl.formatMessage(unloadMessage.message)}: ${[...unsavedRoles, ...unsavedUsers].join(
        ', '
      )}`;
    }
    emitter.emit('addMessage', {
      type: 'info',
      DOMMessage: message,
      highPriority: true,
      actionButtons: (
        <Fragment>
          <Button color='secondary'>
            <FormattedMessage id='snackbar.pageLeaving.button.stay' defaultMessage='Stay' />
          </Button>
          <Button color='secondary' onClick={this.handleConfirmLeave}>
            <FormattedMessage id='snackbar.pageLeaving.button.leave' defaultMessage='Leave' />
          </Button>
        </Fragment>
      ),
    });
  };

  handleConfirmLeave = () => this.props.history.push(this.props.to);

  render() {
    const { classes, replace, to, network, users, roles, location, children } = this.props;
    let needToConfirm;
    if (location.pathname === '/network' && to !== '/network' && network && (network.unsavedChanges || []).length > 0) {
      needToConfirm = true;
    } else if (
      location.pathname.includes('users-roles') &&
      !to.includes('users-roles') &&
      ((users && (users.unsavedChanges || []).length > 0) || (roles && (roles.unsavedChanges || []).length > 0))
    ) {
      needToConfirm = true;
    }
    return (
      <Link {...{ className: classes.link, replace, to, onClick: needToConfirm ? this.handleLinkClick : null }}>
        {children}
      </Link>
    );
  }
}

export default compose(
  injectIntl,
  connect(({ network, users, roles }) => ({ network, users, roles })),
  withRouter,
  withStyles(styles)
)(CustomLink);
