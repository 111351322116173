import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Badge from '@material-ui/core/Badge';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import classnames from 'classnames';

import { getSize } from '../../util/commonHelpers';
import ProgressBar from '../ProgressBar';
import SmartDialog from './SmartDialog';
import ResponsiveDialog from '../responsive-dialog';

const styles = theme => ({
  item: {
    display: 'flex',
  },
  actions: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingBottom: 0,
    paddingTop: 0,
    paddingLeft: '16px',
  },
  collapsibleActions: {
    marginTop: 0,
  },
  card: {
    flexGrow: 1,
  },
  cardContent: {
    paddingBottom: 1,
  },
  minHeight: {
    minHeight: '56px',
  },
  collapsibleContent: {
    paddingBottom: theme.spacing(1),
  },
  header: {
    backgroundColor: theme.palette.widget.headerBackground,
    padding: '16px 12px',
  },
  headerText: {
    color: theme.palette.widget.headerText,
  },
  expand: {
    transform: 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(0deg)',
  },
  badge: {
    top: '2px',
    right: '-6px',
    height: '8px',
    width: '8px',
  },
  button: {
    '& + &': {
      marginLeft: theme.spacing(1),
    },
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  iconButton: {
    padding: theme.spacing(1),
  },
  raid: {
    whiteSpace: 'pre',
    fontFamily: "'Roboto mono', monospace",
  },
});

const freeSpaceLabel = (free, total) => {
  return (
    <FormattedMessage
      id='mainPage.disksWidget.freeSpaceLabel'
      defaultMessage='{free} free of {total}'
      values={{ free, total }}
    />
  );
};

const ConditionCardActionArea = ({ children, withArea, onClick }) =>
  withArea ? <CardActionArea onClick={onClick}>{children}</CardActionArea> : children;

class DisksWidget extends React.Component {
  state = { expanded: false, smartDialogOpen: false, raidDialogOpen: false };

  handleExpandClick = () => this.setState({ expanded: !this.state.expanded });

  handleSmartClick = () => this.setState({ smartDialogOpen: true });

  handleRaidClick = () => this.setState({ raidDialogOpen: true });

  handleCloseSmartDialog = () => this.setState({ smartDialogOpen: false });

  handleCloseRaidDialog = () => this.setState({ raidDialogOpen: false });

  render() {
    const { disksInfo = {}, classes } = this.props;
    const [first, second, ...restPoints] = Object.entries(disksInfo.mountpoints || {});
    const pointsToShowInRolled = [first, second].filter(Boolean);
    const collapsible = restPoints.length > 0;
    const problemDisksCount = Object.values(disksInfo.smart || {}).filter(({ health }) => health !== 'passed').length;
    const raidError = Object.values(disksInfo.mdhealth || {}).some(({ degraded }) => !!degraded);
    const showRaid = disksInfo.mdhealth && Object.keys(disksInfo.mdhealth).length > 0;
    const showSmart = disksInfo.smart && Object.keys(disksInfo.smart).length > 0;
    return (
      <Fragment>
        <Grid item xs={12} sm={6} lg={6} className={classes.item}>
          <Card className={classes.card}>
            <ConditionCardActionArea withArea={collapsible} onClick={this.handleExpandClick}>
              <CardHeader
                title={
                  <Typography variant='h6' className={classes.headerText}>
                    <FormattedMessage id='mainPage.disksInfo' defaultMessage='Disks info' />
                  </Typography>
                }
                className={classes.header}
              />
              <CardContent
                className={classnames(classes.cardContent, pointsToShowInRolled.length < 2 ? classes.minHeight : '')}
              >
                {pointsToShowInRolled.map(([name, { mountpoint, usage }]) => (
                  <ProgressBar
                    key={name}
                    content={mountpoint}
                    title={name}
                    label={freeSpaceLabel(getSize(usage.available), getSize(usage.size))}
                    value={usage.usage}
                  />
                ))}
                {collapsible && (
                  <Collapse in={this.state.expanded}>
                    <div className={classes.collapsibleContent}>
                      {restPoints.map(([name, { mountpoint, usage }]) => (
                        <ProgressBar
                          key={name}
                          content={mountpoint}
                          title={name}
                          label={freeSpaceLabel(getSize(usage.available), getSize(usage.size))}
                          value={usage.usage}
                        />
                      ))}
                    </div>
                  </Collapse>
                )}
              </CardContent>
            </ConditionCardActionArea>
            <CardActions
              className={classnames(classes.actions, collapsible && classes.collapsibleActions)}
              disableSpacing
            >
              {showSmart && (
                <Button color='primary' onClick={this.handleSmartClick} className={classes.button}>
                  <Badge
                    overlap='rectangular'
                    color='error'
                    variant='dot'
                    badgeContent='!'
                    invisible={problemDisksCount === 0}
                    classes={{ badge: classes.badge }}
                  >
                    S.M.A.R.T.
                  </Badge>
                </Button>
              )}
              {showRaid && (
                <Button color='primary' onClick={this.handleRaidClick} className={classes.button}>
                  <Badge
                    overlap='rectangular'
                    color='error'
                    variant='dot'
                    badgeContent='!'
                    invisible={!raidError}
                    classes={{ badge: classes.badge }}
                  >
                    RAID
                  </Badge>
                </Button>
              )}
              {collapsible && (
                <IconButton
                  className={classnames(
                    classes.expand,
                    {
                      [classes.expandOpen]: this.state.expanded,
                    },
                    classes.iconButton
                  )}
                  onClick={this.handleExpandClick}
                  aria-expanded={this.state.expanded}
                >
                  <ExpandLessIcon />
                </IconButton>
              )}
            </CardActions>
          </Card>
        </Grid>
        <SmartDialog open={this.state.smartDialogOpen} onClose={this.handleCloseSmartDialog} smart={disksInfo.smart} />
        <ResponsiveDialog
          open={this.state.raidDialogOpen}
          onClose={this.handleCloseRaidDialog}
          closeMessage={<FormattedMessage id='ResponsiveDialog.close' defaultMessage='Close' />}
          title='RAID'
          message={<span className={classes.raid}>{disksInfo.mdstat}</span>}
          maxWidth='md'
          fullWidth
          withoutConfirm
        />
      </Fragment>
    );
  }
}

export default withStyles(styles)(DisksWidget);
