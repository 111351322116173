import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { FormattedMessage } from 'react-intl';

export default function InterfaceSelectForm(props) {
  const { classes, config, name, propsIfaces, handleEditVlan } = props;
  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor='vlan-interface'>
        <FormattedMessage id='networkPage.networkInterface' defaultMessage='Network interface' />
      </InputLabel>
      <Select value={config.ifaces[0]} onChange={handleEditVlan(name, 'ifaces')} inputProps={{ id: 'vlan-interface' }}>
        {Object.values(propsIfaces)
          .filter(({ name, type, config }) => !name.includes('tun') && type !== 'vlan' && (!config || !config.bond))
          .sort((a, b) => {
            let a_ = a.name.toLowerCase();
            let b_ = b.name.toLowerCase();

            if (a_ < b_) return -1;
            if (a_ > b_) return 1;
            return 0;
          })
          .map(({ name }) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText />
    </FormControl>
  );
}
