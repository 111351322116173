import { fetcher } from '../../util/deps';
import { loadTimeIssues } from '../../util/check-time';
import { SET_ISSUES } from '../constants';

const loadIssues = () => async dispatch => {
  let [issues, time] = await Promise.all([fetcher.get('issues', undefined, false), loadTimeIssues()]);
  let realIssues = [...(issues || []), ...time];
  dispatch(setIssues(realIssues));
};

const setIssues = issues => {
  const type = SET_ISSUES;
  return { type, payload: issues };
};

export default {
  loadIssues,
};
