import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

export default createSvgIcon(
  <g>
    <path
      d={
        'M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 ' +
        '4,21.1 4,20V4C4,2.89 4.89,2 6,2M11,4H6V20H11L18,20V11H11V4Z'
      }
    />
  </g>,
  'file outline'
);
