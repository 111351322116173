import { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import classnames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fill: theme.palette.text.primary,
    dominantBaseline: 'central', // necessary for firefox compatibility
  },
  grey: {
    fill: 'rgba(120,120,120,0.52)',
  },
  load: {
    transform: 'rotate(-90deg)',
    transition: 'stroke-dashoffset 0.4s linear, stroke 0.4s linear',
  },
}));

const rgb = {
  green: '92,184,92',
  yellow: '240,173,78',
  red: '217,83,79',
  grey: '120,120,120',
};

interface LoadIndicatorProps {
  disabled?: boolean;
  thickness?: number;
  size?: number;
  percentage: number;
  isRam?: boolean;
  isSwap?: boolean;
}

const LoadIndicator: FC<LoadIndicatorProps> = ({ disabled, thickness = 10, size = 40, percentage, isRam, isSwap }) => {
  const classes = useStyles();
  const isDetermined = typeof percentage === 'number';
  percentage = isDetermined ? percentage : 0;
  const SIZE = 100;
  const cx = SIZE / 2,
    cy = SIZE / 2,
    r = SIZE / 2 - 10;
  const circumference = 2 * Math.PI * r;
  const offset = ((100 - percentage) / 100) * circumference;
  const loadStyle = {
      strokeDashoffset: `${offset}px`,
      strokeDasharray: `${circumference}px`,
    },
    rootStyle = { width: size, height: size };

  const color = isSwap
    ? rgb.grey
    : percentage < (isRam ? 70 : 60)
      ? rgb.green
      : percentage < (isRam ? 90 : 80)
        ? rgb.yellow
        : rgb.red;
  const strokeUpper = `rgba(${color}, 1)`;
  const strokeLower = disabled ? `rgba(${rgb.grey},0.32)` : `rgba(${color}, 0.12)`;

  return (
    <div style={rootStyle}>
      <svg viewBox={`-${cx} -${cy} ${SIZE} ${SIZE}`}>
        <circle stroke={strokeLower} r={r} fill='none' strokeWidth={thickness} />
        <circle
          key={isDetermined ? 'determined' : 'non-determined'}
          stroke={strokeUpper}
          className={classes.load}
          style={loadStyle}
          r={r}
          fill='none'
          strokeWidth={thickness}
        />
        <text
          className={classnames(classes.text, disabled && classes.grey)}
          alignmentBaseline='central'
          textAnchor='middle'
          fontSize={SIZE / 4}
        >
          {disabled ? 'N/A' : percentage.toFixed(0) + '%'}
        </text>
      </svg>
    </div>
  );
};

export default LoadIndicator;
