import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import { withStyles } from '@material-ui/core/styles';

import { infoActions, modulesActions, servicesActions } from '../redux-stuff/actions';
import { getActiveModules } from '../util/modulesHelpers';
import {
  getModulePackages,
  getFirmwarePackage,
  getBrokenPackages,
  getNextModules,
  filterUnchangedIssues,
} from '../util/semverUtils';
import ResponsiveDialog from './responsive-dialog';
import SemverTable from './SemverTable';
import ConfirmPhrase from './ConfirmPhrase';
import { Typography } from '@material-ui/core';

const styles = () => ({});

class SemverDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { phraseEntered: false };
  }

  setPhraseEntered = () => this.setState({ phraseEntered: true });
  resetPhraseEntered = () => this.setState({ phraseEntered: false });

  componentDidMount() {
    this.props.build || this.props.loadInfo();
    this.props.modules || this.props.loadModules();
    this.props.services || this.props.loadServices();
  }

  static mapStateToProps = store => ({
    build: store.info.build,
    modules: store.modules.modules,
    services: store.services.services,
  });

  render() {
    let {
      nextFirmware,
      modulesToUpdate,
      modulesToRemove,

      build,
      modules,
      services,

      oldFirmware,
      oldVersionFirmware,

      open,
      title,
      message,
      confirmButtonText,
      onClose,
      onConfirm,
      withSpinner,
      disableConfirm,
      pendingBackendEvent,
      closeMessage,
      dontVanish,
      forceSpinner,
      fullWidth,
      closeOnConfirm,
    } = this.props;

    if (!modulesToUpdate) {
      modulesToUpdate = [];
    } else if (!Array.isArray(modulesToUpdate)) {
      modulesToUpdate = [modulesToUpdate];
    }

    if (!modulesToRemove) {
      modulesToRemove = [];
    } else if (!Array.isArray(modulesToRemove)) {
      modulesToRemove = [modulesToRemove];
    }

    let packages = {};
    let nextPackages = {};
    let nextBrokenPackagesFiltered = [];
    if (build && modules && services) {
      // versions before action
      let firmwarePackage = getFirmwarePackage(build);
      // wait until firmware loads
      if (firmwarePackage) {
        // active === docker image instantiated
        let activeModules = getActiveModules(modules, services);
        let modulePackages = getModulePackages(activeModules);
        packages = {
          ...firmwarePackage,
          ...modulePackages,
        };

        let nextFirmwarePackage =
          isEmpty(nextFirmware) || nextFirmware.corrupted ? firmwarePackage : getFirmwarePackage(build, nextFirmware);
        let nextActiveModules = getNextModules(activeModules, { upgrade: modulesToUpdate, remove: modulesToRemove });
        let nextModulePackages = getModulePackages(nextActiveModules);
        nextPackages = {
          ...nextFirmwarePackage,
          ...nextModulePackages,
        };

        let brokenPackages = getBrokenPackages(packages);
        let nextBrokenPackages = getBrokenPackages(nextPackages);
        nextBrokenPackagesFiltered = Object.entries(filterUnchangedIssues(brokenPackages, nextBrokenPackages));
      }
    }

    let issuesFound = !!nextBrokenPackagesFiltered.length;

    return (
      <ResponsiveDialog
        closeMessage={closeMessage}
        dontVanish={dontVanish}
        forceSpinner={forceSpinner}
        fullWidth={fullWidth}
        closeOnConfirm={closeOnConfirm}
        open={open}
        title={title}
        message={message}
        confirmButtonText={confirmButtonText}
        onClose={onClose}
        onConfirm={onConfirm}
        withSpinner={!!withSpinner}
        pendingBackendEvent={pendingBackendEvent}
        onExited={this.resetPhraseEntered}
        disableConfirm={
          disableConfirm || ((oldFirmware || issuesFound || oldVersionFirmware) && !this.state.phraseEntered)
        }
      >
        {this.props.alerts}
        {issuesFound && (
          <SemverTable
            packages={packages}
            nextPackages={nextPackages}
            nextBrokenPackages={nextBrokenPackagesFiltered}
          />
        )}
        {(oldFirmware || issuesFound || oldVersionFirmware) && (
          <ConfirmPhrase
            phraseEntered={this.state.phraseEntered}
            setPhraseEntered={this.setPhraseEntered}
            resetPhraseEntered={this.resetPhraseEntered}
            message={
              <>
                {(oldFirmware || oldVersionFirmware) && (
                  <Typography paragraph>
                    <FormattedMessage
                      id='semver.ConfirmationPhrase.oldFirmware'
                      defaultMessage={`You're about to install an older firmware version, we do not recommend this`}
                      key='oldFirmware'
                    />
                  </Typography>
                )}
                {issuesFound && (
                  <Typography paragraph>
                    <FormattedMessage
                      id='semver.ConfirmationPhrase.issuesFound'
                      defaultMessage={`Some constraints are not met, this may lead to certain components' malfunction`}
                      key='issuesFound'
                    />
                  </Typography>
                )}
              </>
            }
          />
        )}
        {this.props.content}
      </ResponsiveDialog>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(SemverDialog.mapStateToProps, {
    ...infoActions,
    ...modulesActions,
    ...servicesActions,
  })
)(SemverDialog);
