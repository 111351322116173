import { ComponentType, FC } from 'react';
import {
  AppBar,
  IconButton,
  IconButtonProps,
  Theme,
  Toolbar,
  ToolbarProps,
  Typography,
  TypographyProps,
  makeStyles,
} from '@material-ui/core';
import { ContentCopy } from '@/components/customIcons';
import { ArrowBack, Check } from '@material-ui/icons';
import classNames from 'classnames';
import ButtonWithProgress from '@/components/ButtonWithProgress';

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    position: 'relative',
  },
  appBarColored: {
    backgroundColor: theme.palette.appBarBackgroundColor,
  },
  toolbar: {
    padding: `0 ${theme.spacing(1.5)}px`,
  },
  toolBarTypography: {
    flex: 1,
  },
  cancelButton: {
    marginRight: theme.spacing(1),
  },
}));

export interface FullscreenDialogTitleProps {
  children: TypographyProps['children'];
  colored?: boolean;
  spinnerIsActive?: boolean;
  forceSpinner?: boolean;
  disableButtons?: boolean;
  withoutConfirm?: boolean;
  disableConfirm?: boolean;
  ConfirmIcon?: ComponentType;
  handleConfirm: IconButtonProps['onClick'];
  onClose: IconButtonProps['onClick'];
  onCopy: IconButtonProps['onClick'];
  buttons: ToolbarProps['children'];
}

const FullscreenDialogTitle: FC<FullscreenDialogTitleProps> = ({
  children,
  colored,
  spinnerIsActive,
  forceSpinner,
  disableButtons,
  withoutConfirm,
  disableConfirm,
  ConfirmIcon,
  handleConfirm,
  onClose,
  onCopy,
  buttons,
}) => {
  const classes = useStyles();

  return (
    <AppBar className={classNames(classes.appBar, colored && classes.appBarColored)}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color='inherit'
          onClick={onClose}
          disabled={spinnerIsActive}
          aria-label='Close'
          className={classes.cancelButton}
        >
          <ArrowBack />
        </IconButton>
        <Typography variant='h6' color='inherit' className={classes.toolBarTypography}>
          {children}
        </Typography>
        {!disableButtons && !withoutConfirm && !buttons && (
          <ButtonWithProgress showProgress={spinnerIsActive || forceSpinner}>
            <IconButton
              color='inherit'
              onClick={handleConfirm}
              disabled={spinnerIsActive || disableConfirm}
              aria-label='Ok'
            >
              {ConfirmIcon ? <ConfirmIcon /> : <Check />}
            </IconButton>
          </ButtonWithProgress>
        )}
        {buttons}
        {onCopy && (
          <IconButton color='inherit' onClick={onCopy}>
            <ContentCopy />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default FullscreenDialogTitle;
