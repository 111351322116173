import { SET_ACTIONS, RESET_ACTIONS } from '../constants';

const setActions = actions => {
  const type = SET_ACTIONS,
    payload = actions;

  return { type, payload };
};

const resetActions = () => {
  const type = RESET_ACTIONS;
  return { type };
};

export default {
  setActions,
  resetActions,
};
