import { fetcher } from '../../util/deps';
import { SET_INFO, SET_INFO_BUILD, SET_INFO_SYSTEM, SET_INFO_ALIAS } from '../constants';

const setInfo = info => ({ type: SET_INFO, payload: info });

const setInfoBuild = build => ({ type: SET_INFO_BUILD, payload: build });

const setInfoSystem = system => ({ type: SET_INFO_SYSTEM, payload: system });

const setInfoAlias = alias => ({ type: SET_INFO_ALIAS, payload: alias });

const loadInfo = () => async dispatch => {
  let data = await fetcher.get('info', undefined, false);
  if (data) {
    dispatch(setInfo(data));
  }
};

const updateAlias = name => async dispatch => {
  const status = await sendAlias(name);

  // status && backend says ok
  if (!status) {
    return; // throw
  }

  dispatch({ type: SET_INFO_ALIAS, payload: name });
};

const sendAlias = async alias => {
  const status = await fetcher.put('info/alias', { alias }, false);
  return status;
};

export default {
  setInfo,
  setInfoBuild,
  setInfoSystem,
  setInfoAlias,
  loadInfo,
  updateAlias,
};
