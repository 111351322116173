import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import Done from '@material-ui/icons/Done';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';

class Progress extends React.Component {
  render() {
    const { percentage, processing, finished, handleInstall, installAvailable } = this.props;

    return (
      <div className='dropzone'>
        <div className='dropzone-contents'>
          {finished && (
            <Fragment>
              <DialogContentText>
                <FormattedMessage id='fileuploader.completed' defaultMessage='Upload complete' />
              </DialogContentText>

              <Done color='action' className='icon' />

              {handleInstall && (
                <Button onClick={handleInstall} className='dropzone-button'>
                  {installAvailable ? (
                    <FormattedMessage id='fileuploader.install' defaultMessage='Install' />
                  ) : (
                    <FormattedMessage id='fileuploader.details' defaultMessage='Details' />
                  )}
                </Button>
              )}
            </Fragment>
          )}

          {!finished && (
            <Fragment>
              <Typography>
                {processing ? (
                  <FormattedMessage id='fileuploader.processing' defaultMessage='Processing..' />
                ) : (
                  <FormattedMessage id='fileuploader.uploading' defaultMessage='Uploading..' />
                )}
              </Typography>

              <div className={'progress-container'}>
                {isNaN(percentage) || processing ? (
                  <CircularProgress variant='indeterminate' size={65} />
                ) : (
                  <CircularProgress variant='determinate' size={65} value={percentage} />
                )}

                {!isNaN(percentage) && !processing && (
                  <DialogContentText className='progress-container-percentage'>
                    {percentage.toFixed(0)}%
                  </DialogContentText>
                )}
              </div>
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default Progress;
