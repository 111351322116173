import ipaddr, { IPv4 } from 'ipaddr.js';

const isIp = (ip: string) => !/^\d+$/.exec(ip) && ipaddr.isValid(ip);

const isIpv4 = (ip: string) => isIp(ip) && ipaddr.IPv4.isIPv4(ip);

const isIpv6 = (ip: string) => isIp(ip) && ipaddr.IPv6.isIPv6(ip);

const isIpv4Mask = (mask: string) => mask !== '' && !isNaN(+mask) && +mask >= 0 && +mask <= 32;

const isIpv6Mask = (mask: string) => mask !== '' && !isNaN(+mask) && +mask >= 0 && +mask <= 128;

const isValid = (type: 'ipv4' | 'ipv6' | 'ipv4/mask' | 'ipv6/mask', payload: string) => {
  switch (type) {
    case 'ipv4':
      return isIpv4(payload);
    case 'ipv6':
      return isIpv6(payload);
    case 'ipv4/mask': {
      const [ip, mask = ''] = payload.split('/');
      return isIpv4(ip) && isIpv4Mask(mask) && `${ip}/${mask}` === payload;
    }
    case 'ipv6/mask': {
      const [ip, mask = ''] = payload.split('/');
      return isIpv6(ip) && isIpv6Mask(mask) && `${ip}/${mask}` === payload;
    }
    default:
      return new Error('isValid function error: Incorrect type');
  }
};

const getIpVersion = (ip: string) => (isIpv6(ip) ? 'ipv6' : isIpv4(ip) ? 'ipv4' : '');

const isIpv4Multicast = (address: ipaddr.IPv4, bits: number) => {
  return bits >= 4 && (address.toByteArray()[0] & 240) === 224;
};

const isIpv6Multicast = (address: ipaddr.IPv6, bits: number) => {
  return bits >= 8 && address.toByteArray()[0] === 255;
};

const isMulticastRange = (cidr: string) => {
  try {
    let [address, bytes] = ipaddr.parseCIDR(cidr);
    if (address instanceof ipaddr.IPv4) {
      return isIpv4Multicast(address, bytes);
    } else {
      return isIpv6Multicast(address, bytes);
    }
  } catch (error) {
    return false;
  }
};

const isIpv4Match = (ip: string, net: string) => {
  const addr = ipaddr.parse(ip);
  const parsedNet = ipaddr.parseCIDR(net);
  return addr.kind() === 'ipv4' && parsedNet[0].kind() === 'ipv4' && (addr as IPv4).match(parsedNet as [IPv4, number]);
};

// returns: undefined if any ip is not valid, 0 if ips equials, -1 if first < second, 1 if first > second
const compareIpv4 = (first: string, second: string) => {
  if (!isIpv4(first) || !isIpv4(second)) {
    return undefined;
  }
  const firstOctets = first.split('.');
  const secondOctets = second.split('.');
  for (let i = 0; i < 4; i += 1) {
    if (firstOctets[i] === secondOctets[i]) {
      continue;
    }
    return +firstOctets[i] < +secondOctets[i] ? -1 : 1;
  }
  return 0;
};

export { isIp, isIpv4, isIpv6, isValid, getIpVersion, isMulticastRange, isIpv4Match, compareIpv4 };
