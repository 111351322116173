import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Paper from '@material-ui/core/Paper';
import FileUpload from '../components/customIcons/FileUpload';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import compose from 'recompose/compose';
import classnames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import CustomFab from '../components/CustomFab';
import FileUploader from '../components/FileUploader';
import { parsePermissions } from '../util/permissions';
import { licenseActions } from '../redux-stuff/actions';
import TableToolbar from '../components/TableToolbar';
import { getComponentState } from '../util/licenseHelpers';
import PageLoadingIndicator from '../components/PageLoadingIndicator';
import Alerts from '../components/Alerts';

const styles = theme => ({
  root: {
    margin: 'auto',
    maxWidth: 1100,
  },
  mobileRoot: {
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    height: `calc(100vh - 56px)`,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: `calc(100vh - 48px)`,
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100vh - 64px)`,
    },
  },
  filenameCell: {
    [theme.breakpoints.only('xs')]: {
      width: 'calc(100vw - 72px)',
      paddingLeft: theme.spacing(1),
    },
  },
  tableRow: {
    cursor: 'default',
    '&:hover': {
      backgroundColor: theme.palette.table.hover,
    },
    '& .more-button': {
      display: 'none',
    },
    '&:hover .more-button': {
      display: 'inherit',
    },
  },
  paper: {
    position: 'relative',
    width: '100%',
  },
  table: {
    tableLayout: 'fixed',
    '& td': {
      overflowWrap: 'break-word',
      wordBreak: 'break-word',
    },
  },
  resetTable: {
    tableLayout: 'fixed',
    '& tr': {
      height: 'initial',
    },
    '& td': {
      border: 0,
      padding: '1px 2px !important',
      overflowWrap: 'break-word',
      wordBreak: 'break-word',
    },
    '& td:first-child': {
      color: theme.palette.text.secondary,
    },
  },
  limitsTable: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
    },
    '& tr:nth-child(even)': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
  },
  badge: {
    top: '50%',
    right: -theme.spacing(2),
  },
  badgeContent: {
    width: 12,
    textAlign: 'center',
  },
});

const expireTooltipTitle = (state, isGlobal) => {
  return (
    <FormattedMessage
      id='licensePage.componentExpiresOrExpired'
      defaultMessage={
        'License {isGlobal, select, true {} other {for this component }}{state, select, expires {will soon expire} expired {has expired}}'
      }
      values={{ state, isGlobal }}
    />
  );
};

const dateFromTill = (from, till, xs) => {
  return (
    <FormattedMessage
      id='licensePage.validityPeriod.fromTill'
      defaultMessage='{xs, select, true {Validity period: } other {}}{from} - {till}'
      values={{
        from: from,
        till: till,
        xs: xs,
      }}
    />
  );
};

function formatValue(value) {
  if (value instanceof Array) {
    return value.map((item, i) => <div key={i}>{item}</div>);
  } else if (typeof value === 'boolean') {
    return value ? (
      <FormattedMessage id='license.limit.enabled' defaultMessage='enabled' />
    ) : (
      <FormattedMessage id='license.limit.disabled' defaultMessage='disabled' />
    );
  } else {
    return value;
  }
}

class LicensePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      uploadDialogOpen: false,
      permissions: parsePermissions(this.props.permissions, 'license'),
    };
  }

  openFileUploader = () => this.state.dialogFrozen || this.setState({ uploadDialogOpen: true });
  closeFileUploader = () => this.state.dialogFrozen || this.setState({ uploadDialogOpen: false });

  // drag events won't toggle dialog
  openFileUploaderFreeze = () => this.setState({ uploadDialogOpen: true, dialogFrozen: true });
  closeFileUploaderUnfreeze = () => this.setState({ uploadDialogOpen: false, dialogFrozen: false });

  componentDidMount() {
    const { dispatch } = this.props;

    this.actions = {
      ...bindActionCreators(licenseActions, dispatch),
    };

    this.actions.loadLicense();
  }

  static mapStateToProps({ license }) {
    return { license };
  }

  render() {
    const { width, classes, license, addMessage, intl } = this.props;
    const { permissions, uploadDialogOpen } = this.state;

    const { state: licenseState = {} } = license || {};

    const { w } = permissions;
    const { loadLicense } = this.actions || {};

    const xs = width === 'xs';
    const sm = width === 'sm';
    const smDown = xs || sm;
    const mdUp = !xs && !sm;
    const mobile = !mdUp || isMobile;

    const licenseError = licenseState.revoked || licenseState.expires || licenseState.expired;
    const licenseErrorBadgeTitle = licenseState.revoked ? (
      <FormattedMessage id='licensePage.tableToolbar.error.revoked' defaultMessage='License has been revoked' />
    ) : (
      expireTooltipTitle(licenseState.expires ? 'expires' : 'expired', true)
    );

    return (
      <Fragment>
        <div className={classnames(classes.root, mobile ? classes.mobileRoot : '')}>
          <Fragment>
            <Alerts issuesPermission={parsePermissions(this.props.permissions, 'issues').r} />
            <PageLoadingIndicator open={license === null} />

            {license && (
              <Paper className={classes.paper}>
                {license.information && (
                  <TableToolbar subheading={'license'} xs={xs} isMobile={isMobile}>
                    <Table className={classnames(classes.resetTable)} size='small'>
                      <colgroup>
                        <col style={{ width: '140px' }} />
                        <col style={{ width: 'auto' }} />
                      </colgroup>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <FormattedMessage
                              id='licensePage.tableToolbar.licenseIssuedTo'
                              defaultMessage='License issued to'
                            />
                          </TableCell>
                          <TableCell dangerouslySetInnerHTML={{ __html: license.information.subject }} />
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            <FormattedMessage
                              id='licensePage.tableToolbar.licenseNumber'
                              defaultMessage='License number'
                            />
                          </TableCell>
                          <TableCell>{license.information.serial}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            <FormattedMessage
                              id='licensePage.tableToolbar.validityPeriod'
                              defaultMessage='Validity period'
                            />
                          </TableCell>
                          <TableCell>
                            <Badge
                              overlap='rectangular'
                              color='error'
                              invisible={!licenseError}
                              badgeContent={
                                <Tooltip title={licenseErrorBadgeTitle}>
                                  <span className={classes.badgeContent}>!</span>
                                </Tooltip>
                              }
                              classes={{ badge: classes.badge }}
                            >
                              {dateFromTill(
                                intl.formatDate(license.information.validity.notBefore),
                                intl.formatDate(license.information.validity.notAfter)
                              )}
                            </Badge>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableToolbar>
                )}

                {!isEmpty(license.components) && (
                  <Table size='small' className={classes.table}>
                    {!smDown && (
                      <colgroup>
                        <col style={{ width: '38%' }} />
                        <col style={{ width: '20%' }} />
                        <col style={{ width: '42%' }} />
                      </colgroup>
                    )}
                    {!xs && (
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Typography variant='caption'>
                              <FormattedMessage id='licensePage.tableHeading.Name' defaultMessage='Name' />
                            </Typography>
                          </TableCell>

                          {!smDown && (
                            <TableCell>
                              <Typography variant='caption'>
                                <FormattedMessage
                                  id='licensePage.tableHeading.validityPeriod'
                                  defaultMessage='Validity period'
                                />
                              </Typography>
                            </TableCell>
                          )}
                          <TableCell>
                            <Typography variant='caption'>
                              <FormattedMessage id='licensePage.tableHeading.Limits' defaultMessage='Limits' />
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    )}

                    <TableBody>
                      {license.components.map(component => {
                        const { title, serial, validity, limits, revoked } = component;

                        const state = getComponentState(component);

                        const limitsTable = !isEmpty(limits) && (
                          <Table padding='none' className={classnames(classes.resetTable, classes.limitsTable)}>
                            <colgroup>
                              <col style={{ width: '66%' }} />
                              <col style={{ width: '33%' }} />
                            </colgroup>
                            <TableBody>
                              {limits.map(({ name, value }) => (
                                <TableRow key={`${name}${value}`}>
                                  <TableCell>{name}</TableCell>
                                  <TableCell>{formatValue(value)}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        );

                        const showWarningBadge =
                          !licenseState.expired && !licenseState.revoked && (state !== 'ok' || revoked);
                        const badgeMessage = revoked ? (
                          <FormattedMessage
                            id='licensePage.licenseRevoked'
                            defaultMessage='License for this component has been revoked'
                          />
                        ) : (
                          expireTooltipTitle(state, false)
                        );
                        const warningBadge = (
                          <Badge
                            overlap='rectangular'
                            color='error'
                            invisible={!showWarningBadge}
                            badgeContent={
                              <Tooltip title={badgeMessage}>
                                <span className={classes.badgeContent}>!</span>
                              </Tooltip>
                            }
                            classes={{ badge: classes.badge }}
                          >
                            <Typography>
                              {dateFromTill(
                                intl.formatDate(validity.notBefore),
                                intl.formatDate(validity.notAfter),
                                xs
                              )}
                            </Typography>
                          </Badge>
                        );

                        return (
                          <TableRow key={serial} className={classes.tableRow}>
                            <TableCell>
                              {xs && (
                                <Typography>
                                  <strong>{title}</strong> #{serial}
                                </Typography>
                              )}
                              {!xs && (
                                <Typography>
                                  {title} #{serial}
                                </Typography>
                              )}
                              {smDown && warningBadge}
                              {xs && limitsTable}
                            </TableCell>
                            {!smDown && <TableCell>{warningBadge}</TableCell>}
                            {!xs && <TableCell>{limitsTable}</TableCell>}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                )}
              </Paper>
            )}
          </Fragment>
        </div>

        {w && (
          <CustomFab
            label={<FormattedMessage id='licensePage.fabLabel.upload' defaultMessage='Upload' />}
            onClick={this.openFileUploaderFreeze}
            icon={FileUpload}
          />
        )}
        {w && (
          <FileUploader
            open={uploadDialogOpen}
            onClose={this.closeFileUploaderUnfreeze}
            onOpenDnD={this.openFileUploader}
            onCloseDnD={this.closeFileUploader}
            reloadFiles={loadLicense}
            addMessage={addMessage}
            resource='license'
            title={<FormattedMessage id='licenseUploader.title' defaultMessage='License upload' />}
            withOutLink
          />
        )}
      </Fragment>
    );
  }
}

LicensePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(LicensePage.mapStateToProps)(compose(withStyles(styles), withWidth(), injectIntl)(LicensePage));
