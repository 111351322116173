import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';
import compose from 'recompose/compose';
import { FormattedMessage } from 'react-intl';

import { getErrorMessage } from '../../util/getErrorMessage';
import Transition from '../util/DialogTransition';
import { fetcher } from '../../util/deps';

const styles = theme => ({
  formControl: {
    marginTop: theme.spacing(1),
  },
  appBar: {
    position: 'relative',
  },
  toolbar: {
    padding: `0 ${theme.spacing(1.5)}px`,
  },
  cancelButton: {
    marginRight: theme.spacing(1),
  },
  spacer: {
    height: theme.spacing(3),
  },
  toolBarTypography: {
    flex: 1,
  },
  error: {
    color: theme.palette.error.main,
  },
});

class AddNtpServerDialog extends React.Component {
  state = { host: '', error: null };

  handleChangeHost = event => {
    const host = event.target.value.trim().replace(',', '.');
    this.setState({
      host,
      error: this.props.addedHosts.includes(host) ? (
        <FormattedMessage id='addNtpDialog.hostAlreadyAdded' defaultMessage='Host already in list' />
      ) : null,
    });
  };

  handleAddNtpServer = async () => {
    try {
      await fetcher.post(`time/ntp/${this.state.host}`);
      this.props.handleClose();
      this.props.updateState();
    } catch (error) {
      this.setState({ error: getErrorMessage(error.message) });
      this.props.addMessage({ type: 'failure', data: error });
    }
  };

  handleKeyDown = event => {
    if (event.key === 'Enter' && !this.state.error && this.state.host.length > 0) {
      this.handleAddNtpServer();
    }
  };

  handleExited = () => this.setState({ host: '', error: null });

  render() {
    const { open, fullScreen, handleClose, classes } = this.props;
    const title = <FormattedMessage id='addNtpDialog.title' defaultMessage='Add NTP server' />;
    const disableAdd = !!this.state.error || this.state.host.length === 0;
    return (
      <div>
        <Dialog
          open={open}
          onKeyPress={this.handleKeyDown}
          onClose={handleClose}
          TransitionProps={{
            onExited: this.handleExited,
          }}
          fullScreen={fullScreen}
          TransitionComponent={fullScreen ? Transition : undefined}
          maxWidth='sm'
          fullWidth
        >
          {fullScreen && (
            <AppBar className={classes.appBar}>
              <Toolbar className={classes.toolbar}>
                <IconButton color='inherit' onClick={handleClose} aria-label='Close' className={classes.cancelButton}>
                  <CloseIcon />
                </IconButton>
                <Typography variant='h6' color='inherit' className={classes.toolBarTypography}>
                  {title}
                </Typography>
                <IconButton color='inherit' disabled={disableAdd} onClick={this.handleAddNtpServer} aria-label='Ok'>
                  <CheckIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
          )}
          {!fullScreen && <DialogTitle>{title}</DialogTitle>}
          {fullScreen && <div className={classes.spacer} />}
          <DialogContent>
            <DialogContentText>
              <FormattedMessage id='addNtpDialog.enterNtpHost' defaultMessage='Please enter NTP hostname' />
            </DialogContentText>
            <FormControl error={!!this.state.error} className={classes.formControl}>
              <InputLabel htmlFor='host'>
                <FormattedMessage id='addNtpDialog.host' defaultMessage='Hostname' />
              </InputLabel>
              <Input id='host' value={this.state.host} onChange={this.handleChangeHost} autoFocus />
              <FormHelperText>{this.state.error}</FormHelperText>
            </FormControl>
          </DialogContent>
          {!fullScreen && (
            <DialogActions>
              <Button onClick={handleClose} color='primary'>
                <FormattedMessage id='addNtpDialog.cancel' defaultMessage='Cancel' />
              </Button>
              <Button onClick={this.handleAddNtpServer} color='primary' disabled={disableAdd}>
                <FormattedMessage id='addNtpDialog.add' defaultMessage='Add' />
              </Button>
            </DialogActions>
          )}
        </Dialog>
      </div>
    );
  }
}

export default compose(withStyles(styles), withMobileDialog())(AddNtpServerDialog);
